.avatar {
	// border-radius: 2px;
	border-radius: 4px;
	display: inline-block;
	width: 30px;
	height: 30px;
	line-height: 0;

	.user-initials {
	    -webkit-user-select: none;
	    -moz-user-select: none;
	    user-select: none;
	    line-height: 30px;
	    color: #fff;
	    font-weight: 700;
	    text-align: center;
	    text-transform: uppercase;
	}

	img {
		line-height: 0;
	    position: relative;
		// border-radius: 2px;
		border-radius: 4px;
		display: inline-block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&.avatar-sm {
	    // width: 18px;
		// height: 18px;
		 width: 32px;
	    height: 32px;

	    .user-initials {
	    	line-height: 18px;
	    	font-size: 10px;
	    }
	}

	&.avatar-md {
	    width: 48px;
	    height: 48px;

	    .user-initials {
	    	line-height: 48px;
	    	font-size: 24px;
	    }
	}

	&.avatar-lg {
	    width: 64px;
	    height: 64px;

	    .user-initials {
	    	line-height: 64px;
	    	font-size: 32px;
	    }
	}	
	&.avatar-xl {
	    width: 140px;
	    height: 140px;

	    .user-initials {
	    	line-height: 64px;
	    	font-size: 32px;
	    }
	}	
}