/*meter module*/
.MuiAccordionSummary-root {
  width: 100%;
  display: inline-flex;
  padding: 0px 0px !important;
  .MuiAccordionSummary-content{
    width: 100%;
    .heading-container.meter-reading-heading{
      width: 100%;
      .btn-secondary{
        margin-right: 0 !important;
      }
    }
  }
}


.purple-btn, .gray-btn {
  min-width: 100px;
}
.meter-uploads-log-meter-found::-webkit-scrollbar {
  display: none !important;
}
.meter-uploads-log-meter-found {
  scrollbar-width: none !important;
}

.MuiSelect-select:focus{
  background: rgba(0,0,0,0) !important;
}

.mantine-TextInput-wrapper{
  border-color: #DADAE5 !important;
}
.MuiOutlinedInput-notchedOutline{
   border-color: #DADAE5 !important;//#3f51b5; 
   border-width: 1px !important; 
} 

.meterClass .meter-reading-section .data-section .meter-reading-table > tbody tr.table-data {
  box-shadow: none !important;
  background: #ffffff;
}
.meter-date-new-format {
  .react-datepicker__input-container {
    .input-group-focus {
      .custom-date-field {
        .input-group-focus {
          border: none !important;
          box-shadow: none !important;
        }
      }
    }
  }
}
// date set
.meter-date-new-format {
  .react-datepicker-wrapper {
    width: 120.891px !important;
    .react-datepicker__input-container {
      border: none !important;
      box-shadow: none !important;
      .input-group {
        border: none !important;
        position: relative;
        padding-left: 10px;
        .input-group-text {
          padding: 0px 1px 0px 10px;
        }
        .input-group-focus {
          border: none !important;
        }
        .input-group-prepend {
          position: absolute;
          // right: 0;
          right: 5px;
          z-index: 999;
          padding: 10px 0px 10px 0px;
        }
      }
    }
  }
}
.react-datepicker__input-container:hover {
  border: none !important;
  box-shadow: none !important;
}
// .search-meter-home {
//   opacity: 0px !important;
// }
.annual-reading-page-new {
  //   background-color: cornflowerblue;
  .meter-date-new-format {
    .react-datepicker-wrapper {
      width: 120.891px !important;
      .react-datepicker__input-container {
        > form {
          .input-group {
            border: none !important;
            position: relative;
            padding-left: 10px;
            .input-group-text {
              padding: 0px 1px 0px 10px !important;
            }
            .input-group-focus {
              border: none !important;
            }
            .input-group-prepend {
              position: absolute !important;
              right: 5 !important;
              z-index: 999 !important;
              padding: 10px 0px 10px 0px !important;
            }
          }
        }
      }
    }
  }
}
.readings-form {
  width: 100%;
  //
  border: 1px solid #dadae6 !important;
  margin-bottom: 0px !important;
  tbody {
    > tr {
      box-shadow: none !important;
    }
    > td {
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: #0e0e1d !important;
    }
    // input:focus {
    // }
    td:focus-within,
    .blue-border-input {
      background-color: #f2f2fc !important;
      // border: 2px solid #f2f2fc;
      box-sizing: border-box;
      box-shadow: none;
      input {
        background-color: #f2f2fc;
      }
      .react-datepicker-wrapper {
        .input-group-text {
          background-color: #f2f2fc !important;
        }
      }
    }

    input.form-control {
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      margin: 0px !important; // padding-right:0px!important;
      // padding-left:0px!important;
      font-family: Inter !important;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 20px !important;
      letter-spacing: 0em;
      text-align: left;
      color: #0e0e1d !important;
    }
    & .dis-input {
      background-color: white;
    }
    & td {
      input {
        color: #0e0e1d !important;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
      }
      padding: 0 !important;
    }

    & td.number {
      .input {
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    & td.type {
      // width: 18%;
      input {
        //padding-left: 4% !important;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    & td.date {
      // width: 11%;
      .react-datepicker-wrapper {
        width: 120.891px !important;
        .react-datepicker__input-container {
          .input-group {
            border: none !important;
            position: relative;
            padding-left: 10px;
            .input-group-text {
              padding: 0px 1px 0px 10px;
            }
            .input-group-prepend {
              position: absolute;
              right: 5;
              z-index: 4;
              padding: 10px 0px 10px 0px;
            }
          }
        }
      }
      input {
        padding-left: 0.8%;
      }
    }
    // & td.start {
    //   width: 9.5%;
    //   input {
    //     text-align: right;
    //   }
    // }
    // & td.current {
    //   width: 10.5%;
    //   input {
    //     text-align: right;
    //   }
    // }
    // & td.consumption {
    //   width: 10.5%;
    //   input {
    //     font-weight: 600;
    //     text-align: right;
    //   }
    // }
    // & td.unit {
    //   width: 6%;
    // }
    // & td.note {
    //   width: 112px;
    //   padding-right: 120px !important;
    //   .input {
    //     width: 115px;
    //   }
    // }
    // td:last-child {
    //   width: 10%;
    // }
  }
  // & th {
  //   border: 1px solid #dadae6 !important;
  //   text-transform: none !important;
  //   color: $colorLightPurple !important;
  //   width: 100% !important;
  //   //padding: 10px 0px !important; // padding-right: 0px !important;
  //   // padding-left: 0px !important;
  //   font-size: 14px !important;
  //   font-weight: normal !important;
  //   padding-left: 16px !important;
  //   padding-top: 10px;
  //   padding-bottom: 10px;
  //   padding-right: 5px !important;
  // }

  // & th:nth-child(1) {
  //   width: 10.2% !important;
  // }

  // & th:nth-child(2) {
  //   width: 18% !important;
  // }

  // & th:nth-child(3) {
  //   width: 113px !important;
  //   padding-left: 10px !important;
  // }

  // & th:nth-child(4) {
  //   width: 11% !important;
  // }

  // & th:nth-child(5) {
  //   width: 9.5% !important;
  //   padding-left: 10px !important;
  // }

  // & th:nth-child(6) {
  //   width: 10.5% !important;
  //   padding-left: 10px !important;
  // }

  // & th:nth-child(7) {
  //   width: 10.5% !important;
  // }

  // & th:nth-child(8) {
  //   width: 6% !important;
  // }
  // & th:nth-child(9) {
  //   width: 10% !important;
  // }
}
.annual-meter-reacord-headings {
  // background-color: pink !important;
  // &>tbody{
  // .form-control{
  //     width: 0px !important;
  //     border: none !important;
  // }
  & th {
    border: 1px solid #dadae6 !important;
    text-transform: none !important;
    color: $colorLightPurple !important;
    //padding: 10px 0px !important; // padding-right: 0px !important;
    // padding-left: 0px !important;
    font-size: 14px !important;
    font-weight: normal !important;
    padding-left: 16px !important;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 5px !important;
  }

  & th:nth-child(1) {
    width: 11.2% !important;
  }

  & th:nth-child(2) {
    width: 18% !important;
  }

  & th:nth-child(3) {
    width: 113px !important;
    padding-left: 10px !important;
  }

  & th:nth-child(4) {
    width: 11% !important;
  }

  & th:nth-child(5) {
    width: 9.5% !important;
    padding-left: 10px !important;
  }

  & th:nth-child(6) {
    width: 10.5% !important;
    padding-left: 10px !important;
  }

  & th:nth-child(7) {
    width: 10.5% !important;
  }

  & th:nth-child(8) {
    width: 6% !important;
  }
  & th:nth-child(9) {
    width: 10% !important;
  }
}

/* Meter main page */
@media only screen and (max-width: 2560px) {
  .meterClass {
    & .Meter-readings {
      & .heading-right-content {
        & .btn-group {
          background: #e9e9f0 !important;
          height: 36px;
          // width: 206px;
          width: auto;
          padding: 0px 2.1% !important;
          border-radius: 4px;
          display: flex;
          align-items: center;
          .btn-primary {
            border: none !important;
            outline: none !important;
            font-size: 14px;
            font-weight: 500;
          }
          button.switch-btn.reading-btn,
          button.switch-btn.master-btn {
            background: #e9e9f0;
            height: 26px; // padding: 6%;
            margin: 3% auto;
            padding-top: 1% !important;
            padding-bottom: 1% !important;
            border-radius: 4px;
          }
          button.switch-btn.reading-btn:focus,
          button.switch-btn.master-btn:focus {
            border: none !important;
            outline: none !important;
            box-shadow: none !important;
            height: 26px;
            margin: 3% auto; // padding: 6%;
          }
          .active {
            border: none;
            outline: none;
            background: white !important;
            color: $colorDarkPurple !important;
            padding-top: 1% !important; // padding: 2px auto!important;
            // margin:2px auto;
          }
          .false {
            color: $colorPurple1 !important;
          }
        }
      }
    }
    & .meter-reading-section {
      .emptyState {
        opacity: 1 !important;
      }
      & .filters {
        & .section-1 {
          & .meter-reading-search {
            margin-top: 2%;
          }
        }
        & .section-3 {
          & .meter-reading-sort {
            margin-top: 4%;
          }
        }
      }
      & .meter-reading-divider {
        margin: 15px 0px 18px !important;
      }
      & .data-section {
        & .meter-reading-table {
          padding-top: 0px !important;
          padding-bottom: 0px;
          margin: 0px;
          border-collapse: separate;
          border-spacing: 0px 8px;
          background: transparent;
          box-shadow: 0 0 0 0px #eceff2 !important;
          & > tbody {
            & tr.table-heading {
              td.unit {
                padding-left: 24px !important;
                // width: 200px;
              }
            }
            & tr.table-data {
              box-shadow: 0px 1px 2px $box-shadow;
              background: #ffffff;
              & td {
                padding: 19px 20px 19px 0;
              }
              td:nth-child(2) {
                img {
                  padding-right: 11px;
                }
              }
              td:nth-child(3) img {
                padding-right: 4.33px;
              }
              .value-text-meter-rental {
                padding-left: 24px !important;
                // width: 200px;
                width: 18%;
              }
              .value-text-meter-tenant {
                //  padding-left: 17%;
                // width: 299px;
                width: 20% !important;
              }
              td.value-text-occupency-dates {
                //  width: 243px;
                width: 26% !important;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              td.value-text-meter-ocassion {
                // padding-left: 13%;
                // width: 171px;
                width: 20% !important;
                > img {
                  padding-right: 3% !important;
                  margin-top: -3px;
                }
              }
              td.value-text-meter-readingDate {
                //padding-left: 1%;
                // width: 145px;
                width: 14% !important;
              }

              // td.value-text-meter-empty {
              // }
              .record-dropdown {
                background-color: transparent;
                background: transparent;
                box-shadow: none;
                border: none;
                width: 40px;
              }
              .edit-delete-dropdown {
                position: absolute !important;
                left: -60px !important;
                margin: 0px !important;
                width: 208px !important;
                box-shadow: none !important;//0px 8px 16px rgba(14, 14, 29, 0.1), 0px 1px 2px rgba(14, 14, 29, 0.06) !important;
                border-radius: 4px !important;
                border: 1px solid #dadae6 !important;
              }
            }
          }
        }
        & .meter-reading-heading {
          & .heading {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
          }
          & .heading-text-wrapper {
            margin-top: -2px !important;
          }
          & .meter-reading-buttons {
            height: 36px;
            width: 268px;
            margin-top: 0px;
            margin-bottom: 3%;
            & .annual-btn,
            .interim-btn {
              // height: 36px!important;
              padding: 0px !important;
              font-family: Inter !important;
              font-size: 14px !important;
              font-style: normal !important;
              font-weight: 500 !important;
              line-height: 20px !important;
              letter-spacing: 0em !important;
              max-width: 125px;
            }
            & .interim-btn {
              margin: 0px !important;
              margin-right: 8px !important;
              flex-basis: 10%;
              padding-left: 1.2% !important;
              padding-right: 1.2% !important;
            }
            & .annual-btn {
              margin: 0px !important;
              margin-right: 8px;
              flex-basis: 8%;
            }
            & .interim-transparent-btn {
              // background-color: transparent!important;
              color: $colorDarkPurple;
            }
          }
          & h5 > .year-badge.white {
            background-color: white;
            color: $colorLightPurple;
            font-size: 14px;
            font-weight: 500;
            margin-left: 1%;
          }
          & h5 > .year-badge.purple {
            background-color: $background-azure;
            color: $purpleTheme;
            font-size: 14px;
            font-weight: 500;
            margin-left: 1%;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1440px) {
  .meterClass {
    & .Meter-readings {
      & .heading-right-content {
        & .btn-group {
          background: #e9e9f0 !important;
          height: 36px;
          // width: 206px;
          width: auto;
          padding: 0px 2.1% !important;
          border-radius: 4px;
          display: flex;
          align-items: center;
          .btn-primary {
            border: none !important;
            outline: none !important;
            font-size: 14px;
            font-weight: 500;
          }
          button.switch-btn.reading-btn,
          button.switch-btn.master-btn {
            background: #e9e9f0;
            height: 26px; // padding: 6%;
            margin: 3% auto;
            padding-top: 1% !important;
            padding-bottom: 1% !important;
            border-radius: 4px;
          }
          button.switch-btn.reading-btn:focus,
          button.switch-btn.master-btn:focus {
            border: none !important;
            outline: none !important;
            box-shadow: none !important;
            height: 26px;
            margin: 3% auto; // padding: 6%;
          }
          .active {
            border: none;
            outline: none;
            background: white !important;
            color: $colorDarkPurple !important;
            padding-top: 1% !important; // padding: 2px auto!important;
            // margin:2px auto;
          }
          .false {
            color: $colorPurple1 !important;
          }
        }
      }
    }
    & .meter-reading-section {
      .emptyState {
        opacity: 1 !important;
      }
      & .filters {
        & .section-1 {
          & .meter-reading-search {
            margin-top: 2%;
          }
        }
        & .section-3 {
          & .meter-reading-sort {
            margin-top: 4%;
          }
        }
      }
      & .meter-reading-divider {
        margin: 15px 0px 18px !important;
      }
      & .data-section {
        & .meter-reading-table {
          padding-top: 0px !important;
          padding-bottom: 0px;
          margin: 0px;
          border-collapse: separate;
          border-spacing: 0px 8px;
          background: transparent;
          box-shadow: 0 0 0 0px #eceff2 !important;
          & > tbody {
            & tr.table-heading {
              td.unit {
                padding-left: 24px !important;
                // width: 200px;
              }
            }
            & tr.table-data {
              box-shadow: 0px 1px 2px $box-shadow;
              background: #ffffff;
              & td {
                padding: 19px 20px 19px 0;
              }
              td:nth-child(2) {
                img {
                  padding-right: 11px;
                }
              }
              td:nth-child(3) img {
                padding-right: 4.33px;
              }
              .value-text-meter-rental {
                padding-left: 24px !important;
                // width: 200px;
                width: 18%;
              }
              .value-text-meter-tenant {
                //  padding-left: 17%;
                // width: 299px;
                width: 20% !important;
              }
              td.value-text-occupency-dates {
                //  width: 243px;
                width: 26% !important;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              td.value-text-meter-ocassion {
                // padding-left: 13%;
                // width: 171px;
                width: 20% !important;
                > img {
                  padding-right: 3% !important;
                  margin-top: -3px;
                }
              }
              td.value-text-meter-readingDate {
                //padding-left: 1%;
                // width: 145px;
                width: 14% !important;
              }

              // td.value-text-meter-empty {
              // }
              .record-dropdown {
                background-color: transparent;
                background: transparent;
                box-shadow: none;
                border: none;
                width: 40px;
              }
              .edit-delete-dropdown {
                position: absolute !important;
                left: -60px !important;
                margin: 0px !important;
                width: 208px !important;
                box-shadow: none !important;//0px 8px 16px rgba(14, 14, 29, 0.1), 0px 1px 2px rgba(14, 14, 29, 0.06) !important;
                border-radius: 4px !important;
                border: 1px solid #dadae6 !important;
              }
            }
          }
        }
        & .meter-reading-heading {
          & .heading {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
          }
          & .heading-text-wrapper {
            margin-top: -2px !important;
          }
          & .meter-reading-buttons {
            height: 36px;
            width: 268px;
            margin-top: 0px;
            margin-bottom: 3%;
            & .annual-btn,
            .interim-btn {
              // height: 36px!important;
              padding: 0px !important;
            }
            & .interim-btn {
              margin: 0px !important;
              margin-right: 8px !important;
              flex-basis: 10%;
              padding-left: 1.2% !important;
              padding-right: 1.2% !important;
            }
            & .annual-btn {
              margin: 0px !important;
              margin-right: 8px;
              flex-basis: 8%;
            }
            & .interim-transparent-btn {
              // background-color: transparent!important;
              color: $colorDarkPurple;
            }
          }
          & h5 > .year-badge.white {
            background-color: white;
            color: $colorLightPurple;
            font-size: 14px;
            font-weight: 500;
            margin-left: 1%;
          }
          & h5 > .year-badge.purple {
            background-color: $background-azure;
            color: $purpleTheme;
            font-size: 14px;
            font-weight: 500;
            margin-left: 1%;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .meterClass {
    & .Meter-readings {
      flex-wrap: wrap !important;
      & .heading-text-wrapper {
        flex-basis: 100%;
        order: 2;
        margin-top: 4% !important;
        .heading {
          margin: 1% !important;
        }
      }
      & .heading-right-content {
        width: 343px;
        & .btn-group {
          width: auto;
          button.switch-btn.reading-btn,
          button.switch-btn.master-btn {
            margin: 1.5% auto;
          }
          button.switch-btn.reading-btn:focus,
          button.switch-btn.master-btn:focus {
            margin: 1.5% auto;
          }
        }
      }
    }
    & .data-section {
      & .heading-container {
        width: 100vw;
        flex-wrap: wrap;
      }
      & .meter-reading-heading {
        & .meter-reading-buttons {
          width: 342px;
        }
      }
      & .meter-reading-table {
        & > tbody {
          & tr.table-data {
            td.value-text-meter-rental {
              width: 88% !important;
            }
          }
        }
      }
    }
    & .filters {
      .section-3 {
        margin: 0px !important;
        & .meter-reading-sort {
          margin-top: 0px !important;
        }
      }
    }
  }
}
@media only screen and (max-width: 320px) {
  .meterClass .data-section .meter-reading-heading .meter-reading-buttons {
    width: 300px !important;
  }
}

/* Meter main page endd

/*Interim Reading page */
@media only screen and (max-width: 2560px) {
  .interim-meter-reading-page {
    .cost-type-menu-indivisual {
      display: flex;
      justify-content: space-between;
      width: 220px;
      font-size: 14px;
      background: #ffffff !important;
      border: 1px solid #dadae6;
      box-sizing: border-box;
      box-shadow: 0px 1px 2px rgba(14, 14, 29, 0.06) !important;
      border-radius: 4px;
    }

    .annual-dropdown {
      // width: 312px;
      width: 380px;
      font-size: 14px;
      display: flex;
      justify-content: space-around;
      background: #ffffff !important;
      border: 1px solid #dadae6;
      box-sizing: border-box;
      box-shadow: 0px 1px 2px rgba(14, 14, 29, 0.06) !important;
      border-radius: 4px;
      margin-right: 6px;
    }

    .tenant-subheading {
      display: inline;
      margin-left: 5%;
      margin-right: 2%;
      padding-right: 0.5%;
      border-right: 1px solid #dadae5;
    }

    .interim-meter-heading {
      font-size: 20px !important;
      font-weight: 600;
      line-height: 32px;
      margin-left: -8px;
    }
    .utitlity-annual-unit-heading {
      .heading-text-wrapper {
        .heading {
          font-size: 16px;
        }
      }
    }
    & .annual-reading-search {
      width: 352px;
      margin-left: -4px;
    }
    & .meter-record-btn {
      & .add-meter-btn {
        margin-top: 2.4%;
        margin-bottom: 2.3%;
        margin-left: 0px;
        margin-right: 0px;
      }
      margin-top: 1%;
    }
    .billing-dropdown {
      margin-left: 40.333%;
      margin-top: 1%;
      display: flex;
      justify-content: flex-end;
      .billing-dropdown-option {
        width: 300px;
      }
    }
    .annual-tenant {
      padding-left: 0px !important;
      font-size: 14px;
      font-weight: 400;
      color: $colorPurple1;
      margin-top: 0.5%;
      max-width: 21% !important;
    }
    .annual-occupency-dates-meter {
      padding-right: 2%;
      border-right: 1px solid #dadae5;
    }
    .annual-value {
      padding-left: 0px !important;
      font-size: 14px;
      font-weight: 500; // color: $colorPurple1;
      //   margin-top: 0.5%;
      max-width: 21% !important;
      margin-right: 1.8%;
      display: inline;
    }
    img.heading-icon {
      margin-right: 1% !important;
      margin-left: 0.8%;
    }
    img.heading-icon:hover {
      cursor: pointer;
    }
    & .heading-right-content {
      .btn-container {
        width: 153px;
        margin-right: -4px;
        margin-top: 2px;
        .cancel-btn {
          flex-basis: 5%;
          padding-right: 7%;
          padding-left: 7%;
          margin-left: 12px !important;
        }
        .save-btn {
          flex-basis: 5%;
          padding-right: 7%;
          padding-left: 7%;
          margin-left: 7% !important;
        }
      }
    }
    & .interim-row {
      padding: 0px !important;
      //margin-right: -33%;
      // margin-left: 1%;
      & option {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: $colorBlack !important;
      }
    }
    & .interim-unit-dropbox {
      max-width: 18%;
      margin-right: 0px !important;
      padding-right: 0px !important;
    }
    & .interim-tenant-dropbox {
      padding-left: 4px !important;
      max-width: 18%;
      margin-left: -14px !important ;
    }
    & .interim-occupancy {
      padding-left: 0px !important;
      font-size: 14px;
      font-weight: 400;
      color: $colorPurple1;
      margin-top: 0.5%;
      max-width: 21%;
      margin-right: 1.8%;
      & .interim-date {
        display: inline;
        font-weight: 500;
      }
    }
    & .interim-occasion-radio {
      margin-top: 0.5%;
      padding-left: 0px;
      display: flex;
      justify-content: flex-end;
      .form-check {
        .form-check-input[type='radio']:before {
          content: '';
          position: absolute;
          display: none;
        }
        .form-check-input[type='radio'] {
          -webkit-appearance: none;
          outline: none;
          background: $colorWhite;
          /* Oceanic Purple/200 */
          // position: absolute;
          width: 15px;
          height: 15px; //  left: 0px;
          //  top: 0px;
          border: 1px solid $colorGrey;
          border-radius: 8px;
        }
        .form-check-input[type='radio']:after {
          content: '';
          position: absolute;
          display: none;
        }
        .form-check-input[type='radio']:checked {
          background: linear-gradient(180deg, $blueForBg 0%, $purpleTheme 100%);
        }
        .form-check-input:after {
          content: '';
          position: absolute;
          display: none;
        }
        .form-check-input:checked:after {
          display: block;
          top: 3px;
          left: 2.5px;
          width: 9.5px;
          height: 9.5px;
          border-radius: 8px;
          background: $colorWhite;
          border: 1px solid $Border-blue;
        }
        .form-check-label {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          color: #0e0e1d;
        }
      }
      & .form-check label {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: $colorBlack !important;
      }
      label:nth-child(3) {
        padding: 0px !important;
      }
    }
    & .interim-readings-table,
    .annual-readings-table {
      border: 1px solid #dadae5 !important;
    }
    & #meterForm,
    & #annualForm,
    & .annualForm {
      // width:2225px;
      width: 100%;
      // margin-left: 0.7%;
      & .form-row {
      }
      & table.readings-form {
        width: 97.8% !important;
        //margin-left: 0.8% !important;

        tbody {
          & td {
            input {
              // font-size: 13px;
              font-size: 14px;
              color: #0e0e1d !important;
              font-family: Inter;
              // font-size: 14px;
              font-style: normal;
              font-weight: 400 !important;
              line-height: 20px;
              letter-spacing: 0em;
              text-align: left;
            }
          }
          // & td.number {
          //   width: 11%;
          // }
          // & td.type {
          //   width: 27.4%;
          //   input {
          //     padding-left: 7% !important;
          //   }
          // }
          & td.date {
            //  width: 13%;
            .react-datepicker-wrapper {
              //width: 120.891px !important;
              .react-datepicker__input-container {
                > form {
                  .input-group {
                    border: none !important;
                    position: relative;
                    padding-left: 10px;
                    .input-group-text {
                      padding: 0px 1px 0px 10px;
                    }
                    .input-group-prepend {
                      position: absolute;
                      // right: 0;
                      right: 5px;
                      z-index: 999;
                      padding: 10px 0px 10px 0px;
                    }
                  }
                }
              }
            }
            input {
              padding-left: 1.8%;
            }
          }
          // & td.start {
          //   width: 10%;
          //   input {
          //     text-align: right;
          //   }
          // }
          // & td.current {
          //   width: 11%;
          //   input {
          //     text-align: right;
          //   }
          // }
          // & td.consumption {
          //   width: 11%;
          //   input {
          //     text-align: right;
          //     font-weight: 600;
          //   }
          // }
          // & td.unit {
          //   width: 7%;
          //   input {
          //     text-align: center;
          //   }
          // }
          //   & td.note {
          //     width: 112px;
          //     padding-right: 120px !important;
          //     .input {
          //       width: 115px;
          //     }
          //   }
          // td:last-child {
          //   width: 10%;
          // }
        }
      }
    }
    & .meter-record-heading {
      .meter-date-new-format {
        .react-datepicker-wrapper {
          .react-datepicker__input-container {
            > form {
              .input-group {
                border: none !important;
                // .input-group-prepend {
                //     display: none !important;
                // }
              }
            }
          }
        }
      }
      height: 46px;
      align-items: center !important;
      background-color: #f7f7fa;
      // & th {
      //   border: 1px solid #dadae6 !important;
      //   text-transform: none !important;
      //   color: $colorLightPurple !important;
      //   //padding: 10px 0px !important; // padding-right: 0px !important;
      //   // padding-left: 0px !important;
      //   font-size: 14px !important;
      //   font-weight: normal !important;
      //   padding-left: 16px !important;
      //   padding-top: 10px;
      //   padding-bottom: 10px;
      //   padding-right: 5px !important;
      // }

      // & th:nth-child(1) {
      //   width: 13% !important;
      // }

      // & th:nth-child(2) {
      //   width: 226px !important;
      // }

      // & th:nth-child(3) {
      //   width: 113px !important;
      //   padding-left: 10px !important;
      // }

      // & th:nth-child(4) {
      //   width: 124.297px !important;
      // }

      // & th:nth-child(5) {
      //   width: 113px !important;
      //   padding-left: 10px !important;
      // }

      // & th:nth-child(6) {
      //   width: 12% !important;
      //   padding-left: 10px !important;
      // }

      // & th:nth-child(7) {
      //   width: 117px !important;
      // }

      // & th:nth-child(8) {
      //   width: 67.7969px !important;
      // }
      // & th:nth-child(9) {
      //   width: 124.328px !important;
      // }
    }
  }
}
@media only screen and (max-width: 1440px) {
  .interim-meter-reading-page {
    .cost-type-menu-indivisual {
      display: flex;
      justify-content: space-between;
      width: 220px !important;
      font-size: 14px;
      background: #ffffff !important;
      border: 1px solid #dadae6;
      box-sizing: border-box;
      box-shadow: 0px 1px 2px rgba(14, 14, 29, 0.06) !important;
      border-radius: 4px;
    }

    .annual-dropdown {
      // width: 312px !important;
      width: 380px !important;
      font-size: 14px;
      display: flex;
      justify-content: space-around;
      background: #ffffff !important;
      border: 1px solid #dadae6;
      box-sizing: border-box;
      box-shadow: 0px 1px 2px rgba(14, 14, 29, 0.06) !important;
      border-radius: 4px;
    }

    .tenant-subheading {
      display: inline;
      margin-left: 5%;
      margin-right: 2%;
      padding-right: 0.5%;
      border-right: 1px solid #dadae5;
    }
    .interim-meter-heading {
      font-size: 20px !important;
      font-weight: 600;
      line-height: 32px;
    }
    .utitlity-annual-unit-heading {
      .heading-text-wrapper {
        .heading {
          font-size: 16px;
        }
      }
    }
    .annual-occupency-dates-meter {
      padding-right: 2%;
      border-right: 1px solid #dadae5;
    }
    & .annual-reading-search {
      width: 352px;
    }
    & .meter-record-btn {
      & .add-meter-btn {
        margin-top: 2.4%;
        margin-bottom: 2.3%;
        margin-left: 0px;
        margin-right: 0px;
      }
      margin-top: 2%;
    }
    .billing-dropdown {
      margin-left: 41.333%;
      margin-top: 1%;

      display: flex;
      justify-content: flex-end;

      .billing-dropdown-option {
        width: 300px;
      }
    }
    .annual-tenant {
      padding-left: 0px !important;
      font-size: 14px;
      font-weight: 400;
      color: $colorPurple1;
      margin-top: 0.5%;
      max-width: 21% !important;
    }
    // .annual-value {
    //   padding-left: 0px !important;
    //   font-size: 14px;
    //   font-weight: 500; // color: $colorPurple1;
    //   margin-top: 0.5%;
    //   max-width: 21% !important;
    //   margin-right: -0.2%;
    //   display: inline;
    // }
    .annual-occupency-dates-meter {
      padding-right: 2%;
      border-right: 1px solid #dadae5;
    }
    .annual-value {
      padding-left: 0px !important;
      font-size: 14px;
      font-weight: 500; // color: $colorPurple1;
      //   margin-top: 0.5%;
      max-width: 21% !important;
      margin-right: 1.8%;
      display: inline;
    }

    img.heading-icon {
      margin-right: 1% !important;
      margin-left: 0.8%;
      cursor: pointer;
    }
    img.heading-icon:hover {
      cursor: pointer;
    }
    & .heading-right-content {
      .btn-container {
        width: 153px;
        margin-right: -4px;
        margin-top: 2px;
        .cancel-btn {
          flex-basis: 5%;
          padding-right: 7%;
          padding-left: 7%;
          margin-left: 12px !important;
        }
        .save-btn {
          flex-basis: 5%;
          padding-right: 7%;
          padding-left: 7%;
          margin-left: 7% !important; //9
        }
      }
    }
    & .interim-row {
      padding: 0px !important;
      //margin-right: -33%;
      // margin-left: 1%;
      & option {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: $colorBlack !important;
      }
    }
    & .interim-unit-dropbox {
      max-width: 18% !important;
      margin-right: 0px !important;
      padding-right: 0px !important;
    }
    & .interim-tenant-dropbox {
      padding-left: 4px !important;
      max-width: 18% !important;
      // margin-left: -14px !important ;
      margin-left: 3% !important;
    }
    & .interim-occupancy {
      padding-left: 0px !important;
      font-size: 14px;
      font-weight: 400;
      color: $colorPurple1;
      margin-top: 0.5%;
      max-width: 21% !important;
      margin-right: 1.8%;
      margin-left: 3%;
      & .interim-date {
        display: inline;
        font-weight: 500;
      }
    }
    & .interim-occasion-radio {
      margin-top: 0.5%;
      .form-check {
        .form-check-input[type='radio']:before {
          content: '';
          position: absolute;
          display: none;
        }
        .form-check-input[type='radio'] {
          -webkit-appearance: none;
          outline: none;
          background: $colorWhite;
          /* Oceanic Purple/200 */
          // position: absolute;
          width: 15px;
          height: 15px; //  left: 0px;
          //  top: 0px;
          border: 1px solid $colorGrey;
          border-radius: 8px;
        }
        .form-check-input[type='radio']:after {
          content: '';
          position: absolute;
          display: none;
        }
        .form-check-input[type='radio']:checked {
          background: linear-gradient(180deg, $blueForBg 0%, $purpleTheme 100%);
        }
        .form-check-input:after {
          content: '';
          position: absolute;
          display: none;
        }
        .form-check-input:checked:after {
          display: block;
          top: 3px;
          left: 2.5px;
          width: 9.5px;
          height: 9.5px;
          border-radius: 8px;
          background: $colorWhite;
          border: 1px solid $Border-blue;
        }
        .form-check-label {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          color: #0e0e1d;
        }
      }
      & .form-check label {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: $colorBlack !important;
      }
      label:nth-child(3) {
        padding: 0px !important;
      }
    }
    & .interim-readings-table,
    .annual-readings-table {
      border: 1px solid #dadae5 !important;
    }
    .meter-record-heading {
      > thead {
        text-align: center;
      }
    }
    & #meterForm,
    & #annualForm,
    & .annualForm {
      //  width:1133px;
      & .form-row {
      }

      & table.readings-form {
        width: 97.8% !important;
        //margin-left: 0.8% !important;

        tbody {
          & td {
            input {
              font-size: 14px;
              color: #0e0e1d !important;
              font-family: Inter;
              //   font-size: 14px;
              font-style: normal;
              font-weight: 400 !important;
              line-height: 20px;
              letter-spacing: 0em;
              text-align: left;
            }
          }
          // & td.number {
          //   width: 11%;
          // }
          // & td.type {
          //   width: 18.7%;
          //   input {
          //     padding-left: 3% !important;
          //   }
          // }
          & td.date {
            // width: 11%;
            .react-datepicker-wrapper {
              width: 120.891px !important;
              .react-datepicker__input-container {
                > form {
                  .input-group {
                    border: none !important;
                    position: relative;
                    padding-left: 10px;
                    .input-group-text {
                      padding: 0px 1px 0px 10px;
                    }
                    .input-group-prepend {
                      position: absolute;
                      // right: 0;
                      right: 5px;
                      z-index: 999;
                      padding: 10px 0px 10px 0px;
                    }
                  }
                }
              }
            }
            input {
              padding-left: 1.8%;
            }
          }
          // & td.start {
          //   width: 10%;
          //   input {
          //     text-align: right;
          //   }
          // }
          // & td.current {
          //   width: 11%;
          //   input {
          //     text-align: right;
          //   }
          // }
          // & td.consumption {
          //   width: 11%;
          //   input {
          //     text-align: right;
          //     font-weight: 600;
          //   }
          // }
          // & td.unit {
          //   width: 7%;
          //   input {
          //     text-align: center;
          //   }
          // }
          // td:last-child {
          //   width: 10%;
          // }
          //   & td.note {
          //     width: 112px;
          //     padding-right: 120px !important;
          //     .input {
          //       width: 115px;
          //     }
          //   }
          // td:last-child {
          //   width: 10%;
          // }
        }
      }
    }
    & .meter-record-heading {
      .meter-date-new-format {
        .react-datepicker-wrapper {
          .react-datepicker__input-container {
            > form {
              .input-group {
                border: none !important;
                // .input-group-prepend {
                //     display: none !important;
                // }
              }
            }
          }
        }
      }
      height: 46px;
      align-items: center !important;
      background-color: #f7f7fa;
      & th {
        border: 1px solid #dadae6 !important;
        text-transform: none !important;
        color: $colorLightPurple !important;
        //padding: 10px 0px !important; // padding-right: 0px !important;
        // padding-left: 0px !important;
        font-size: 14px !important;
        font-weight: normal !important;
        padding-left: 16px !important;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 5px !important;
      }

      & th:nth-child(1) {
        width: 128 !important;
      }

      & th:nth-child(2) {
        width: 202px !important;
      }

      & th:nth-child(3) {
        width: 96px !important;
        padding-left: 10px !important;
      }

      & th:nth-child(4) {
        width: 144px !important;
      }

      & th:nth-child(5) {
        width: 105px !important;
        padding-left: 10px !important;
      }

      & th:nth-child(6) {
        width: 123 !important;
        padding-left: 10px !important;
      }

      & th:nth-child(7) {
        width: 122px !important;
      }

      & th:nth-child(8) {
        width: 72px !important;
      }
      & th:nth-child(9) {
        width: 124.328px !important;
      }

      //   & th.number {
      //     width: 11% !important;
      //   }
      //   & th.type {
      //     width: 20% !important;
      //     //padding-left: 1.8% !important;
      //   }
      //   & th.date {
      //     width: 10% !important;
      //   }
      //   & th.start {
      //     width: 10% !important;
      //   }
      //   & th.current {
      //     width: 10% !important;
      //   }
      //   & th.consumption {
      //     width: 10% !important;
      //   }
      //   & th.unit {
      //     width: 10% !important;
      //   }
      //   & th.lastchild {
      //     width: 10% !important;
      //   }
    }
  }
}
@media only screen and (max-width: 1024px) {
  // .interim-meter-reading-page #meterForm,
  // .interim-meter-reading-page #annualForm {
  //     //    width:780px!important;
  // }
  .interim-meter-reading-page #meterForm table.readings-form,
  .interim-meter-reading-page .annualForm table.readings-form {
    width: 97% !important;
    margin-left: 1.3% !important;
  }
  .interim-meter-reading-page .meter-record-heading {
    .meter-date-new-format {
      .react-datepicker-wrapper {
        .react-datepicker__input-container {
          > form {
            .input-group {
              border: none !important;
              // .input-group-prepend {
              //     display: none !important;
              // }
            }
          }
        }
      }
    }
    // th {
    //     font-size: 11px;
    // }
    // th.number {
    //     width: 12%;
    // }
  }
  .interim-row {
    width: 100% !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .interim-meter-reading-page .interim-occupancy {
    max-width: 44% !important;
    margin-left: 3% !important;
  }
  .interim-meter-reading-page .interim-unit-dropbox {
    max-width: 50% !important;
  }
  .interim-meter-reading-page .interim-tenant-dropbox {
    max-width: 50% !important;
  }
  .interim-meter-reading-page #meterForm table.readings-form tbody {
    td {
      font-size: 11px;
    }
    td.number {
      width: 12%;
    }
  }
}
@media only screen and (max-width: 1366px) {
  .interim-meter-reading-page {
    .cost-type-menu-indivisual {
      display: flex;
      justify-content: space-between;
      width: 100% !important;
      font-size: 14px;
      background: #ffffff !important;
      border: 1px solid #dadae6;
      box-sizing: border-box;
      box-shadow: 0px 1px 2px rgba(14, 14, 29, 0.06) !important;
      border-radius: 4px;
    }
    .annual-dropdown {
      width: 100% !important;
      font-size: 14px;
      display: flex;
      justify-content: space-around;
      background: #ffffff !important;
      border: 1px solid #dadae6;
      box-sizing: border-box;
      box-shadow: 0px 1px 2px rgba(14, 14, 29, 0.06) !important;
      border-radius: 4px;
    }

    & .annual-reading-search {
      width: 302px;
    }
    & .meter-record-btn {
      & .add-meter-btn {
        margin-top: 2.4%;
        margin-bottom: 2.3%;
        margin-left: 0px;
        margin-right: 0px;
      }
      margin-top: 2%;
    }
    .billing-dropdown {
      margin-left: 41.333%;
      margin-top: 1%;
      display: flex;
      justify-content: flex-end;

      .billing-dropdown-option {
        width: 300px;
      }
    }
    .annual-tenant {
      padding-left: 0px !important;
      font-size: 14px;
      font-weight: 400;
      color: $colorPurple1;
      margin-top: 0.5%;
      max-width: 21% !important;
    }
    .annual-value {
      padding-left: 0px !important;
      font-size: 14px;
      font-weight: 500; // color: $colorPurple1;
      margin-top: 0.5%;
      max-width: 21% !important;
      margin-right: 1.8%;
    }
    img.heading-icon {
      margin-right: 1% !important;
      margin-left: 0.8%;
      cursor: pointer;
    }
    img.heading-icon:hover {
      cursor: pointer;
    }
    & .heading-right-content {
      .btn-container {
        width: 153px;
        margin-right: -4px;
        margin-top: 2px;
        .cancel-btn {
          flex-basis: 5%;
          padding-right: 7%;
          padding-left: 7%;
          margin-left: 12px !important;
          margin-right: 7px !important;
        }
        .save-btn {
          flex-basis: 5%;
          padding-right: 7%;
          padding-left: 7%;
          // margin-left: 9% !important;
        }
      }
    }
    & .interim-row {
      padding: 0px !important;
      //margin-right: -20%;
      margin-left: 0.5%;
      .form-control {
        text-overflow: ellipsis;
        // font-size: 13px;
        font-size: 14px;
      }
      & option {
        font-weight: 400;
        line-height: 16px;
        color: $colorBlack !important;
      }
    }
    & .interim-unit-dropbox {
      max-width: 18% !important;
      margin-right: 0px !important;
      padding-right: 0px !important;
    }
    & .interim-tenant-dropbox {
      // padding-left: 4px !important;
      // max-width: 16% !important;
      // margin-left: -14px !important ;
      max-width: 18% !important;
      margin-left: 2px;
    }
    & .interim-occupancy {
      padding-left: 0px !important;
      // font-size: 13px;
      font-size: 14px;
      font-weight: 400;
      color: $colorPurple1;
      margin-top: 0.5%;
      max-width: 21% !important;
      margin-left: 3%;
      max-width: 290px !important;
      & .interim-date {
        display: inline;
        font-weight: 500;
      }
    }
    & .interim-occasion-radio {
      margin-left: 3%;
      .form-check {
        .form-check-label {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          color: #0e0e1d;
        }
      }
      .form-check:last-child {
        padding-right: 0px;
        margin-right: 0px;
      }
    }
    & .interim-readings-table,
    .annual-readings-table {
      border: 1px solid #dadae5 !important;
    }
    & #meterForm,
    & #annualForm,
    & .annualForm {
      //  width:990px!important;
      & .form-row {
      }
      & table.readings-form {
        width: 97.8% !important;
        margin-left: 0.8% !important;

        tbody {
          & td {
            input {
              // font-size: 13px;
              font-size: 14px;
              color: #0e0e1d !important;
              font-family: Inter;
              //    font-size: 14px;
              font-style: normal;
              font-weight: 400 !important;
              line-height: 20px;
              letter-spacing: 0em;
              text-align: left;
            }
          }
          & td.number {
            width: 11%;
          }
          & td.type {
            width: 27.4%;
            input {
              padding-left: 7% !important;
            }
          }
          & td.date {
            width: 13%;
            .react-datepicker-wrapper {
              width: 120.891px !important;
              .react-datepicker__input-container {
                > form {
                  .input-group {
                    border: none !important;
                    position: relative;
                    .input-group-text {
                      padding: 0px 1px 0px 10px;
                    }
                    .input-group-prepend {
                      position: absolute;
                      // right: 0;
                      right: 5px;
                      z-index: 999;
                      padding: 10px 0px 10px 0px;
                    }
                  }
                }
              }
            }
            input {
              padding-left: 1.8%;
            }
          }
          & td.start {
            width: 10% !important;
            input {
              text-align: right;
            }
          }
          & td.current {
            width: 11%;
            input {
              text-align: right;
            }
          }
          & td.consumption {
            width: 11%;
            input {
              text-align: right;
              font-weight: 600;
            }
          }
          & td.unit {
            width: 7%;
            input {
              text-align: center;
            }
          }
          //   & td.note {
          //     width: 112px;
          //     padding-right: 120px !important;
          //     .input {
          //       width: 115px;
          //     }
          //   }
          td:last-child {
            width: 10%;
          }
        }
      }
    }
    & .meter-record-heading {
      .meter-date-new-format {
        .react-datepicker-wrapper {
          .react-datepicker__input-container {
            > form {
              .input-group {
                border: none !important;
                position: relative;
                padding-left: 10px;
                // .input-group-prepend {
                //     display: none !important;
                // }
              }
            }
          }
        }
      }
      // & th {
      //     font-size: 12px;
      // }
    }
  }
}
@media only screen and (max-width: 900px) and (-webkit-min-device-pixel-ratio: 1) {
  .interim-meter-reading-page {
    .cost-type-menu-indivisual {
      display: flex;
      justify-content: space-between;
      width: 100% !important;
      font-size: 14px;
      background: #ffffff !important;
      border: 1px solid #dadae6;
      box-sizing: border-box;
      box-shadow: 0px 1px 2px rgba(14, 14, 29, 0.06) !important;
      border-radius: 4px;
    }
    .annual-occupency-dates-meter {
      padding-right: 2%;
      border-right: 0px solid #dadae5 !important;
    }
    .annual-dropdown {
      width: 100% !important;
      font-size: 14px;
      display: flex;
      justify-content: space-around;
      background: #ffffff !important;
      border: 1px solid #dadae6;
      box-sizing: border-box;
      box-shadow: 0px 1px 2px rgba(14, 14, 29, 0.06) !important;
      border-radius: 4px;
    }

    .interim-meter-heading {
      margin-left: 0;
      flex-wrap: wrap;
      .heading-text-wrapper {
        img.heading-icon {
          margin-right: 4% !important;
          margin-left: 0.8%;
          cursor: pointer;
        }
        flex-basis: 100%;
      }
      .heading-right-content {
        flex-basis: 100%;
        & .btn-container {
          width: 100%;
          & .save-btn {
            //    padding: 0px!important;
            flex-basis: 7%;
            padding-right: 32%;
            padding-left: 32%;
            margin-left: 3% !important;
          }
          & .cancel-btn {
            //    padding: 0px!important;
            margin-left: 0px !important;
            flex-basis: 0% !important;
            padding-left: 9px !important;
            padding-right: 13px !important;
          }
        }
      }
    }

    .billing-dropdown {
      margin-left: 0px;
      .dropdown {
        width: 393px !important;
      }
    }
    .interim-unit-dropbox,
    .interim-tenant-dropbox {
      max-width: 100% !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
      margin-left: 0px !important;
      height: 36px;
      .form-control {
        option {
          font-size: 14px;
        }
      }
      .form-control {
        option {
          font-size: 14px;
        }
      }
    }
    .interim-unit-dropbox {
      margin-bottom: 8px;
    }
    .interim-tenant-dropbox {
      margin-bottom: 16px;
    }
    // .mobile-interim {
    //   margin-top: 10px !important;
    // }
    .indivisual-occupency-header {
      padding: 14px 0px !important;
    }

    .indivisual-dropdown-right-header {
      margin-top: 6px !important;
      margin-bottom: 20px !important;
    }
    .interim-occupancy {
      max-width: 100% !important;
      margin-bottom: 3%;
    }
    .interim-row {
      margin-right: auto;
    }
    .annual-reading-search {
      width: 393px;
      height: 3px;
    }
    .annual-value {
      width: 393px !important;
      max-width: 393px !important;
      margin: 10px 0px 0px 0px;
    }
    .tenant-subheading {
      display: block !important;
      // margin-left: 5%;
      // margin-right: 0.5%;
      margin: 10px 0px 0px 0px !important;
      padding-right: 0% !important;
      border-right: none;
      .annual-value {
        margin-left: 0px !important;
        margin-right: 0px !important;
      }
      .interim-occupancy {
        margin-left: 0px !important;
      }
    }
    .mobile-data {
      font-size: 14px;
      margin-bottom: 4% !important;
      border-collapse: separate;
      border-spacing: 0px 8px;
      .mobile-interim-heading {
        th {
          text-transform: none;
          border: none;
          padding-top: 0px !important;
          padding-bottom: 0px !important;
        }
        th.type {
          text-align: left;
        }
        th.current {
          text-align: right;
        }
      }
      .mobile-reading-table-rows {
        tr {
          background: white;
          margin-bottom: 8px;
          height: 56px;
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          color: #0e0e1d;

          td {
            font-weight: normal !important;
          }
          td.mobile-meter-type {
            padding-left: 5%;
          }
          td.mobile-meter-current-value {
            text-align: right;
            padding-right: 5%;
          }
        }
      }
    }
  }
  .utitlity-annual-unit-heading {
    .heading-text-wrapper {
      .card-title {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.mobile-meter-detail-modal {
  .modal-content {
    .modal-body {
      .mobile-meter-form {
        color: #0e0e1d !important;
        .form-row {
          margin-top: 4%;
          .meter-label {
            font-size: 14px;
            color: $colorPurple1;
          }
          .meter-mobile {
            input {
              border: none !important;
              outline: none !important;
              box-shadow: none !important;
              text-align: right !important;
              font-size: 14px !important;
              color: #0e0e1d !important;
              // color: $colorBlack !important;
              font-weight: 500;
              background-color: #ffffff;
            }
          }
          .meter-mobile-consumption {
            input {
              font-weight: 600 !important;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  //modal height and footer on mobile
  .mobile-meter-detail-modal {
    padding: 0px;
    margin: 0px;
    .modal-content {
      width: 100%;
      height: 100%;
      border-radius: 0px;
    }
    .center-spacing {
      position: absolute;
      bottom: 0px;
      left: 0px;
      padding: 16px;
      width: 100%;
    }
    .cancel_addbtn {
      position: absolute;
      bottom: 0px;
      left: 0px;
      padding: 16px;
      width: 100%;
    }
  }
  // .add-meter-modal {
  //   padding: 0px;
  //   margin: 0px;
  //   .modal-content {
  //     width: 100%;
  //     height: 100vh;
  //     border-radius: 0px;
  //   }
  //   .cancel_addbtn {
  //     position: absolute;
  //     bottom: 0px;
  //     left: 0px;
  //     padding: 16px;
  //     width: 100%;
  //   }
  // }
  .meter-edit-modal {
    .cancel_addbtn {
      position: absolute;
      bottom: 0px;
      left: 0px;
      padding: 16px;
      width: 100%;
    }
  }
}
@media only screen and (max-width: 375px) {
  .interim-meter-reading-page .annual-reading-search .annual-value {
    width: 328px !important;
  }
  .indivisual-occupency-header {
    padding: 14px 0px !important;
  }
  .utitlity-annual-unit-heading {
    .heading-text-wrapper {
      .card-title {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .utitlity-annual-unit-heading {
    margin-left: 54px !important;
  }
  .annual-reading-search {
    .search-input {
      width: 343px !important;
    }
  }
  .indivisual-dropdown-right-header {
    margin-bottom: 20px !important;
  }
}
@media only screen and (max-width: 320px) {
  .interim-meter-reading-page .interim-meter-heading .heading-right-content .btn-container .cancel-btn {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
  .interim-meter-reading-page .annual-reading-search .annual-value {
    width: 288px !important;
  }
  .interim-meter-reading-page .indivisual-dropdown-right-header {
    padding-left: 0px !important;
    padding-right: 0px !important;
    div {
      input {
        font-size: 10px !important;
        margin-right: 0px !important;
      }
    }
  }
  .cancel_addbtn {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
}
/*meter module*/

.meter-date-new-format {
  .react-datepicker__input-container {
    .input-group-focus {
      border: none !important;
      box-shadow: none !important;
    }
  }
}

.indivisual-header-meter {
  @media only screen and (max-width: 900px) {
    display: flex;
    width: 100%;
    margin-left: 0;
  }
  @media only screen and (max-width: 1100px) {
    display: flex;
    flex-direction: 'column';
  }
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -4px;
  .indivisual-dropdown-left-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    .indivisual-dropdown-one {
      @media only screen and (max-width: 500px) {
        margin-right: 0;
      }
      margin-right: 16px;
      min-width: 220px;
      flex: 1;
    }
    .indivisual-dropdown-two {
      @media only screen and (max-width: 500px) {
        margin-right: 0;
      }
      margin-right: 16px;
      min-width: 220px;
      flex: 1;
    }
    .indivisual-occupency-header {
      // margin-top: -12px;
      margin-top: -30px;
      display: 'flex';
      align-items: 'center';
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #0e0e1d;
      > span {
        font-weight: 500;
      }
    }
  }
  .indivisual-dropdown-right-header {
    @media only screen and (max-width: 900px) {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #0e0e1d;
      margin-top: -26px;
      .form-check:last-child {
        margin-right: 0px;
        padding-right: 0px;
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .indivisual-dropdown-one {
    //min-width: 200px !important;
  }
  .indivisual-dropdown-two {
    //min-width: 200px !important;
  }
  // .indivisual-occupency-header {

  // }
  .indivisual-dropdown-right-header {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #0e0e1d;
    //margin-top: -26px;
    .form-check:last-child {
      margin-right: 0px;
      padding-right: 0px;
    }
  }
}
@media only screen and (max-width: 2560px) {
  .interim-meter-heading {
    width: 99.8%;
  }
  .indivisual-header-meter {
    width: 100%;
    //background-color: aqua;
    .indivisual-dropdown-right-header {
      .form-check:last-child {
        margin-right: 0px;
        padding-right: 0px;
        > label {
          margin-right: 0px;
          padding-right: 0px;
        }
      }
    }
  }
}

@media only screen and (max-width: 414px) {
  .meterClass .Meter-readings .heading-right-content {
    width: 386px;
  }
  .meter-reading-buttons {
    width: 378px !important;
  }
  .annual-reading-search {
    width: 381px !important;
  }
  .utitlity-annual-unit-heading {
    .heading-text-wrapper {
      margin-left: 20px;
    }
  }
  input:disabled + .form-check-label {
    color: #bfbfd2 !important;
    opacity: 85%;
  }
}

.empty-annual-meter-reading {
  .main-container {
    text-align: center;
    margin-top: 8%;
    margin-bottom: 8%;
  }
}

/////////// german /////////
.interim-meter-heading {
  display: flex;
  justify-content: space-between;
  .heading-text-wrapper {
    width: 500px;
    // background-color: antiquewhite;
    max-width: 500px;
  }
  .heading-right-content {
    display: flex;
    /* flex: 1; */
    width: 250px;
    .btn-container {
      flex: 1 1;
    }
  }
  // .heading.card-title{
  //   max-width: 50%
  // }
  // .heading-right-content{
  //   width: 250px;
  //   .btn-container{
  //     // display: contents;
  //   }
  // }
}

.popperDatepicker {
  z-index: 5;
}
