@media only screen and (max-width: 1440px) {
  .cost-type-card {
    display: none;
  }
  .allocation-card {
    display: none;
  }
}
@media only screen and (max-width: 2560px) {
  .cost-type-card {
    display: none;
  }
  .allocation-card {
    display: none;
  }
}
@media only screen and (max-width: 1024px) {
  .generalinfo-page {
    .zipcode-property {
      //padding-left: 15px !important;
    }
  }
}
@media only screen and (max-width: 767px) {
  .property-navbar .switch-btn {
    white-space: nowrap;
    padding: 18px 0px 17px !important;
  }
  .heading-container {
    margin: 5px 0px 0px 0px;
  }
  // ------------------------  general inoformaion start -----------------------------------------//
  .generalinfo-page {
    .custom-input {
      margin: 0px;
    }
    .profile-image-container {
      margin-top: 17px;
    }
    .zipcode-property {
      //padding-left: 15px !important;
    }
    .danger-text {
      margin: 30px 0px 0px 0px;
    }
    .generalinfo-buttons {
      padding: 0% 5% !important;
    }
    .zipcode-property {
      .form-control {
        padding: 0px 8px;
      }
    }
    padding: 0px 0px 20px;
  }
  // ------------------------  general inoformaion end -----------------------------------------//

  //----------------------------------- cost type start ------------------------------//
  .cost-page {
    .page-header {
      .heading-container {
        .heading-text-wrapper {
          .card-title {
            margin: 0px 0px 11px !important;
          }
        }
      }
      .filters {
        .section-3 {
          margin: 0px 0px 0px !important;
        }
        margin-top: 8px;
      }
    }
    .table-responsive {
      .table-responsive-cost.table {
        thead {
          display: none;
        }
        .table-row {
          display: none;
        }
        background: transparent;
      }
    }

    margin-top: -6px;
  }
  .cost-type-card {
    .left-side {
      ul {
        li {
          list-style: none;
        }
        .gray-text {
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          color: #454576;
        }
        .black-text {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #0e0e1d;
        }
        padding: 0px;
        margin: 0px;
      }
      display: flex;
      justify-content: space-between;
      flex-flow: column;
      width: 53%;
    }
    .center-side {
      ul {
        li.active {
          span {
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: #0e0e1d;
            margin: 0px 0px 0px 8px;
          }

          display: flex;
        }
        list-style: none;
        padding: 0;
        margin: 0;
      }
      .switch-btn {
        padding-bottom: 0px !important;
        display: flex;
        align-items: center;
      }
      width: 28%;
      align-self: flex-end;
    }
    .right-side {
      .record-options {
        .record-dropdown {
          padding: 0px;
          background: none;
          border: none;
          box-shadow: none;
        }
      }
      align-self: flex-start;
      width: 19%;
      flex-direction: row-reverse;
    }
    background: #ffffff;
    box-shadow: 0px 1px 2px rgba(14, 14, 29, 0.06);
    border-radius: 4px;
    padding: 16px 16px;
    display: flex !important;
    justify-content: space-between;
    margin-bottom: 4px;
    height: 125px;
  }
  //----------------------------------- cost type end ------------------------------
  //----------------------------------- Allocation type Start ------------------------------
  .allocation-page {
    .table-responsive {
      .table-responsive-allocation {
        thead {
          display: none;
        }
        .table-row {
          display: none;
        }
        background: transparent;
      }
    }
    .page-header {
      .filters {
        .section-3 {
          margin: 0px 0px 0px !important;
        }
        margin-top: 1px;
      }
    }
    margin-top: -5px;
  }
  .allocation-card {
    .left-side {
      ul {
        li {
          list-style: none;
        }
        li.active {
          span {
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: #0e0e1d;
            margin: 0px 0px 0px 8px;
          }
          display: flex;
        }
        .gray-text {
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          color: #454576;
        }
        .black-text {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #0e0e1d;
        }
        li.active {
          .switch-btn {
            span {
              font-weight: normal;
              font-size: 14px;
              line-height: 16px;
              color: #0e0e1d;
              margin-left: 8px;
            }
            padding-bottom: 0px !important;
            display: flex;
            align-items: center;
          }
        }
        padding: 0px;
        margin: 0px;
      }
      display: flex;
      justify-content: space-between;
      flex-flow: column;
      width: 53%;
    }
    .center-side {
      ul {
        li {
          .text-verified {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            color: #059266;
            margin-left: 4.33px !important;
          }
          img {
            width: 15.33px;
            height: 15.31px;
          }
          list-style: none;
        }
        .gray-text {
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          color: #454576;
        }
        padding: 0px;
        margin: 0px;
      }
      align-self: center;
      width: 23%;
    }
    .right-side {
      .record-options {
        .record-dropdown {
          padding: 0px;
          background: none !important;
          border: none !important;
          box-shadow: none !important;
        }
      }
      align-self: flex-start;
      width: 24%;
      flex-direction: row-reverse;
    }
    padding: 16px;
    height: 152px;
    background: #ffffff;
    box-shadow: 0px 1px 2px rgba(14, 14, 29, 0.06);
    border-radius: 4px;
    display: flex !important;
    justify-content: space-between;
    margin-bottom: 3px;
  }
  //----------------------------------- Allocation type end ------------------------------
}
