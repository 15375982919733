@media only screen and (max-width: 1284px) {
  // N/ Dashboard
  #primary-content {
    padding: 24.52px 16px 16px 16px !important;
    .custom-card {
      .custom-card-body {
        .custom-card-content.content-xl {
          .custom-card-title {
            font-size: 22px;
          }
        }
      }
    }
    .btn-text {
      font-size: 11.5px;
    }
    .dashboardCard .btn.btn-secondary {
      font-size: 14px;
    }
  }
  .app-sidebar {
    width: 248px;
    #main-menu {
      height: 100%;
    }
  }
  #page-content {
    margin-left: 248px;
  }
  .top-section .card-heading {
    font-size: 16px;
  }
  .billing-card {
    .card-body {
      padding: 13px;
      min-height: 230px !important;
      .table {
        .table-data {
          display: flex;
          justify-content: space-between;
          .value-text-:first-child {
            width: 110px;
            white-space: nowrap;
            // overflow: hidden;
            // text-overflow: ellipsis;
          }
        }
        .table-heading {
          display: flex;
          justify-content: space-between;
        }
      }
      .top-section {
        // margin-bottom: 8px;
        .card-heading {
          font-size: 16px;
        }
      }
      .btn-container {
        button {
          .btn-text {
            width: 70px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          padding: 8px 7px;
        }
      }
      .button-img-wrapper .btn-text {
        font-size: 11.5px;
      }
    }
  }
  tr {
    &.table-data {
      .left-side {
        max-width: 110px !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        white-space: nowrap;
      }
    }
  }
  .dashboard {
    .undefined {
      &.card {
        .card-body {
          .info-field {
            .text-img-wrapper {
              .card-value {
                width: 100px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  //---------------- Login & Signup Screen ----------------------------------------//
  .signup-page-wrapper {
    .footer.signup-footer {
      .bottom-text {
        width: 30.3%;
      }
      .bottom-btn-wrapper {
        width: 36.1%;
      }
      .bottom-spacer {
        width: 30%;
      }
    }
  }
}


@media only screen and (max-width: 1023px) {
  //---------------- Login & Signup Screen ----------------------------------------//
  .login-page {
    padding: 0px;
    .logo-section {
      margin-bottom: 24.17px;
      img {
        width: 100.61px;
        height: 47.31px;
      }
    }
    .main-content-section {
      .card-body {
        width: 343px;
      }
    }
    .bottom-section {
      position: absolute;
      bottom: 0;
    }
  }
  #primary-content {
    padding: 24.52px 16px 16px 16px !important;
  }
  .forgot-pw-page {
    .width-btn {
      width: 100%;
    }
    .reset-btn {
      margin-top: 24px;
    }
    padding: 0px;
    .logo-section {
      margin-bottom: 24.17px;
      img {
        width: 100.61px;
        height: 47.31px;
        margin: 0;
      }
    }
    .main-form-section {
      .forgot-form {
        &.card {
          width: 343px;
        }
      }
    }
    .ins-sent {
      &.card {
        .card-body {
          width: 343px;
        }
      }
    }
  }

  .signup-page-wrapper {
    padding: 0;
    display: block;
    width: 100%;
    .signup-page {
      width: 100%;
      .logo-section {
        margin-bottom: 24.17px;
        img {
          width: 100.61px;
          height: 47.31px;
        }
      }
      .main-content-section {
        .steps-content {
          .signup-heading-wrapper {
            .heading-container {
              .heading-text-wrapper {
                .card-title {
                  font-size: 20px;
                }
              }
            }
          }
          .subscription-step {
            .signup-card-content {
              .subscription-options {
                width: 100%;
              }
            }
          }
        }
      }
    }
    .footer.signup-footer {
      padding: 16px;
      .bottom-btn-wrapper {
        width: 100%;
        .purple-btn {
          width: 255px;
        }
      }
      .bottom-spacer {
        display: none;
      }
      .bottom-text {
        display: none !important;
      }
    }
    input#signup-zip {
      padding-right: 0px;
    }
  }
  .signup-page-wrapper.signup-step-3.step-2{
    .signup-footer {
      position: absolute !important;
      bottom: auto !important;
    }
  }
}
@media only screen and (max-width: $breakPointSm) {
  .app-sidebar {
    transform: translate(-100%);
    transition: all 0.3s ease;
  }

  .bottom-nav{
    scrollbar-width: none !important;
  }
  .bottom-nav::-webkit-scrollbar{
    display: none !important;
  }

  .sidebar-overlay {
    background: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
  }
  .side-menu-collapsed {
    height: 100% !important;
    .app-sidebar {
      transform: translate(0%);
      flex-direction: row;
      bottom: 0;
      top: auto;
      //height: 65px;
      width: 100%;
      align-items: flex-end;
      & .site-logo-bar,
      .sidebar-dropdown-container {
        display: none;
      }
      > nav {
        overflow-y: hidden !important;
        //overflow-x: hidden !important;
        // margin-right: 4%;
        // margin-left: 2%;
        #main-menu {
          flex-direction: row;
          align-items: flex-end;
          & .navbar {
            // padding: 0;
            width: 100%;
            //padding: 0rem 1rem !important;
          }
          .separator {
            flex-grow: 1;
          }
          .navbar-light .navbar-toggler-icon {
            background: url("../../../../../assets/images/module/general/more2.svg");
            background-repeat: no-repeat;
            background-position-x: right;
            padding-left: 2px;
          }
          & .navbar-collapse {
            flex-basis: auto;
            display: flex;
          }
          .collapse:not(.show) {
            display: none;
          }
          & .nav-item {
            height: auto;
            .side-nav-icon {
              margin-right: 0px;
              margin-top: 9px !important;
            }
            .nav-item-label {
              font-size: 10px;
            }
            a,
            a.active,
            a:focus {
              outline: none;
              border: none;
              padding: 0px 13px !important;
              position: relative;
              flex-direction: column;
            }
          }
          /* Chrome version 29 and above */
          @media screen and (-webkit-min-device-pixel-ratio:0)
          and (min-resolution:.001dpcm) {
          & .nav-item {
            height: auto !important;
            .side-nav-icon {
              margin-right: 0px;
            }
            .nav-item-label {
              font-size: 10px;
            }
            a,
            a.active,
            a:focus {
              outline: none;
              border: none;
              padding: 5px 13px;
              position: relative;
              flex-direction: column;
            }
          }
          }
          @-moz-document url-prefix() {
            & .nav-item {
              //height: auto;
              .side-nav-icon {
                margin-right: 0px;
              }
              .nav-item-label {
                font-size: 10px;
              }
              a,
              a.active,
              a:focus {
                outline: none;
                border: none;
                padding: 5px 13px;
                position: relative;
                flex-direction: column;
              }
            }
          }
        }
      }
    } // Stop app from scrolling when scrolling through app-sidebar
    overflow: hidden;
    height: 100vh;
  }
  #page-content {
    margin-left: 0;
  }
  .sidebar-dropdown-container {
    margin-top: 7px;
    .dropdown {
      .property-dropdown {
        padding: 0;
        .property-parent.card {
          .card-body {
            border-radius: 0;
            box-shadow: 0px 1px 2px rgba(14, 14, 29, 0.06);
          }
        }
      }
      .property-dropdown-menu {
        border-radius: 0px !important;
        width: 100% !important;
        transform: translate3d(-7px, 47px, 0px) !important;
        .dropdown-item {
          .add-property-btn {
            width: 97%;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  #page-content {
    #primary-content {
      padding: 14px 16px 50px !important;
      & > div > .row {
        > .col-md-4,
        > .col-md-6,
        > .col-12 {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
      .dashboardCard {
        & .avatar {
          width: 48px;
          height: 48px;
          > img {
            width: 48px;
            height: 48px;
          }
        }
        & > .custom-card-body.body-size-xl {
          padding: 16px 17px !important;
        }
        & .custom-card-body .custom-card-content.content-xl {
          padding-left: 16px;
          .custom-card-title {
            font-size: 18px !important;
            padding-top: 5px !important;
          }
          .custom-card-subtitle {
            font-size: 12px;
          }
          .property-info-tiles {
            margin-left: -67px;
            margin-right: -66px;
            width: auto;
            .app-label {
              color: $colorPurple1;
            }
               
            .value-text-container {
              .card-value {
                color: $colorBlack;
              }
            }
          }
        }
        .btn.btn-secondary {
          align-self: flex-start;
          margin-right: -6px;
          color: $colorDarkPurple;
          font-weight: 500px;
        }
        .undefined.card {
          .card-body {
            padding: 16px !important;
          }
        }
      }
      .btn-text {
        font-size: 14px;
      }
    }
  }
  .sidebar-dropdown-container {
    margin-top: 0;
    .property-dropdown {
      padding: 0px;
    }
    .property-dropdown-menu {
      margin-top: 12px;
      & .add-property-btn {
        width: 94.5% !important;
      }
    }
  }

  Button.color-btn {
    &.gray-btn {
      margin: 0px 0px 0px 0px !important;
    }
  }
  // .col-md-4 {
  //     padding-left: 12px;
  //     padding-right: 12px;
  // }
  .heading-container {
    .heading-text-wrapper {
      .heading {
        //margin: 0px 0px 16px !important;
        font-size: 18px;
      }
    }
  }
  .custom-card {
    .custom-card-body {
      &.body-size-sm {
        border-radius: 0px;
        background: #ffffff;
        box-shadow: 0px 1px 2px rgba(14, 14, 29, 0.06);
        border: none;
        height: 56px;
        padding: 12px 16px;
        .arrow-icon {
          width: 20px;
        }
      }
    }
    &.card-size-sm {
      margin-top: 0px !important;
    }
  }
  .billing-card {
    .card-body {
      padding: 16px;
      .table {
        .table-data {
          .value-text- {
            &:first-child {
              width: auto;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
      .top-section {
        .card-heading {
          font-size: 18px;
        }
      }
    }
  }
  .dashboard {
    .seeAllMargin {
      margin-right: 5px;
    }
  }
  .custom-input {
    justify-content: flex-start;
    margin: 0px 0px 0px 20px;
  }

  .add_proprty_modal {
    .modal-content {
      border-radius: 0px;
    }
    width: 100%;
    margin: 0px;
    border-radius: 0px;

    .modal-header {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .profile_modal_radiobtns {
      flex-flow: column;
    }
  }
}
