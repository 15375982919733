.emptystate-dashboard {

  .main-container-dashbaord {
    height: 185px;
    text-align: center;
    margin-top: 0px !important;
    padding: 15px 5px;
    background-color: #f7f7fa;
    border-radius: 4px;
  }
  .empty-state-heading-dashbaord {
    text-align: center;
    margin-top: 12px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #13135c;
  }
  .empty-state-subheading-dashbaord {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 12px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    color: #727293;
  }
  .empty-state-icon-dashbaord {
    text-align: center;
    background: #f7f7fa !important;
    padding: 14px;
    border-radius: 0px !important;
  }
}

.main-container {
  text-align: center;
  margin-top: 90px;
}

.empty-state-heading {
  margin-top: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #13135c;
}

.empty-state-subheading {
  margin-top: 12px;
  margin-bottom: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #727293;
}

// .empty-state-icon {
//   background-color: white !important;
//   padding: 14px;
//   border-radius: 4px !important;
// }
.emptystate-dashboard.no-rental-units {
  width: 78vw !important;
}

.empty-state-btn-added {
  .main-container-dashbaord {
    padding: 15px 5px;
  }
  .empty-state-icon-dashbaord{
    padding: 0px;
  }
  .empty-state-icon {
    padding: 0px !important;
  }
}

///////////// empty figma screen /////////

