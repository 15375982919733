/*property module*/
.purple-btn {
  padding: 7px 11px !important;
}
.purple-btn, .gray-btn {
  min-width: 100px;
}
//=========== allocation page ===========//

.form-control.is-invalid{
  border-color: rgb(220, 53, 69) !important;
  box-shadow: none !important;
}

.allocation-page {
  .table-responsive-allocation.table {
    background-color: #f7f7fa;
  }
  .text-verified {
    margin-left: 3%;
    color: #059266;
  }

  .table-row {
    //   box-shadow: 0px 1px 2px rgb(14 14 29 / 6%);
    background: #ffffff;
    border-bottom: 8px solid #f7f7fa;
    height: 75px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    // text-transform: capitalize !important;
    color: #111147;
  }

  .name-allocation {
    padding-left: 24px !important;
    //width: 433px !important;
  }

  .allocation-measure {
    //padding-left: 0px !important;
    //width: 226px !important;
    // text-transform: uppercase !important;
  }

  .allocation-meter {
    //padding-left: 0px !important;
    //width: 226px !important;
  }

  .active-allocation {
    //padding-left: 0px !important;
    //width: 170px !important;
  }

  .dots-allocation {
    padding-right: 24px !important;
    color: #f7f7fa !important;
    //width: 48px !important;
  }

  .table-responsive {
    margin-top: -1%;
  }

  .table.table {
    box-shadow: 0 0 0 0px #eceff2 !important;
  }

  table.table tbody td {
    border: 0px solid #eceff2 !important;
  }

  .table-responsive-allocation {
    .table-heading-allocaion {
      padding-top: 21px !important;
      padding-bottom: 12px !important;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      text-transform: capitalize !important;
      color: #727293;
      border: 0px solid !important;
    }
  }

  .table-costype-active-inactive {
    display: flex;
    vertical-align: middle;
    padding: 17px 0px !important;
  }

  .active-inactive {
    margin-top: 1.5%;
    margin-left: 3%;
  }

  .property-single_dropdown {
    text-align: left !important;
    outline: none;
    line-height: 1.5em;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    height: 38px !important;
    background: #fff;
    border: 1px solid #d5dce6;
    border-radius: 4px;
    width: 100% !important;
    // box-shadow: inset 0 2px 0 0 #f2f5f8;
  }

  .property-single_dropdown:focus {
    color: $colorBlack !important;//#495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0 #fff !important;
  }
}

//==============.add_allocation_key_modal ================//

.allocation-page {
  & .divider {
    display: none;
  }

  .dots-allocation {
    // width: 48px !important;
    .record-options {
      .record-dropdown {
        padding: 0px !important;
        border: none !important;
        background: none !important;
        box-shadow: none !important;
      }
      .record-dropdown:active {
        padding: 0px !important;
        border: none !important;
        background: none !important;
        box-shadow: none !important;
        background-color: #fff !important;
      }
    }
  }
}
.add_allocation_key_modal {
  .name-allocation-modal {
    margin-top: 5px;
  }

  .button-allocation-modal {
    margin-top: 10px;
  }

  .property-modal-label {
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    line-height: 20px;
    color: #727293;
  }

  .property-single_dropdown-allocation-modal {
    text-align: left !important;
    outline: none;
    line-height: 1.5em;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    background: #fff;
    border: 1px solid #d5dce6;
    border-radius: 4px;
    width: 100% !important;
    display: flex;
    justify-content: space-between;
  }

  .key-allocation-modal {
    margin-top: 5px;
  }

  .property-single-dropdown-options {
    outline: none;
    line-height: 1.5em;
    background: #fff;
    margin: 8px 12px !important;
  }

  .property-single-dropdown-menu {
    padding: 0px;
    border-radius: 4px;
    width: 100% !important;

    .dropdown-item {
      margin-top: 8px !important;
    }
  }

  .add-allocationkey-dropdown-btn {
    width: 190px !important;
    margin-left: -15px !important;
  }
}

.modal.fade {
  & .add_allocation_key_modal.modal-dialog {
    //max-width: 304px !important;
    @media screen and (max-width: 500px){
      max-width: 100% !important;
    }
    .modal-content {
      // height: 327px !important;

      .modal-header {
        height: 56px !important;
        padding: 0px !important;

        .modal-title {
          margin: 16px 0px 16px 24px;
        }

        .close {
          margin: 13px 27px 15px 0px;
          padding: 0px;

          > span {
            font-size: 18px;
          }
        }
      }

      .modal-body {
        padding: 8px 24px !important;
      }
      .cancel_addbtn {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
}

///////////////==========.add cost_key page ============/////////////////////

.cost-page {
  .divider {
    display: none;
  }
  .dot-cost {
    .record-options {
      .record-dropdown {
        padding: 0px !important;
        border: none !important;
        background: none !important;
        box-shadow: none !important;
      }
      .record-dropdown:active {
        padding: 0px !important;
        border: none !important;
        background: none !important;
        box-shadow: none !important;
        background-color: #fff !important;
      }
    }
  }

  .table-responsive {
    margin-top: -1% !important;
  }

  .table-responsive-cost {
    // > thead {
    //   background: #f8f9fb !important;
    //   width: 100%;
    // }
    .table-heading-cost {
      padding-top: 21px !important;
      padding-bottom: 12px !important;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      text-transform: capitalize !important;
      color: #727293;
      border: 0px solid !important;
    }
  }
  .table-responsive-cost.table {
    background-color: #f7f7fa;
    // .property-types-emptystates {
    //   text-align: center;
    // }
  }

  .last-text {
    color: #f7f7fa;
  }

  .name-cost {
    padding-left: 24px !important;
    width: 433px !important;
  }

  .allocation-key-cost {
    padding-left: 0px !important;
    width: 226px !important;
  }

  .active-cost {
    // padding-left: 0px !important;
    // width: 182px !important;
    //padding-left: 0px !important;
    width: 226px !important;
    //margin-left: 33px;
  }

  .dot-cost {
    padding-left: 0px !important;
    width: 48px !important;
    color: #f7f7fa !important;
  }
  .table.table {
    box-shadow: 0 0 0 0px #eceff2 !important;
  }

  table.table tbody td {
    border: 0px solid #eceff2 !important;
  }
  .table-row {
    background: #ffffff;
    border-bottom: 8px solid #f7f7fa;
    height: 75px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-transform: capitalize !important;
    color: #111147;
  }

  .table-costype-active-inactive {
    display: flex;
    vertical-align: middle;
    padding: 17px 0px !important;
  }

  .active-inactive {
    margin-top: 1.5%;
    margin-left: 3%;
  }
}
.cost-modal-value {
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  overflow: hidden;
  height: 23px;
  text-align: left;
  // width: 200px !important;
  width: 100% !important;
}

.modal.fade {
  & .add_cost_type_modal {
    max-width: 504px !important;
    height: 202px !important;

    .modal-header {
      height: 56px !important;
      padding: 0px !important;

      .modal-title {
        margin: 16px 0px 16px 24px;
      }

      .close {
        margin: 13px 27px 15px 0px;
        padding: 0px;

        > span {
          font-size: 18px;
        }
      }
    }

    .modal-body {
      .property-modal-label {
        font-weight: 400;
        font-style: normal;
        font-size: 12px;
        line-height: 20px;
        color: #727293;
      }

      .property-single_dropdown-allocation-modal {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      .key-allocation-modal {
        margin-top: -11px;
      }

      .property-single-dropdown-options {
        outline: none;
        line-height: 1.5em;
        background: #fff;
        margin: 8px 12px !important;
      }

      .property-single-dropdown-menu {
        padding: 0px;
        border-radius: 6px;
        width: 100% !important;
        // height: 210px;
        overflow-y: auto;
        overflow-x: hidden;
        font-size: 14px;

        .dropdown-item {
          margin-top: 8px !important;
        }
        .allocation-cost-type-modal {
          margin: 10px 0px !important;
        }
      }
      .four-three-allocationKey {
        height: 210px !important;
      }
      .two-allocationKey {
        height: 145px !important;
      }
      .three-allocationKey {
        height: 180px !important;
      }

      .add-allocationkey-dropdown-btn {
        width: 100% !important;
        // margin-left: -15px !important;
      }
    }
    .cancel_addbtn {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }
  }
}

////////////property modal / general information ////////

.generalinfo-page {
  .property-input-box {
    display: flex;
    .delete-propertypicture-button {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #727293;
      border: none;
      background: transparent;
      margin-top: 8px;
      margin-left: 10px;
    }
  }
  .file-err {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #e8193c;
  }
  .dropdown {
    box-shadow: none !important; // 0px 0px 2px rgba(14, 14, 29, 0.06);
    &:focus {
      outline: none;
    }
    .btn.btn-secondary:not(:disabled):not(.disabled) {
      // div {
      font-size: 14px;
      line-height: 25px;
      color: #0e0e1d;
      // }
      &:active {
        background: none;
        border: none;
        box-shadow: none;
        background-color: #ffffff !important;
        border: 1px solid #dadae6;
        height: 36px;
        border-radius: 4px;
      }
      background: none;
      border: none;
      box-shadow: none;
      background-color: #ffffff !important;
      border: 1px solid #dadae6;
      height: 36px;
      border-radius: 4px;
    }
    .property-single-dropdown-menu {
      .dropdown-item {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #0e0e1d;
        margin: 4px 0px;
        padding: 8px 12px !important;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:hover {
          background: #f2f2fc !important;
        }
      }
      padding: 0 !important;
      background: #ffffff;
      border: 1px solid #dadae6;
      box-sizing: border-box;
      box-shadow: 0px 8px 16px rgba(14, 14, 29, 0.1), 0px 1px 2px rgba(14, 14, 29, 0.06);
      border-radius: 4px;
    }
  }
  .add-property-modal-text {
    display: flex;
    justify-content: space-between;
  }
  padding-left: 2px;

  .form-control.is-invalid {
    background-image: none;
  }

  .form-group {
    .form-control {
      &:focus {
        border: 1px solid #dadae6;
        box-sizing: border-box;
        //box-shadow: 0px 1px 2px rgba(14, 14, 29, 0.06);
      }
      background: #ffffff;
      border: 1px solid #dadae6;
      box-sizing: border-box;
      //box-shadow: 0px 1px 2px rgba(14, 14, 29, 0.06);
      border-radius: 4px;
      height: 36px;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #0e0e1d;
    }
    margin-bottom: 13px !important;
  }

  .form-label {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    padding: 0px !important;
    margin-bottom: 1px;
    color: #727293;
  }

  .text-icon {
    padding-left: 0px !important;
  }

  .addbtn-icon {
    position: absolute;
    margin-top: -30px;
    right: 26px;
    border: 1px solid #dadae6;
    border-radius: 4px;
    padding: 4px;
    cursor: pointer;
  }

  .subbtn-icon {
    position: absolute;
    margin-top: -30px;
    right: 53px;
    border: 1px solid #dadae6;
    border-radius: 4px;
    padding: 9px 6px;
    cursor: pointer;
  }

  .noofunit {
    .form-control.is-invalid {
      background-image: none;
    }
  }

  .property-single_dropdown-allocation-modal {
    text-align: left !important;
    outline: none;
    line-height: 1.5em;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    background: #fff;
    border: 1px solid #d5dce6;
    border-radius: 4px;
    width: 100% !important;
    display: flex;
    justify-content: space-between;
  }

  .property-single-dropdown-menu {
    width: 100%;
    padding: 10px 15px;
  }
  // .generalInfo_radiobtns{
  //   // padding-left: 17px !important;
  // }

  .zipcode-property {
    padding-left: 0px !important;
  }

  .danger-text {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #0e0e1d;
    flex: none;
    order: 0;
    align-self: stretch;
    margin-top: 48px;
  }

  .delete-button {
    border: 0px solid !important;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 0px !important;
    background-color: #f7f7fa;
    color: #e8193c;
    margin-bottom: 20px;
    cursor: pointer;
    text-align: left;
    width: fit-content;
  }

  .delete-property-label{
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #0e0e1d;
    padding: 0px !important;
    margin-top: 35px !important;
    margin-bottom: 18px !important;
  }

  .cut-off-edit-button {
    border: 0px solid !important;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    padding: 0px !important;
    background-color: #f7f7fa;
    margin-left: 10px;
    color: #4949F5;
    cursor: pointer;
  }
  .profile-image-container {
    display: flex;
    margin-top: 38px;

    .profile-image {
      width: 108px;
      height: 108px;
      margin-right: 24px;
      margin-bottom: 16px;

      img {
        width: 100%;
        border-radius: 4px;
        height: 100%;
        object-fit: cover;
      }
    }

    .upload-container {
      display: flex;
      flex-flow: column;
      margin: 18px 0px 0px 0px;

      .upload-btn {
        cursor: pointer;
        background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
        border: 1px solid $colorGrey;
        box-sizing: border-box;
        box-shadow: 0px 1px 2px $box-shadow;
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $colorDarkPurple;
        padding: 7px 10px;
        // width: 115px;
        width: 125px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px 0px 13px 0px;
      }

      .file-desc {
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        color: $colorLightPurple;
      }
    }
  }
}

.emptyproperty {
  text-align: center;
  margin-top: 10%;
}

.emptyproperty-icon {
  height: 16px;
  margin: auto !important;
  width: 16px;
}

.emptyproperty-text {
  margin-top: 25px;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #13135c;
}

.add_proprty_modal,
.generalinfo-page {
  .file-err {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #e8193c;
  }
  .react-datepicker-year-header {
    display: none !important;
  }
  .form-control.is-invalid {
    background-image: none !important;
  }
  .cancel_addbtn {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .add-property-modal-text {
    display: flex;
    justify-content: space-between;
  }

  .add-property-modal-text-INFO {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #111147;
  }

  .add-property-modal-text-Icon {
    height: 20px;
    width: 20px;
  }

  .profile_modal_radiobtns {
    display: flex;
    justify-content: space-between;
  }

  .profile_modal_radiobtns-label {
    margin-left: 4px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #0e0e1d;
  }

  .custom-input {
    display: flex;
    justify-content: flex-end;

    .custom-input_padding {
      padding-left: 0px !important;

      .custom-input_margin {
        margin-left: -20px;
      }
    }
  }

  .addbtn-icon {
    position: absolute;
    margin-top: -30px;
    right: 26px;
    border: 1px solid #dadae6;
    border-radius: 4px;
    padding: 4px;
  }

  .subbtn-icon {
    position: absolute;
    margin-top: -30px;
    right: 53px;
    border: 1px solid #dadae6;
    border-radius: 4px;
    padding: 9px 6px;
  }

  .noofunit {
    .form-control.is-invalid {
      background-image: none;
    }
  }

  .add_property_modal_dropdown {
    .property-single_dropdown {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
    .property-single-dropdown-menu {
      width: 100%;
      padding: 10px 15px;
    }
    .invalid-feedback {
      width: 100% !important;
      margin-top: 0.25rem !important;
      font-size: 80% !important;
      color: #dc3545 !important;
    }

    .heatingDropdown_errormsg {
      width: 100% !important;
      margin-top: 0.25rem !important;
      font-size: 80% !important;
      color: #dc3545 !important;
    }
  }
}

.image_container_property {
  .profile-image-container {
    display: flex;
    margin-top: 38px;

    .profile-image {
      width: 108px;
      height: 108px;
      margin-right: 24px;
      margin-bottom: 16px;

      img {
        width: 100%;
        border-radius: 4px;
        height: 100%;
        object-fit: cover;
      }
    }

    .upload-container {
      display: flex;
      flex-flow: column;
      margin: 18px 0px 0px 0px;

      .upload-btn {
        cursor: pointer;
        background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
        border: 1px solid $colorGrey;
        box-sizing: border-box;
        box-shadow: 0px 1px 2px $box-shadow;
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $colorDarkPurple;
        padding: 7px 10px;
        // width: 115px;
        width: 125px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px 0px 13px 0px;
      }

      .file-desc {
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        color: $colorLightPurple;
      }
    }
  }
}
/////////////

.siderbar_empty_property {
  .main-container {
    margin-top: 0px !important;
    background-color: #f2f2fc;
    border-radius: 4px;
    margin-left: 12px;
    margin-right: 12px;
    padding: 16px 20px;
  }
  .empty-state-icon {
    display: none;
  }
  .icon-container {
    display: none;
  }
}

// .react-datepicker__current-month {
//   display: none !important;
// }
.property-navbar {
  cursor: pointer;
  overflow-x: scroll;
  scrollbar-width: none !important;
}
.property-navbar::-webkit-scrollbar{
  display: none;
}
.emptystate-property-routes {
  pointer-events: none !important;
  cursor: default;
  color: #bfbfd2;
}
