// show receipt modal

.emptyState {
  opacity: 0.5;
}
.purple-btn, .gray-btn {
  min-width: 100px;
}

table.table.borderless.record-list-table tr.table-data {
  box-shadow: none !important;
  background: #FFFFFF;
}

.MuiSelect-select:focus{
  background: rgba(0,0,0,0) !important;
}

.MuiOutlinedInput-notchedOutline{
   border-color: #DADAE5 !important;//#3f51b5; 
   border-width: 1px !important; 
}

.show-receipt-modal {
  .modal-body {
    padding: 24px 21px !important;

    .show-receipt-container {
      .receipt-side-img {
        position: relative;
        top: 0;
        left: 0;
        width: 205px;
        height: 249px;
        .avatar {
          height: 100%;
          width: 100%;
        }
        .download-icon {
          width: 15.84px;
          height: 16px;
          cursor: pointer;
          position: absolute;
          bottom: 21px !important;
          right: 16px !important;
        }
        .expand-icon {
          cursor: pointer;
          position: absolute;
          bottom: 16px !important;
          left: 16px !important;
        }
      }
      .show-receipt-content {
        margin: 0px 0px 0px 39px;
        width: 69%;
        .text-row {
          margin-bottom: 24px;
        }
        .heading-container {
          display: flex;
          justify-content: center;
          align-items: center;
          .heading-text-wrapper {
            .card-title {
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: $colorBlack;
            }
          }
          .heading-right-content {
            align-self: center !important;
            .btn-link {
              .button-img-wrapper {
                .btn-text {
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 24px;
                  text-decoration-line: underline;
                  color: $label-color;
                }
              }
            }
          }
        }
        .receipt-info-field {
          .app-label {
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: $label-color;
            margin-bottom: 0px;
          }
          .value-text-container {
            .card-value {
              font-weight: 500;
              font-size: 14px;
              line-height: 20px; // color: $colorBlack;
              //white-space: normal;
              //text-overflow: ellipsis;
              word-break: break-all;
              width: 110px;
              overflow: hidden;
            }
            .text-img-wrapper{
              align-items: baseline;
            }
          }
        }
      }
    }
    .show-modal-footer {
      .gray-btn {
        margin: 0 !important;
        padding: 7px 11px !important;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $colorDarkPurple;
        margin-top: 24px !important;
      }
    }
  }
  .modal-content {
    width: 712px;
    background: $colorWhite;
    box-shadow: 0px 1px 4px rgba(14, 14, 29, 0.16), 0px 16px 24px rgba(14, 14, 29, 0.08);
    border-radius: 4px;
  }
}
// delete module
.modal-header {
  background: $globalBackgroundColor;
  border-bottom: 1px solid $colorGrey !important;
  padding: 16px 24px;
  .modal-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $colorBlack !important;
  }
  .close {
    padding: 16px 15px;
    &:hover {
      color: $colorLightPurple !important;
      color: $colorLightPurple;
    }
    span {
      color: $colorLightPurple;
    }
  }
}
.custom-modal {
  .modal-content {
    .modal-body {
      padding: 20px 24px;
      .modal-inner-body.delete-receipt-body {
        p {
          font-size: 14px;
          line-height: 24px;
          color: $colorBlack;
          font-weight: 400;
        }
        .center-spacing {
          margin: 0px 0px 24px 0px;
          display: flex;
          justify-content: space-between;
          span {
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: $colorBlack;
          }
          span.right-side {
            font-size: 14px;
            line-height: 24px;
            text-align: right;
            color: $colorDeepPurple;
            font-weight: normal;
          }
        }
      }
      .delete-modal-footer {
        .gray-btn {
          margin: 0 !important;
          background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
          border: 1px solid $colorGrey;
          box-sizing: border-box;
          box-shadow: 0px 1px 2px $box-shadow;
          border-radius: 4px;
          padding: 7px 11px !important;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $colorDarkPurple !important;
        }
        .red-btn {
          float: right;
          padding: 7px 13px;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $colorWhite;
          background: linear-gradient(180deg, $colorRed 0%, #db1738 100%);
          border: 1px solid #d41636;
          box-sizing: border-box;
          box-shadow: 0px 1px 2px $box-shadow;
          border-radius: 4px;
        }
      }
    }
  }
}
// show receipt modal
.show-receipt-modal {
  .modal-body {
    padding: 24px !important;
    .show-receipt-container {
      .receipt-side-img {
        .pdf-preview {
          width: 100%;
          height: 100%;
        }
        .pdf-preview::after {
          content: '';
          display: block;
        }
        position: relative;
        top: 0;
        left: 0;
        // width: 30%;
        // height: 100%;
        .avatar {
          height: 100%;
          width: 100%;
        }
        .download-icon {
          width: 15.84px;
          height: 16px;
          cursor: pointer;
          position: absolute;
          bottom: 19px;
          right: 15px;
        }
        .expand-icon {
          cursor: pointer;
          position: absolute;
          bottom: 14px;
          left: 14px;
        }
      }
      .show-receipt-content {
        margin: 0px 0px 0px 24px;
        width: 70%;

        .text-row {
          margin-bottom: 24px;
        }
        .heading-container {
          display: flex;
          justify-content: center;
          align-items: center;
          .heading-text-wrapper {
            .card-title {
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: $colorBlack;
            }
          }
          .heading-right-content {
            align-self: center !important;
            .btn-link {
              .button-img-wrapper {
                .btn-text {
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 24px;
                  text-decoration-line: underline;
                  color: $label-color;
                }
              }
            }
          }
        }
        .receipt-info-field {
          .app-label {
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: $label-color;
            margin-bottom: 0px;
          }
          .value-text-container {
            .card-value {
              font-weight: 500;
              font-size: 14px;
              line-height: 20px; // color: $colorBlack;
              // white-space: nowrap;
              // text-overflow: ellipsis;
              word-break: break-all;
              width: 190px;
              overflow: hidden;
              color: $colorBlack;
            }
            .text-img-wrapper{
              align-items: baseline;
            }
          }
        }
      }
    }
    .show-modal-footer {
      .gray-btn {
        margin: 0 !important;
        padding: 7px 11px !important;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $colorDarkPurple;
        margin-top: 24px !important;
      }
    }
  }
  .modal-content {
    width: 712px;
    background: $colorWhite;
    box-shadow: 0px 1px 4px rgba(14, 14, 29, 0.16), 0px 16px 24px rgba(14, 14, 29, 0.08);
    border-radius: 4px;
  }
}
// edit receipt modal
.add-edit-receipt-modal {
  .property-single_dropdown {
    display: flex;
    justify-content: space-between;
  }
  .modal-content {
    .modal-body {
      padding: 24px;
      .add-receipt-modal {
        .multiselect-container {
          .searchBox {
            // width: 11%;
          }
          .optionListContainer {
            margin: 8px 0px 0px 0px;
            height: 216px;
            .optionContainer {
              height: 120%;
              margin: 0px;
              background: $colorWhite;
              border: 1px solid $colorGrey;
              box-sizing: border-box;
              box-shadow: 0px 8px 16px rgba(14, 14, 29, 0.1), 0px 1px 2px $box-shadow;
              border-radius: 4px;
              display: flex;
              flex-flow: column;
              Button.color-btn {
                flex: 0;
                margin: 0px 8px 2px;
              }
              li {
                padding: 8px 12px;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: $colorBlack;
                margin: 0px 0px 4px;
                display: flex;
                justify-content: space-between;
                flex-direction: row-reverse;
                align-items: center;
                &:hover {
                  background: $hover-color !important;
                }
                .checkbox[type='checkbox']:checked {
                  & & {
                    background: $hover-color;
                  }
                }
                .checkbox {
                  -webkit-appearance: none;
                  display: flex;
                  float: right;
                  margin: 0px;
                  font-size: 20px !important;
                }
                .checkbox[type='checkbox']:after {
                  position: relative;
                  display: block;
                  width: 16px;
                  height: 12px;
                  content: '';
                  background-image: url('../../../../../assets/images/module/general/Check.svg') !important;
                  background-repeat: no-repeat;
                  background-position: center;
                }
                .checkbox[type='checkbox']:checked:after {
                  filter: invert(32%) sepia(75%) saturate(7390%) hue-rotate(233deg) brightness(101%) contrast(101%);
                }
              }
              li.bgSelected {
                background-color: rgb(242, 242, 252) !important;
              }
            }
          }
        }
        .file-details {
          .value-text-container {
            margin: 0px 0px 15px;

            .text-img-wrapper {
              .card-value {
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: $colorBlack;
              }
              img {
                width: 14px !important;
                height: 16px !important;
                cursor: pointer;
              }
            }
          }
        }
        .file-dropzone {
          &:focus {
            outline: none;
          }
          background: $globalBackgroundColor;
          border: 1px dashed$border-gray;
          box-sizing: border-box;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center; // padding: 39px 55px;
          margin: 0px 0px 24px 0px;
          // cursor: pointer;
          section {
            .drag-div {
              display: flex;
              flex-flow: column;
              justify-content: center;
              align-items: center;
              padding: 16px 0px;
              p.first {
                color: $purpleTheme;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                margin: 8px 0px 4px 0px;
              }
              p.second {
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                color: $colorLightPurple;
              }
              img {
                filter: invert(32%) sepia(75%) saturate(7390%) hue-rotate(233deg) brightness(101%) contrast(101%);
              }
            }
            .fileSelected {
              p.first {
                color: $border-gray;
              }
              p.second {
                color: #dadae5;
              }
              img {
                filter: none;
              }
            }
            div {
              &:focus {
                outline: none;
              }
              p {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                margin: 0;
                color: $border-gray; // padding: 40px 55px
              }
            }
          }
        }
        .form-group {
          .form-label {
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            color: $colorLightPurple;
            margin: 0px 0px 3px;
          }

          .form-control {
            background: $colorWhite;
            border: 1px solid $colorGrey;
            box-sizing: border-box;
            // box-shadow: 0px 1px 2px $box-shadow;
            border-radius: 4px;
            height: 36px;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: $colorBlack;
          }
        }
        .switch-container {
          display: flex;
          // .react-switch-bg {
          //     width: 28px !important;
          //     height: 16px !important;
          // }
          // margin: 15px 0px 16px;
          // .react-switch-handle {
          //     height: 12px !important;
          //     width: 12px !important;
          //     top: 2px !important;
          //     left: 0px;
          // }
          .app-label {
            @media only screen and (max-width: 500px){
              font-weight: 400;
              font-size: 10px;
              line-height: 16px;
              color: $colorBlack;
              width: 100%;
            }
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: $colorBlack;
          }
          .multiselect-container {
            background: $colorWhite;
            border: 1px solid $colorGrey;
            box-sizing: border-box;
            box-shadow: 0px 1px 2px $box-shadow;
            border-radius: 4px; // height: 36px;
            cursor: pointer;
            .search-wrapper {
              &:focus-within {
                border: 1px solid $purpleTheme;
                outline: none;
              }
              ._1cgu0CqbPSvU9G_sHmk1wv {
                top: 25% !important;
              }
              .chip {
                .icon_cancel {
                  color: $colorLightPurple;
                }
                margin: 0px 4px 0px 0px;
                padding: 3px 8px;
                background: $hover-color !important;
                border-radius: 4px !important;
              }
              background: none;
              box-shadow: none !important;
              border: none;
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              color: $colorDeepPurple;
              padding: 3px 4px;
            }
          }
        }
        .app-label {
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          color: $colorBlack;
        }
        .multiselect-container {
          background: $colorWhite;
          border: 1px solid $colorGrey;
          box-sizing: border-box;
          box-shadow: 0px 1px 2px $box-shadow;
          border-radius: 4px; // height: 36px;
          .search-wrapper {
            background: none;
            box-shadow: none !important;
            border: none;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: $colorDeepPurple; // padding: 3px 11px !important;
            &:focus-within {
              border: 1px solid $purpleTheme;
              outline: none;
            }
          }
        }
        .selectArrow {
          background-color: $purpleTheme;
        }
        .chip {
          margin: 0px 4px 0px 0px;
          padding: 3px 8px;
          background: $hover-color !important;
          border-radius: 4px !important;
        }
        .icon_cancel {
          color: $colorLightPurple;
        }

        .time-frame-section {
          .custom-date-field {
            @media only screen and (max-width: 500px){
              width: 100%;
            }
            width: 50%;
          }

          .time-frame-heading {
            margin: 0px 0px 0px 0px;
            display: flex;
            justify-content: space-between;
            .value-text-container {
              .card-value {
                font-weight: 500 !important;
                font-size: 14px;
                line-height: 20px;
                color: $colorDeepPurple;
              }
            }
          }
          .time-frame-options {
            .single-time-option {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin: 0px 0px 10px;
              .form-check {
                .form-check-input[type='radio']:before {
                  content: '';
                  position: absolute;
                  display: none;
                }
                .form-check-input[type='radio'] {
                  -webkit-appearance: none;
                  outline: none;
                  background: $colorWhite;
                  /* Oceanic Purple/200 */
                  // position: absolute;
                  width: 16px;
                  height: 16px; //  left: 0px;
                  //  top: 0px;
                  border: 1px solid $colorGrey;
                  border-radius: 8px;
                }
                .form-check-input[type='radio']:after {
                  content: '';
                  position: absolute;
                  display: none;
                }
                .form-check-input[type='radio']:checked {
                  background: linear-gradient(180deg, $blueForBg 0%, $purpleTheme 100%);
                }
                .form-check-input:after {
                  content: '';
                  position: absolute;
                  display: none;
                }
                .form-check-input:checked:after {
                  display: block;
                  top: 3px;
                  left: 3px;
                  width: 8px;
                  height: 8px;
                  border-radius: 8px;
                  background: $colorWhite;
                  border: 1px solid $Border-blue;
                }
                .form-check-label {                  
                  @media only screen and (max-width: 500px){
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    color: $colorBlack;
                    width: 150px;
                    white-space: wrap;
                    margin-left: 5px;
                    margin-top: 4px;
                  }
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 16px;
                  margin-left: 5px;
                  margin-top: 4px;
                  color: $colorBlack;
                }
              }
              span {
                @media only screen and (max-width: 500px){
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 16px;
                  color: $label-color;
                }
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                color: $label-color;
              }
            }
          }
        }
        p.card-value.error {
          font-size: 80%;
          color: #dc3545;
        }
      }
      .add-receipt-modal-footer {
        display: flex;
        justify-content: space-between;
        margin: 22px 0px 0px;
        button.color-btn.gray-btn.btn.btn-secondary {
          margin: 0px !important;
          background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
          border: 1px solid $colorGrey;
          box-sizing: border-box;
          box-shadow: none;//0px 1px 2px $box-shadow;
          border-radius: 4px;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $colorDarkPurple;
          padding: 7px 11px !important;
          float: left;
        }
        button.color-btn.red-btn.btn.btn-secondary {
          background: linear-gradient(180deg, $purpleTheme 0%, $blueForBg 100%);
          border: 1px solid $Border-blue;
          box-sizing: border-box;
          box-shadow: 0px 1px 2px $box-shadow;
          border-radius: 4px;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $colorWhite;
          padding: 7px 11px !important;
          float: right;
        }
      }
    }
  }
}
.date-range-focus {
  padding-left: 30px !important;
}
// Date picker start
.date-range-focus:focus {
  border: 1px solid $purpleTheme !important;
  box-shadow: 0px 0px 2px 2px $box-shadow-light !important;
  border-radius: 4px;
  outline: none !important;
}
.react-datepicker__input-container:focus {
  outline: none;
}
.react-datepicker__input-container,
.custom-date-field {
  outline: none;
  box-shadow: none !important; //0px 1px 2px $box-shadow;
  .input-group-prepend .input-group-text {
    background-color: $colorWhite !important;
    // border-right:0px ;
    // outline: none!important;
    border: none !important;
  }
  .input-group {
    border: 1px solid $colorGrey;
    border-radius: 4px;
    box-shadow: none !important; //0px 0px 2px $box-shadow;
    height: 36px;
    background-color: $colorWhite;
    
  }
  .date-input {
    box-shadow: none !important;
    margin-left: -9px !important;
    outline: none !important;
    height: 30px !important;
    margin-top: 4px;
    border: none !important;
  }
  .input-group-focus {
    border: 1px solid $purpleTheme;
    box-shadow: 0px 0px 2px 2px $box-shadow-light;
    border-radius: 4px;
    outline: none !important;
    .input-group-prepend .input-group-text {
      border: none !important;
    }
    .form-control {
      border: none !important;
      outline: none;
      box-shadow: none !important;
    }
  }
}
.react-datepicker {
  background: $colorWhite !important;
  box-shadow: 0px 8px 16px rgba(14, 14, 29, 0.1), 0px 1px 2px $box-shadow;
  border-radius: 4px !important;
  width: 272px;
  //height: 254px;
  border: none;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 32px;
  .react-datepicker__triangle {
    display: none;
    margin-top: 0 !important;
  }
  .react-datepicker__month-container {
    width: 100%;
    height: 100% !important;
    border: none !important;
    .react-datepicker__header {
      background: none;
      border: none;
      .react-datepicker__current-month {
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        // color: $colorBlack;
        display: flex;
        padding: 0px 12px;
      }
      .react-datepicker__day-names {
        .react-datepicker__day-name {
          color: $colorLightPurple !important;
        }
      }
    }
    .react-datepicker__month {
      margin: 0px;
      .react-datepicker__week {
        .react-datepicker__day--selected,
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--selected,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--selected,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--selected,
        .react-datepicker__year-text--in-selecting-range,
        .react-datepicker__year-text--in-range,
        .react-datepicker__day--keyboard-selected,
        .react-datepicker__month-text--keyboard-selected,
        .react-datepicker__quarter-text--keyboard-selected,
        .react-datepicker__year-text--keyboard-selected {
          background: linear-gradient(180deg, $purpleTheme 0%, $blueForBg 100%) !important;
          border: 1px solid $Border-blue;
          box-sizing: border-box;
          box-shadow: 0px 1px 2px $box-shadow;
          border-radius: 4px;
          outline: none;
        }
        .react-datepicker__day--outside-month {
          color: #9a9ab5;
        }
      }
    }
  }
  // .react-datepicker__navigation{
  //     border: 0.32rem solid transparent;
  // }
  .react-datepicker__navigation--next {
    border: 0.3rem solid transparent;
    border-left-color: $colorLightPurple !important;
  }
  .react-datepicker__navigation--previous {
    border: 0.3rem solid transparent;
    border-right-color: $colorLightPurple !important;
  }
  button.react-datepicker__navigation.react-datepicker__navigation--previous {
    left: 202px !important;
    top: 27px !important;
  }

  // button.react-datepicker__navigation.react-datepicker__navigation--previous::after{

  //     height: 12px;
  //   position: absolute;
  //   display: block;
  //   background:url('../../../../../assets/images/module/general/prevIcon.svg');
  //   width: 16px;

  //   content: "";

  // background-repeat: no-repeat;
  //  background-position: center;
  // }
  button.react-datepicker__navigation.react-datepicker__navigation--next {
    top: 27px;
    right: 25px;
  }
}

.daterangepicker td.active {
  background: linear-gradient(180deg, $purpleTheme 0%, $blueForBg 100%) !important;
}
.table-condensed {
  & thead {
    tr:nth-child(2) {
      & th {
        color: $colorLightPurple;
        font-weight: 500;
      }
    }
  }
}
.bs-tooltip-top {
  & .tooltip-inner {
    background-color: $dark-blue !important;
    max-width: 235px;
    padding-right: 1.5rem;
  }
  & .arrow:before {
    border-top-color: $dark-blue !important;
  }
}

@media only screen and (max-width: $breakPointXl), screen and (max-height: 808px) {
  // Receipt header start
  .page-header {
    margin-top: 4px;
    .heading-container {
      display: flex;
      justify-content: center;
      align-items: center; // .heading-text-wrapper {
      // }
      .heading-right-content {
        .purple-btn {
          &:focus {
            box-shadow: none;
          }
          &:active {
            color: $colorWhite !important;
          }
          margin: 0;
        }
      }
    }
  }
  .filters {
    display: flex;
    align-items: center;
    margin: 16px 0px 19px;
    .section-1 {
      //flex: 0.8;
      .search-filter {
        .search-icon {
          left: 10px;
          top: 10px;
        }
        .search-input {
          background: $colorWhite;
          border: 1px solid $colorGrey;
          box-sizing: border-box;
          box-shadow: none !important;//0px 1px 2px $box-shadow;
          border-radius: 4px;
          height: 36px;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: $colorLightPurple;
          outline: none;
          padding: 8px 8px 8px 33px;
          // width: 352px;
          width: 100%;
        }
      }
    }
    .sort-filter {
      line-height: 16px;
      .sort-lable {
        font-size: 14px;
        color: $colorPurple1;
      }
      .sort-value {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $colorDeepPurple;
      }
      .sort-icon {
        margin: 0px 7px;
        width: 5.78px;
        height: 12px;
      }
    }
    .section-2 {
      flex: 1.3;
      .switch-filter {
        .app-label {
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: $colorBlack;
        }
        .switch-container {
          display: flex;
          div {
            .react-switch-handle {
              width: 12px !important;
              height: 12px !important;
              left: 2px;
              top: 2px !important;
            }
          }
        }
      }
      margin: 0px 22px;
    } // .section-3{
    //      flex: 0.3;
    // }
  }
  #primary-content {
    & > .receiptsClass {
      .divider {
        margin-bottom: 0px;
      }
      table.table.borderless.record-list-table {
        padding-top: 0px !important;
        padding-bottom: 0px;
        padding: 0px;
        margin: 0px;
        tbody {
          // display: flex;
          tr.table-heading {
            border-spacing: 0px !important; // display: flex;
            // justify-content: flex-start;
            td.receipt,
            .amount,
            .cost-type,
            .time-frame,
            .date {
              padding-bottom: 2px;
              padding-top: 12px;
            } // align-items: flex-start;
            td.receipt {
              width: 18%;
              text-align: left;
            }
            td.amount {
              width: 15%;
              text-align: right;
            }
            .cost-type {
              padding-left: 12%;
              width: 25%; // 	text-align: left;
            }
            .date {
              width: 10%; //text-align: left;
            }
            .time-frame {
              text-align: left;
            }
            .empty {
              width: 14.4%;
              text-align: right;
            }
          }
          tr.table-data {
            .text-img-wrapper .btn-img.left {
              margin-right: 11px !important;
            }
            td {
              padding-top: 1.5%;
              padding-bottom: 1.5%;
            } // td.amount,.cost-type,.receipt,.time-frame,.date{
            //     width:8% !important;
            // }
            td:first-child {
              color: $colorBlack !important;
              padding-top: 0px;
              padding-bottom: 0px;
              margin: 0px;
            }
            td:not(td:first-child) {
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;
            }
            .receipt {
              width: 18%;
              text-align: left;
            }
            .amount {
              width: 8%;
              text-align: right;
            }
            .cost-type {
              padding-left: 12%;
              width: 25%;
            }
            .date {
              width: 12%;
              text-align: left;
            }
            .time-frame {
              width: 20%;
              // width: 18%; //german
              text-align: left;
            }
            .empty {
              width: 13.5%;
              .show-btn {
                // margin-right: 16px;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: $colorDarkPurple;
                background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
                border: 1px solid $colorGrey;
                box-sizing: border-box;
                box-shadow: 0px 1px 2px $box-shadow;
                border-radius: 4px;
                margin-right: 25px;
              }
              .btn-group {
                .record-dropdown {
                  background-color: transparent;
                  background: transparent;
                  box-shadow: none;
                  border: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $breakPointLg), screen and (max-height: 808px) {
  // Receipt header start
  .page-header {
    margin-top: 1px;
    .heading-container {
      display: flex;
      justify-content: center;
      align-items: center; // .heading-text-wrapper {
      // }
      .heading-right-content {
        .purple-btn {
          &:focus {
            box-shadow: none;
          }
          &:active {
            color: $colorWhite !important;
          }
          margin: 0;
        }
      }
    }
  }
  .filters {
    display: flex;
    align-items: center;
    margin: 12px 0px 19px;
    .section-1 {
      //flex: 0.8;
      .search-filter {
        .search-icon {
          left: 10px;
          top: 10px;
        }
        .search-input {
          background: $colorWhite;
          border: 1px solid $colorGrey;
          box-sizing: border-box;
          box-shadow: none !important; //0px 1px 2px $box-shadow;
          border-radius: 4px;
          height: 36px;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: $colorLightPurple;
          outline: none;
          padding: 8px 8px 8px 33px;
          // width: 352px;
          width: 100%;
        }
      }
    }
    .sort-filter {
      line-height: 16px;
      .sort-lable {
        font-size: 14px;
        color: $colorPurple1;
      }
      .sort-value {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $colorDeepPurple;
      }
      .sort-icon {
        margin: 0px 7px;
        width: 5.78px;
        height: 12px;
      }
    }
    .section-2 {
      flex: 1.3;
      .switch-filter {
        .app-label {
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: $colorBlack;
        }
        .switch-container {
          display: flex;
          div {
            .react-switch-handle {
              width: 12px !important;
              height: 12px !important;
              left: 2px;
              top: 2px !important;
            }
          }
        }
      }
      margin: 0px 22px;
    } // .section-3{
    //      flex: 0.3;
    // }
  }
  //Receipt Header End
  #primary-content {
    padding: 21px 23px !important;
    & > .receiptsClass {
      .divider {
        margin-bottom: 0px;
      }
      table.table.borderless.record-list-table {
        padding-top: 0px !important;
        padding-bottom: 0px;
        margin: 0px;
        tbody {
          // display: flex;
          tr.table-heading {
            border-spacing: 0px !important; // display: flex;
            // justify-content: flex-start;
            td {
              padding-bottom: 2px;
              padding-top: 12px;
            } // align-items: flex-start;
            td.receipt {
              width: 24% !important;
              text-align: left;
            }
            td.amount {
              width: 10%;
              text-align: right;
            }
            .cost-type {
              padding-left: 6%;
              width: 23%; // 	text-align: left;
            }
            .date {
              width: 12.5%; //text-align: left;
            }
            .time-frame {
              text-align: left;
              // width: 17.5%;
              width: 16.5%; //german
            } // .empty {
            // 	width: 14.4%;
            // }
          }
          tr.table-data {
            td {
              padding-top: 1.7%;
              padding-bottom: 1.7%;
            }
            td:first-child {
              color: $colorBlack !important;
              padding-top: 0px;
              padding-bottom: 0px;
              margin: 0px;
              white-space: nowrap;
              text-overflow: ellipsis;
              max-width: 108px;
              overflow: hidden;
            }
            td:not(td:first-child) {
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;
            }
            .receipt {
              width: 20%;
              text-align: left;
            }
            .amount {
              width: 10%;
              text-align: right;
            }
            .cost-type {
              padding-left: 6%;
              width: 23%;
            }
            .date {
              width: 12.5%;
              text-align: left;
            }
            .time-frame {
              // width: 17.5%;
              width: 16.5%; //german
              text-align: left;
            }
            .empty {
              width: 13.5%;
              .show-btn {
                margin-right: 2%;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: $colorDarkPurple;
                background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
                border: 1px solid $colorGrey;
                box-sizing: border-box;
                box-shadow: 0px 1px 2px $box-shadow;
                border-radius: 4px;
              }
              .btn-group {
                .record-dropdown {
                  background-color: transparent;
                  background: transparent;
                  box-shadow: none;
                  border: none;
                }
              }
            }
          }
        }
      }
      .edit-delete-dropdown {
        position: absolute !important;
        left: -60px !important;
        margin: 0px !important;
        width: 208px !important;
        box-shadow: 0px 8px 16px rgba(14, 14, 29, 0.1), 0px 1px 2px $box-shadow !important;
        border-radius: 4px !important;
        border: 1px solid $colorGrey !important;
      }
      // .dropdown-item {
      //   padding: 0.25rem 0.9rem !important;
      // }
    }
  }
}

@media only screen and (max-width: 1330px) {
  #primary-content {
    & > .receiptsClass {
      .divider {
        margin-bottom: 0px;
      }
      table.table.borderless.record-list-table {
        padding-top: 0px !important;
        padding-bottom: 0px;
        margin: 0px;

        tbody {
          // display: flex;
          tr.table-heading {
            border-spacing: 0px !important; // display: flex;
            // justify-content: flex-start;
            td {
              padding-bottom: 2px;
              padding-top: 12px;
            } // align-items: flex-start;
            td.receipt {
              width: 20% !important;
              text-align: left;
            }
            td.amount {
              width: 10%;
              text-align: right;
            }
            .cost-type {
              padding-left: 4%;
              width: 20%; // 	text-align: left;
            }
            .date {
              width: 11%; //text-align: left;
            }
            .time-frame {
              text-align: left;
              width: 16%;
              // width: 14%; //german
            } // .empty {
            // 	width: 14.4%;
            // }
          }
          tr.table-data {
            td {
              padding-top: 1.7%;
              padding-bottom: 1.7%;
            }
            td:first-child {
              color: $colorBlack !important;
              padding-top: 0px;
              padding-bottom: 0px;
              margin: 0px;
            }
            td:not(td:first-child) {
              font-size: 13px;
              font-weight: 400;
              line-height: 24px;
            }
            .receipt {
              .card-value {
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 108px;
                overflow: hidden;
              }
              width: 20%;
              text-align: left;
            }
            .amount {
              width: 11%;
              text-align: right;
            }
            .cost-type {
              padding-left: 4%;
              width: 20%;
            }
            .date {
              width: 11%;
              text-align: left;
            }
            .time-frame {
              width: 16%;
              // width: 14%; //german
              text-align: left;
            }
            .empty {
              width: 16%;
              .show-btn {
                margin-right: 0px;
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                color: $colorDarkPurple;
                background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
                border: 1px solid $colorGrey;
                box-sizing: border-box;
                box-shadow: 0px 1px 2px $box-shadow;
                border-radius: 4px;
              }
              .btn-group {
                .record-dropdown {
                  background-color: transparent;
                  background: transparent;
                  box-shadow: none;
                  border: none;
                }
              }
            }
          }
        }
      }
    }
  }
  .filters {
    .section-1 {
      .filters {
        .section-1 {
          .search-filter {
            .search-input {
              width: auto !important;
            }
          }
        }
      }
      flex: 1;
    }
    .section-2 {
      .switch-filter {
        .app-label {
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .filters {
    flex-wrap: wrap;
    .section-2 {
      flex-basis: 100%;
      margin: 4% 0px 0px !important;
    }
    .section-3 {
      flex-basis: 100%;
      margin: 3% 0px 0px !important;
    }
    & .section-1 {
      & .search-filter .search-input {
        width: 100%;
      }
    }
  }
  .side-menu-collapsed .app-sidebar > nav #main-menu .nav-item {
    padding-right: 6%;
  }
  .navbar-toggler-icon:after {
    content: 'More';
    position: absolute;
    font-size: 10px;
    font-style: normal;
    font-weight: 750 !important;
    line-height: 20px;
    right: 14%;
    padding-top: 13px;
  }
  // & .nav-item {
  //   height: auto;
  //   .side-nav-icon {
  //     margin-right: 0px;
  //   }
  //   .nav-item-label {
  //     font-size: 10px;
  //   }
  //   a,
  //   a.active,
  //   a:focus {
  //     outline: none;
  //     border: none;
  //     padding: 5px 0px !important;
  //     position: relative;
  //     flex-direction: column;
  //   }
  // }

  //----------------------- new ---------------------
  #primary-content {
    .receiptsClass {
      .table.borderless.record-list-table {
        .table-data {
          td {
            &:first-child {
              padding: 0px 0px 0px 16.83px;
            }
            &:not(td:first-child) {
              text-align: right;
              padding: 0px 4.83px 0px 0px;
            }
          }
          padding: 12px;
          height: 56px;
        }
      }
    }
  }

  //------------------- show receipt modal start ------------------
  .show-receipt-modal {
    max-width: 767px !important;
    width: 100%;
    margin: 0px;
    .modal-content {
      border-radius: 0px !important;

      .modal-header {
        padding: 16px !important;
        border-radius: 0px;
      }
      .modal-body {
        .show-receipt-container {
          flex-flow: column !important;

          .receipt-side-img {
            height: 349px !important;
            width: 100% !important;
          }
          .show-receipt-content {
            margin: 8px 0px 17px 0px !important;
            width: 100%;
            min-height: 377px;
          }
          .heading-container {
            .heading-text-wrapper {
              card-title {
                margin: 0px 0px 4px !important;
              }
            }
          }
          .text-row {
            margin-bottom: 0px;
            .receipt-info-field {
              margin-bottom: 26px;
            }
          }
        }
        .show-modal-footer {
          .gray-btn {
            margin-top: 0px !important;
          }
          bottom: 0px;
          padding: 16px 0px;
          width: 100%;
          left: 0px;
          box-shadow: 0px -1px 2px $box-shadow;
        }
        padding: 16px !important;
      }
    }
  }
  //------------------- show receipt modal end ------------------
  //------------------- Delete receipt modal Start ----------------
  .delete-receipt-modal {
    //max-width: 767px !important;
    margin: 5px !important;
    .modal-content {
      width: 100%;
      //height: 100vh;
      border-radius: 0px;
      .modal-header {
        padding: 16px !important;
        border-radius: 0px;
      }
      .modal-body {
        border-radius: 0px;
        padding: 16px !important;
        .delete-receipt-body {
          p {
            font-weight: 400;
            //width: 65%;
          }
        }
        .delete-modal-footer {
          box-shadow: 0px -1px 2px $box-shadow;
          position: relative;
          bottom: 0;
          left: 0px;
          padding: 16px;
          width: 100%;
        }
      }
    }
  }
  //------------------- Delete receipt modal End ------------------
  //------------------- Add/Edit receipt modal Start ------------------
  // .add-edit-receipt-modal {
  //   max-width: 767px !important;
  //   .property-single_dropdown {
  //     display: flex;
  //     justify-content: space-between;
  //   }
  //   .modal-content {
  //     width: 100%;
  //     height: 100%;
  //     border-radius: 0;
  //     .modal-header {
  //       border-radius: 0;
  //       padding: 16px !important;
  //     }
  //     .modal-body {
  //       .add-receipt-modal {
  //         .file-dropzone {
  //           width: 100%;
  //           height: 100px;
  //         }
  //         .switch-container {
  //           margin: 14px 0px 9px;
  //           display: flex;
  //         }
  //         .time-frame-section {
  //           .custom-date-field {
  //             width: 100%;
  //             min-height: 123px;
  //           }
  //         }
  //       }
  //       padding: 16px;
  //     }
  //     .add-receipt-modal-footer {
  //       position: absolute;
  //       bottom: 0px;
  //       padding: 16px;
  //       left: 0px;
  //       box-shadow: 0px -1px 2px $box-shadow;
  //       background: $colorWhite;
  //       width: 100%;
  //     }
  //   }
  //   margin: 0px;
  //   width: 100%;
  //   height: 100%;
  // }
  //------------------- Add/Edit receipt modal End ------------------
  .modal-dialog.expandImageModal {
    .modal-body {
      .main-div {
        width: 100% !important;
      }
    }
  }
}

.record-list-table {
  .ct-receipt {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 139px;
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 1240px) {
  .record-list-table {
    .ct-receipt {
      width: 96px;
    }
  }
}

// .interaction-area {
//   div:focus-within {
//     border: none;
//     box-shadow: none;
//   }
// }

.receipt-german-three-dots {
  text-align: center;
  .record-options {
    // margin-left: -12px;
    margin-right: 19px;
  }
  .record-dropdown {
    border: none;
    background: transparent;
    padding: 0px;
    margin-right: 0px;
    border-radius: 0px;
    border-color: transparent;
    box-shadow: none;
    margin-left: -15px;
    // margin-right: 15px;
  }
  // .record-dropdown{
  //   border:none;
  //   box-shadow: none;
  //   background: none;
  //   background-color: none;
  //   margin-left: -15px;
  //   margin-right: 15px;
  // }
}
