@media only screen and (max-width: 1439px) {
    .section-content {
        .col-9 {
            padding-right: 15px;
        }
        .col-3 {
            padding-left: 15px;
        }
        input#person-city {
            width: 100%;
        }
        input#person-zip {
            padding: 9px;
            text-align: left;
        }
    }
    .payment-history {
        .table-heading {
            .bill-name {
                width: 38%;
            }
            .amount {
                width: 40%;
            }
        }
        .table-data {
            .bill-name {
                width: 36.5%;
            }
            .date {
                width: 22%;
            }
            .amount {
                width: 21.7%;
            }
            .download {
                width: 17%;
                text-align: right;
            }
        }
    }
    .tab-pane {
        .section-content {
            .subs-options-container {
                .subscription-options {
                    .subscription-card {
                        width: 304px;
                    }
                }
            }
        }
    }
    .account-width {
        width: 37%;
    }
}

@media only screen and (max-width: $breakPointMd) {
    .account-width {
        width: 49%;
    }
}

@media only screen and (max-width: 600px) {
    .account-width {
        width: 100%;
    }
}

@media only screen and (max-width: $breakPointSm) {
    .tab-pane {
        .section-content {
            .subs-options-container {
                .subscription-options {
                    .subscription-card {
                        width: 100%;
                    }
                }
            }
        }
    }
    .account-width.personal {
        padding-bottom: 60px;
    }
}

@media only screen and (max-width: 767px) {
    #primary-content {
        padding: 22px 16px !important;
        .profile-heading {
            .heading-text-wrapper {
                .card-title {
                    font-size: 18px;
                }
                .heading {
                    margin: 0px !important;
                }
            }
        }
        .tab-pane {
            .section-content {
                padding: 0px 0px 40px 0px;
                .subs-options-container {
                    .subscription-options {
                        .subscription-card {
                            width: 343px;
                        }
                    }
                    .subscription-options.checked {
                        .subscription-card {
                            width: 343px;
                        }
                    }
                    flex-flow: column;
                }
                .custom-navbar {
                    overflow-x: scroll;
                }
                .payment-history {
                    .table-heading {
                        .bill-name {
                            display: none;
                        }
                        .date {
                            display: none;
                        }
                        .amount {
                            width: 100%;
                            text-align: left;
                            padding: 0px 0px 0px 0px !important;
                        }
                        td {
                            &:nth-child(3) {
                                padding: 0px !important;
                            }
                        }
                    }
                    .table-data {
                        .bill-name {
                            display: none;
                        }
                        .date {
                            display: none;
                        }
                        .amount {
                            width: 50%;
                            text-align: left;
                        }
                        .download {
                            width: 50%;
                            text-align: right;
                        }
                    }
                }
            }
        }
        .custom-navbar {
            overflow: auto;
            white-space: nowrap;
            .switch-btn {
                padding-bottom: 11px !important;
            }
        }
        .account-width {
            width: 100%;
        }
        input#person-zip {
            padding: 5px !important;
            text-align: left !important;
        }
    }
}
