input[type='file'] {
  display: none;
}

.profilepic_view {
  margin-bottom: 1.5%;
}
.custom-file-upload {
  background-color: #f7f7fa;
  padding: 8px 20px;
  border-radius: 0px;
  border-radius: 4px;
  padding: 8px, 12px, 8px, 12px;
  border: 1px solid #dadae5;
  // box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
  //   0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  color: #252563;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
}

.image_container {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.profile_div {
  display: flex;
  margin-top: 37px;
}

.button_text {
  margin-left: 24px;
  margin-top: 20px;
}

.png_jpg_text {
  color: #727293;
  font-weight: normal;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}
