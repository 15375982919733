.modal-dialog.image-modal {
  z-index: 999999;
  img.close-icon {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 20px;
    height: 20px;
  }
  margin: 0 auto;
  background: transparent;
  max-width: 100% !important;
  // padding: 0px;
  // margin-top: 0px;

  > .modal-content {
    background: transparent;
    position: relative;
    top: 0px;
    left: 0px;
    height: 90vh;

    padding: 0;
    // padding-top: 0px;
    // right: 0;
    // position: fixed;
    // background: transparent;
    // padding-top: 0px !important;
    // right: 0;
    // position: fixed;
    // padding: 0px !important;
    // background: transparent;

    > .modal-header {
      background-color: transparent !important;
      border-bottom: 0px solid transparent !important;

      > .close {
        > span {
          font-size: 40px;
          color: white !important;
        }
      }
    }

    > .modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .main-div {
        position: relative;
        top: 0px;
        left: 0px;
        height: 100%;
        width: auto;
        padding-top: 42px;
        display: flex;
        justify-content: center;
        img.main-img {
          height: 100%;
        }
        img.close-icon {
          position: absolute;
          top: 0px;
          right: 0px;
          width: 20px;
          height: 20px;
        }
      }

      // >img
      // {
      // height: 85vh !important;
      // width: 100%;
      // }
    }
  }
}
