.master-data-section {
  // .search-meter-home {
  //   opacity: 0px !important;
  // }
  & .master-meter-table {
    padding-top: 0px !important;
    padding-bottom: 0px;
    margin: 0px;
    border-collapse: separate;
    border-spacing: 0px 8px;
    background: transparent;
    box-shadow: 0 0 0 0px #eceff2 !important;
    > td {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */

      /* Oceanic Purple/900 */

      color: #0e0e1d;
    }
    // .search-meter-home {
    //   opacity: 0px !important;
    // }
  }
  .master-table-labels {
    padding: 2px 0px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #727293 !important;

    &:nth-child(1) {
      padding-left: 25px !important;
    }
  }

  .master-table-data {
    background-color: #fff;
    border: none;
    padding: 9px 0px;
    color: #0e0e1d !important;
    font-weight: 500 !important;
    height: 72px;

    & .meter-icon {
      padding-left: 27px;
      width: 36%;
    }

    & .master-meterno {
      padding-left: 1%;
    }
    & .master-type {
      width: 32%;
    }
    & .master-location {
      width: 27%;
    }
    & .master-dots {
      background-color: transparent;
      background: transparent;
      box-shadow: none;
      border: none;
    }
    .record-dropdown {
      background-color: transparent;
      background: transparent;
      box-shadow: none;
      border: none;
    }
    .edit-delete-dropdown {
      position: absolute !important;
      left: -60px !important;
      margin: 0px !important;
      width: 208px !important;
      box-shadow: none !important;//0px 8px 16px rgba(14, 14, 29, 0.1), 0px 1px 2px rgba(14, 14, 29, 0.06) !important;
      border-radius: 4px !important;
      border: 1px solid #dadae6 !important;
    }
  }
}

.add-meter-modal {
  max-width: 504px !important;
  //   .form-rental-unit-value {
  //     background-color: red;
  //     > option {
  //       background-color: green;
  //     }
  // }
  .modal-content {
    .modal-header {
      height: 56px !important;
      padding: 16px 24px !important;
      .modal-title {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #0e0e1d;
      }
    }
    .modal-body {
      padding: 24px 24px !important;
      //switch container
      .switch-container {
        display: flex;
        align-items: center;
        margin-left: 14px;
        margin-bottom: 16px;
      }
      .app-label {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #0e0e1d;
      }
      .meter-modal {
        .property-single_dropdown {
          display: flex;
          justify-content: space-between;
          .span-in-allocation {
            white-space: nowrap;
            text-overflow: ellipsis;
            // flex: 1;
            word-break: break-all;
            overflow: hidden;
            height: 23px;
            text-align: left;
            width: 166px !important;
          }
          .dropdown-arrow-height > img {
            height: 4px !important;
          }
          :active {
            background: none !important;
          }
        }
        .rental-unit {
          overflow-x: hidden;
          overflow-y: auto;
          font-size: 14px;
          // height: 200px;
          transform: none;
          width: 100% !important;
          padding: 5px 10px !important;
        }
        .btn.btn-secondary:focus,
        .btn.btn-secondary:hover {
          background: none !important;
          box-shadow: none !important;
          outline: none !important;
        }
        // .property-single-dropdown-menu {
        //   width: 100% !important;
        //   padding: 5px 10px !important;
        // }
        .property-single-dropdown-menu {
          width: 100% !important;
          padding: 5px 10px !important;
        }
        .allocation-dropdown-menu {
          overflow-x: hidden;
          overflow-y: auto;
          font-size: 14px;
          height: 200px;
          transform: none;
        }
        .form-label {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 20px;
          color: #727293;
        }
      }
    }

    .cancel_addbtn {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }
  }

  .dropStyle.dropdown {
    width: 100%;
  }
}
.meter-edit-modal {
  max-width: 504px !important;
  //   .form-rental-unit-value {
  //     background-color: red;
  //     > option {
  //       background-color: green;
  //     }
  // }
  .modal-content {
    .modal-header {
      height: 56px !important;
      padding: 16px 24px !important;
      .modal-title {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #0e0e1d;
      }
    }
    .modal-body {
      padding: 24px 24px !important;
      //switch container
      .switch-container {
        display: flex;
        align-items: center;
        margin-left: 14px;
        margin-bottom: 16px;
      }
      .app-label {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #0e0e1d;
      }
      .meter-modal {
        .property-single_dropdown {
          display: flex;
          justify-content: space-between;
          > span {
            white-space: nowrap;
            text-overflow: ellipsis;
            // flex: 1;
            word-break: break-all;
            overflow: hidden;
            height: 23px;
            text-align: left;
          }
          .dropdown-arrow-height > img {
            height: 4px !important;
          }
          :active {
            background: none !important;
          }
        }
        .rental-unit {
          overflow-x: hidden;
          overflow-y: auto;
          font-size: 14px;
          // height: 200px;
          transform: none;
          width: 100% !important;
          padding: 5px 10px !important;
        }
        .btn.btn-secondary:focus,
        .btn.btn-secondary:hover {
          background: none !important;
          box-shadow: none !important;
          outline: none !important;
        }
        // .property-single-dropdown-menu {
        //   width: 100% !important;
        //   padding: 5px 10px !important;
        // }
        .property-single-dropdown-menu {
          width: 100% !important;
          padding: 5px 10px !important;
        }
        .allocation-dropdown-menu {
          overflow-x: hidden;
          overflow-y: auto;
          font-size: 14px;
          height: 200px;
          transform: none;
        }
        .form-label {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 20px;
          color: #727293;
        }
      }
    }

    .cancel_addbtn {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }
  }

  .dropStyle.dropdown {
    width: 100%;
  }
}

.add-edit-receipt-modal {
  .property-single_dropdown {
    display: flex;
    justify-content: space-between;
  }
  .cancel_addbtn {
    display: flex !important;
    justify-content: space-between !important;
    margin-top: 20px !important;
  }
}

.master-data-section-icon {
  .heading {
    .heading-icon {
      height: 16px;
      width: 16px;
      margin-right: 10px;
      margin-bottom: 3px;
    }
    > span {
      font-size: 16px !important;
    }
  }
}

// @media only screen and (max-width:1440px){
//     .master-table-data{
//         .meter-icon {
//             width: 80% !important;
//         }
//     }
// }

@media only screen and (max-width: 767px) {
  .master-table-data {
    .meter-icon {
      width: 80% !important;
    }
  }
}

@media only screen and (max-width: 320px) {
  .master-table-data {
    .meter-icon {
      width: 80% !important;
    }
  }
}

.master-data-section {
  .mobile-master-table-labels-labels{
    padding: 2px 0px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #727293 !important;

    &:nth-child(1){
      padding-left: 12px !important;
    }
  }
}