//-----------------------------------Main tenat Page Start -----------------------------------------//
.main-tenant-page .tenant-record-row .row-table-container .table-data {
  margin-bottom: 16px;
  background: #FFFFFF;
  box-shadow: none !important;
  border-radius: 4px;
  height: 72px;
  padding: 29.83px 27px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
}
.purple-btn, .gray-btn {
  min-width: 100px;
}
.main-tenant-page {
  .page-header {
    margin: 0px;
    .main-tenant-heading {
      .heading-text-wrapper {
        margin-top: 0px !important;
        .card-title {
          font-weight: 600;
          font-size: 20px;
          line-height: 32px;
          color: $colorBlack;
        }
      }
    }
    .heading-right-content {
      margin: 2px 0px 0px 0px;
      button {
        height: 36px;
        background: linear-gradient(180deg, $purpleTheme 0%, $blueForBg 100%);
        border: 1px solid $Border-blue;
        box-sizing: border-box;
        box-shadow: 0px 1px 2px $box-shadow;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 11px;
        .btn-text {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: $colorWhite;
        }
      }
    }
    .filters {
      margin: 14px 0px 19px;
    }
  }
  .tenant-record-row {
    .row-heading-container {
      .heading-container {
        margin: 21px 0px 17px 0px;
        .heading-text-wrapper {
          margin-top: 0px !important;
          .card-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 32px;
            color: $colorBlack;
          }
        }
        .heading-right-content {
          .record-options {
            .record-dropdown {
              &:active {
                background: none !important;
              }
              padding: 0px;
              background: none;
              border: none;
              box-shadow: none;
              margin: 0px 16px 0px 0px;
            }
          }
          .color-btn {
            &:active {
              background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%) !important;
              border: 1px solid $colorGrey;
              box-sizing: border-box;
              box-shadow: 0px 1px 2px $box-shadow;
              border-radius: 4px;
              // width: 99px; //german
              height: 36px;
              padding: 6px 11px;
            }
            background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
            border: 1px solid $colorGrey;
            box-sizing: border-box;
            box-shadow: 0px 1px 2px $box-shadow;
            border-radius: 4px;
            // width: 99px; //german
            height: 36px;
            padding: 6px 11px;
          }
        }
      }
    }
    .row-table-container {
      margin:29px 0px 29px 0px;
      .table {
        .table-heading {
          display: flex;
          align-items: center;
          padding: 0px 26px 11px;
          td {
            padding: 0 5px !important;
          }
          .fullname {
            width: 25%;
          }
          .email {
            width: 25%;
          }
          .phone-number {
            width: 20%;
          }
          .move-in {
            width: 12.5%;
          }
          .move-out {
            width: 12.5%;
          }
        }
      }
      .table-data {
        margin-bottom: 16px;
        background: $colorWhite;
        box-shadow: 0px 1px 2px $box-shadow;
        border-radius: 4px;
        height: 72px;
        padding: 29.83px 27px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        td {
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          color: $colorBlack;
          display: flex;
          align-items: center;
          padding: 0 5px !important;
        }
        .tenant-name {
          cursor: pointer;
          font-weight: 500;
          width: 25%;
          img {
            width: 10px;
            height: 12.33px;
            margin-right: 10px;
          }
        }
        .tenant-email {
          width: 25%;
          color: #5555FF;
          cursor: pointer;
        }
        .tenant-num {
          width: 20%;
          color: #5555FF;
          cursor: pointer;
        }
        .tenant-in {
          width: 12.5%;
        }
        .tenant-out {
          width: 12.5%;
        }
        .empty {
          width: 5%;
          .record-options {
            .record-dropdown {
              padding: 0px;
              background: none;
              box-shadow: none;
              border: none;
              img {
                 width: 16px;
                // height: 4.33px;
              }
            }
          }
        }
      }
      .transparent-btn.tc-dark-purple {
        .btn-text {
          font-weight: 500;
          font-size: 13px;
          line-height: 24px;
          text-decoration-line: underline;
          color: $colorLightPurple;
        }
        margin: 0px 0px 0px 0px;
      }
    }
  }
  table.table.borderless {
    tbody {
      tr {
        td {
          padding: 0px !important;
        }
      }
    }
  }
  .btn.btn-secondary:not(:disabled):not(.disabled):active {
    //background: linear-gradient(180deg, $purpleTheme 0%, $blueForBg 100%);
    //   border: 1px solid $Border-blue;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px $box-shadow;
    border-radius: 4px;
    background: #f7f7fa;
    /* Oceanic Purple/200 */

    border: 1px solid #dadae6;
    box-sizing: border-box;
  }
}

//-----------------------------------Main tenat Page End -----------------------------------------//

//-----------------------------------Add Rental Unit Modal Start -----------------------------------------//
.modal-inner-body.rental-unit-modal {
  .form-group {
    margin-bottom: 23px;
    .form-label {
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: $colorLightPurple;
      margin: 0px 0px 4px 0px;
    }
    .form-control {
      background: $colorWhite;
      border: 1px solid $colorGrey;
      box-sizing: border-box;
      //box-shadow: 0px 1px 2px $box-shadow;
      border-radius: 4px;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: $colorBlack;
      height: 36px;
      //padding: 8px 12px;
    }
  }
}
.center-spacing.rental-unit-modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .gray-btn {
    &:active {
      background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%) !important;
      border: 1px solid $colorGrey !important;
      box-sizing: border-box;
      box-shadow: 0px 1px 2px $box-shadow !important;
      border-radius: 4px;
    }
    margin: 0px;
    padding: 0px;
    flex: 0.2;
    height: 36px;
    background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
    border: 1px solid $colorGrey;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px $box-shadow;
    border-radius: 4px;
  }
  .purple-btn {
    &:active {
      color: $colorWhite !important;
      background: linear-gradient(180deg, $purpleTheme 0%, $blueForBg 100%) !important;
      border: 1px solid $Border-blue !important;
      box-sizing: border-box;
      box-shadow: 0px 1px 2px $box-shadow !important;
      border-radius: 4px;
      font-weight: normal;
    }
    flex: 0.2;
    padding: 7px 11px !important;
    background: linear-gradient(180deg, $purpleTheme 0%, $blueForBg 100%) !important;
    border: 1px solid $Border-blue;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px $box-shadow;
    font-size: 14px;
    font-weight: normal !important;
    line-height: 20px;
    color: $colorWhite !important;
    height: 36px;
  }
}
//-----------------------------------Add Rental Unit Modal End -----------------------------------------//

//-----------------------------------Add Tenant Page Start -----------------------------------------//
.tenant-head {
  .heading-text-wrapper {
    .card-title {
      .heading-icon {
        margin-right: 15px;
        cursor: pointer;
      }
      display: flex;
      align-items: center;
    }
  }
  .heading-right-content {
    // width: 18%; //german
    .btn-container {
      align-items: center;
      display: flex;
      justify-content: space-between;
      .gray-btn {
        margin-right: 10px;
        flex: 0.47;
        &:active {
          background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
          border: 1px solid $colorGrey;
          box-sizing: border-box;
          box-shadow: 0px 1px 2px $box-shadow;
          color: $colorWhite !important;
        }
      }
      .purple-btn {
        flex: 1;
        height: 36px;
        width: 200px;//german
        background: linear-gradient(180deg, $purpleTheme 0%, $blueForBg 100%) !important;
        border: 1px solid $Border-blue;
        box-sizing: border-box;
        box-shadow: 0px 1px 2px $box-shadow;
        border-radius: 4px;
        line-height: 20px;
        padding: 7px 11px !important;
      }
    }
  }
}

.tenant-info-section {
  margin: 7px 0px 0px 0px;
  .err {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }
  .section-heading {
    span {
      color: #727293;
    }
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $colorDeepPurple;
    margin: 0px 0px 0px;
  }
  .form-group {
    .form-check.form-check-inline {
      margin: 0px;
      .form-check-label {
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: $colorBlack;
        padding: 0px;
      }
    }
    .form-label {
      margin: 0px 0px 7px 0px !important;
    }
    .calendar-input {
      display: flex;
      background: $colorWhite;
      border: 1px solid $colorGrey;
      box-sizing: border-box;
      //box-shadow: 0px 1px 2px $box-shadow;
      border-radius: 4px;
      padding: 0px 12.33px;
      img {
        margin-right: 8.33px;
        width: 16px;
      }
      .react-datepicker-wrapper {
        border: none;
        .react-datepicker__input-container {
          .form-control {
            border: none !important;
            padding: 0px !important;
            box-shadow: none !important;
          }
        }
      }
    }
    .radio-btns {
      display: flex;
      justify-content: space-between;
      margin: 16px 0px 0px 0px !important;
      .tenant-radio-options {
        // background-color: green($color: #000000);
        .form-check-input[type="radio"] {
          background-color: $colorWhite;
          border-radius: 8px;
          border: 1px solid #dadae6;
          cursor: pointer;
          height: 16px;
          width: 16px;
          box-shadow: 0px 1px 2px $box-shadow;
          box-sizing: border-box;
          -webkit-appearance: none;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .form-check-input[type="radio"]:after {
          background-color: $colorWhite;
          border-radius: 25px;
          content: "";
          display: block;
          height: 8px;
          position: relative;
          width: 8px;
          -webkit-appearance: none;
        }
        .form-check-input[type="radio"]:checked:after {
          background-color: $colorWhite;
          -webkit-appearance: none;
        }
      }
      .tenant-radio-options.checked {
        // background-color: pink;
        .form-check-input[type="radio"] {
          background-color: #4242ed;
          border-radius: 8px;
          border: 1px solid #4242ed;
          cursor: pointer;
          height: 16px;
          width: 16px;
          box-shadow: 0px 1px 2px $box-shadow;
          box-sizing: border-box;
          -webkit-appearance: none;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .form-check-input[type="radio"]:after {
          background-color: $colorWhite;
          border-radius: 25px;
          content: "";
          display: block;
          height: 8px;
          position: relative;
          width: 8px;
          -webkit-appearance: none;
        }
        .form-check-input[type="radio"]:checked:after {
          background-color: $colorWhite;
          -webkit-appearance: none;
        }
      }
    }
    margin: 7px 0px 0px 0px !important;
  }

  .occupancy-dates {
    margin: 25px 0px 0px 0px;
  }
  .no-rental-container {
    margin-bottom: 50px !important;
    background: $background-azure;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    margin: 26px 0px 0px;
    height: 156px;
    .no-rent-state {
      .main-container {
        margin: 0px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        .icon-container {
          background: $colorWhite;
          border-radius: 4px;
          height: 40px;
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 19px;
          .empty-state-icon {
            width: 15.39px;
            height: 15.22px;
          }
        }
        .empty-state-heading {
          margin: 0px 0px 4px;
        }
        .purple-btn {
          &:focus {
            outline: none;
            box-shadow: none;
          }
          margin-top: 0px !important;
          width: 82px !important;
          height: 36px !important;
          padding: 7px 11px !important;
          .btn-text {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: $colorWhite;
          }
        }
        .empty-state-subheading {
          display: none;
        }
      }
    }
  }
  .table-data {
    td {
      font-size: 14px;
      line-height: 24px;
    }
    .rent-net {
      padding: 0px !important;
      width: 23%;
    }
    .rent-advance {
      width: 23%;
    }
    .total-rent {
      width: 23%;
    }
    .rent-time-frame {
      width: 25%;
    }
    .empty {
      .record-options {
        .record-dropdown {
          &:active {
            background-color: transparent !important;
          }
          .empty .record-options .record-dropdown {
            img {
              width: 16px;
              height: 4.33px;
            }
          }
          padding: 0px;
          border: none;
          box-shadow: none;
          background: none;
        }
      }
      width: 1.7%;
    }

    background: $colorWhite;
    box-shadow: 0px 1px 2px $box-shadow;
    border-radius: 4px;
    display: flex;
    padding: 24px;
    height: 72px;
    margin-bottom: 16px;
  }
  .table-heading {
    .rent-net {
      padding: 0px !important;
      width: 23%;
    }
    .rent-advance {
      width: 23%;
    }
    .total-rent {
      width: 23%
    }
    .rent-time-frame {
      width: 25%;
    }
    display: flex;
    padding: 22px 24px;
  }

  td {
    padding: 0px !important;
  }
  .transparent-btn.tc-dark-purple {
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    text-decoration-line: underline;
    color: $colorLightPurple;
    padding: 0px !important;
  }
  // .add-rent-table-section {
  //   margin-bottom: 80px !important;
  // }
  .rent-section-header {

    .color-btn {
      flex: 0.07;
      .button-img-wrapper{
        //width: 100px;//german
      }
    }
    .btn.btn-secondary:not(:disabled):not(.disabled):active {
      background: linear-gradient(180deg, $colorWhite 0%, #f7f7fa 100%) !important;
      border: 1px solid $colorGrey;
      box-sizing: border-box;
      box-shadow: 0px 1px 2px $box-shadow !important;
      border-radius: 4px;
      outline: none;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.tenant-doc-section {
  .section-heading {
    span {
      color: #727293;
    }
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $colorDeepPurple;
    margin: 8px 0px 0px 0px;
  }
  .file-dropzone {
    //background: $background-azure;
    border: 1px dashed #b8b8f4;
    box-sizing: border-box;
    border-radius: 4px;
    // width: 352px;
    // height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    //margin: 22px 0px 0px 0px;
    margin: 0 0 24px;
    img {
      filter: invert(32%) sepia(75%) saturate(7390%) hue-rotate(233deg) brightness(101%) contrast(101%);
    }
    .drag-div {
      &:focus {
        outline: none;
      }
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      padding: 17px 61px;
      img {
        width: 16px;
        height: 15.5px;
      }
      .first {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: $purpleTheme;
        margin: 5px 0px 0px 0px;
      }
      .second {
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: $colorLightPurple;
        margin: 4px 0px 0px 0px;
      }
    }
  }
  .file-details {
    .value-text-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .card-value {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $colorBlack;
      }
    }
    margin: 19px 0px 0px 0px;
  }
  .text-img-wrapper {
    width: 352px !important;
  }
}
.tenant-address-section {
  input#tenant-zip {
    padding: 8px 8px 8px 12px;
  }
}
.tenant-notes-section {
  margin: 4px 0px 0px 0px;
}
.section-content .col-3 {
  padding-left: 15px;
}
.section-content .col-9 {
  padding-right: 15px;
}
.rent-modal {
  .rental-unit-modal {
    .err {
      width: 100%;
      margin-top: 0.25rem;
      font-size: 80%;
      color: #dc3545;
    }

    .modal-rent-heading {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin: 0px 0px 12px;
      color: $colorDeepPurple;
      span {
        color: $colorLightPurple;
      }
    }
    .calendar-input {
      display: flex;
      background: $colorWhite;
      border: 1px solid $colorGrey;
      box-sizing: border-box;
      //box-shadow: 0px 1px 2px $box-shadow;
      border-radius: 4px;
      padding: 0px 12.33px;
      img {
        width: 16px;
        margin-right: 8.33px;        
      }
      .react-datepicker-wrapper {
        .react-datepicker__input-container {
          .form-control {
            border: none;
            box-shadow: none !important;
            padding: 0px;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: $colorLightPurple;
          }
        }
      }
    }
  }
  width: 344px;
  height: 360px;
}

.center-spacing.rental-unit-modal-footer {
  .gray-btn {
    padding: 7px 11px !important;
  }
  .purple-btn {
    //flex: 0.45 !important;
    padding: 7px 11px !important;
  }
}
.rental-unit-modal {
  .form-group {
    margin: 0px 0px 24px;
  }
}
.Toastify__toast.Toastify__toast--dark.neoverv-toast {
  background-color: #13135c !important ;
  color: white !important;
}

/*Add by salim*/

.main-tenant-page .tenant-record-row .row-table-container .table-data td a {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #0e0e1d;
  display: flex;
  align-items: center;
}

.main-tenant-page .tenant-record-row .row-table-container .table-data td a:hover {
  text-decoration: none;
}

//-----------------------------------Add Tenant Page End -----------------------------------------//


.tenant-doc{
  >section{
    width: 300px;
  }
 
}
