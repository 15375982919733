.drag-handler-table-cell{
  padding-right: 0px !important;
  padding-left: 16px !important;
  text-align: center !important;
}

/*utility billing module*/
.Utility-Billing-top-nav {
  //margin-top: 7px;
}

.purple-btn {
  border: 1px solid #d5dce6 !important;
}

// .MuiTableCell-root {
//   padding: 16px 10px !important;
// }

.gray-btn {
  min-width: 100px;
}
// .Utility-Billing-top-nav{
//   .MuiPaper-elevation1{
//     box-shadow: none !important;
//   }
//   .MuiPaper-root.MuiAccordion-root{
//     background: #f8f9fb !important;
//     border: none !important;
//   }
// }

// .optionListContainer{
//   bottom: 0;
// }
.tooltip.show{
  opacity: 1;
}
.bs-tooltip-bottom {
  & .tooltip-inner {
    padding: 10px;
    background-color: $light-blue !important;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px;
    font-family: "intercom-font", "Helvetica Neue", "Apple Color Emoji", Helvetica, Arial, sans-serif; 
    font-size-adjust: none; 
    font-size: 15px; 
    font-style: normal; 
    letter-spacing: normal; 
    font-stretch: normal; 
    font-variant: ■normal; 
    font-weight: normal; 
    text-align: left; 
    text-decoration: none; 
    text-emphasis: ► none currentcolor; 
    text-indent: 0px; 
    text-justify: auto; 
    text-shadow: none; 
    text-transform: none; 
    animation-play-state: running; 
    backface-visibility: visible; 
    background-color: transparent; 
    border: 0px none transparent; 
    border-radius: 0px; 
    color: inherit;
  }
  & .arrow:before {
    border-bottom-color: $light-blue !important; 
  }
}
.mantine-Notification-description{
  color: black !important;
  font-weight: 500;
  //padding-bottom: 5px;
}
.mantine-Notification-icon{
  padding-top: 3px !important;
}
.mantine-Notification-closeButton{
  //padding-bottom: 3px !important;
}
// .ub-table-container::-webkit-scrollbar {
//   display: none;
// }
.ub-table-container {
  //scrollbar-width: none !important;
  padding-bottom: 175px !important;
}

.heating-cost-type::-webkit-scrollbar {
  display: none;
}
.heating-cost-type {
  scrollbar-width: none !important;
}

.chip::-webkit-scrollbar{
  display: none;
}
.searchWrapper{
  padding-left: 0px;
}
.chip{
  scrollbar-width: none !important;
  align-items: start;
  height: 100%;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.icon_down_dir{
  background: white;
  //position: relative;
  margin-left: 10px;
  right: 6% !important;
  top: 50% !important;
  //transform: none;
}
.utility_headers_containers .table-responsive .table .table-data {
  width: 100%;
  padding: 27px 29.83px;
  display: -webkit-flex;
  display: flex;
  margin-bottom: 8px;
  background: #FFFFFF;
  box-shadow: none !important;
  border-radius: 4px;
  height: 72px;
  -webkit-align-items: center;
  align-items: center;
}

.utility_headers_containers {
  margin-top: -17px;
  .utility_headers_box {
    display: flex;
    padding: 12px 0px;
    height: 67px;
    // width: 100% !important;
  }
  .utility_headers_date {
    display: flex;
    margin-top: 11px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }
  .utility_headers_current_period {
    margin-top: 9px;
    margin-left: 14px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px 8px;
    height: 28px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: $colorLightPurple;
    background-color: $sidenavBgColor;
    border-radius: 4px;
  }
  .utility_headers_previous_period {
    margin-top: 9px;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px 8px;
    height: 28px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: $purpleTheme;
    background-color: $background-azure;
    border-radius: 4px;
  }
  .utility_headers_start_billing_button {
    display: flex;
    position: absolute;
    right: 24px;
    margin-top: 4px;
    .record-options {
      .record-dropdown {
        background: none !important;
        border: none !important;
        box-shadow: none !important;
      }
      .record-dropdown:active {
        background-color: none !important;
        border: none !important;
        box-shadow: none !important;
        color: none !important;
      }
      .record-dropdown:focus {
        background-color: none !important;
        border: none !important;
        box-shadow: none !important;
        color: none !important;
      }
    }
  }
  .edit_portion {
    margin: 21px 0px 12px 0px !important;
  }
  .utility_edit_text {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $colorDarkPurple;
  }
  .utility-billing-table {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: $table-heading;
  }
  .utility-billing-table-th {
    text-transform: capitalize !important;
  }

  //---------------------- Utility billing 1st page start----------------------------//
  .table-responsive {
    padding: 0px;
    .table {
      box-shadow: none;
      border: none;
      display: flex;
      flex-flow: column;
      background: transparent;
      .utility-billing-table {
        border: none;
        width: 100%;
        background: $globalBackgroundColor;
        display: flex;
        padding: 10px 24px;
        .utility-billing-table-th {
          padding: 0px;
          border: none;
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          color: $colorLightPurple;
        }
        .tenant {
          width: 30%;
        }
        .rental-unit {
          width: 30%;
        }
        .occupancy-period {
          width: 40%;
        }
      }
      .table-data {
        width: 100%;
        padding: 27px 29.83px;
        display: flex;
        margin-bottom: 8px;
        background: $colorWhite;
        box-shadow: 0px 1px 2px $box-shadow;
        border-radius: 4px;
        height: 72px;
        align-items: center;
        td {
          padding: 0px;
          border: none;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: $colorBlack;
        }
        .tenant {
          width: 30%;
        }
        .rental-unit {
          width: 30%;
        }
        .occupancy-period {
          width: 25%;
        }
        .download {
          .download-btn-web {
            .color-btn {
              &:not(:disabled) {
                &:not(.disabled) {
                  &:active {
                    background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
                    /* Oceanic Purple/200 */

                    border: 1px solid $colorGrey;
                    box-sizing: border-box;
                    /* Card */

                    box-shadow: 0px 1px 2px $box-shadow;
                    border-radius: 4px;
                  }
                }
              }
              &:focus {
                outline: none;
                background: transparent;
                box-shadow: none;
              }
            }
          }
          .download-btn-mobile {
            display: none;
          }
          width: 14.7%;
          flex-direction: row-reverse;
          display: flex;
        }
      }
    }
  }
  //------------------------------- Utility billing 1st page end -----------------------------//
}

//---------------------------------------- Utility Bills screen start ---------------------------------//
.utility-billing-head {
  .heading-right-content {
    width: 23.5% !important;
    .purple-btn {
      &:not(:disabled) {
        &:not(.disabled) {
          &:active {
            color: $colorWhite !important;
          }
        }
      }
      flex: 0.7 !important;
    }
    .gray-btn {
      flex: 0.3 !important;
      justify-content: center;
      align-items: center;
    }
    .gray-btn.disable {
      background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
      opacity: 0.32;
      border: 1px solid $colorGrey;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }
  .heading-text-wrapper {
    .current-period-badge {
      background: $background-azure !important;
      border-radius: 4px;
      font-weight: 500 !important;
      font-size: 12px !important;
      line-height: 16px;
      text-align: center;
      color: $purpleTheme !important;
      margin-left: 6px !important;
      padding: 2px 4px;
    }
  }
  .mobile-top-btn-section {
    display: none;
  }
}

.utility-bills-section {
  margin: 23px 0px 0px 0px;
  .utility-bills-total-details-sub {
    display: flex;
    align-items: center;
  .days {
    margin-right: 24px;
    width: auto;
    margin-top: 17px;
  }
  lable {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $colorPurple1;
    margin: 0px 0px 0px 0px;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $colorBlack;
    margin: 0px;
  }
  .total-allocation {
    margin-right: 24px;
    width: auto;
    margin-top: 17px;
  }
  .payments {
    margin-right: 24px;
    width: auto;
    margin-top: 17px;
  }
  .balance {
    width: auto;
    margin-top: 17px;
    //margin-left: 24px;
    .red-color {
      color: $colorRed;
    }
  }
  }
  .abc {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: $colorBlack;
    .utility-cost-allocation {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: $colorBlack;
    }
  }
  .utility-cost-allocation-subheading {
    font-size: 14px;
    line-height: 20px;
    color: $colorPurple1;
  }
  .table {
    margin-top: 24px;
    background: $colorWhite !important;
    display: flex;
    flex-flow: column;
    // position: absolute;
    // // white-space: nowrap;
    // overflow: scroll;
    // display: -webkit-inline-box;
    // width: 96%;
    thead {
      background: none;
      .table-heading.ub-heading {
        padding: 0px !important;
        background: $globalBackgroundColor !important;
        border: none;
        width: 100%;
        display: flex;
        border-radius: 4px;
        .table-heading {
          border: none;
          background: none;
          height: 48px;
          width: 100%;
          display: flex;
          text-align: left;
          td {
            padding: 0px;
            padding: 0px 0px 0px 7px;
            font-weight: 500;
            margin: 0px;
            font-size: 14px;
            line-height: 20px;
            border: 1px solid $colorGrey;
            color: $colorLightPurple;
            display: flex;
            justify-content: left;
            align-items: center;
            text-transform: capitalize;
          }
          .ub-label-cost {
            width: 20.2%;
            // width: 224px;
          }
          .ub-label-total-cost {
            width: 9%;

            // width: 98px;
          }
          .ub-label-key {
            width: 17%;
            // width: 189px;
          }

          .ub-label-total-value {
            width: 9.5%;
            // width: 105px;
          }
          .ub-label-tenant-value {
            width: 10.7%;
            // width: 118px;
          }
          .ub-label-unit {
            width: 6.9%;
            justify-content: flex-end;
            padding: 16px;
            // width: 76px;
          }
          .ub-label-tenant-cost {
            width: 13.8%;
            // width: 152px;
          }
          .ub-label-comment {
            width: 12.9%;
            // width: 142px;
          }
        }
      }
    }
    .ub-body {
      .ub-row {
        border: none;
        background: none;
        height: 48px;
        width: 100%;
        display: flex;
        text-align: left;
        td {
          padding: 0px;
          padding: 14px 15px 14px 16px;
          font-weight: 400;
          margin: 0px;
          font-size: 14px;
          line-height: 20px;
          border: 0.5px solid $colorGrey;
          color: $colorDeepPurple;
          display: flex;
          justify-content: left;
          align-items: center;
          border-radius: 0px;
          // text-transform: capitalize;
        }
        .ub-label-cost {
          // width: 224px;
          width: 20.2%;
          color: $colorBlack;
          font-weight: normal;
        }
        .ub-label-total-cost {
          width: 9%;
          justify-content: flex-end;
          // width: 98px;
        }
        .ub-label-key {
          width: 17%;
          // width: 189px;
        }

        .ub-label-total-value {
          // width: 105px;
          width: 9.5%;
          justify-content: flex-end;
          padding: 0px 16px;
        }
        .ub-label-tenant-value {
          width: 10.7%;
          // width: 118px;
          justify-content: flex-end;
          padding: 0px 16px;
        }
        .ub-label-unit {
          width: 6.9%;
          // width: 76px;
          justify-content: flex-end;
          padding: 0px 16px;
          span {
            font-size: 9px;
            margin-top: -5px;
            margin-left: 1px;
          }
        }
        .ub-label-tenant-cost {
          width: 13.8%;
          // width: 152px;
          color: $colorBlack;
          font-weight: 600;
          justify-content: flex-end;
          padding: 0px 16px;
        }
        .ub-label-comment {
          width: 12.9%;
          // width: 142px;
          .form-control {
            border: none;
            outline: none;
            background: no-repeat;
            box-shadow: none;
            padding: 0px;
            color: $colorLightPurple;
          }
        }
      }
    }
  }
  .utility-bills-total-details {
    .interim-unit-dropbox {
      p {
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        margin: 0px 0px 5px 0px;
        /* identical to box height, or 167% */

        /* Oceanic Purple/500 */

        color: $colorLightPurple;
      }
      width: 21%;
      //margin-right: 24px;
      .dropdown {
        .dropdown-select {
          &:not(:disabled) {
            &:not(.disabled) {
              &:active {
                background-color: $colorWhite !important;
                border: 1px solid $colorGrey;
                box-sizing: border-box;
                box-shadow: none !important; //0px 1px 2px $box-shadow;
              }
            }
          }

          height: 36px;
          display: flex;
          justify-content: space-between;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          /* identical to box height, or 143% */

          /* Oceanic Purple/900 */

          color: $colorBlack;
          background: $colorWhite;
          border: 1px solid $colorGrey;
          box-sizing: border-box;
          box-shadow: none !important;// 0px 1px 2px $box-shadow;
          border-radius: 4px;
          width: 100%;
          align-items: center;
          text-align: left;
          padding: 8px 16.5px 8px 12px;
        }
        .ub-menu.dropdown-menu {
          .utilityRow-options {
            padding: 8px 12px;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $colorBlack;
          }
          width: 100%;
          background: $colorWhite;
          border: 1px solid $colorGrey;
          box-sizing: border-box;
          box-shadow: 0px 8px 16px rgba(14, 14, 29, 0.1), 0px 1px 2px $box-shadow;
          border-radius: 4px;
          padding: 8px 0px;
          transform: translate3d(0px, 36px, 0px) !important;
        }
      }
    }
    display: flex;
    align-items: center;
    .utility-bills-total-details-sub {
      display: flex;
      align-items: center;
    .days {
      margin-right: 24px;
      width: auto;
      margin-top: 17px;
    }
    lable {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: $colorPurple1;
      margin: 0px 0px 0px 0px;
    }
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $colorBlack;
      margin: 0px;
    }
    .total-allocation {
      margin-right: 24px;
      width: 20%;
      margin-top: 17px;
    }
    .payments {
      margin-right: 24px;
      width: 20%;
      margin-top: 17px;
    }
    .balance {
      width: 20%;
      margin-top: 17px;
      //margin-left: 24px;
      .red-color {
        color: $colorRed;
      }
    }
    }
    .print-pdf {
      width: 40.5%;
      flex-direction: row-reverse;
      display: flex;
      .color-btn {
        &.btn.btn-secondary {
          &:not(:disabled) {
            &:not(.disabled) {
              &:active {
                background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
                /* Oceanic Purple/200 */

                border: 1px solid $colorGrey;
                box-sizing: border-box;
                /* Card */

                box-shadow: 0px 1px 2px $box-shadow;
                border-radius: 4px;
              }
            }
          }
        }
        &:hover {
          background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
          /* Oceanic Purple/200 */
        }
        &:focus {
          box-shadow: none;
        }
        flex: 0.25;
        height: 36px;
        .btn-text {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $colorDarkPurple;
          white-space: nowrap;
        }
      }
    }
  }
  .mobile-view-tenant-bill-info {
    display: none;
  }
}

//---------------------------------------- Utility Bills screen end ---------------------------------//

//---------------------------------------- Overview screen start ---------------------------------//
.overview-section {
  .table {
    /* width */
    ::-webkit-scrollbar {
      width: 2px !important;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $colorGrey;
      border-radius: 5px;
    }
    width: 95.5%;
    position: absolute;
    height: auto !important;
    overflow: scroll;
    .ub-body {
      border-radius: 4px;
      .ub-row {
        display: -webkit-inline-box;
        td {
          border-radius: 0px !important;
        }
        .cost-type {
          // width: 354px;
          width: 16.3%;
        }
        .summe-mieter {
          // width: 129px;
          width: 6%;
          justify-content: flex-end;
          font-weight: 600;
        }
        .width-160 {
          // width: 160px;
          width: 7.5%;
          justify-content: flex-end;
        }
      }
      .table-heading {
        display: -webkit-inline-box;
        td {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $colorLightPurple;
          background: $globalBackgroundColor;
        }
        .cost-type {
          // width: 354px;
          width: 16.3%;
        }
        .summe-mieter {
          width: 6%;
          // width: 129px;
          white-space: nowrap;
          font-weight: 500;
        }
        .width-160 {
          // width: 160px;
          width: 7.5%;
          justify-content: flex-end;
          span {
            width: 145px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
.mobile-top-btn-section {
  display: none;
}
//---------------------------------------- Overview screen end ---------------------------------//
.gray-btn {
  &:not(:disabled) {
    &:not(.disabled) {
      :active {
        background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
        border-radius: 4px;
      }
    }
  }
  &:focus {
    background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%) !important;
    /* Oceanic Purple/200 */

    border: 1px solid $colorGrey;
    box-sizing: border-box;
    /* Card */

    box-shadow: 0px 1px 2px $box-shadow;
    border-radius: 4px;
  }
  &:hover {
    background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%) !important;
    /* Oceanic Purple/200 */

    border: 1px solid $colorGrey;
    box-sizing: border-box;
    /* Card */

    box-shadow: 0px 1px 2px $box-shadow;
    border-radius: 4px;
  }
  background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
  /* Oceanic Purple/200 */

  box-sizing: border-box;
  /* Card */

  box-shadow: 0px 1px 2px $box-shadow;
  border-radius: 4px;
  .btn-text {
    &:not(:disabled) {
      &:not(.disabled) {
        :active {
          outline: none;
          border: none !important;
        }
      }
    }
  }
}

// ***************************** Zuweisung modal ***********************
.Zuweisung-modal {
  margin-left: 25% !important;
  max-width: 1000px !important;
  .modal-content {
    width: 100%;
    height: 100%;
    .modal-header {
      padding: 16px 24px !important;
    }
    .table-responsive-Zuweisung {
      .Zuweisung-table-head {
        display: flex;
        .Zuweisung-table-head-tenant {
          margin-right: 24px;
          width: 30%;
        }
        .Zuweisung-table-occupancy {
          margin-right: 24px;
          width: 20%;
        }
        .Zuweisung-table-head-rentalUnit {
          width: 24.3%;
          margin-right: 24px;
        }
        .Zuweisung-table-head-amount {
          width: 16.7%;
        }
        th {
          font-weight: normal;
          font-size: 12px;
          line-height: 20px;
          color: #727293;
          margin: 0px 0px 4px;
        }
      }
    }
  }
}
.custom-modal {
  .modal-content {
    .modal-body {
      .Zuweisung-table-row {
        display: flex;
        margin-bottom: 16px;
        justify-content: center;
        align-items: center;
        .Zuweisung-table-one {
          width: 30%;
          margin-right: 24px;
          background: $colorWhite;
          border: 1px solid $colorGrey;
          box-sizing: border-box;
          box-shadow: 0px 1px 2px $box-shadow;
          border-radius: 4px;
          height: 36px;
          display: flex;
          justify-content: left;
          align-items: center;
          padding: 8px 12px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: #0e0e1d;
        }
        .Zuweisung-table-two.occupancy {
          width: 20%;
          margin-right: 24px;
          .form-control {
            background: $colorWhite;
            border: 1px solid $colorGrey;
            box-sizing: border-box;
            //box-shadow: 0px 1px 2px $box-shadow;
            border-radius: 4px;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #0e0e1d;
            display: flex;
            align-items: center;
            justify-content: left;
            font-style: normal;
          }
        }

        .Zuweisung-table-three.amount {
          width: 16.7%;
          .form-control {
            background: $colorWhite;
            border: 1px solid $colorGrey;
            box-sizing: border-box;
            //box-shadow: 0px 1px 2px $box-shadow;
            border-radius: 4px;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #727293;
            display: flex;
            align-items: center;
            justify-content: left;
            font-style: normal;
          }
        }
        .Zuweisung-table-four {
          width: 16.7%;
          margin-right: 6px;
          background: $colorWhite;
          border: 1px solid $colorGrey;
          box-sizing: border-box;
          box-shadow: 0px 1px 2px $box-shadow;
          border-radius: 4px;
          height: 36px;
          display: flex;
          justify-content: left;
          align-items: center;
          padding: 8px 12px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: #727293;
        }
        div {
          font-weight: normal;
          font-size: 12px;
          line-height: 20px;
          color: $colorBlack;
        }
      }
      .Zuweisung-table-two.rentalUnit {
        width: 24.3%;
        margin-right: 24px;
        // display: flex;
        // justify-content: left;
        // align-items: center;
        // padding: 8px 12px;
        // background: $colorWhite;
        // border: 1px solid $colorGrey;
        // box-sizing: border-box;
        // box-shadow: 0px 1px 2px $box-shadow;
        // border-radius: 4px;
        .form-control {
          background: $colorWhite;
          border: 1px solid $colorGrey;
          box-sizing: border-box;
          //box-shadow: 0px 1px 2px $box-shadow;
          border-radius: 4px;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: #0e0e1d;
          display: flex;
          align-items: center;
          justify-content: left;
          font-style: normal;
        }
      }
      .cancel_addbtn-Zuweisung {
        display: flex;
        justify-content: space-between;
        margin: 24px 0px 0px 0px !important;
        .gray-btn {
          margin: 0px;
          height: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 7px 11px !important;
        }
        .purple-btn {
          //height: 36px;
          padding: 7px 11px !important;
        }
      }
    }
  }
}

@media screen and (max-width: $breakPointSm) {
  .Zuweisung-table-row {
    .Zuweisung-table-two.occupancy .form-control {
      padding: 10px 1px 10px 5px;
      background: #ffffff;
    }
    .Zuweisung-table-two.occupancy {
      margin-right: 10px !important;
    }
    .Zuweisung-table-two.rentalUnit {
      margin-right: 10px !important;
    }
    .Zuweisung-table-one {
      margin-right: 10px !important;
    }
  }
}
// ***************************** Zuweisung modal ***********************

// ********************page 3 4 modal

@media only screen and (max-width: 1440px) {
  .utility-bills-section {
    margin: 23px 0px 0px 0px;
    .abc {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: $colorBlack;
      .utility-cost-allocation {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: $colorBlack;
      }
    }
    .utility-cost-allocation-subheading {
      font-size: 14px;
      line-height: 20px;
      color: $colorPurple1;
    }
    .table {
      margin-top: 24px;
      background: $colorWhite !important;
      display: flex;
      flex-flow: column;
      position: absolute;
      // white-space: nowrap;
      overflow: scroll;
      display: -webkit-inline-box;
      width: 96%;
      thead {
        background: none;
        .table-heading.ub-heading {
          padding: 0px !important;
          background: $globalBackgroundColor !important;
          border: none;
          width: 100%;
          display: flex;
          border-radius: 4px;
          .table-heading {
            border: none;
            background: none;
            height: 48px;
            width: 100%;
            display: flex;
            text-align: left;
            td {
              padding: 0px;
              padding: 0px 0px 0px 9px;
              font-weight: 500;
              margin: 0px;
              font-size: 14px;
              line-height: 20px;
              border: 1px solid $colorGrey;
              color: $colorLightPurple;
              display: flex;
              justify-content: left;
              align-items: center;
              text-transform: capitalize;
            }
            .ub-label-cost {
              // width: 20.2%;
              width: 224px;
            }
            .ub-label-total-cost {
              // width: 9%;

              width: 98px;
            }
            .ub-label-key {
              // width: 17%;
              width: 189px;
            }

            .ub-label-total-value {
              // width: 9.5%;
              width: 105px;
            }
            .ub-label-tenant-value {
              // width: 10.7%;
              width: 118px;
            }
            .ub-label-unit {
              // width: 6.9%;
              justify-content: flex-end;
              padding: 16px;
              width: 76px;
            }
            .ub-label-tenant-cost {
              // width: 13.8%;
              width: 152px;
            }
            .ub-label-comment {
              // width: 12.9%;
              width: 142px;
            }
          }
        }
      }
      .ub-body {
        .ub-row {
          border: none;
          background: none;
          height: 48px;
          width: 100%;
          display: flex;
          text-align: left;
          td {
            padding: 0px;
            padding: 14px 15px 14px 16px;
            font-weight: 400;
            margin: 0px;
            font-size: 14px;
            line-height: 20px;
            border: 0.5px solid $colorGrey;
            color: $colorDeepPurple;
            display: flex;
            justify-content: left;
            align-items: center;
            border-radius: 0px;
            // text-transform: capitalize;
          }
          .ub-label-cost {
            width: 224px;
            // width: 20.2%;
            color: $colorBlack;
            font-weight: normal;
          }
          .ub-label-total-cost {
            // width: 9%;
            justify-content: flex-end;
            width: 98px;
          }
          .ub-label-key {
            // width: 17%;
            width: 189px;
          }

          .ub-label-total-value {
            width: 105px;
            // width: 9.5%;
            justify-content: flex-end;
            padding: 0px 16px;
          }
          .ub-label-tenant-value {
            // width: 10.7%;
            width: 118px;
            justify-content: flex-end;
            padding: 0px 16px;
          }
          .ub-label-unit {
            // width: 6.9%;
            width: 76px;
            justify-content: flex-end;
            padding: 0px 16px;
            span {
              font-size: 9px;
              margin-top: -5px;
              margin-left: 1px;
            }
          }
          .ub-label-tenant-cost {
            // width: 13.8%;
            width: 152px;
            color: $colorBlack;
            font-weight: 600;
            justify-content: flex-end;
            padding: 0px 16px;
          }
          .ub-label-comment {
            // width: 12.9%;
            width: 142px;
            .form-control {
              border: none;
              outline: none;
              background: no-repeat;
              box-shadow: none;
              padding: 0px;
              color: $colorLightPurple;
            }
          }
        }
      }
    }
    .utility-bills-total-details {
      .interim-unit-dropbox {
        p {
          font-weight: normal;
          font-size: 12px;
          line-height: 20px;
          margin: 0px 0px 5px 0px;
          /* identical to box height, or 167% */

          /* Oceanic Purple/500 */

          color: $colorLightPurple;
        }
        width: 21%;
        //margin-right: 24px;
        .dropdown {
          .dropdown-select {
            &:not(:disabled) {
              &:not(.disabled) {
                &:active {
                  background-color: $colorWhite !important;
                  border: 1px solid $colorGrey;
                  box-sizing: border-box;
                  box-shadow: none !important; // 0px 1px 2px $box-shadow;
                }
              }
            }

            height: 36px;
            display: flex;
            justify-content: space-between;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */

            /* Oceanic Purple/900 */

            color: $colorBlack;
            background: $colorWhite;
            border: 1px solid $colorGrey;
            box-sizing: border-box;
            box-shadow: none !important;// 0px 1px 2px $box-shadow;
            border-radius: 4px;
            width: 100%;
            align-items: center;
            text-align: left;
            padding: 8px 16.5px 8px 12px;
          }
          .ub-menu.dropdown-menu {
            .utilityRow-options {
              padding: 8px 12px;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: $colorBlack;
            }
            width: 100%;
            background: $colorWhite;
            border: 1px solid $colorGrey;
            box-sizing: border-box;
            box-shadow: 0px 8px 16px rgba(14, 14, 29, 0.1), 0px 1px 2px $box-shadow;
            border-radius: 4px;
            padding: 8px 0px;
            transform: translate3d(0px, 36px, 0px) !important;
          }
        }
      }
      display: flex;
      align-items: center;
      .days {
        margin-right: 24px;
        width: 6.5%;
        margin-top: 17px;
      }
      lable {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: $colorPurple1;
        margin: 0px 0px 0px 0px;
      }
      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $colorBlack;
        margin: 0px;
      }
      .total-allocation {
        // margin-right: 24px;
        margin-right: 10px;
        width: 7.8%;
        margin-top: 17px;
      }
      .payments {
        margin-right: 24px;
        width: 9%; //6.8%;
        margin-top: 17px;
      }
      .balance {
        width: 10%;
        margin-top: 17px;
        .red-color {
          color: $colorRed;
        }
      }
      .print-pdf {
        width: 43.5%;
        flex-direction: row-reverse;
        display: flex;
        .color-btn {
          &.btn.btn-secondary {
            &:not(:disabled) {
              &:not(.disabled) {
                &:active {
                  background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
                  /* Oceanic Purple/200 */

                  border: 1px solid $colorGrey;
                  box-sizing: border-box;
                  /* Card */

                  box-shadow: 0px 1px 2px $box-shadow;
                  border-radius: 4px;
                }
              }
            }
          }
          &:hover {
            background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
            /* Oceanic Purple/200 */
          }
          &:focus {
            box-shadow: none;
          }
          flex: 0.25;
          height: 36px;
          .btn-text {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $colorDarkPurple;
            white-space: nowrap;
          }
        }
      }
    }
    .mobile-view-tenant-bill-info {
      display: none;
    }
  }
  .overview-section {
    .table {
      /* width */
      ::-webkit-scrollbar {
        width: 2px !important;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $colorGrey;
        border-radius: 5px;
      }
      width: 95.5%;
      position: absolute;
      height: auto !important;
      overflow: scroll;
      .ub-body {
        border-radius: 4px;
        .ub-row {
          display: -webkit-inline-box;
          td {
            border-radius: 0px !important;
          }
          .cost-type {
            width: 354px;
            // width: 16.3%;
          }
          .summe-mieter {
            width: 129px;
            // width: 6%;
            justify-content: flex-end;
            font-weight: 600;
          }
          .width-160 {
            width: 160px;
            // width: 7.5%;
            justify-content: flex-end;
          }
        }
        .table-heading {
          display: -webkit-inline-box;
          td {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $colorLightPurple;
            background: $globalBackgroundColor;
          }
          .cost-type {
            width: 354px;
            // width: 16.3%;
          }
          .summe-mieter {
            // width: 6%;
            width: 129px;
            white-space: nowrap;
            font-weight: 500;
          }
          .width-160 {
            width: 160px;
            // width: 7.5%;
            justify-content: flex-end;
            span {
              width: 145px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
