/*user module*/
#primary-content {
  padding: 21px 24px !important;
}
.purple-btn, .gray-btn {
  min-width: 100px;
}
//------------------- Profile page start----------------------
.profile-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
  .heading-text-wrapper {
    margin-top: 0px !important;
    .card-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 32px;
      color: $colorBlack;
    }
  }
  .heading-right-content {
    .gray-btn {
      cursor: pointer;
      padding: 7px 11px !important;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $colorDarkPurple;
      background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
      border: 1px solid $colorGrey;
      box-sizing: border-box;
      box-shadow: 0px 1px 2px $box-shadow;
      border-radius: 4px;
      &:hover {
        background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
        border: 1px solid $colorGrey;
        box-sizing: border-box;
        box-shadow: 0px 1px 2px $box-shadow;
        border-radius: 4px;
      }
    }
  }
}
.switch-btn {
  font-weight: 500;
  line-height: 24px;
  padding-bottom: 19px !important;
  color: $colorLightPurple;
}

.switch-btn.active {
  border-bottom: 2px solid $purpleTheme !important;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  color: $colorDeepPurple;
}
.profile-section {
  .profile-content {
    padding: 0px 0px;
    background: none;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    // width: 35.5%;
    .tab-pane {
      // width: 32.5%;
      .section-container {
        .section-content {
          .purple-btn {
            // margin-top: 11px !important;
            height: 36px;
            // width: 145px;
            // width: 200px; //german
            padding: 7px 11px !important;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .profile-image-container {
            display: flex;
            .profile-image {
              width: 108px;
              height: 108px;
              margin-right: 24px;
              margin-bottom: 16px;
              img {
                // width: 100%;
                border-radius: 100%;
                // height: 100%;
                object-fit: cover;
              }
            }
            .upload-container {
              display: flex;
              flex-flow: column;
              margin: 18px 0px 0px 0px;
              .upload-btn {
                cursor: pointer;
                background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
                border: 1px solid $colorGrey;
                box-sizing: border-box;
                box-shadow: 0px 1px 2px $box-shadow;
                border-radius: 4px;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: $colorDarkPurple;
                padding: 7px 10px;
                // width: 115px;
                width: 125px;
                height: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0px 0px 13px 0px;
              }
              .file-desc {
                font-weight: normal;
                font-size: 12px;
                line-height: 20px;
                color: $colorLightPurple;
              }
            }
          }
          .form-group {
            margin-bottom: 13px !important;
            .form-label {
              font-weight: normal;
              font-size: 12px;
              line-height: 20px;
              color: $colorLightPurple;
              margin: 0px 0px 3px 0px;
              padding: 0px !important;
            }
            .form-control {
              width: 100%;
              height: 36px;
              //padding: 8px 0px 8px 9px;
              background: $colorWhite;
              border: 1px solid $colorGrey;
              box-sizing: border-box;
              //box-shadow: 0px 1px 2px $box-shadow;
              border-radius: 4px;
              outline: none;
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              color: $colorBlack;
              height: 36px;
            }
          }
          .danger-zone {
            margin: 40px 0px 0px 0px;
            .danger-heading {
              .heading-text-wrapper {
                .card-title {
                  font-weight: 600;
                  font-size: 18px;
                  line-height: 24px;
                  color: $colorBlack;
                  margin: 0px 0px 0px 0px !important;
                }
              }
            }
            .danger-btn {
              margin: 0px !important;
              padding: 0;
              font-weight: 500 !important;
              font-size: 14px;
              line-height: 20px;
              color: $colorRed;
            }
          }
        }
      }
    }
    div#left-tabs-example-tabpane-history {
      width: 100% !important;
      margin-top: -17px;
    }
  }
}

//------------------- Profile page End----------------------

//------------------- Password page start--------------------
.password-field {
  img {
    position: absolute;
    top: 13px;
    right: 13px;
  }
}
.section-content {
  .col-9 {
    padding-right: 0px;
  }
  .col-3 {
    padding-left: 0;
  }
  .purple-btn {
    &:active {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      /* Oceanic Purple/0 */

      color: $colorWhite;
    }
    margin: 8px 0px 0px 0px;
  }
}
.purple-btn {
  &:active {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* Oceanic Purple/0 */

    color: $colorWhite;
  }
  margin: 0px;
}
//------------------- Password page End----------------------

//------------------- Subscription page start--------------------
.password-field {
  img {
    position: absolute;
    top: 13px;
    right: 13px;
  }
}
.tab-pane {
  .section-content {
    .subs-cancel-container {
      margin-top: 3%;
      .dark-purple-btn {
        margin: 0px;
        padding: 0px !important;
        .btn-text {
          margin: 0px;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          text-decoration-line: underline;
          color: $colorPurple1;
        }
      }
    }

    // .purple-btn {
    //   margin: 8px 0px 0px 0px;
    // }
    .subs-options-container {
      display: flex;
      margin-top: 10px;
      .subscription-options.checked {
        cursor: pointer;
        .subscription-card {
          cursor: pointer;
          display: flex;
          width: 352px;
          height: 100%;
          left: 0px;
          top: 0px;
          background: $colorWhite;
          box-shadow: 0px 1px 2px $box-shadow;
          border-radius: 4px;
          margin: 10px 24px 17px 0px;
          padding: 23px 23px 0px 23px;
          opacity: 1;
          .form-check-input[type='radio'] {
            background-color: #4242ed;
            border-radius: 8px;
            border: 1px solid #4242ed;
            cursor: pointer;
            height: 16px;
            width: 16px;
            box-shadow: 0px 1px 2px $box-shadow;
            box-sizing: border-box;
            -webkit-appearance: none;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .form-check-input[type='radio']:after {
            background-color: $colorWhite;
            border-radius: 25px;
            content: '';
            display: block;
            height: 8px;
            position: relative;
            width: 8px;
            -webkit-appearance: none;
          }
          .form-check-input[type='radio']:checked:after {
            background-color: $colorWhite;
            -webkit-appearance: none;
          }
          .form-check-label {
            cursor: pointer;
            div {
              p {
                cursor: pointer;
                margin: 0px 0px 5px;
                span {
                  &:first-child {
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 24px;
                    color: $colorDeepPurple;
                    margin: 0px 4px 0px 0px;
                  }
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 24px;
                  color: $colorLightPurple;
                }
                &:last-child {
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 24px;
                  color: $colorPurple1 !important;
                }
              }
              .purple-btn {
                opacity: 1;
                padding: 7px 11px !important;
                width: 109px;
                height: 36px;
                .btn-text {
                  margin: 0px;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  text-align: center;
                  color: $colorWhite !important;
                }
              }
            }
            .discount-badge {
              cursor: pointer;
              background: $hover-color;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              text-align: center;
              color: $purpleTheme;
              width: 130px;
              height: 28px;
              padding: 4px 12px;
            }
          }
        }
      }
      .subscription-options {
        .subscription-card {
          background: $globalBackgroundColor;
          border: 1px solid $colorGrey;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          width: 352px;
          height: 100%;
          left: 0px;
          top: 0px;
          // background:$colorWhite;
          box-shadow: 0px 1px 2px $box-shadow;
          border-radius: 4px;
          margin: 10px 24px 17px 0px;
          padding: 23px 23px 0px 23px;
          // opacity: 1;

          .form-check-input[type='radio'] {
            background-color: $colorWhite;
            border-radius: 8px;
            border: 1px solid #dadae6;
            cursor: pointer;
            height: 16px;
            width: 16px;
            box-shadow: 0px 1px 2px $box-shadow;
            box-sizing: border-box;
            -webkit-appearance: none;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .form-check-input[type='radio']:after {
            background-color: $colorWhite;
            border-radius: 25px;
            content: '';
            display: block;
            height: 8px;
            position: relative;
            width: 8px;
            -webkit-appearance: none;
          }
          .form-check-input[type='radio']:checked:after {
            background-color: $colorWhite;
            -webkit-appearance: none;
          }
          .form-check-label {
            cursor: pointer;
            div {
              p {
                cursor: pointer;
                margin: 0px 0px 5px;
                span {
                  &:first-child {
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 24px;
                    color: $colorDeepPurple;
                    margin: 0px 4px 0px 0px;
                  }
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 24px;
                  color: $colorLightPurple;
                }
                &:last-child {
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 24px;
                  color: $colorPurple1 !important;
                }
              }
              .purple-btn {
                &:hover {
                  background: linear-gradient(180deg, $purpleTheme 0%, $blueForBg 100%);
                  /* Purple Azure/700 */

                  border: 1px solid $Border-blue;
                  box-sizing: border-box;
                  /* Card */

                  box-shadow: 0px 1px 2px $box-shadow;
                  border-radius: 4px;
                }
                opacity: 0.3;
                padding: 7px 11px !important;
                width: 109px;
                height: 36px;
                .btn-text {
                  &:hover {
                    margin: 0px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    text-align: center;
                    color: $colorWhite !important;
                  }
                  margin: 0px;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  text-align: center;
                  color: $colorWhite !important;
                }
              }
            }
          }
          .discount-badge {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px 12px;
            height: 28px;
            background: $hover-color;
            border-radius: 4px;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: $purpleTheme;
            width: 130px;
            height: 28px;
          }
        }
      }
    }
  }
  .info-box {
    display: flex;
    flex-flow: column;
    margin-top: 7px;
    .form-info {
      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $colorPurple1;
        margin: 0px 0px 9px;
      }
    }
  }
}

//------------------- Subscription page End----------------------
//------------------- Payment History page start----------------------
.payment-history {
  .table-heading {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    margin-bottom: 11px;
    margin-top: 02px;
    // td {
    // 	&:nth-child(2) {
    // 		padding: 0px 0px 0px 75px !important;
    // 	}
    // }
    .bill-name {
      width: 42.5%;
    }

    .date {
      text-align: left;
      padding: 0px !important;
      width: 22%;
    }

    .amount {
      width: 35.2%;
      padding: 0 !important;
    }
  }
  .table-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    padding: 18px 23px;
    .bill-name {
      width: 42.2%;
    }

    .date {
      text-align: left;
      padding: 0px !important;
      width: 23%;
    }

    .amount {
      width: 24.3%;
      padding: 0 !important;
      span {
        color: $label-color;
      }
    }
    .download {
      width: 10.1%;
      .download-btn {
        width: 115px;
        height: 36px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $colorDarkPurple;
        left: 0px;
        top: 0px;
        background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
        border: 1px solid $colorGrey;
        box-sizing: border-box;
        box-shadow: 0px 1px 2px rgba(14, 14, 29, 0.06);
        border-radius: 4px;
      }
    }

    td {
      font-weight: 500 !important;
      font-size: 14px;
      line-height: 24px;
      color: $colorBlack !important;
      padding: 0px !important;
    }
    .text-nl.text-normal.value-text- {
      font-weight: 500 !important;
      font-size: 14px;
      line-height: 24px;
      color: $colorBlack;
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $colorPurple1;
        margin: 0px 0px 0px 4px;
      }
      .download-btn {
        padding: 7px 11px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $colorDarkPurple;
        background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
        border: 1px solid $colorGrey;
        box-sizing: border-box;
        box-shadow: 0px 1px 2px $box-shadow;
        border-radius: 4px;
        &:active {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $colorDarkPurple !important;
          background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
          border: 1px solid $colorGrey;
          box-sizing: border-box;
          box-shadow: 0px 1px 2px $box-shadow;
          border-radius: 4px;
        }
      }
    }
  }
  .table-heading {
    td {
      font-weight: 500 !important;
      font-size: 12px !important;
      line-height: 20px;
      color: $sideNavTextColor !important;
      &:nth-child(3) {
        padding: 0px 184px 0px 0px !important;
      }
    }
  }
}

//------------------- Payment History page End----------------------

//------------------- Legal page start ----------------------
.legal-main-div {
  .legal-para {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #454576;
  }
  .text-underline {
    text-decoration: underline;
  }
}
//------------------- Legal page end ----------------------
.account-width {
  width: 31.9%;
  .user-profile-cancel-btn {
    height: 36px;
    width: 145px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .property-input-box {
    display: flex;
    .delete-propertypicture-button {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #727293;
      border: none;
      background: transparent;
      margin-top: 8px;
      margin-left: 10px;
    }
  }
  .file-err {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #e8193c;
  }
}
.account-width.pw {
  margin-top: 5px;
}

.account-width.personal {
  margin-top: 9px;
}
.account-width.payment {
  margin-top: 5px;
  .next-line-payment {
    margin-bottom: 30px;
  }
  .payment-head {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    //line-height: 16px;
    color: #454576;//#727293;
  }
  .payment-text {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    //line-height: 24px;
    color: #454576;
  }
  .last-text {
    margin-top: 10px;
  }
  .purple-btn {
    width: 213px !important;
    outline: none;
    &:active {
      color: $colorWhite !important;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: $breakPointLg) {
  .section-content {
    .col-9 {
      padding-right: 0px;
    }
    .col-3 {
      padding-left: 0;
    }
    input#person-city {
      width: 253px;
    }
  }
}

.pass-instr {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #13135c;
}
.password-instructions {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #454576;
}

.creditcard-discount-badge {
  cursor: pointer;
  background: $hover-color;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: $purpleTheme;
  width: 130px;
  height: 28px;
  padding: 4px 12px;
  margin-right: 10px;
  margin-top: -2px;
}
.credit-card-badge-box {
  display: flex;
  justify-content: space-between;
}
