.dd-wrapper {
    position: relative;
    top:0; left:0;
}

.dd-wrapper .dd-header{
    display: flex;
    width: 100%;
    height: 36px;
    /* background: red; */
    border-radius: 4px;
    border: 1px solid #DADAE5;
    padding: 1%;
    align-items: center; 
    box-sizing: border-box;
}

.dd-wrapper  .dd-header .interaction-area{
    flex:1;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* background-color: blue; */
    // padding-bottom: 2px;
    // margin-top: 1%;
    box-sizing: border-box;
    flex-wrap: nowrap;
    scrollbar-width: thin; 
    
}

.dd-wrapper  .dd-header .interaction-area::-webkit-scrollbar {
    height: 4px;
}

/* .dd-wrapper  .dd-header .interaction-area::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(117, 113, 113, 0.3); 
    border-radius: 10px;
} */

.dd-wrapper  .dd-header .interaction-area::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 20px rgba(117, 113, 113, 0.3); 
}


// .dd-wrapper  .dd-header .interaction-area .dd-header-title{
//     display: flex;
//     flex-direction: row;
//     /* uncommit it you want it multi lines */
//     /* flex-wrap: wrap; */
//     flex-wrap: nowrap;
//     /* padding-bottom: 5px; */

    
// }
.dd-wrapper  .dd-header .interaction-area .dd-header-title-bold{
    word-break: keep-all;
    white-space: nowrap
}
.dd-wrapper  .dd-header .interaction-area input[type=text], .dd-wrapper  .dd-header .interaction-area input[type=text]:focus-visible, .dd-wrapper  .dd-header .interaction-area input[type=text]:focus{
    border: none;
    background-color: transparent;
    height: 100%;
    width: 150px;
    // width:40px;
    //  min-width: 20px;
    // max-width: auto;
    outline: none;

    word-break: keep-all;
    white-space: nowrap
}

.dd-wrapper  .dd-header .dd-header-action{
    /* background-color: wheat; */
    //padding-bottom: 5px;
}
.dd-wrapper  .dd-header .dd-header-action-icon{
    padding-left: 10px;
    padding-right: 10px;
}


.dd-wrapper .dd-header .interaction-area .dd-header-title-bold.selection{
    background-color: #F2F2FC;
    border-radius: 4px;
    margin-right:4px;
    // padding: 4px 8px;
    padding-left:6px;
    font-size: 14px;
}

.dd-wrapper .dd-header .interaction-area .dd-header-title-bold.selection .delete-btn{
background-color: transparent;
outline: none;
border:none;
padding: 4px 10px;
}

.dd-wrapper ul.dd-list{
    /* background-color: blue; */
    width: 100%;
    height: 200px;
    overflow: auto;
    padding: 2% 0px;
    /* margin-top: 3px; */
    list-style: none;
    border-radius: 4px;
    border: 1px solid #DADAE5;
    background-color: white;
    position: absolute;
    top:26px;
    left:0;
    display: flex;
    flex-direction: column;
    z-index: 10;
}

.dd-wrapper ul.dd-list::-webkit-scrollbar {
    /* margin-top: 10px; */
    height: 4px;
    width: 4px;
}

/* .dd-wrapper ul.dd-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(117, 113, 113, 0.3); 
    border-radius: 10px;
} */

.dd-wrapper ul.dd-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 20px rgba(117, 113, 113, 0.3); 
}

.dd-wrapper ul.dd-list li.dd-list-item{
    margin-bottom: 3px;
    padding-left: 2%;
    padding-right: 2%;
    font-size: 14px;

}
.dd-wrapper ul.dd-list li.dd-list-item.heading{
    margin-bottom: 3px;
    padding-left: 3.5%;
    padding-right: 3.5%;
    display: flex;
    flex-direction: row;
    background-color: transparent;
}
.dd-wrapper ul.dd-list li.last-content{
    margin-bottom: 3px;
    padding-left: 2%;
    padding-right: 2%;
    padding-left: 3.5%;
    padding-right: 3.5%;
}
.dd-wrapper ul.dd-list li.last-content button{
    width: 100% !important;
    margin-left: 0px !important;
}
.dd-wrapper .dd-list li.dd-list-item:hover, .dd-wrapper .dd-list li.dd-list-item.selected{
    background-color: #F2F2FC;
}

.dd-wrapper .dd-list .dd-list-item button{
    width: 100%;
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    padding: 1.5%;
    background-color: transparent;
    outline: none;
    border: none;

}

.dd-wrapper .dd-list .dd-list-item button .cover-space, .dd-wrapper ul.dd-list li.dd-list-item.heading .cover-space{
    flex: 1;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.cover-space{
    flex: 1;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    font-size: 14px;
    max-width: 100px !important;
    display: block;
}

.cover-space-without-width{
    flex: 1;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    font-size: 14px;
    display: block;
    width: fit-content;
}