/* MODAL STYLES
   ----------------------------- */
.modal {
  @media only screen and (max-width: 500px){
    max-width: auto !important;
    max-height: 100vh !important;
    padding-bottom: 80px;
  }
  
  .modal-header {
    border-bottom: 0;
    padding: 12.1px 24px !important;
  }

  .modal-content {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    border: 0;
  }

  .modal-footer {
    border-top: 0;
  }

  .modal-content {
    border-radius: $cardRoundedCorner;
    @include cardShadow;
    padding: $panelPadding;
  }

  &.fade {
    .modal-dialog {
      // opacity: 0;
      // transform: translate(0, -7%) scale(0.9);
      // transition: all .25s ease;

      max-width: 502px;
    }
      .modal-dialog.expandImageModal {
        // opacity: 0;
        // transform: translate(0, -7%) scale(0.9);
        // transition: all .25s ease;
        @media only screen and (max-width: 500px){
          max-width: 100vw !important;
          height: 850px !important;
          background: rgba(0, 0, 0, 0.8);
        }
        max-width: 100vw !important;
        height: 1350px !important;
        background: rgba(0, 0, 0, 0.8);
        
        .modal-content {
          .modal-header {
            z-index: 999;
          }
          .modal-body {
            // z-index: 99;
            top: -60px !important;
            height: 640px !important;
            // width: 700px !important;
            .main-div {
              width: auto;
              .main-pdf {
                height: 100%;
                width: 100%;
              }
            }
          }
        }
      }
    }
    // .mobile-meter-detail-modal,
    // .mobile-utlity-cost-modal {
    //   width: 100vw;
    //   margin: auto;
    //   .modal-content {
    //     width: 100vw;
    //     height: 100vh;
    //   }
    // }
    &.show {
      // .modal-dialog {
      // 	opacity: 1;
      // 	transform: translate(0, 6%) scale(1);
      // }
      .show-receipt-modal {
        //transform: translate(-12%, 26%) scale(1)!important;
        max-width: 712px !important;
      }
      .utility-bills-modal {
        max-width: 100vw !important ;
      }
    }
  } 

.modal-backdrop {
  background-color: $modalOverlayBG;
}

// ------------------------------
.mobile-utlity-cost-modal {
  .mobile-costType,
  .mobile-amount,
  .mobile-allocationKey,
  .mobile-meterData,
  .mobile-receipt {
    margin-top: 16px;
    margin-bottom: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #727293;
  }
  .multiselect-container {
    width: 100% !important;
    .search-wrapper {
      height: 38px;
      input {
        display: none !important;
      }
    }
  }
  .add-cost-btns {
    position: absolute;
    bottom: 14px;
    display: flex;
    justify-content: space-between;
    width: 90% !important;
  }
  // .mobile-meter-value{
  // 	margin-left: 5px;
  // }
  .modal-error-msg {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }
}

///////////// tenant modal css

.add_tenant_modal {
  .error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }
  .calender-invalid {
    border: 1px solid red !important;
  }
  .section-heading {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #111147;
    .section-part-two {
      margin-top: 16px;
    }
  }
  .form-check-label-tenant {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #727293;
  }
  .calendar-input {
    display: flex;
    /* margin-right: 1%; */
    outline: none;
    line-height: 1.5em;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    background: #fff;
    border: 1px solid #d5dce6;
    //box-shadow: inset 0 2px 0 0 #f2f5f8;
    border-radius: 4px;
    .form-control {
      outline: none !important;
      background: none;
      border: 0px solid transparent !important;
      box-shadow: none !important;
      /* border-radius: 4px; */
    }
  }
  .file-dropzone {
    // background: #f7f7fa;
    background: #f2f2fc;
    border: 1px dashed #bfbfd2;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 0px 24px 0px;
    > section:focus {
      outline: none !important;
    }

    .drag-div {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      padding: 16px 0px;
      img {
        -webkit-filter: invert(32%) sepia(75%) saturate(7390%) hue-rotate(233deg) brightness(101%) contrast(101%);
        filter: invert(32%) sepia(75%) saturate(7390%) hue-rotate(233deg) brightness(101%) contrast(101%);
      }
      p.first {
        color: #5555ff;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        margin: 8px 0px 4px 0px;
      }
      p.second {
        font-size: 12px;
        font-weight: 400 !important;
        line-height: 20px !important;
        color: #727293;
      }
    }
    .drag-div:focus {
      background: #f2f2fc !important;
    }
  }
  .file-dropzone:focus {
    outline: none !important;
  }
  .row-margin-top {
    margin-top: -8px;
  }
  .add-tenant-modal-footer {
    display: flex !important;
    justify-content: space-between !important;
  }
  :focus {
    outline: none !important;
    background-color: #fff;
    border-color: none !important;
    outline: 0;
    box-shadow: none !important;
  }

  .drag-div {
    &.fileSelected {
      img {
        // -webkit-filter: none;
        filter: none;
      }
      p.first {
        color: #bfbfd2;
      }
      p.second {
        color: #dadae5;
      }
    }
  }
}

/////// help modal

.help-modal-conent {
  .help-modal-footer {
    margin-top: 20px;
    margin-left: 0px;
  }
  .help-goBack-btn {
    margin: 0px !important;
  }
}

//////////// heating cost type modal css

.heating-cost-modal {
  :focus {
    outline: none !important;
    background-color: #fff;
    border: 1px solid #d5dce6 !important;
    outline: 0;
    box-shadow: none !important;
  }
  .heating-cost-text {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #111147;
  }
  .cp-total-cost-label,
  .cp-cost-position-label,
  .cp-amount-label,
  .cp-receipts-label,
  .cp-cost-position-label,
  .cp-amount-label {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 20px;
    color: #727293;
  }
  .cp-cost-icons {
    display: flex;
    justify-content: space-between;
  }
  .dropdown-select {
    display: flex;
    justify-content: space-between !important;
  }

  .cancel_add_btn_container {
    display: flex;
    justify-content: space-between;
    margin: 20px 0px !important;
    .gray-btn {
      margin-left: 0px !important;
    }
  }
  .dustbin-icon {
    display: flex;
    align-items: flex-start;
    padding-top: 10px;
  }

  .add-cp-btn {
    margin: 15px 0px 0px 0px !important;
  }

  .cp-multiselect {
    margin: 25px 0px 30px 0px !important;
    .optionListContainer {
      border: 1px solid #dadae6;
      border-radius: 4px;
      padding: 5px;
    }
  }
  .cp-menu {
    width: 112% !important;
    overflow: auto;
    font-size: 14px;
  }
}
