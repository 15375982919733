@media only screen and (max-width: 1350px) {
  .tenant-head {
    .heading-right-content {
      // width: 25% !important;//german
    }
  }
}
@media only screen and (max-width: 1240px) {
  .main-tenant-page {
    .tenant-record-row {
      .row-table-container {
        .table-data {
          .tenant-name {
            cursor: pointer;
            span {
              white-space: nowrap;
              width: 150px;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
  .tenant-info-section {
    .err {
      width: 100%;
      margin-top: 0.25rem;
      font-size: 80%;
      color: #dc3545;
    }
    .rent-section-header {
      .color-btn {
        flex: 0.1 !important;
        // .button-img-wrapper{
        //   width: 100px !important;//german
        // }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .main-tenant-page {
    .tenant-record-row {
      .row-table-container {
        .table {
          .table-heading {
            display: none !important;
          }
        }
        .table-data {
          .tenant-name {
            cursor: pointer;
            width: 90% !important;
          }
          .empty {
            width: 10% !important;
            display: flex;
            flex-direction: row-reverse;
          }
          .tenant-email {
            display: none;
          }
          .tenant-num {
            display: none;
          }
          .tenant-in {
            display: none;
          }
          .tenant-out {
            display: none;
          }
          height: 56px !important;
          padding: 21.83px 25.83px 21.83px 19px !important;
        }
      }
      .row-heading-container {
        .heading-container {
          margin: 0px !important;
        }
      }
    }
    .filters {
      .section-3 {
        margin: 0px !important;
      }
    }
    .page-header {
      .main-tenant-heading {
        .heading-text-wrapper {
          .card-title {
            margin: 0px !important;
          }
        }
      }
    }
  }

  //---------------------- add rental unit modal -----------------------------
  .add-rental-unit-modal {
    .modal-content {
      .modal-header {
        border-radius: 0px;
        padding: 16px !important;
      }
      .modal-body {
        .rental-unit-modal {
          .form-group {
            .form-label {
              margin: 0px;
            }
            // .err {
            //     width: 100%;
            //     margin-top: 0.25rem;
            //     font-size: 80%;
            //     color: #dc3545;
            // }
          }
        }
        .rental-unit-modal-footer {
          position: absolute;
          bottom: 0px;
          left: 0px;
          padding: 16px;
          width: 100%;
        }
        border-radius: 0px;
        padding: 16px;
      }
      width: 100%;
      height: 100%;
      border-radius: 0px;
    }
    margin: 0px;
    width: 100%;
    height: 100%;
  }

  //-------------------------------- add tenant page ----------------------------------//
  .tenant-head {
    margin: 10px 0px 0px 0px !important;
    .heading-right-content {
      // width: 53% !important;
      width: 58% !important; //german
      .btn-container {
        .purple-btn {
          height: 45px !important;//german
          padding: 7px 11px !important;//german
          flex: 0.84 !important;
        }
        .gray-btn {
          height: 45px;//german
          flex: 0.1 !important;
        }
      }
    }
    .heading-text-wrapper {
      display: flex;
      justify-content: left;
      align-items: center;
      .card-title {
        display: flex;
        align-items: center;
      }
    }
  }
  .tenant-navbar {
    white-space: nowrap;
    overflow-x: scroll;
  }
  .tenant-info-section {
    margin: 0px !important;
    .col-12 {
      .form-group {
        .form-label {
          margin: 0px 0px 0px 0px !important;
        }
        margin-top: 0px !important;
      }
    }
    .col-6 {
      .form-group {
        .form-label {
          margin: 12px 0px 5px 0px !important;
        }
        margin-top: 0px !important;
      }
    }
    .occupancy-dates {
      margin: 20px 0px 0px 0px !important;
    }
    .rent-section-header {
      .color-btn {
        flex: 0.4 !important;
        // .button-img-wrapper{
        //   width: 100px !important;//german
        // }
      }
      margin: 8px 0px 12px;
    }
    .no-rental-container {
      margin-bottom: 50px !important;
      .icon-container {
        margin: 0px 0px 15px 0px !important;
      }
      .empty-state-heading {
        margin: 0px !important;
      }
      padding: 16px 10px !important;
    }
    .table-heading {
      display: none !important;
    }
    .table-data {
      .rent-time-frame {
        display: none;
      }
      .empty {
        width: auto !important;
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 56px !important;
      padding: 21.83px 25.83px 21.83px 19px !important;
    }
  }
  //----------------------------------- rent modal --------------------------------//

  .rent-modal {
    margin: 0px;
    width: 100% !important;
    min-height: calc(100% - 0rem) !important;
    .modal-content {
      height: 100%;
      width: 100%;
      .modal-header {
        padding: 16px !important;
        border-radius: 0px;
      }
      .modal-body {
        padding: 16px;
        .rental-unit-modal-footer {
          position: absolute;
          bottom: 0px;
          padding: 16px;
          width: 100%;
          left: 0;
          background: #ffffff;
          box-shadow: 0px -1px 2px rgba(14, 14, 29, 0.06);
        }
      }
    }
  }
  .tenant-doc-section {
    .text-img-wrapper {
      width: 352px !important;
    }
    .file-dropzone {
      .drag-div {
        padding: 17px 56px !important;
      }
      width: 100% !important;
    }
  }
  .tenant-notes-section {
    textarea {
      width: 100% !important;
    }
  }
  .delete-rent-modal {
    //width: 100%;
    //height: 100%;
    //margin: 0px;
    .modal-content {
      height: 100%;
      border-radius: 0px;
      .modal-header {
        padding: 16px !important;
      }
      .modal-body {
        padding: 16px;
        // .delete-modal-footer {
        //   position: absolute;
        //   bottom: 0px;
        //   left: 0;
        //   padding: 16px;
        //   width: 100%;
        // }
        // .delete-receipt-body {
        //   p {
        //     width: 63%;
        //   }
        // }
      }
    }
  }
}
