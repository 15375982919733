/*general*/
.drag-handler-table-cell{
  padding-right: 0px !important;
  padding-left: 16px !important;
  text-align: center !important;
}
.property-info-tiles {
  //width: 400px;
  display: flex;
  //justify-content: space-between;
  padding-bottom: 0 !important;
}
.property-type-button{
  border: 1px rgba(154, 154, 181, 1.00) solid;
  padding: 15px;
  border-radius: 4px;
}
.property-type-button-mobile{
  border: 1px rgba(154, 154, 181, 1.00) solid;
  padding: 15px;
  border-radius: 4px;
  min-height: 128px;
}
.property-type-button:hover{
  border: 1px #5555FF solid;
  cursor: pointer;
}
.MuiAccordion-root:before{
  background-color: white !important;
}
.MuiAccordion-root:hover{
  //background-color: #5555FF !important;
}
.mantine-Accordion-control{
  padding: 0 !important;
}
.mantine-Accordion-contentInner{
  padding: 16px 0px !important;
}
.mantine-Accordion-item{
  border-bottom: none !important;
}
// .mantine-Group-child{
//   margin-bottom: 0 !important;
// }
.mantine-Accordion-icon{
  margin-left: 0 !important;
}
.first-step-links{
  color: #5555FF !important;
  //text-decoration: none !important;
}
.first-step-description-text{
  font-family: Inter, sans-serif !important;
}
.first-step-title{
  font-family: Inter, sans-serif !important;
  font-weight: 500 !important;
  margin-top: -1px;
}
.first-step-done{
  margin-right: 10px;
  margin-top: -2px;
}
@supports (-moz-appearance:none){
  .first-step-done{
    margin-top: 0px;
  }
}

.first-step-accordion-content:hover{
  .first-step-title{
    opacity: 1 !important;  
  }
}
.first-step-numbering{
  margin-top: -3.5px;
}
@supports (-moz-appearance:none){
  .first-step-numbering{
    margin-top: -0.5px;
  }
}
.dashboard-frist-step-sub-accordions.MuiAccordion-root{
  margin-bottom: 10px !important;
}
.dashboard-frist-step-sub-accordions.MuiAccordion-root.Mui-expanded{
  border: 1px solid #5555FF !important;
  border-radius: 3px !important;
  margin: 0px !important;
  margin-bottom: 10px !important;
}
.MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px !important;
}
.dashboard-frist-step-sub-accordions .MuiAccordionSummary-root.Mui-expanded {
  min-height: 0px !important;
  color: #5555FF !important;
  padding: 8px 16px !important;
  border: none !important;
  border-radius: 3px !important;
  .first-step-sub-accordions-icons {
    transform: rotateZ(90deg) !important;
  }
}
.dashboard-frist-step-sub-accordions .MuiAccordionSummary-root {
  min-height: 0px !important;
  font-size: 14px !important;
  padding: 8px 16px !important;
  color: #13135C !important;
  border: 1px solid white !important;
}
.dashboard-frist-step-sub-accordions .MuiAccordionSummary-root:hover{
  border: 1px solid #5555FF !important;
  border-radius: 3px !important;
  //box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  color: #5555FF !important;
}
.dashboard-frist-step-sub-accordions .MuiAccordionSummary-root.Mui-expanded:hover{
  border: none !important;
  box-shadow: none !important;
}
.dashboard-frist-step-sub-accordions {
  .MuiAccordionDetails-root{
    border-radius: 3px !important;
    //box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  }
}
.dashboard-first-step-guide{
  background: white;
  //padding: 10px 0px 0px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  .dashboard-first-step-guide-title{
    padding: 10px 17px;
    font-size: 18px;
    font-weight: 600;
    color: #0E0E1D;
  }
  .dashboard-first-step-guide-progressbar{
    background-color: #D3D3D3 !important;
    border: 0.2px solid #D8D8D8;
  }
}
.modal {
  overflow-y:auto;
}
.mantine-Notification-icon{
  background-color: white !important;
  margin-bottom: 3px;
  margin-right: 8px !important;
}

.delete-cost-position:hover{
  color: #E8193C !important;
  opacity: 1 !important;
}
.delete-cost-position{
  opacity: 0.4 !important;
}
.table-material-Zuweisung{
  thead{
    tr{
      border-spacing: 0px !important;
      th{
        padding: 16px 12px 0px;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #727293;
        font-family: Inter, sans-serif !important;
      }
    }
  }
  tbody{
    tr{
      background-color: rgb(247, 247, 250) !important;
      td{
        font-family: Inter, sans-serif !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        padding: 16px 12px;
      }
    }
    .white-background{
      background-color: white !important;
    }
  }
}
.table-material-Zuweisung-2{
  thead{
    tr{
      th{
        padding: 0px 12px 0px;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #727293;
        font-family: Inter, sans-serif !important;
      }
    }
  }
  tbody{
    tr{
      td{
        font-family: Inter, sans-serif !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        padding: 0px 12px;
      }
    }
  }
}
.utility-table-row-head{
  th{
    padding: 16px 10px 0px !important;
  }
  th:first-child{
    padding-left: 16px !important;
  }
  th:last-child{
    padding-right: 16px !important;
  }
}
.MuiTableCell-paddingCheckbox{
  width: 5% !important;
}
.utility-table-row, .utility-table-row-head{
  .drag-handler-table-cell{
    padding-right: 0px !important;
    padding-left: 16px !important;
    text-align: center !important;
  }
  td{
    padding: 16px 10px !important;
  }
  td:first-child{
    padding-left: 16px !important;
  }
  td:last-child{
    padding-right: 16px !important;
  }
}
.multiSelectContainer{
  cursor: pointer;
}
.multiSelectContainer input{
  font-size: 14px !important;
}
.disable_ms{
  opacity: 1 !important;
  background-color: #f7f7fa !important;
}
.form-control{
  padding-top: 7px !important;
  border: 1px solid #dadae6 !important;
}
.form-control:disabled{
  background-color: #f7f7fa !important;
}
// .calendar-input .react-datepicker-wrapper .react-datepicker__input-container .form-control:disabled{
//   background-color: #ffffff !important;
// }
.billTable-comment-form-control{
  border: none !important;
}
.add-meter-modal .modal-content .modal-body .meter-modal .property-single_dropdown{
  padding-top: 7px !important;
}
.tenant-info-section .table-data{
  box-shadow: none !important;
}
.form-check-label{
  font-weight: 400;
  font-size: 14px;
  //line-height: 16px;
  color: #0e0e1d;
  //padding: 0;
}
.zipcode-property{
  padding-left: 0 !important;
}
.link-to-profile:hover{
  text-decoration: none !important;
  color: rgba(86, 85, 255, 1.00) !important;
}

.property-parent, .custom-card .custom-card-body {
  box-shadow: none !important;
}
.chipSpanText {
  scrollbar-width: none !important;
}
.chipSpanText::-webkit-scrollbar {
  display: none;
}
.dropdown-scroll{
  scrollbar-width: none !important;
}
.dropdown-scroll::-webkit-scrollbar {
  display: none;
}
.text-property-item {
  scrollbar-width: none !important;
}
.text-property-item::-webkit-scrollbar {
  display: none;
}
.sidebar-dropdown-cardtitle{
  scrollbar-width: none !important;
}
.sidebar-dropdown-cardtitle::-webkit-scrollbar{
  display: none;
}
.MuiListItem-root.Mui-selected {
  background: none !important;
}
.MuiListItem-root.Mui-selected:hover, .MuiListItem-root:hover{
  background: #E3E3FC !important;
}
.interaction-area{
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
}
.add-meter-modal .modal-content .modal-body .meter-modal .property-single_dropdown .dropdown-arrow-height > img{
  margin-top: -4px !important;
}
.modal.fade .add_cost_type_modal .modal-body .property-single_dropdown-allocation-modal{ 
  .cost-modal-value, img{
    padding-top: 3px !important;
  }
}
.react-datepicker__input-container .date-input, .custom-date-field .date-input {
  margin-top: 3px !important;
}

.generalinfo-page .dropdown .btn.btn-secondary {
  padding-top: 6px !important;
}

.purple-btn, .gray-btn, .red-btn {
  min-width: 100px;
}
.MuiOutlinedInput-input {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.add-meter-modal .modal-content .modal-body .meter-modal .property-single_dropdown{
  background: white !important;
}
.modal.fade .add_cost_type_modal .modal-body .property-single_dropdown-allocation-modal{
  background: white !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.MuiIconButton-root{
  box-shadow: none !important;
}

.MuiOutlinedInput-adornedEnd {
  padding-right: 5px !important;
}

.react-datepicker__input-container .input-group-prepend .input-group-text, .custom-date-field .input-group-prepend .input-group-text {
  padding-left: 5px !important;
}

.add_tenant_modal .calendar-input, .tenant-info-section .form-group .calendar-input, .rent-modal .rental-unit-modal .calendar-input, .calendar-input {
  padding-left: 5px !important;
}

.generalinfo-page .dropdown .btn.btn-secondary:not(:disabled):not(.disabled){
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.dd-header, .form-control, .dd-wrapper .dd-header {
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-top: 5px!important;//5px !important;
  .dd-header-action{
    margin-top: -4px !important;
  }
}
.dd-header-title-bold:focus {
  border: none !important;
}

.optionListContainer{
  margin-top: 2px !important;
  //width: auto !important;
  .gray-btn{
    width: 100% !important;
    margin: 0 0 2px !important;
    //white-space: nowrap;
    //min-width: 155px !important;
  }
}
.add_proprty_modal .modal-body .add_property_modal_dropdown .dropdown .btn.btn-secondary:not(:disabled):not(.disabled){
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-top: 7px !important;
}

.ub-other-cost-distribution-table-body-cell-cost-type .optionListContainer{
  width: auto !important;//width: 178px !important;
}
.multiSelectContainer li{
  white-space: nowrap !important;
}
.ub-other-cost-distribution-table-body-cell-allocation-key .optionListContainer{
  width: 100% !important;
  
}
.multiSelectContainer li:hover{
  background: #f2f2fc !important;
}
.MuiPaper-elevation8 {
  box-shadow: 0 8px 16px rgba(14,14,29,.1),0 1px 2px rgba(14,14,29,.06) !important;
  border: 1px solid #dadae6 !important;
  margin-top: -5px;
}

.dd-wrapper .dd-header .interaction-area input[type="text"], .dd-wrapper .dd-header .interaction-area input[type="text"]:focus-visible, .dd-wrapper .dd-header .interaction-area input[type="text"]{
  padding: 0;
}

.main-tenant-page .page-header .heading-right-content button{
  //border: 1px solid #d5dce6 !important;
}

.heading-right-content .color-btn, .upload-container .upload-btn, .gray-btn {
  box-shadow: none !important;
}

.color-btn{
  padding: 7px 11px;
}

.grey-btn:hover{
  background-color: linear-gradient(to bottom, #ffffff 0%, #f8f8fa 100%) !important;
}

.purple-btn {
  padding: 7px 11px !important;
  border: 1px solid #d5dce6 !important;
}

.btn.btn-secondary{
  color: #252563;
}

.tooltips{
  display: flex;
  align-items: center;
}

.heading {
  display: flex;
  align-items: center;
}


.MuiTableCell-root{
  border-bottom: none !important;
}
.meter-uploads-log-meter-found::-webkit-scrollbar {
  display: none !important;
}
.meter-uploads-log-meter-found {
  scrollbar-width: none !important;
}

.form-control:disabled, .form-control[readonly] {
  background-color: rgb(247, 247, 250);
}

.form-control{
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-top: 7px !important;
}

.btn.btn-secondary{
  // padding-left: 5px !important;
  // padding-right: 5px !important;
}
.dd-header{
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.dd-wrapper .dd-header .interaction-area input[type="text"]{
  padding-left: 0px !important;
}

.tenant-move-in-date-picker-popper{
  z-index: 999 !important;
}
.react-datepicker-popper{
  z-index: 9999 !important;
}

@media print
{
@page {
  margin: 15mm 0mm 15mm 0mm;
  display: block;
  overflow: visible;
}
@supports (-moz-appearance:none){
  @page {
    margin: 15mm 0mm 15mm 15mm;
    table{
      padding-bottom: 100mm;
    }
    width: 100px;
  }
  
}
}

.master-data-section .master-table-data .edit-delete-dropdown{
  box-shadow: none !important;
}

.btn.btn-secondary:focus{
  box-shadow: none !important;
}


// .react-datepicker {
//   height: 278px !important;
// }
// text styles
//sizes
.text-xlg {
  font-size: 24px;
}
.text-lg {
  font-size: 18px;
}
.text-nl {
  font-size: 14px;
}
.text-sm {
  font-size: 16px;
}
.text-xs {
  font-size: 12px;
}

//weight
.text-bolder {
  font-weight: 600;
}
.text-bold {
  font-weight: 500;
}
.text-normal {
  font-weight: normal;
}

//colors
.tc-light-purple {
  color: $colorLightPurple;
}
.tc-black {
  color: $colorBlack;
}
.tc-deep-purple {
  color: $colorDeepPurple;
}
.tc-red {
  color: $colorRed;
}
.tc-white {
  color: $colorWhite !important;
}
.bg-purple {
  background: $purpleTheme !important;
}
.tc-dark-purple {
  color: $colorDarkPurple;
}
.tc-purple-1 {
  color: $colorPurple1;
}

// info cards
.info-field {
  display: flex;
  flex-direction: column;
}
.info-field.mb-3 {
  margin: 0px 0px 9px !important;
}
.app-label {
  font-size: 12px;
  font-weight: 400;
  color: $colorLightPurple;
  display: inline-block;
}
.info-field.pt-3 .app-label {
  margin: 0;
}
.value-text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  .card-value {
    font-size: 14px;
    font-weight: 500;
    // color: $colorDeepPurple;
    padding: 0;
    margin: 0;
    flex: 1;
  }
  &.text-xlg .card-value {
    font-size: 24px;
  }
  &.text-lg .card-value {
    font-size: 18px;
  }
  &.text-nl .card-value {
    font-size: 14px;
  }
  &.text-sm .card-value {
    font-size: 16px;
  }
  &.text-xs .card-value {
    font-size: 12px;
  }
  &.tc-red .card-value {
    color: $colorRed;
  }
}
.text-img-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  .btn-img {
    width: 14.3px;
    height: 16px;

    &.left {
      margin-right: 8px;
    }
    &.right {
      margin-left: 8px;
    }
  }
  .btn-text {
    flex: 1;
    padding: 0;
    margin: 0;
  }
}

//borderless table

table.table.borderless {
  box-shadow: none;
  background: transparent;

  tbody tr td {
    border: none;
    padding: 9px 0px;
    color: $colorDeepPurple;
    font-weight: 500;
  }
  tbody tr.table-heading td {
    padding: 2px 0px;
    font-size: 12px;
    font-weight: 500;
    color: $colorLightPurple;
  }
  &.record-list-table {
    border-collapse: separate;
    border-spacing: 0px 8px;
    tr {
      td {
        padding-bottom: 10px;
        padding-top: 20px;
      }
      td:first-child {
        // box-shadow: none !important;
        padding: 0px 10px;
      }
    }
    // tr.table-heading{

    // }
    tr.table-data {
      box-shadow: 0px 1px 2px rgba(14, 14, 29, 0.06);
      background: $colorWhite;
    }
  }
}

@media only screen and (max-width: $breakPointXl), screen and (max-height: 808px) {
  .receiptsClass {
    .divider {
      margin-bottom: 0px;
    }
    table.table.borderless.record-list-table {
      padding-top: 0px !important;
      padding-bottom: 0px;
      padding: 0px;
      margin: 0px;
      tbody {
        // display: flex;
        tr.table-heading {
          border-spacing: 0px !important; // display: flex;
          // justify-content: flex-start;
          td.receipt,
          .amount,
          .cost-type,
          .time-frame,
          .date {
            padding-bottom: 2px;
            padding-top: 12px;
          } // align-items: flex-start;
          td.receipt {
            width: 18%;
            text-align: left;
          }
          td.amount {
            width: 15%;
            text-align: right;
          }
          .cost-type {
            padding-left: 12%;
            width: 25%; // 	text-align: left;
          }
          .date {
            width: 10%; //text-align: left;
          }
          .time-frame {
            text-align: left;
          }
          .empty {
            width: 14.4%;
            text-align: right;
          }
        }
        tr.table-data {
          .text-img-wrapper .btn-img.left {
            margin-right: 11px !important;
          }
          td {
            padding-top: 1.5%;
            padding-bottom: 1.5%;
          } // td.amount,.cost-type,.receipt,.time-frame,.date{
          //     width:8% !important;
          // }
          td:first-child {
            color: $colorBlack !important;
            padding-top: 0px;
            padding-bottom: 0px;
            margin: 0px;
          }
          td:not(td:first-child) {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
          }
          .receipt {
            width: 18%;
            text-align: left;
          }
          .amount {
            width: 8%;
            text-align: right;
          }
          .cost-type {
            padding-left: 12%;
            width: 25%;
          }
          .date {
            width: 12%;
            text-align: left;
          }
          .time-frame {
            width: 20%;
            // width: 18%; //german
            text-align: left;
          }
          .empty {
            width: 13.5%;
            .show-btn {
              // margin-right: 16px;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: $colorDarkPurple;
              background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
              border: 1px solid $colorGrey;
              box-sizing: border-box;
              box-shadow: 0px 1px 2px $box-shadow;
              border-radius: 4px;
              margin-right: 25px;
            }
            .btn-group {
              .record-dropdown {
                background-color: transparent;
                background: transparent;
                box-shadow: none;
                border: none;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $breakPointLg), screen and (max-height: 808px) {
  //Receipt Header End
  .receiptsClass {
      .divider {
        margin-bottom: 0px;
      }
      table.table.borderless.record-list-table {
        padding-top: 0px !important;
        padding-bottom: 0px;
        margin: 0px;
        tbody {
          // display: flex;
          tr.table-heading {
            border-spacing: 0px !important; // display: flex;
            // justify-content: flex-start;
            td {
              padding-bottom: 2px;
              padding-top: 12px;
            } // align-items: flex-start;
            td.receipt {
              width: 20% !important;
              text-align: left;
            }
            td.amount {
              width: 10%;
              text-align: right;
            }
            .cost-type {
              padding-left: 6%;
              width: 23%; // 	text-align: left;
            }
            .date {
              width: 12.5%; //text-align: left;
            }
            .time-frame {
              text-align: left;
              // width: 17.5%;
              width: 16.5%; //german
            } // .empty {
            // 	width: 14.4%;
            // }
          }
          tr.table-data {
            td {
              // padding-top: 1.7%;
              // padding-bottom: 1.7%;
              
            }
            td:first-child {
              color: $colorBlack !important;
              padding-top: 0px;
              padding-bottom: 0px;
              margin: 0px;
              white-space: nowrap;
              text-overflow: ellipsis;
              max-width: 108px;
              overflow: hidden;
            }
            td:not(td:first-child) {
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;
            }
            .receipt {
              width: 20%;
              text-align: left;
            }
            .amount {
              width: 10%;
              text-align: right;
            }
            .cost-type {
              padding-left: 6%;
              width: 23%;
            }
            .date {
              width: 12.5%;
              text-align: left;
            }
            .time-frame {
              // width: 17.5%;
              width: 16.5%; //german
              text-align: left;
            }
            .empty {
              width: 13.5%;
              .show-btn {
                margin-right: 2%;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: $colorDarkPurple;
                background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
                border: 1px solid $colorGrey;
                box-sizing: border-box;
                box-shadow: 0px 1px 2px $box-shadow;
                border-radius: 4px;
              }
              .btn-group {
                .record-dropdown {
                  background-color: transparent;
                  background: transparent;
                  box-shadow: none;
                  border: none;
                }
              }
            }
          }
        }
      }
      .edit-delete-dropdown {
        position: absolute !important;
        left: -40px !important;
        margin: 0px !important;
        width: 208px !important;
        box-shadow: 0px 8px 16px rgba(14, 14, 29, 0.1), 0px 1px 2px $box-shadow !important;
        border-radius: 4px !important;
        border: 1px solid $colorGrey !important;
      }
      // .dropdown-item {
      //   padding: 0.25rem 0.9rem !important;
      // }
      
    }
  }

// @media only screen and (max-width: 600px){
//   .receiptsClass {
//     .dropdown-item {
//       padding: 10px !important;
//     }
//   }
// }

@media only screen and (max-width: 1330px) {
   .receiptsClass {
      .divider {
        margin-bottom: 0px;
      }
      table.table.borderless.record-list-table {
        padding-top: 0px !important;
        padding-bottom: 0px;
        margin: 0px;

        tbody {
          // display: flex;
          tr.table-heading {
            border-spacing: 0px !important; // display: flex;
            // justify-content: flex-start;
            td {
              padding-bottom: 2px;
              padding-top: 12px;
            } // align-items: flex-start;
            td.receipt {
              width: 20% !important;
              text-align: left;
            }
            td.amount {
              width: 10%;
              text-align: right;
            }
            .cost-type {
              padding-left: 4%;
              width: 20%; // 	text-align: left;
            }
            .date {
              width: 11%; //text-align: left;
            }
            .time-frame {
              text-align: left;
              width: 16%;
              padding-left: 2.5%;
              // width: 14%; //german
            } // .empty {
            // 	width: 14.4%;
            // }
          }
          tr.table-data {
            td {
              padding-top: 1.7%;
              padding-bottom: 1.7%;
            }
            td:first-child {
              color: $colorBlack !important;
              padding-top: 0px;
              padding-bottom: 0px;
              margin: 0px;
            }
            td:not(td:first-child) {
              font-size: 13px;
              font-weight: 400;
              line-height: 24px;
            }
            .receipt {
              .card-value {
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 108px;
                overflow: hidden;
              }
              width: 20%;
              text-align: left;
            }
            .amount {
              width: 11%;
              text-align: right;
            }
            .cost-type {
              .ct-receipt {
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 60px;
                overflow: hidden;
              }
              padding-left: 4%;
              width: 20%;
            }
            .date {
              width: 11%;
              text-align: left;
            }
            .time-frame {
              width: 16%;
              // width: 14%; //german
              text-align: left;
              padding-left: 2.5%;
            }
            .empty {
              width: 16%;
              .show-btn {
                margin-right: 0px;
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                color: $colorDarkPurple;
                background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
                border: 1px solid $colorGrey;
                box-sizing: border-box;
                box-shadow: 0px 1px 2px $box-shadow;
                border-radius: 4px;
              }
              .btn-group {
                .record-dropdown {
                  background-color: transparent;
                  background: transparent;
                  box-shadow: none;
                  border: none;
                }
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .receiptsClass {
       .divider {
         margin-bottom: 0px;
       }
       table.table.borderless.record-list-table {
         padding-top: 0px !important;
         padding-bottom: 0px;
         margin: 0px;
 
         tbody {
           // display: flex;
           tr.table-heading {
             border-spacing: 0px !important; // display: flex;
             // justify-content: flex-start;
             td {
               padding-bottom: 2px;
               padding-top: 12px;
             } // align-items: flex-start;
             td.receipt {
               width: 20% !important;
               text-align: left;
             }
             td.amount {
               width: 10%;
               text-align: right;
             }
             .cost-type {
               padding-left: 4%;
               width: 20%; // 	text-align: left;
             }
             .date {
               width: 11%; //text-align: left;
             }
             .time-frame {
               text-align: left;
               width: 16%;
               // width: 14%; //german
             } // .empty {
             // 	width: 14.4%;
             // }
           }
           tr.table-data {
             td {
               padding-top: 1.7%;
               padding-bottom: 1.7%;
             }
             td:first-child {
               color: $colorBlack !important;
               padding-top: 0px;
               padding-bottom: 0px;
               margin: 0px;
             }
             td:not(td:first-child) {
               font-size: 13px;
               font-weight: 400;
               line-height: 24px;
             }
             .receipt {
               .card-value {
                 white-space: nowrap;
                 text-overflow: ellipsis;
                 max-width: 300px;
                 overflow: hidden;
               }
               width: 80%;
               text-align: left;
             }
             .amount {
               width: 11%;
               text-align: right;
             }
             .cost-type {
               padding-left: 4%;
               width: 20%;
             }
             .date {
               width: 11%;
               text-align: left;
             }
             .time-frame {
               width: 16%;
               // width: 14%; //german
               text-align: left;
             }
             .empty {
               width: 16%;
               .show-btn {
                 margin-right: 0px;
                 font-weight: 500;
                 font-size: 13px;
                 line-height: 20px;
                 color: $colorDarkPurple;
                 background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
                 border: 1px solid $colorGrey;
                 box-sizing: border-box;
                 box-shadow: 0px 1px 2px $box-shadow;
                 border-radius: 4px;
               }
               .btn-group {
                 .record-dropdown {
                   background-color: transparent;
                   background: transparent;
                   box-shadow: none;
                   border: none;
                 }
               }
             }
           }
         }
       }
     }
   }
@media only screen and (max-width: 767px) {
  //----------------------- new ---------------------
    .receiptsClass {
      .table.borderless.record-list-table {
        .table-data {
          td {
            &:first-child {
              padding: 0px 0px 0px 16.83px;
            }
            &:not(td:first-child) {
              text-align: right;
              padding: 0px 4.83px 0px 0px;
            }
          }
          padding: 12px;
          height: 56px;
        }
      }
    }
}

//buttons

Button.color-btn {
  font-size: 12px;
  flex: 1;
  &.purple-btn {
    cursor: pointer;
    background: $purpleTheme;
    color: $colorWhite !important;
    // padding-top: 8px;
    // padding-bottom: 8px;
    padding: 7px 11px !important;
    outline: none;
    margin: 0px;
    &:hover {
      background: $purpleTheme;
    }

    &:active {
      background-color: $purpleTheme !important;
    }
  }
  &.gray-btn {
    cursor: pointer;
    color: $colorDarkPurple;
    // padding-top: 8px;
    // padding-bottom: 8px;
    padding: 7px 11px !important;
    margin: 0px;
  }
}
.button-img-wrapper {
  display: flex;
  align-items: center;

  .btn-img {
    width: 12px;
    height: 12px;

    &.left {
      margin-right: 8px;
    }
    &.right {
      margin-left: 8px;
    }
  }
  .btn-text {
    flex: 1;
    padding: 0;
    margin: 0;
  }
}
//transparent btn
Button.transparent-btn {
  font-weight: 500;
  font-size: 14px;
}

Button.transparent-btn:hover,
Button.transparent-btn:focus {
  color: $colorDarkPurple;
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

//divider
.divider {
  border-top: 1px solid $colorGrey;
  margin: 15px 0px 18px;
}

//custom card - utility/meter
.card-label {
  font-size: 12px;
  font-weight: 500;
  color: $colorLightPurple;
  padding: 0;
  margin: 0;
}
.card-value {
  font-size: 14px;
  font-weight: 500;
  // color: $colorDeepPurple;
  padding: 0;
  margin: 0;
}

//custom image card
.custom-card {
  &.card-size-sm {
    margin-bottom: 14px;
  }
  &.card-size-md {
    margin-bottom: 14px;
  }
  &.card-size-lg {
    margin-bottom: 18px;
  }
  &.card-size-xl {
    margin-bottom: 24px;
  }
  .custom-card-body {
    display: flex;
    flex-direction: row;

    &.body-size-sm {
      padding: 10px;
    }
    &.body-size-md {
      padding: 14px;
    }
    &.body-size-lg {
      padding: 18px;
    }
    &.body-size-xl {
      padding: 24px;
    }

    .custom-card-content {
      flex: 1;
      text-align: left;

      .custom-card-header {
        margin-bottom: 9px;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      &.content-sm {
        padding-left: 12px;

        .sidebar-dropdown-cardtitle {
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 140px;
          overflow: hidden;
        }
        .custom-card-subtitle {
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 140px;
          overflow: hidden;
        }

        .custom-card-title {
          font-weight: 500;
          font-size: 14px;
          color: $colorBlack;
        }
        .custom-card-subtitle {
          font-weight: normal;
          font-size: 12px;
          color: $colorLightPurple;
          white-space: nowrap;
          width: 149px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &.content-md {
        padding-left: 16px;

        .custom-card-title {
          font-weight: 600;
          font-size: 24px;
          color: $colorBlack;
        }
        .custom-card-subtitle {
          font-weight: normal;
          font-size: 12px;
          color: $colorLightPurple;
        }
      }

      &.content-lg {
        padding-left: 18px;

        .custom-card-title {
          font-weight: 600;
          font-size: 24px;
          color: $colorBlack;
        }
        .custom-card-subtitle {
          font-weight: normal;
          font-size: 12px;
          color: $colorLightPurple;
        }
      }
      &.content-xl {
        padding-left: 24px;

        .custom-card-title {
          font-weight: 600;
          font-size: 24px;
          color: $colorBlack;
        }
        .custom-card-subtitle {
          font-weight: normal;
          font-size: 14px;
          color: $colorLightPurple;
        }
      }
    }
  }
}

//property dropdown
.sidebar-dropdown-container {
  .dropdown > button {
    width: 100%;
  }
}
.sidebar-dropdown-container {
  .full-three-properties {
    height: 200px !important;
  }
  .single-property {
    height: 119px;
  }
  // .two-properties{
  // }
}
.property-dropdown {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.property-dropdown-menu {
  width: 100% !important;
}

//custom modal
.custom-modal {
  .modal-content {
    padding: 0;
  }
  .modal-footer Button.color-btn {
    display: initial;
    width: auto;
  }
}
// --------------dashboard----------------------
@media only screen and (max-width: 950px) {
  .dashboard {
    .mobile-dashboard-view-row-one {
      display: none;
      
    }
    .rentalunit-cards-mobile {
      // height: 128px;
    }
  }
}

@media only screen and (max-width: 820px) {
  .dashboard {
    .mobile-dashboard-view-row-one {
      display: none;
    }
    .rentalunit-cards-mobile {
      // height: 168px;
    }
  }
}
@media only screen and (max-width: 600px) {
  .dashboard {
    .mobile-dashboard-view-row-one {
      display: none;
    }
    .rentalunit-cards-mobile {
      // height: 128px;
    }
  }
}
.dashboard {
  margin-top: 5px;
  & .card-subtitle {
    margin-top: -0.3rem !important;
  }
  & .edit-dashboard-btn {
    padding-top: 0.33rem;
    padding-bottom: 0.33rem;
    margin-top: -3px;
    padding-right: 0.55rem;
    padding-left: 0.55rem;
  }
  .edit-purple-btn {
    max-width: fit-content !important;
  }
  .desktop-property-card-margin{
    margin-left: 10%;
  }
  // & .area-margin {
  //   margin-left: -11%;
  // }
  & .label-margin {
    margin-left: -31%;
  }
  & .text-margin {
    margin-left: -31%;
  }
  & .seeAllMargin {
    margin-right: -12px;
  }
}
.right-side {
  display: flex;
  justify-content: end;
  float: right;
}
.top-section {
  margin: 0px 0px 13px;
  .card-heading {
    font-weight: 600;
    font-size: 18px;
    color: $colorBlack;
    margin-bottom: 0px;
  }
}
.billing-card.card {
  margin-bottom: 1.5rem;
}
.billing-card .card-body .table-heading td:first-child {
  text-transform: capitalize;
}
.billing-card .card-body {
  .top-section {
    margin: 0px 0px 18px;
  }
  .table-heading {
    td {
      &:first-child {
        text-transform: capitalize;
      }
      &:last-child {
        float: right;
      }
    }
  }
  // padding: 24px;
  min-height: 260px !important;
  .btn-link {
    padding: 0;
  }
}
.reciept-card {
  .button-img-add-btn {
    position: absolute;
    bottom: 12px;
  }
  .table-data {
    > td:first-child {
      white-space: nowrap;
      text-overflow: ellipsis;
      // max-width: 140px !important;
      max-width: 130px !important;
      padding-right: 25px;
      overflow: hidden;
    }
  }
}
.dashboard-first-row-cards {
  height: 256px !important;
}
.add-rental-dashboard-cards {
  cursor: pointer;
  // height: 172px;
  .record-options {
    .record-dropdown {
      border: none;
      background: transparent;
      padding: 0px;
      margin: 0px;
      border-radius: 0px;
      border-color: transparent;
      box-shadow: none;
    }
    .btn.btn-secondary:not(:disabled):not(.disabled):active {
      background: transparent;
      background: none;
      color: transparent;
      background-color: transparent;
    }
  }
}
.custom-card .custom-card-body.body-size-sm {
  border: 1px solid $colorGrey;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px $box-shadow;
  border-radius: 4px;
}
.property {
  border: none !important;
  box-shadow: none;
}
.property-child {
  box-shadow: none;

  .custom-card-body {
    border: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    background: $colorWhite !important;
    outline: none;
    &:hover {
      background: $hover-color !important;
    }
  }
  .custom-card-header {
    margin: 0px 0px 8px !important;
  }
}
.dropdown-menu {
  a {
    &:hover {
      background: none !important;
    }
    &:active {
      background: none !important;
    }
  }
  button {
    &:hover {
      // background: none !important;
      background-color: #f2f2fc !important;
    }
    &:active {
      background: none !important;
    }
  }
}

.property-dropdown-menu {
  padding: 8px 0px;
  margin: 0;
  top: 0px !important;
  left: 7px !important;
  width: 92.5% !important;
  border-radius: 4px;
  box-shadow: 0px 1px 2px $box-shadow;
  
  .dropdown-item {
    padding: 0;
    .add-property-btn {
      display: flex;
      width: 92%;
      justify-content: center;
      align-items: center;
      margin: 12px 11px 0px;
      background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
      border: 1px solid $colorGrey;
      box-sizing: border-box;
      box-shadow: 0px 1px 2px $box-shadow;
      border-radius: 4px;
      padding: 8px 0px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $colorDarkPurple;
      &:hover {
        background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%) !important;
      }
    }
  }
}
button.transparent-btn.undefined.btn.btn-link {
  padding: 0;
  img.btn-img.left {
    margin: 0;
  }
}
.undefined.card-body {
  padding: 20px;
}

.dropdown-menu {
  button {
    &:focus {
      border: none;
      outline: none;
    }
  }
}
.property-child.card {
  box-shadow: none;
}
.btn-secondary {
  &:not(:disabled) {
    &:not(.disabled) {
      &:active {
        &:focus {
          box-shadow: none;
        }
      }
    }
    &:not(.disabled).active {
      &:focus {
        box-shadow: none;
      }
    }
  }
}
.show {
  > .btn-secondary.dropdown-toggle {
    &:focus {
      box-shadow: none;
    }
  }
}
.property-dropdown .property-parent {
  background: $colorWhite;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: none !important;//0px 1px 2px $box-shadow !important;
}
// #main-menu {
// 	.nav-item {
// 		>a.active {
// 			box-shadow: none;
// 		}
// 	}
// }
.custom-card.card-size-sm {
  margin: 0;
  margin-top: -8px !important;
}

//show-receipt-modal

.receipt-side-img {
  width: 205px !important;
  height: 272px !important;
}
.show-receipt-container {
  display: flex;
  flex-direction: row;
}

//heading-container
.heading-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  .heading-text-wrapper {
    flex: 1;
    .heading {
      font-weight: 600;
      font-size: 20px;
      color: $colorBlack;
    }
  }
}

.mantine-Select-input:focus, .mantine-Select-input:focus-within{
  border: 1px solid #ced4da !important;
}
.mantine-Select-input {
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-top: 1px !important;
}

//dropzone
// .file-dropzone {
//   border: 1px solid $colorGrey;
// }
.file-dropzone {
  &:focus {
    outline: none;
  }
  background: $globalBackgroundColor;
  border: 1px dashed$border-gray;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center; // padding: 39px 55px;
  margin: 0px 0px 24px 0px;
  // cursor: pointer;
  section {
    .drag-div {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      padding: 16px 0px;
      p.first {
        color: $purpleTheme;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        margin: 8px 0px 4px 0px;
      }
      p.second {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        color: $colorLightPurple;
      }
      img {
        filter: invert(32%) sepia(75%) saturate(7390%) hue-rotate(233deg) brightness(101%) contrast(101%);
      }
    }
    .fileSelected {
      p.first {
        color: $border-gray;
      }
      p.second {
        color: #dadae5;
      }
      img {
        filter: none;
      }
    }
    div {
      &:focus {
        outline: none;
      }
      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        margin: 0;
        color: $border-gray; // padding: 40px 55px
      }
    }
  }
}

//filter-container
.filters {
margin-top: 0 !important;
//margin-bottom: 14px !important;
  .section-2 {
    .switch-filter,
    .sort-filter {
      display: inline-block;
    }
  }
  .section-1 {
    .search-filter {
      position: relative;
      top: 0;
      left: 0;

      .search-icon {
        position: absolute;
        top: 9 !important;
        left: 10 !important;
        width: 16px;
        height: 16px;
      }
    }
  }
}

.dashboard-meter-options {
  .record-dropdown {
    padding: 0px;
    margin: 0;
    box-shadow: none;
    border: none;
    // background-color: none;
    background: none;
  }
  .record-dropdown:active {
    background: none !important;
    background-color: none !important;
  }
  .record-dropdown:hover {
    background: none !important;
  }
  .record-dropdown:focus {
    background: none !important;
    box-shadow: none;
    border: none;
  }
}

.text-meter-recordoptions {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  margin: 0;
  -webkit-flex: 1 1;
  flex: 1 1;
}

//----------------------------------------------- Login page start -------------------------------------------

.info-msg {
  z-index: 9999 !important;
  background-color: #f2f2fc;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 48px;
  position: absolute;
  top: 0;
  // padding: 0px !important;
  // margin: 0px !important;
  // margin-left: -25px;

  .info-msg-div {
    display: flex;
    text-align: center;
    justify-content: center;
  }
  .info-msg-text {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #111147;
    margin: 4px 10px;
  }
}

// #primary-content {
//     padding: 0px !important;
//     margin: 0px !important;
// }
// @media only screen and (max-width: 1440px), screen and (max-height: 808px) {
//     #primary-content {
//         padding: 0px !important;
//     }
// }

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  padding-top: 43px;
  margin-top: 48px !important;
  // position: absolute;
  // left: 0;
  // right: 0;
  // top: 0;
  // bottom: 0;

  .logo-section {
    margin-bottom: 62px;
    img {
      width: 158px;
    }
  }
  .main-content-section {
    // margin: 500px 0px;
    .card-body {
      .form-group {
        margin-bottom: 13px;
      }
      padding: 17px 24px 17px;
      width: 352px;
      // height: 276px;
      background: $colorWhite;
      box-shadow: 0px 1px 2px$box-shadow;
      border-radius: 4px;
      .top-section {
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
        color: $colorBlack;
      }
      .form-group {
        .form-label {
          font-weight: normal;
          font-size: 12px;
          line-height: 20px;
          color: $colorLightPurple;
          margin: 0px 0px 5px 0px;
        }
        .form-control {
          width: 305px;
          height: 37px;
          background: $colorWhite;
          border: 1px solid $colorGrey;
          box-sizing: border-box;
          //box-shadow: 0px 1px 2px $box-shadow;
          border-radius: 4px;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          /* identical to box height, or 143% */

          /* Oceanic Purple/900 */

          color: $colorBlack;
        }
        .password-field {
          img {
            position: absolute;
            right: 8px;
            top: 14px;
            cursor: pointer;
          }
        }
      }
      .center-spacing {
        display: flex;
        justify-content: space-between;
        margin: 25px 0px 0px 0px;
        align-items: center;
        a {
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: $label-color;
          text-decoration: none;
        }
        .purple-btn {
          cursor: pointer;
          .btn-text {
            opacity: 0.9;
          }
          &:active {
            background: linear-gradient(180deg, $purpleTheme 0%, $blueForBg 100%);
            /* Purple Azure/700 */

            border: 1px solid$Border-blue;
            box-sizing: border-box;
            /* Card */

            box-shadow: 0px 1px 2px$box-shadow;
            border-radius: 4px;
          }
          background: linear-gradient(180deg, $purpleTheme 0%, $blueForBg 100%);
          /* Purple Azure/700 */

          border: 1px solid$Border-blue;
          box-sizing: border-box;
          /* Card */

          box-shadow: 0px 1px 2px$box-shadow;
          border-radius: 4px;
          height: 36px;
          // width: 65px;
          width: 80px; //german
          flex: 0.3;
          display: flex;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: $colorWhite !important;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .card {
      margin: 0;
    }
  }
  .bottom-section {
    margin: 32px 0px 0px 0px;
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $label-color;
      a {
        color: #13135c;
        font-weight: 600;
        text-decoration: none;
      }
    }
  }
}

//----------------------------------------------- Login page end -------------------------------------------
.signup-zip-code {
  padding-left: 11px !important;
  padding-right: 11px !important;
}
//----------------------------------------------- forgot password start -------------------------------------------
.forgot-pw-page {
  .width-btn {
    width: 100%;
  }
  .reset-btn {
    margin-top: 24px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  padding-top: 43px;
  // position: absolute;
  // top: 0;
  // left: 0;
  // bottom: 0;
  // right: 0;
  .logo-section {
    img {
      width: 158px;
      margin-bottom: 64px;
    }
  }
  .main-form-section {
    .forgot-form.card {
      margin: 0;
      width: 352px;
      padding: 21px 24px 17px;
      .card-body {
        padding: 0;
        .forgot-text {
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: $label-color;
          margin: 0px 0px 12px;
        }
        .form-group {
          margin: 0;
          .form-label {
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            color: $colorLightPurple;
            margin: 0px 0px 4px 0px;
          }
          .form-control {
            background: $colorWhite;
            border: 1px solid $colorGrey;
            box-sizing: border-box;
            //box-shadow: 0px 1px 2px$box-shadow;
            border-radius: 4px;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: $colorBlack;
          }
        }
        .center-spacing {
          display: flex;
          margin: 24px 0px 0px 0px;
          justify-content: space-between;
          align-items: center;
          a {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: $label-color;
          }
          .purple-btn {
            cursor: pointer;
            // padding: 8px 12px;
            flex: 0.6;
            width: 142px;
            height: 36px;
            background: linear-gradient(180deg, $purpleTheme 0%, $blueForBg 100%);
            border: 1px solid$Border-blue;
            box-sizing: border-box;
            box-shadow: 0px 1px 2px$box-shadow;
            border-radius: 4px;
            .btn-text {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              text-align: center;
              color: $colorWhite;
            }
          }
        }
      }
    }
  }
}

//----------------------------------------------- forgot password end -------------------------------------------

//------------------------------------------- instructions page start -------------------------------------
.forgot-pw-page .ins-sent.card {
  margin: 0;
}

.forgot-pw-page .ins-sent.card .card-body {
  padding: 24px 24px 16px 24px;
  width: 352px;
}

.forgot-pw-page .ins-sent.card .card-body .forgot-text {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 24px;
  color: $label-color;
}

.forgot-pw-page .ins-sent.card .card-body .purple-btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  background: linear-gradient(180deg, $purpleTheme 0%, $blueForBg 100%);
  /* Purple Azure/700 */
  border: 1px solid$Border-blue;
  box-sizing: border-box;
  /* Card */
  box-shadow: 0px 1px 2px$box-shadow;
  border-radius: 4px;
  text-decoration: none;
}

.forgot-pw-page .ins-sent.card .card-body .purple-btn div {
  display: flex;
}

.forgot-pw-page .ins-sent.card .card-body .purple-btn .btn-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  text-align: center;

  /* Oceanic Purple/0 */
  color: $colorWhite;
}

.forgot-pw-page .ins-sent.card .card-body .purple-btn .btn-text:focus {
  outline: none;
}
//------------------------------------------- instructions page end -------------------------------------
//------------------------------------------- signup page start -------------------------------------
.signup-page-wrapper {
  display: table;
  margin: 0 auto;
  padding-top: 43px;
  .signup-page {
    width: 352px;
    // height: 295px;
    height: 100%;
    .logo-section {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px 0px 40px;
      img {
        width: 158px;
        height: 100%;
      }
    }
    .main-content-section {
      margin-bottom: 50px;
      .steps-content {
        .signup-heading-wrapper {
          .heading-container {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0px 0px 6px;
            .heading-text-wrapper {
              .card-title {
                margin: 0px !important;
                font-weight: 600;
                font-size: 24px;
                line-height: 40px;
                color: $colorBlack;
              }
            }
            .heading-right-content {
              margin-top: 10px;
              p {
                margin: 0 !important;
                font-weight: 500;
                font-size: 14px;
                line-height: 32px;
                color: $colorLightPurple;
              }
            }
          }
          .subheading {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: $label-color;
            margin: 0px 0px 40px;
            white-space: break-spaces;
          }
        }
        .signup-card-content {
          .form-group {
            margin-bottom: 11px;
            .form-label {
              font-weight: normal;
              font-size: 12px;
              line-height: 20px;
              color: $colorLightPurple;
              margin: 0px 0px 4px;
              padding: 0;
            }
            .form-control {
              outline: none;
              background: $colorWhite;
              border: 1px solid $colorGrey;
              box-sizing: border-box;
              //box-shadow: 0px 1px 2px$box-shadow;
              border-radius: 4px;
              height: 36px;
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              padding: 8px 12px;
              /* identical to box height, or 143% */

              /* Oceanic Purple/900 */

              color: $colorBlack;
            }
          }
        }
      }
    }
  }
  .footer.signup-footer {
    display: flex;
    // width: 100% !important;
    position: absolute;
    bottom: 0 !important;
    left: 0 !important;
    justify-content: space-between;
    padding: 22px 24px;
    background: $colorWhite;
    box-shadow: 0px -1px 2px$box-shadow;
    height: 80px;
    align-items: center;
    width: 100%;
    .bottom-text {
      width: 37%;
      p {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $label-color;
        margin: 0 !important;
        a {
          color: #16165e;
          font-weight: 600;
          text-decoration: none;
        }
      }
    }
    .bottom-btn-wrapper {
      // width: 352px;
      width: 385px; //german;
      // width: 26%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .gray-btn {
        width: 80px;
        cursor: pointer;
        margin: 0 !important;
        background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
        border: 1px solid $colorGrey;
        box-sizing: border-box;
        box-shadow: 0px 1px 2px$box-shadow;
        border-radius: 4px;
        padding: 7px 11px !important;
        outline: none;
        p.btn-text {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $colorDarkPurple !important;
        }
        p.purple-text {
          color: $colorDarkPurple !important;
        }
      }
      .purple-btn {
        &:active {
          background: linear-gradient(180deg, $purpleTheme 0%, $blueForBg 100%);
          border: 1px solid$Border-blue;
          box-sizing: border-box;
          box-shadow: 0px 1px 2px$box-shadow;
          border-radius: 4px;
          outline: none;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $colorWhite !important;
          padding: 7px 11px !important;
        }
        background: linear-gradient(180deg, $purpleTheme 0%, $blueForBg 100%);
        border: 1px solid$Border-blue;
        box-sizing: border-box;
        box-shadow: 0px 1px 2px$box-shadow;
        border-radius: 4px;
        outline: none;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $colorWhite !important;
        padding: 7px 11px;
        cursor: pointer;
        width: 100%;
      }
    }
    .bottom-spacer {
      width: 37%;
    }
  }
}

//2560
@media only screen and (min-width: 1200px) {
  .signup-page-wrapper {
    &.signup-step-3 {
      .signup-footer {
        position: absolute !important;
        bottom: auto !important;
      }
    }
  }
  .signup-page-wrapper.signup-step-3.step-2 {
    .signup-footer {
      position: absolute !important;
      bottom: auto !important;
    }
  }
  .signup-page-wrapper {
    &.signup-step-3.step-4 {
      .signup-footer {
        position: absolute !important;
        bottom: 0 !important;
        .color-btn.purple-btn.btn.btn-secondary {
          // display: none;
        }
      }
    }
  }
  .signup-page-wrapper {
    &.step-1 {
      .signup-footer {
        position: absolute !important;
        bottom: 0px !important;
      }
    }
  }
}
@media only screen and (min-width: 1440px) {
  .signup-page-wrapper {
    &.signup-step-3 {
      .signup-footer {
        position: absolute !important;
        bottom: 0 !important;
      }
    }
  }
  .signup-page-wrapper.signup-step-3.step-2 {
    .signup-footer {
      position: absolute !important;
      bottom: auto !important;
    }
  }
}
@media only screen and (min-width: 576px) {
  .signup-page-wrapper.signup-step-3.step-2 {
    .signup-footer {
      position: absolute !important;
      bottom: auto !important;
    }
  }
}
.signup-page-wrapper {
  &.step-6 {
    .checkbox-div {
      padding-bottom: 20px;
    }
    .signup-footer {
      position: absolute !important;
      bottom: auto !important;
    }
  }
}

//------------------------------------------- signup page end -------------------------------------
//------------------------------------------- subscription plan start -------------------------------------
.signup-page-wrapper {
  .signup-page {
    .main-content-section {
      .steps-content {
        .subscription-step {
          .signup-card-content {
            .subscription-options.checked {
              // width: 366px; //german
              background: $hover-color;
              border: 1px solid $purpleTheme;
              box-sizing: border-box;
              border-radius: 4px;
              .subscription-card {
                .form-check-input[type="radio"] {
                  background-color: #4242ed;
                  border-radius: 8px;
                  border: 1px solid #4242ed;
                  cursor: pointer;
                  height: 16px;
                  width: 16px;
                  box-shadow: 0px 1px 2px $box-shadow;
                  box-sizing: border-box;
                  -webkit-appearance: none;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
                .form-check-input[type="radio"]:after {
                  background-color: $colorWhite;
                  border-radius: 25px;
                  content: "";
                  display: block;
                  height: 8px;
                  position: relative;
                  width: 8px;
                  -webkit-appearance: none;
                }
                .form-check-input[type="radio"]:checked:after {
                  background-color: $colorWhite;
                  -webkit-appearance: none;
                }
                .form-check-label {
                  p {
                    .discount-badge {
                      background: $colorWhite;
                      color: $colorLightPurple;
                    }
                  }
                }
              }
            }
            .subscription-options {
              // width: 352px;
              width: 366px; //german
              height: 96px;
              cursor: pointer;
              background: $colorWhite;
              border: 1px solid $colorGrey;
              box-sizing: border-box;
              box-shadow: 0px 1px 2px $box-shadow;
              border-radius: 4px;
              margin: 0px 0px 16px;
              padding: 20px 20px;
              .subscription-card {
                .form-check-input[type="radio"] {
                  background-color: $colorWhite;
                  border-radius: 8px;
                  border: 1px solid #dadae6;
                  cursor: pointer;
                  height: 16px;
                  width: 16px;
                  box-shadow: 0px 1px 2px $box-shadow;
                  box-sizing: border-box;
                  -webkit-appearance: none;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
                .form-check-input[type="radio"]:after {
                  background-color: $colorWhite;
                  border-radius: 25px;
                  content: "";
                  display: block;
                  height: 8px;
                  position: relative;
                  width: 8px;
                  -webkit-appearance: none;
                }
                .form-check-input[type="radio"]:checked:after {
                  background-color: $colorWhite;
                  -webkit-appearance: none;
                }
                .form-check-label {
                  cursor: pointer;
                  padding: 0 !important;
                  margin: 0px 0px 0px 16px;
                  p {
                    &:first-child {
                      margin: 0px 0px 5px 0px;
                      font-weight: normal;
                      font-size: 14px;
                      line-height: 24px;
                      color: $colorLightPurple;
                      span {
                        &:first-child {
                          font-weight: 600;
                          font-size: 24px;
                          line-height: 24px;
                          color: $colorDeepPurple;
                          margin: 0px 6px 0px 0px;
                        }
                      }
                    }
                    &:last-child {
                      margin: 0;
                      font-weight: normal;
                      font-size: 14px;
                      line-height: 24px;
                      color: $label-color;
                    }
                    .discount-badge {
                      cursor: pointer;
                      background: $hover-color;
                      border-radius: 4px;
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 20px;
                      text-align: center;
                      color: $purpleTheme;
                      padding: 4px 8px;
                      outline: none;
                      margin: 0px 0px 0px 30px;
                    }
                  }
                }

                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

//------------------------------------------- subscription plan end -------------------------------------
.signup-page-wrapper {
  .signup-page {
    .main-content-section {
      .steps-content {
        .payment-step {
          // background-color: green;
          .paymentMode-options.checked {
            background: $hover-color;
            border: 1px solid $purpleTheme;
            box-sizing: border-box;
            border-radius: 4px;

            .creditcard-discount-badge {
              background: #ffffff;
              color: #727293;
            }

            .paymentMode-card {
              .form-check-input[type="radio"] {
                background-color: #4242ed;
                border-radius: 8px;
                border: 1px solid #4242ed;
                cursor: pointer;
                height: 16px;
                width: 16px;
                box-shadow: 0px 1px 2px $box-shadow;
                box-sizing: border-box;
                -webkit-appearance: none;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .form-check-input[type="radio"]:after {
                background-color: $colorWhite;
                border-radius: 25px;
                content: "";
                display: block;
                height: 8px;
                position: relative;
                width: 8px;
                -webkit-appearance: none;
              }
              .form-check-input[type="radio"]:checked:after {
                background-color: $colorWhite;
                -webkit-appearance: none;
              }
              .form-check-label {
                width: 100%;
                p {
                  .discount-badge {
                    background: $colorWhite;
                    color: $colorLightPurple;
                  }
                }
              }
            }
          }

          .paymentMode-options {
            //background-color: pink;
            width: 352px;
            height: 64px;

            cursor: pointer;
            background: $colorWhite;
            border: 1px solid $colorGrey;
            box-sizing: border-box;
            box-shadow: 0px 1px 2px $box-shadow;
            border-radius: 4px;
            margin: 0px 0px 16px;
            padding: 20px 20px;
            input {
              cursor: pointer;
              height: 16px;
              width: 16px;
              background: $colorWhite;
              border: 1px solid $colorGrey;
              box-sizing: border-box;
              box-shadow: 0px 1px 2px $box-shadow;
              border-radius: 8px;
            }
            .form-check-input[type="radio"] {
              background-color: $colorWhite;
              border-radius: 8px;
              border: 1px solid #dadae6;
              cursor: pointer;
              height: 16px;
              width: 16px;
              box-shadow: 0px 1px 2px $box-shadow;
              box-sizing: border-box;
              -webkit-appearance: none;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .form-check-input[type="radio"]:after {
              background-color: $colorWhite;
              border-radius: 25px;
              content: "";
              display: block;
              height: 8px;
              position: relative;
              width: 8px;
              -webkit-appearance: none;
            }
            .form-check-input[type="radio"]:checked:after {
              background-color: $colorWhite;
              -webkit-appearance: none;
            }
            .form-check-label {
              cursor: pointer;
              padding: 0 !important;
              margin: 0px 0px 0px 16px;
              p {
                &:first-child {
                  margin: 0px 0px 5px 0px;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 24px;
                  color: $colorLightPurple;
                  span {
                    &:first-child {
                      font-weight: 600;
                      font-size: 24px;
                      line-height: 24px;
                      color: $colorDeepPurple;
                      margin: 0px 6px 0px 0px;
                    }
                  }
                }
              }
            }
            cursor: pointer;
          }
        }
      }
    }
  }
}
//------------------------------------------- summary start -------------------------------------
.signup-page-wrapper {
  .signup-page {
    .summary-step {
      .signup-card-content {
        .checkbox-div {
          margin-top: 20px;
        }
        .form-check-box {
          display: flex;
        }
        .text-label-inbox {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          color: #454576;
        }
        .underline-text {
          text-decoration: underline;
        }
        .info-box {
          margin: 0px 0px 40px;
          .info-heading {
            h5 {
              margin: 0px 0px 12px 0px;
              font-weight: 500;
              font-size: 16px;
              line-height: 20px;
              color: #13135c;
            }
          }
          .form-info {
            .center-spacing {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
            p {
              margin: 0px 0px 8px 0px;
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              color: $colorPurple1;
            }
          }
          &:first-child {
            .form-info {
              p {
                margin: 0px;
              }
            }
          }
        }
      }
    }
  }
}

//------------------------------------------- summary end -------------------------------------
//------------------------------------------- Personal details start -------------------------------------
.signup-page-wrapper {
  .signup-page {
    .main-content-section {
      .steps-content {
        .signup-card-content {
          .form-group {
            .password-field {
              img {
                position: absolute;
                top: 13px;
                right: 13px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

.btn-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.verify-non-berify-accounts {
  .success-div {
    width: 352px !important;
    background: #e3f7f0;
    border: 1px solid #24ae83;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 16px 16.33px !important;
    .payment-details-div {
      display: flex;
      .payment-left-icon {
      }
      .payment-center-text {
        margin-left: 31.67px;
        margin-top: 4px;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #027d57;
      }
      .payment-right-icon {
        margin-left: 65px;
        margin-top: 3px;
      }
    }
  }
  .failed-div {
    background: #fceff1;
    width: 352px !important;
    border: 1px solid #f95a75;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 16px 16.33px !important;
    .payment-details-div {
      display: flex;
      .payment-left-icon {
      }
      .payment-center-text {
        margin-left: 31.67px;
        margin-top: 4px;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #d41636;
      }
      .payment-right-icon {
        margin-left: 65px;
        margin-top: 3px;
      }
    }
  }

  .payment-detail-row-two {
    margin-left: 45.67px !important;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #454576;
    padding-top: 8px;
  }
}
//------------------------------------------- Personal details end -------------------------------------
//------------------------------------------- Add property modal on sidebar-------------------------------
.add_proprty_modal {
  width: 440px;
  .modal-header {
    background: #f7f7fa;
    padding: 16px 24px !important;
  }
  .modal-body {
    padding: 25px 24px !important;
    .profile-image-container {
      .upload-container {
        margin-top: 20px;
      }
      margin: 0;
    }

    .form-group {
      .form-label {
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
        color: #727293;
        padding: 0px;
      }
      label.heating-temp {
        width: 80px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .form-control {
        &:focus {
          border: 1px solid #dadae6;
        }
        background: #ffffff;
        box-sizing: border-box;
        //box-shadow: 0px 1px 2px rgba(14, 14, 29, 0.06) !important;
        border-radius: 4px;
        outline: none !important;
        height: 36px;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #0e0e1d;
      }
      margin-bottom: 12px;
    }
    .disbaled-temperature {
      background: #f7f7fa !important;
      color: #727293 !important;
      border: 1px solid #dadae6;
    }
    .add_property_modal_dropdown {
      .dropdown {
        &:focus {
          outline: none;
        }
        .btn.btn-secondary:not(:disabled):not(.disabled) {
          div {
            font-size: 14px;
            line-height: 20px;
            color: #0e0e1d;
          }
          &:active {
            background: none;
            border: none;
            box-shadow: none;
            background-color: #ffffff !important;
            border: 1px solid #dadae6;
            height: 36px;
            border-radius: 4px;
          }
          background: none;
          border: none;
          box-shadow: none;
          background-color: #ffffff !important;
          border: 1px solid #dadae6;
          height: 36px;
          border-radius: 4px;
        }
        .property-single-dropdown-menu {
          .dropdown-item {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #0e0e1d;
            margin: 4px 0px;
            padding: 8px 12px !important;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &:hover {
              background: #f2f2fc !important;
            }
          }
          padding: 0 !important;
          background: #ffffff;
          border: 1px solid #dadae6;
          box-sizing: border-box;
          box-shadow: 0px 8px 16px rgba(14, 14, 29, 0.1), 0px 1px 2px rgba(14, 14, 29, 0.06);
          border-radius: 4px;
        }
      }
    }
    .add-property-modal-text {
      .add-property-modal-text-INFO {
        margin-bottom: 11px;
      }
      margin: 13px 0px 0px 0px;
    }
    .profile_modal_radiobtns {
      .radio {
        label {
          padding: 0;
        }
        cursor: pointer;
      }
      .custom-input {
        margin: 5px 0px 0px 0px;
      }
    }
    .cancel_addbtn {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
    }
  }
}

//------------------------------------------- Add property modal on sidebar -------------------------------
//------------signup-successful

.signup-successful {
  width: 352px;
  height: 152px;

  .signup-success-head {
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #0e0e1d;
  }
  .signup-success-text {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #454576, 100%;
  }
  // .signup-footer {
  //   display: none;
  // }
  .signup-login-btn {
    width: 100%;
  }
}
.step-7 {
  .signup-footer {
    display: none !important;
  }
}

/*------------------------------------------- Common by salim -------------------------------*/
.g-error-message {
  width: 100%;
  margin-top: 20px !important;
  font-size: 80%;
  text-align: left !important;
  color: #dc3545;
  line-height: 16px;
}

/*----------------------------------------- remove next btn in step 4-------------------------------*/
.signup-page-wrapper {
  &.signup-step-3.step-4 {
    .signup-footer {
      .color-btn.purple-btn.btn.btn-secondary {
        // display: none;
      }
    }
  }
}

.text_subscription {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  // text-align: left;
  color: #454576;
}

@media only screen and (max-width: 660px) {
  .intercom-lightweight-app-launcher, .intercom-namespace div{
    //display: none !important;
    bottom: 80px !important;
  }
}

.form-control.invalid{
  border-color: rgb(220, 53, 69) !important;
}

.navigate-button {
  border: 0px solid !important;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  padding: 0px !important;
  background-color: #ffffff;
  color: #5555FF;
  cursor: pointer;
  //white-space: nowrap;
}
.numeric-format-text-view{
  border: none;
  font-size: 14px; 
  font-weight: 500; 
  font-family: Inter, sans-serif;
  background: none;
}

.current-subscription{
  .current-subscription-title{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
    color: #727293;
  }
  .current-subscription-text{
    width: 430px;
    //height: 51px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #615C7E;
    margin-top: 15px;
  }
  @media only screen and (max-width: 600px){
    .current-subscription-text {
      width: auto !important;
    }
  }
  .current-subscription-card{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 364px;
    height: 85px;
    border: 1px solid #DADAE5;
    border-radius: 3px;
    margin-top: 20px;
    padding: 20px;
    //cursor: pointer;
    background-color: white;
  }

  @media only screen and (max-width: 600px){
    .current-subscription-card {
      width: auto !important;
    }
  }
  .current-subscription-card-value{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 45px;
    color: #13135C;
  }
  .current-subscription-card-monat{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: rgba(19, 19, 92, 0.6);
    margin-left: 10px;
  }
}

.upgrade-subscription{
  margin-top: 30px;
  .upgrade-subscription-title{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
    color: #727293;
  }
  .upgrade-subscription-text{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #727293;
    margin-top: 15px;
    cursor: pointer;
  }
  .upgrade-subscription-card{
    // display: flex;
    // justify-content: space-between;
    //align-items: center;
    display: flex;
    flex-direction: column;
    width: 364px;
    height: 85px;
    border: 1px solid #DADAE5;
    border-radius: 3px;
    margin-top: 20px;
    padding: 20px;
    cursor: pointer;
    background-color: white;
  }
  @media only screen and (max-width: 600px){
    .upgrade-subscription-card{
      width: auto !important;
    }
  }
  .upgrade-subscription-card-value{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 45px;
    color: #13135C;
  }
  .upgrade-subscription-card-monat{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: rgba(19, 19, 92, 0.6);
    margin-left: 10px;
  }
}

.upgrade-subscription-card-value{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 45px;
  color: #13135C;
}
.upgrade-subscription-card-monat{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: rgba(19, 19, 92, 0.6);
  margin-left: 10px;
}
.upgrade-subscription-card{
  display: flex;
  justify-content: space-between;
  //align-items: center;
  width: 364px;
  height: 85px;
  border: 1px solid #DADAE5;
  border-radius: 3px;
  margin-top: 20px;
  padding: 20px;
  cursor: pointer;
}

.current-subscription-title{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  color: #727293;
}

.payment-template{
  margin-top: 80px;
  .payment-template-title{
    width: 430px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    color: #0E0E1D;
    margin-top: 70px;
  }
  .payment-template-text{
    width: 459px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    //text-align: center;
    color: #454576;
    margin-bottom: 20px;
  }
  .payment-template-options, .mantine-RadioGroup-label{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #454576;
  }
  label{
    margin-bottom: 0 !important;
    padding-right: 0 !important;
  }
}

.payment-template-success{
  margin-top: 10px;
  .payment-template-success-title{
    //width: 430px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    color: #0E0E1D;
    margin-top: 50px;
  }
  .payment-template-success-text{
    width: 459px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #454576;
    margin-bottom: 40px;
  }
}

.payment-invoice-button{
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #5555FF !important;
  cursor: pointer;
}
.invoice-table{
  border-radius: 4px !important;
  width: 75% !important;
  border-collapse: separate;
  border-spacing: 0px 8px;
    tr:first-child{
      td{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 17px;
        color: rgb(114, 114, 147);
        background-color: #F7F7FA;
        padding-bottom: 0 !important;
      }
    }
    tr{
      background-color: white;
      margin-bottom: 16px;
      td{
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
      td::first-letter{
        text-transform: capitalize;
      }
    }
    tr:last-child{
      border-bottom: none;
    }
  
}

@media only screen and (max-width: 600px) {
  .invoice-table {
      width: 100% !important;
  }
}

.new-subscriptions-card{
  width: 314px;
  background: #FFFFFF;
  border: 0.5px solid rgba(114, 114, 147, 0.4);
  border-radius: 7px;
  justify-content: center;
  .new-subscriptions-card-text{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #13135C;
  }
}

@media only screen and (max-width: 600px){
  .new-subscriptions-card{
    width: auto !important;
  }
}

.invoice-pdf-heading{
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #13135C;
}

.invoice-pdf-name{
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
}

.invoice-pdf-company-address{
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
}

.invoice-label{
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgb(118, 118, 118);
}

.invoice-label-value{
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.invoice-text{
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15.8px;
  line-height: 20px;
  white-space: nowrap;
  letter-spacing: -0.1px;
}

.red-btn {
  float: right;
  padding: 7px 13px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $colorWhite !important;
  background: linear-gradient(180deg, $colorRed 0%, #db1738 100%) !important;
  border: 1px solid #d41636 !important;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px $box-shadow;
  border-radius: 4px;
}

.mantine-Modal-root{
  margin-left: auto !important;
  margin-right: auto !important;
}

.payment-heading-main{
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #0e0e1d;
}

.company-logo-upload-label, .company-contact-information-label, .delete-account{
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #0e0e1d;
  margin: 0px 0px 3px 0px;
  padding: 0px !important;
}

.add-allocation-sub-headings { 
  font-size: 14px;
  color: rgb(19, 19, 92);
  font-weight: 500;
}

.messaging-container{
  padding-top: 10px;
}

.message-card{
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  padding: 16px;
  height: 105px;
  cursor: pointer;
  
  .message-card-description{
    font-size: 12px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    color: #13135C;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    height: 25px !important;
    overflow-y: hidden;
    a{
      background-color: #e6e6ff;
      color: #000;
      padding: 2px 5px;
      border-radius: 4px;
      border: 1px solid #ced4da !important;
      pointer-events: none;
      font-weight: 400;
      //cursor: default;
    }
    a:hover{
      text-decoration: none !important;
    }
    p{
      text-overflow: ellipsis;
      overflow-x: hidden;
    }
  }
}
.message-card-time{
  font-weight: 400;
  font-size: 12px;
  color: #13135C;
  white-space: nowrap !important;
}
.message-select-all-btn{
  font-weight: 400;
  font-size: 12px;
  color: #4949F5;
  text-decoration: underline;
  cursor: pointer;
}
.message-card-title{
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #13135C;
  white-space: nowrap;
  p{
    margin-bottom: 0px !important;
  }
  a{
    background-color: #e6e6ff;
    color: #000;
    padding: 2px 5px;
    border-radius: 4px;
    border: 1px solid #ced4da !important;
    pointer-events: none;
    font-weight: 400;
    //cursor: default;
  }
  a:hover{
    text-decoration: none !important;
  }
}
.message-card-title-mobile{
  p{
    //width: 174px;
    overflow: scroll;
    white-space: nowrap;
    text-overflow: ellipsis;
    scrollbar-width: none !important;
  }
  p::-webkit-scrollbar{
    display: none !important;
  }
}
.message-card-ten-cards{
  font-size: 12px;
  font-family: Inter;
  background-color: #F2F2FC;
  font-weight: 400;
  padding: 1px 10px 1px 1px;
  border-radius: 10px;
  color: #13135C;
  cursor: pointer;
  scrollbar-width: none !important;
}
.message-card-ten-cards::-webkit-scrollbar{
  display: none !important;
}
lable{
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #454576;
  margin: 0px 0px 0px 0px;
}
.mantine-Select-label{
  margin-bottom: 0px !important;
}
.ql-container.ql-snow{
  border-top: none !important;
  border-color: rgba(218, 218, 229, 1) !important;
}

.ql-toolbar.ql-snow{
  border-color: rgba(218, 218, 229, 1) !important;
}

.mantine-Input-input:focus{
  border-color: #ced4da !important;
}
.mantine-Input-input{
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.demo-editor{
  background-color: white;
  height: 380px !important;
  border: 1px solid #ced4da;
  padding: 0px 10px;
  font-weight: 400;
}
.rdw-editor-toolbar{
  //border: 1px solid #ced4da !important;
  border: none !important;
  background-color: transparent !important;
  padding-left: 0 !important;
}
.rdw-option-wrapper{
  border: none !important;
  background-color: transparent !important;
}
.rdw-inline-wrapper:first-child{
  .rdw-option-wrapper{
    margin-left: 0 !important;
  }
}
.rdw-inline-wrapper, .rdw-fontsize-wrapper, .rdw-list-wrapper, .rdw-text-align-wrapper{
  flex-wrap: nowrap;
}
.rdw-fontsize-dropdown{
  min-width: 60px;
  border-color: #ced4da !important;
  border-radius: 4px !important;
}
.rdw-dropdown-optionwrapper:hover {
  box-shadow: none !important;
  background-color: #FFFFFF;
}
.rdw-dropdownoption-highlighted, .rdw-dropdownoption-active{
  background-color: #e6e6ff !important;
}
.rdw-dropdown-carettoopen {
  height: 0px;
  width: 0px;
  position: absolute;
  top: 42%;
  right: 10%;
  border-top: 4px solid #727293;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
}
.rdw-dropdown-carettoclose{
  height: 0px;
  width: 0px;
  position: absolute;
  top: 42%;
  right: 10%;
  border-bottom: 4px solid #727293;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
}
.rdw-remove-wrapper, .rdw-emoji-wrapper, .rdw-embedded-wrapper{
  display: none !important;
}
.rdw-dropdown-selectedtext{
  span{
    font-weight: normal;
    font-size: 14px !important;
    margin-top: 1px;
    font-family: sans-serif;
    color: #13135C !important;
  }
}
.rdw-dropdown-selectedtext:hover{
  text-decoration: none !important
}
.rdw-dropdown-wrapper:hover, .rdw-option-wrapper:hover{
  box-shadow: none !important;
}

.rdw-option-active{
  background-color: #e6e6ff !important;
  box-shadow: none !important;
  color: #fff;
  border-radius: 4px !important;
}

.mantine-MultiSelect-searchInputEmpty::placeholder{
  
}
.mantine-MultiSelect-searchInput{
  font-weight: normal;
  font-size: 14px !important;
  line-height: 20px;
  font-family: sans-serif;
  margin: 0;
  color: #13135C !important;
}
.mantine-MultiSelect-searchInput::placeholder{
  color: #727293 !important;
  font-size: 14px !important;
}
.mantine-MultiSelect-searchInput::-moz-placeholder{
  color: #727293 !important;
  font-size: 14px !important;
}
.mantine-MultiSelect-searchInput::-webkit-input-placeholder{
  color: #727293 !important;
  font-size: 14px !important;
}
.mantine-MultiSelect-wrapper, .mantine-MultiSelect-input{
  height: 36px !important;
}
.mantine-MultiSelect-input:focus-within{
  border-color: #ced4da !important;
}
.mantine-MultiSelect-input{
  padding-right: 20px !important;
}
.mantine-MultiSelect-rightSection{
  width: 22px !important;
}
.mantine-MultiSelect-hovered{
  background-color: #e6e6ff !important;
}
.mantine-MultiSelect-values{
  //flex-wrap: nowrap;
  // overflow-x: scroll;
  // overflow-y: hidden;
  
}

.blank-template-icon{
  border: 1px dashed rgb(191, 191, 210);
  justify-content: center !important;
  padding: 20px 55px;
  border-radius: 4px;
  cursor: pointer;
}
.blank-template-icon:hover{
  border-color: #4949F5;
}
.template-icon{
  border: 1px solid rgb(191, 191, 210);
  //justify-content: center !important;
  min-width: 240px;
  height: 220px;
  //padding: 49px;
  border-radius: 4px;
  cursor: pointer;
}
.template-icon:hover{
  border-color: #4949F5;
}
.template-icon-img{
  background: #e6e6ff;
  height: 65%;
  padding: 20px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.template-icon-text{
  text-align: center;
}
.template-icon-sub-text{
  font-size: 10px;
  font-weight: 400;
  max-width: 218px !important;
  max-height: 32px;
  text-overflow: ellipsis;
  overflow: hidden;
  
}
.template-title{
  color: #13135C;
  font-size: 12px;
  font-weight: 400;
}
.template-modal{
  max-width: 800px !important;
}
.template-modal-categories-container{
  width: 170px;
  overflow-y: scroll; 
  overflow-x: hidden; 
  height: 730px;
  scrollbar-width: none !important;
}
.template-modal-categories-container::-webkit-scrollbar {
  display: none;
}
.template-modal-main-container{
  overflow-y: scroll; 
  overflow-x: hidden; 
  height: 730px;
  scrollbar-width: none !important;
}
.template-modal-main-container::-webkit-scrollbar {
  display: none;
}
.template-modal-categories{
  //color: #13135C;
  font-weight: 500;
  font-size: 14px;
  //padding: 5px;
}
.template-modal-sub-categories{
  color: #13135C;
  font-weight: 400;
  font-size: 12px;
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
  width: 100%;
}
.template-modal-sub-categories:hover{
  background-color: #e6e6ff;
}
.rdw-block-wrapper, .rdw-fontfamily-wrapper, .rdw-colorpicker-wrapper, .rdw-link-wrapper, .rdw-history-wrapper, .rdw-option-hide, .rdw-image-wrapper{
  display: none !important;
}
.forward-icon{
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.multi-select-messages-tenants{
  .mantine-MultiSelect-values{
    //white-space: nowrap;
    //display:flex;
    max-height: 34px !important;
    overflow-x: scroll !important;
    overflow-y: hidden !important;
    flex-wrap: nowrap !important;
    scrollbar-width: none !important;
  }
  .mantine-MultiSelect-values::-webkit-scrollbar {
    display: none;
  }
}

.messages-list-screen{
  .filters{
    align-items: flex-end;
  }
}
.rdw-editor-toolbar{
  width: fit-content;
}
.editor-add-tenant-name-placeholder{
  background-color: white;
  padding: 0px 5px;
  font-size: 14px;
  border-radius: 4px;
  //height: 22px;
  font-weight: 400;
  font-family: Inter, sans-serif;
  border: 1px solid #ced4da !important;
  cursor: pointer;
}
.tenant-block{
  background-color: #4949F5 !important;
}
.rdw-mention-link, .rdw-link-decorator-wrapper{
  background-color: rgb(205, 205, 255, 0.5);//#e6e6ff;
  color: black;
  padding: 2px 5px;
  border-radius: 4px;
  border: 1px solid #ced4da !important;
  a{
    color: black !important;
  }
  a:hover{
    color: black !important;
    text-decoration: none;
  }
  .rdw-link-decorator-icon{
    display: none !important;
  }
}
.rdw-mention-link:hover, .rdw-link-decorator-wrapper:hover{
  color: #4949F5;
  text-decoration: none;
}
.rdw-suggestion-option:hover{
  background-color: #e6e6ff;
  cursor: pointer;
}
.public-DraftStyleDefault-block{
  line-height: 27px;
}
.allocation-type-button{
  border: 1px rgba(154, 154, 181, 1.00) solid;
  padding: 15px;
  border-radius: 4px;
  min-width: 200px;
  min-height: 177px;
}
.allocation-type-button-mobile{
  border: 1px rgba(154, 154, 181, 1.00) solid;
  padding: 15px;
  border-radius: 4px;
  min-width: auto;
  min-height: 220px;
}
.allocation-meter-type-button{
  border: 1px rgba(154, 154, 181, 1.00) solid;
  padding: 15px;
  border-radius: 4px;
  min-width: 200px;
  min-height: 110px;
  cursor: pointer;
}
.allocation-meter-type-button-mobile{
  border: 1px rgba(154, 154, 181, 1.00) solid;
  padding: 15px;
  border-radius: 4px;
  min-width: auto;
  min-height: 110px;
  cursor: pointer;
}
.allocation-type-button:hover{
  border: 1px #5555FF solid;
  cursor: pointer;
}
.add_allocation_key_modal{
  max-width: 520px !important;
}
.edit_allocation_key_modal{
  max-width: 330px !important;
}
.individual-allocation-key-modal {
  //margin-left: 25% !important;
  max-width: 1000px !important;
  .modal-content {
    width: 100%;
    height: 100%;
    .modal-header {
      padding: 16px 24px !important;
    }
    .table-responsive-Zuweisung {
      .Zuweisung-table-head {
        display: flex;
        .Zuweisung-table-head-tenant {
          margin-right: 24px;
          width: 30%;
        }
        .Zuweisung-table-occupancy {
          margin-right: 24px;
          width: 20%;
        }
        .Zuweisung-table-head-rentalUnit {
          width: 24.3%;
          margin-right: 24px;
        }
        .Zuweisung-table-head-amount {
          width: 16.7%;
        }
        th {
          font-weight: normal;
          font-size: 12px;
          line-height: 20px;
          color: #727293;
          margin: 0px 0px 4px;
        }
      }
    }
  }
}

.multiSelectContainer ul{
  max-height: 200px !important;
}
.receipt-main-modal{
  max-width: 1200px !important;
}
.message-preview-main-modal{
  max-width: 1200px !important;
}
.message-preview-main-mobile-modal{
  max-width: auto !important;
}
.ub-printing-options-modal{
  max-width: 530px !important;
  font-size: 14px;
  line-height: 24px;
  color: rgb(14, 14, 29);
  font-weight: 400;
  font-family: Inter, sans-serif;
}
.select-ub-printing-options-switch-label-text{
  color: #000000;
}

.addbtn-icon {
  position: absolute;
  margin-top: -30px;
  right: 26px;
  border: 1px solid #dadae6;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
}

.subbtn-icon {
  position: absolute;
  margin-top: -30px;
  right: 53px;
  border: 1px solid #dadae6;
  border-radius: 4px;
  padding: 9px 6px;
  cursor: pointer;
}

.custom-card-pricing{
  min-width: none !important;
  min-height: none !important;
  border: 1px rgba(154, 154, 181, 1.00) solid;
  padding: 6px;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
}

.meter-type-button{
  border: 1px rgba(154, 154, 181, 1.00) solid;
  padding: 15px;
  border-radius: 4px;
  min-width: 200px;
  min-height: 130px;
  background-color: white;
}
.meter-type-button-mobile{
  border: 1px rgba(154, 154, 181, 1.00) solid;
  padding: 15px;
  border-radius: 4px;
  min-width: auto;
  min-height: 130px;
}
.meter-type-button:hover{
  //border: 1px #5555FF solid;
  cursor: pointer;
}

.add-allocationkey-dropdown-btn {
  width: 100% !important;
  // margin-left: -15px !important;
}

.bs-popover-top > .arrow{
  display: none !important;
}
.modal-sub-headings { 
  font-size: 14px;
  color: rgb(19, 19, 92);
  font-weight: 500;
}

.sub-text-ub-costposition-amount{
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: #5555FF;
  font-family: Inter, sans-serif;
  background-color: #f2f2fc;
  padding: 2px 5px;
  border-radius: 4px;
  cursor: pointer;
}

.sub-text-ub-costposition-amount-disabled{
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: #727293;
  font-family: Inter, sans-serif;
  background-color: #F7F7FA;
  padding: 2px 5px;
  border-radius: 4px;
  cursor: pointer;
}

.ub-vat-labour-switch{
  justify-content: flex-end;
}

.mantine-TextInput-input{
  padding-left: 5px !important;
  padding-top: 2px !important;
  font-family: sans-serif !important;
}

.mantine-TextInput-input:focus, .mantine-TextInput-input:focus-within{
  border-color: #dadae6 !important;
}

.mantine-TextInput-input::placeholder{
  color: #b3b3c4 !important
}

.dd-wrapper .custom-select-dropdown .interaction-area{
  //padding-top: 4px !important;
  font-family: sans-serif !important;
}
// .dd-wrapper .custom-select-dropdown-pt-0 .interaction-area{
//   padding-top: 0px !important;
// }
.optional-label{
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #727293;
}

.mantine-TextInput-rightSection .mantine-Select-rightSection{
  margin-top: -3px !important;
}

.form-control-transparent{
  border: none !important;
  width: 100px !important;
  height: 0px !important;
  padding-left: 0px !important;
  background: transparent !important;
}

.form-control-transparent:focus{
  border: rgb(213, 220, 230) !important;
}

.dd-wrapper-disable{
  background-color: #f7f7fa !important;
}

.ub-vat-labour-text{
  white-space: nowrap;
}

.ub-betriebskostenabrechung-right-content{
  width: auto !important;
}

.bs-tooltip-bottom {
  & .tooltip-inner {
    padding: 10px;
    background-color: $light-blue !important;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px;
    font-family: "intercom-font", "Helvetica Neue", "Apple Color Emoji", Helvetica, Arial, sans-serif; 
    font-size-adjust: none; 
    font-size: 15px; 
    font-style: normal; 
    letter-spacing: normal; 
    font-stretch: normal; 
    font-variant: ■normal; 
    font-weight: normal; 
    text-align: left; 
    text-decoration: none; 
    text-emphasis: ► none currentcolor; 
    text-indent: 0px; 
    text-justify: auto; 
    text-shadow: none; 
    text-transform: none; 
    animation-play-state: running; 
    backface-visibility: visible; 
    background-color: transparent; 
    border: 0px none transparent; 
    border-radius: 0px; 
    color: inherit;
  }
  & .arrow:before {
    border-bottom-color: $light-blue !important; 
  }
}

.documents-breadcrumbs{
  margin-left: 0px !important;
}

@media (max-width: 600px) {
  .documents-breadcrumbs{
    margin-top: 0px !important;
  }
}

.form-group {
  .form-label {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: $colorLightPurple;
    margin: 0px 0px 3px;
  }

  .form-control {
    background: $colorWhite;
    border: 1px solid $colorGrey;
    box-sizing: border-box;
    // box-shadow: 0px 1px 2px $box-shadow;
    border-radius: 4px;
    height: 36px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $colorBlack;
  }
}

.documents-list-table {
  border-collapse: separate !important;
  border-spacing: 0px 8px !important;
  thead{
    tr{
      th{
        padding-bottom: 0px !important;
        padding-top: 0px !important;
        color: #727293 !important;
      }
    }
  }
  tbody{
    tr{
      td{
        color: #111147 !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        font-family: Inter, sans-serif !important;
      }
    }
  }
}

.documents-header-right-section{
  //color: #727293;
  color: rgb(95, 95, 122);
  font-weight: 400;
  //color: #111147;
  font-size: 11px;
  font-weight: 400;
  //text-align: justify;
}

.move-copy-documents-modal-accordion-control:hover{
  background-color: white !important;
}

.move-copy-documents-modal-accordion-content{
  padding-left: 15px !important;
}

.move-copy-documents-modal-accordion-icon{
  display: none !important;
}

.move-copy-documents-modal-accordion-contentInner{
  padding: 5px 0px !important;
}
.archive-meter-checkbox-label{
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  color: #727293;
}

.property-single_dropdown.form-control.btn.btn-secondary{
  background: #FFFFFF !important;
  padding-right: 15px !important;
}

.meter-readings-searchbar{
  .mantine-TextInput-input{
    padding-left: 36px !important;
  }
}

.meter-archive-accordion-control:hover{
  background-color: white !important;
}

.meter-archive-accordion-icon{
  display: none !important;
}

.add-tenant-modal{
    max-width: 750px !important;
}

.archive-meter{
  td{
    color: #727293 !important;
  }
}

.documents-filter-title{
  font-family: Inter, sans-serif;
  line-height: 1.55;
  text-decoration: none;
  color: rgb(17, 17, 71);
  font-size: 16px;
  margin: 10px;
}

.documents-filter-select-label{
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
  margin-bottom: 0.5rem;
}

.icon-container{
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.mantine-Breadcrumbs-breadcrumb{
  align-items: center !important;
  display: flex !important;
}

.document-search-container{
  width: 500px;
}

@media (max-width: 550px) {
  .document-search-container{
    width: 100%;
  }
}

.override-documents-modal-text{
  font-size: 14px;
  line-height: 24px;
  color: rgb(14, 14, 29);
  font-weight: 400;
  font-family: Inter, sans-serif;
}

.documents-breadcrumbs-scrollbar {
  scrollbar-width: none !important;
}
.documents-breadcrumbs-scrollbar::-webkit-scrollbar {
  display: none;
}

.upload-root-documents::before{
 display: none !important;
}

.partner-list-row-heading{
  font-size: 18px;
  font-weight: 500;
  color: #13135C;
  font-family: Inter, sans-serif;
}

.partner-list-page-row{
  background: white;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 880px;
  height: 162px;
}

.partner-list-row-tag-badge{
  background-color: #e6e6ff;
  font-size: 10px;
  font-weight: 400;
  padding: 2px 6px;
  color: #5555FF;//#727293;
  border-radius: 4px;
}

.partner-list-row-description{
  font-size: 14px;
  font-weight: 400;
  font-family: Inter, sans-serif;
  color: #13135C;
}

.partner-list-page-row-popular-badge{
  background-color: #E3E3FC;
  color: #5555FF;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 10px;
  border-radius: 4px;
}

.badge-background-color-white{
  background-color: white !important;
}

.tenant-selector-search-input{
  cursor: pointer !important;

}

.tenant-selector-input{
  opacity: 1 !important;
  padding-left: 5px !important;
}
.tenant-selector-input-with-values{
  opacity: 1 !important;
  padding-left: 10px !important;
}
.messaging-subject-input::placeholder{
  font-size: 14px !important;
  color: #727293 !important;
  opacity: 0.6 !important;
  font-family: Inter, sans-serif !important;
}

.messaging-subject-input{
  font-size: 14px !important;
  color: #13135C !important;
  font-family: Inter, sans-serif !important;
}
input .messaging-subject-input[value^="<a href=\"\" target=\"_self\">"] {
  background-color: #5555FF;
}



.ub-sending-select-tenant-modal-accordion-control:hover{
  background-color: white !important;
}

.ub-sending-select-tenant-modal-accordion-content{
  padding-left: 0px !important;
}

.ub-sending-select-tenant-modal-accordion-icon{
  display: none !important;
}

.ub-sending-select-tenant-modal-accordion-contentInner{
  padding: 5px 0px !important;
}

.recipients-selection-input{
  padding-left: 35px !important;
  font-size: 14px !important;
  color: #13135C !important;
  font-family: Inter, sans-serif !important;
}

.recipients-selection-input::placeholder{
  color: #727293 !important;
  opacity: 0.6 !important;
  font-family: Inter, sans-serif !important;
}

.partner-bullet-point-icon{
  background: url('../../../../../assets/images/module/general/partner-bullet-icon.svg') no-repeat left center;
  margin-top: 12px;
  padding-left: 17.5px;
}

.partner-list{
  padding: 0;
  margin: 0;
  margin-left: 5px;
  list-style-type: none;
}

.app-main-menu{
  scrollbar-width: none !important;
}

.app-main-menu::-webkit-scrollbar{
  display: none;
}

.custom-pricing-numeric-input{
  font-weight: 600;
  font-family: Inter, sans-sarif;
  font-size: 16px;
  color: rgb(85, 85, 255);
  border: none !important;
  background-color: transparent !important;
  width: 100% !important;
}
.custom-pricing-numeric-input:focus-visible{
  outline: none !important;
}
.pricing-table-cell-borders{
  border-bottom: 0.5px solid rgb(19, 19, 92) !important;
  border-right: 0.5px solid rgb(19, 19, 92) !important;
}
.add-border-bottom{
  border-bottom: 0.5px solid rgb(19, 19, 92) !important;
}

.public-DraftStyleDefault-unorderedListItem{
  .public-DraftStyleDefault-block{
    margin: 0px !important;
  }
}
@media screen and (max-width: 1340px){
  .heading-right-content.heating-screen-next-button{
    margin-left: -300px;
  }
}

.partner-dashboard-card-content{
  background: //linear-gradient(0deg, #F0F0F0, #F0F0F0),
  linear-gradient(0deg, #F8FAFC, #F8FAFC);
  box-shadow: 0px 0px 4px 0px #0000000D;
  border: 0.5px solid #F0F0F0;
  padding: 20px 20px 0 35px;
  border-radius: 10px;
  cursor: pointer;
  span{
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }
}

@media screen and (max-width: 1100px){
  .partner-dashboard-card-content{
    padding: 10px 10px 0 15px;
    span{
      word-break: break-all;
    }
  }
}

.partner-dashboard-card-button{
  background: linear-gradient(180deg, #5555FF 0%, #4949F5 100%),
  linear-gradient(0deg, #4242ED, #4242ED);
  border: 0.69px solid rgba(66, 66, 237, 1);
  box-shadow: 0px 0px 11.391451835632324px 0px rgba(14, 14, 29, 0.15);
  width: Fixed (134px);
  height: Hug (28.11px);
  padding: 5.56px 8.34px 5.56px 8.34px;
  border-radius: 6.25px;
  border: 0.69px;
  span{
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
  }

}

.partner-receipt-popover-text{
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  color: rgba(19, 19, 92, 1);
}

.preview-recipients-list{
  .preview-selected-recipient{
    background-color: #E3E3FC;
    border-radius: 4px;
  }
  .preview-recipients-list-item{
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    cursor: pointer;
  }

  .preview-recipients-list-item-disabled{
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    cursor: pointer;
    opacity: 0.5;
  }
  .preview-recipients-list-item-disabled:hover{
    background-color: transparent;
  }
  .preview-recipients-list-item:hover{
    background-color: #E3E3FC;
    border-radius: 4px;
  }
}

.btn-group-messaging {
  background: #e9e9f0 !important;
  height: 36px;
  // width: 206px;
  width: auto;
  padding: 0px 10px !important;
  border-radius: 4px;
  display: flex;
  align-items: center;
  z-index: 999;
  .btn-primary {
    border: none !important;
    outline: none !important;
    font-size: 14px;
    font-weight: 500;
    background-color: #e9e9f0 !important;
  }
  button.switch-btn {
    height: 26px !important;
    background-color: none !important;
  }
  button.switch-btn {
    color: #13135C !important;
  }
  .switch-btn.active{
    color: #13135C !important;
    background-color: white !important;
    border-radius: 4px;
    border: none !important;
  }
  .button.switch-btn:focus{
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
  .switch-btn.active:focus{
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
  .btn.btn-primary:focus{
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
}

// .message-preview-box{
//   scrollbar-width: none !important;
// }

// .message-preview-box::-webkit-scrollbar{
//   display: none;
// }

.place-holder-container{
  scrollbar-width: none !important;
}

.place-holder-container::-webkit-scrollbar{
  display: none;
}

.place-holder-container{
  -webkit-mask: linear-gradient(90deg,#0000,#000 1% 99%,#0000);
}

.message-subject-editor{
  height: 36px !important;
  background-color: white !important;
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  overflow-y: hidden;
  padding-left: 5px;
  font-weight: 400 !important;
  .public-DraftStyleDefault-block.public-DraftStyleDefault-ltr{
    margin: 3px 0 0 !important;
  }
}

.message-subject-toolbar{
  //display: none !important;
  position: absolute;
  top: 375px;
  left: 500px;
  background: white !important;
  box-shadow: 0px 0px 4px 4px #0000000D;
  //padding-top: 0 !important;
  padding-right: 0 !important;
  padding-left: 4px !important;
}

@media only screen and (max-width: 600px){
  .message-subject-toolbar{
    //display: none !important;
    position: absolute;
    top: 400px;
    left: 240px;
    max-width: fit-content;
    background: white !important;
    box-shadow: 0px 0px 4px 4px #0000000D;
    //padding-top: 0 !important;
    padding-right: 0 !important;
    padding-left: 4px !important;
  }
}

.message-body-toolbar-mobile{
  // position: absolute;
  // top: 600px;
  // left: 7px;
  // background: white !important;
  // box-shadow: 0px 0px 4px 4px #0000000D;
  //padding-right: 0 !important;
  //padding-left: 4px !important;
  padding: 0 !important;
  z-index: 9999;
  white-space: nowrap !important;
  overflow-x: scroll !important;
}

.messages-list-recipients{
  scrollbar-width: none !important;
}

.messages-list-recipients::-webkit-scrollbar{
  display: none;
}

.messages-list-recipients{
  //overflow: hidden;
  -webkit-mask: linear-gradient(90deg,#0000,#000 1% 99%,#0000);
}

.message-detail-body{
  line-height: 27px !important;
  font-weight: 400 !important;
  a{
    background-color: #e6e6ff;
    color: #000;
    padding: 2px 5px;
    border-radius: 4px;
    border: 1px solid #ced4da !important;
    pointer-events: none;
    //cursor: default;
  }
  a:hover{
    text-decoration: none !important;
  }
  p{
    margin-bottom: 0;
  }
}

.mantine-TextInput-invalid{
  color: #000000 !important;
}

.delete-message{
  background: none !important;
  border: none !important;
}

.preview-pdf-subject{
  p{
    margin-bottom: 0 !important;
  }
}

// .delete-subscription-modal{
//   width: 1000px !important;
// }

.mobile-annual-reading-search{
  width: auto !important;
}

.mobile-bank-transaction-search{
  width: 100% !important;
}

.tenant-administration-accordion-inner-content{
  padding: 0 !important;
}

.tenant-administration-accordion-control:hover{
  background-color: transparent !important;
}

.tenant-administration-accordion-icon{
  display: none !important;
}

.mantine-Breadcrumbs-breadcrumb:hover{
  text-decoration: none !important;
}

.energy-consumption-and-c02-emission-table-cell-inner-group{
  scrollbar-width: none !important;
  overflow-x: scroll;
}
.energy-consumption-and-c02-emission-table-cell-inner-group::-webkit-scrollbar {
  display: none;
}

.assessment-card-box-two-heading-text, .assessment-card-box-three-heading-text{
  font-size: 13px !important;
  font-weight: 500 !important;
  white-space: nowrap !important;
}
.assessment-card-box-two-heading-circle, .assessment-card-box-three-heading-circle{
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #5353FE;
  border: 4px solid white;
}
.assessment-card-box-two-end-text, .assessment-card-box-three-end-text{
  font-size: 9px;
  font-weight: normal;
  color: #727293;
}

.assessment-card-icon-text{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #FFFFFF;
}

.arrow-right {
  width: 0; 
  height: 0; 
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
}

.consumption-card-value{
  font-size: 24px !important;
  font-weight: bold;
  margin-right: 5px !important;
  white-space: nowrap;
}

.consumption-card-value-scale{
  font-size: 16px !important;
  font-weight: bold;
  margin-right: 5px !important;
  white-space: nowrap;
}

.consumption-card-unit{
  font-size: 12px !important;
  white-space: nowrap;
}

.consumption-card-unit-scale{
  font-size: 10px !important;
  white-space: nowrap;
  margin-left: -2px;
}

.tenant-administration-display-messages, .tenant-administration-display-documents, .tenant-administration-display-consumptions{
  .intercom-zhilou{
    margin-top: 1px;
  }
}

.daterangepicker{
  z-index: 9999999 !important;
}

.receipts-filter-range-date-picker-input{
  height: 40px !important;
}

.receipts-filter-range-date-picker-img{
  margin-top: 2px;
}

.rental-incom-range-date-picker-input{
  height: 36px !important;
  width: 100% !important;
}

.rental-incom-range-date-picker-main-container{
  width: 100% !important;
}

.rental-income-export-date-range{
  width: 100% !important;
}

.rental-income-export-date-range{
  // height: 36px !important;
  // border: 1px solid #dadae6 !important;
  // padding: 5px !important;
  // border-radius: 4px !important;
  // font-size: 14px !important;
  // font-weight: 400 !important;
  // font-family: Inter, sans-serif;
  // width: auto !important;
  // background-color: white !important;
}
.rental-income-export-date-range:focus-visible{
  //border: white !important;
  //outline: none !important;
}
input.rental-income-export-date-range:focus-visible {
  outline: none !important;
}

.rental-income-export-date-range {
  // .react-datepicker__tab-loop{
  //   display: none !important;
  // }
  .react-datepicker__tab-loop {
    position: absolute;
    top: 0;
  }
  
  .react-datepicker__triangle {
    display: none !important;
  }
  .react-datepicker-wrapper{
    width: 220px !important;
  }
  .react-datepicker {
    height: auto !important;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: #4242ed !important;
  }
}

.form-control-meter-readings{
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  padding: 0px !important;
  background: transparent !important;
  color: #111147 !important;
  font-family: Inter, sans-sarif !important;
  font-size: 14px !important;
}

.rental-income-list-table {
  border-collapse: separate !important;
  border-spacing: 0px 8px !important;
  thead{
    tr{
      th{
        padding-bottom: 0px;
        padding-top: 0px;
        color: #727293 !important;
      }
    }
  }
  tbody{
    tr{
      td{
        color: #111147 !important;
        font-size: 14px !important;
        font-weight: 300 !important;
        font-family: Inter, sans-serif !important;
      }
    }
  }
}

.selected-card-filter{
  //background-color: #e6e6ff;
  background-color: #ffffff;
  border-radius: 15px !important;
  padding: 2px 10px;
  height: 28px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  span{
    font-size: 12px;
    //color: #5555FF;
    color: #13135C;
  }
  img{
    width: 12px;
  }
}

.mantine-RadioGroup-label{
  white-space: nowrap;
  margin-bottom: 0px !important;
  cursor: pointer;
}

.mantine-RadioGroup-radio{
  cursor: pointer;
}

.rental-income-payment-column-not-paid{
  color: #E8193C !important;
  text-align: center;
}

.rental-income-payment-column-not-paid:focus{
  color: #E8193C !important;
}

.rental-income-payment-column-full-paid{
  color: #059266 !important;
  text-align: center;
}

.rental-income-payment-column-full-paid:focus{
  color: #059266 !important;
}

.rental-income-payment-column-partial-paid{
  color: #D97706 !important;
  text-align: center;
}

.rental-income-payment-column-partial-paid:focus{
  color: #D97706 !important;
}


.tenant-list-scrollable{
  scrollbar-width: none !important;
}

.tenant-list-scrollable::-webkit-scrollbar{
  display: none;
}

.tenants-list-checkbox-rental-income{
  width: auto !important;
  height: auto !important;
}

.tenants-list-checkbox-rental-income-inner{
  width: auto !important;
  height: auto !important;
}

.tenants-list-checkbox-rental-income-input:checked{
  background-color: #5555FF !important;
  border-color: #5555FF !important;
}

.mantine-Checkbox-label{
  margin-bottom: 0 !important;
}

.mantine-RadioGroup-radio:checked{
  background-color: #5555FF !important;
  border-color: #5555FF !important;
}

.house-comparison-scale-row{
  //height: 50px !important;
  width: 100%;
}

.house-comparison-scale-col-value-indicator{
  width: 11.1% !important; 
}

.house-comparison-scale-col-1{
  background: linear-gradient(89.59deg, rgba(5, 146, 102, 1), rgba(5, 146, 102, 0.9)) !important;
  width: 11.1% !important;
  height: 30px !important;
  border-radius: 2px 0px 0px 2px;
}

.house-comparison-scale-col-2{
  background: linear-gradient(89.59deg, rgba(5, 146, 102, 0.9), rgba(5, 146, 102, 0.8)) !important;
  width: 11.1% !important;
  height: 30px !important;
}

.house-comparison-scale-col-3{
  background: linear-gradient(89.59deg, rgba(5, 146, 102, 0.8), rgba(191, 148, 26, 0.84)) !important;
  width: 11.1% !important;
  height: 30px !important;
}

.house-comparison-scale-col-4{
  background: linear-gradient(89.59deg, rgba(191, 148, 26, 0.84), rgba(191, 148, 26, 0.74)) !important;
  width: 11.1% !important;
  height: 30px !important;
}

.house-comparison-scale-col-5{
  background: linear-gradient(89.59deg, rgba(191, 148, 26, 0.74), rgba(255, 149, 0, 0.78)) !important;
  width: 11.1% !important;
  height: 30px !important;
}

.house-comparison-scale-col-6{
  background: linear-gradient(89.59deg, rgba(255, 149, 0, 0.78), rgba(255, 149, 0, 0.68)) !important;
  width: 11.1% !important;
  height: 30px !important;
}

.house-comparison-scale-col-7{
  background: linear-gradient(89.59deg, rgba(255, 149, 0, 0.68), rgba(252, 131, 9, 0.81) ) !important;
  width: 11.1% !important;
  height: 30px !important;
}

.house-comparison-scale-col-8{
  background: linear-gradient(89.59deg, rgba(252, 131, 9, 0.81), rgba(232, 25, 60, 0.9)) !important;
  width: 11.1% !important;
  height: 30px !important;
}

.house-comparison-scale-col-9{
  background: linear-gradient(89.59deg, rgba(232, 25, 60, 0.9), rgba(232, 25, 60, 1)) !important;
  width: 11.1% !important;
  height: 30px !important;
  border-radius: 0px 2px 2px 0px;
}

.house-comparison-scale-col-value-indicator-line{
  height: 40px;
  width: 1px !important;
  border-right: 1px dashed #727293;
}

.partner-list-row-tag-badge-failed{
  background-color: rgb(255, 203, 203);
  font-size: 10px;
  font-weight: 400;
  padding: 2px 6px;
  color: rgb(232, 25, 60);//#727293;
  border-radius: 4px;
}

.form-control-transparent-rent-income{
  border: none !important;
  //height: 0px !important;
  padding-left: 20px !important;
  background: transparent !important;
  width: 100px !important;
}

.form-control-transparent-rent-income:focus{
  border: rgb(213, 220, 230) !important;
}

.rental-income-payment-column-not-paid-modal{
  color: #E8193C !important;
}

.rental-income-payment-column-not-paid-modal:focus{
  color: #E8193C !important;
}

.rental-income-payment-column-full-paid-modal{
  color: #059266 !important;
}

.rental-income-payment-column-full-paid-modal:focus{
  color: #059266 !important;
}

.rental-income-payment-column-partial-paid-modal{
  color: #D97706 !important;
}

.rental-income-payment-column-partial-paid-modal:focus{
  color: #D97706 !important;
}

.receipts-table{
  overflow-x: hidden !important;
  scrollbar-width: 12px !important;
  //max-height: 530px;
}

.receipts-table:hover{
  overflow-x: scroll !important;
  scrollbar-width: 12px !important;
}

// .receipts-table::-webkit-scrollbar {
//   display: block !important;
//   width: 12px !important;
// }

.receipts-table::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background: #ced4da;
}

.receipts-table::-webkit-scrollbar-thumb {
  background: #727293;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

.receipts-table::-webkit-scrollbar-corner {
  background: #000;
}

.dropdown-select:hover, .dropdown-select:focus, .dropdown-select:active{
  background: white !important;
}

.btn-text{
  white-space: nowrap !important;
}

.bank-details-card{
  font-family: Inter, sans-sarif;
  background-color: white !important;
  padding: 10px 20px;
  //width: auto;
  //height: 150px;
  min-height: 67px !important;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  z-index: 99;
  cursor: pointer;
  border: 1px solid transparent;
  max-width: 260px !important;
}

.bank-details-card-mobile{
  padding-right: 40px !important;
}

.bank-details-card-container{
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
}

.bank-add-card{
  background-color: #5555FF !important;
  width: 50px;
  padding: 15px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
// .bank-add-card:hover{
//   width: 60px;
// }

.bank-details-card-account-balance{
  font-size: 14px;
  white-space: nowrap;
  color: #059266;
}
.bank-details-card-iban{
  font-size: 12px !important;
  white-space: nowrap;
  font-weight: 300 !important;
}
.bank-details-card-account-name{
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
}
.bank-details-card-heading-2{
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 400;
}
.bank-details-card-heading-2-tooltip{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.bank-details-card-heading{
  font-size: 16px;
  white-space: nowrap;
  color: #5555FF;
}

.dropdown-item{
  font-weight: 400;
  line-height: 20px;
  color: rgb(14, 14, 29);
  padding: 10px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  span{
    font-size: 14px !important;
  }
}

.dropdown-menu{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border: 1px solid #ccc !important;
  max-height: 200px !important;
  overflow-y: scroll !important;
}


.tenant-transaction-selection-input{
  font-size: 14px !important;
  color: #13135C !important;
  font-family: Inter, sans-serif !important;
}

.bank-cards{
  border-radius: 4px;
  cursor: pointer;
}

.bank-cards:hover{
  background-color: #E3E3FC;
}

.selected-bank{
  background-color: #f2f2fc !important;
}

.mantine-Textarea-input{
  border-color: #DADAE5 !important;
  padding: 5px !important;
  font-size: 14px !important;
  font-family: Inter, sans-sarif !important;
}

.mantine-Textarea-input:focus{
  border-color: #DADAE5 !important;
}

.mantine-Textarea-input:focus-within{
  border-color: #DADAE5 !important;
}

.rental-income-rent-received-modal-form-label{
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  padding: 0 !important;
  margin-bottom: 1px;
  color: #727293;
}

.dropdown-menu-with-auto-height{
  max-height: fit-content !important;
}

.side-bar-dropdown-card-body{
  scrollbar-width: none !important;
}

.side-bar-dropdown-card-body::-webkit-scrollbar{
  display: none;
}

.billingstatement-control-sheet-table-container{
  overflow-x: hidden !important;
  scrollbar-width: 12px !important;
}

.billingstatement-control-sheet-table-container:hover{
  overflow-x: scroll !important;
  scrollbar-width: 12px !important;
}

.billingstatement-control-sheet-table-container::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background: #ced4da;
}

.billingstatement-control-sheet-table-container::-webkit-scrollbar-thumb {
  background: #727293;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

.billingstatement-control-sheet-table-container::-webkit-scrollbar-corner {
  background: #000;
}

.custom-icon-button{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.custom-icon-button:hover{
  background-color: transparent !important;

}

.chevron-button{
  padding: 1px;
  margin-bottom: 6px; 
  font-weight: 400;
  cursor: pointer;
  border-radius: 4px;
  background-color: #e6e6ff;
  opacity: 0.5 !important;
  border: 1px solid #ced4da;
}

.chevron-button:hover{
  opacity: 1 !important;
}

.mantine-Checkbox-input{
  width: 20px !important;
  height: 20px !important;
}

div:has(div.tax-context-popover-wrapper){
  position: absolute !important;
  right: 0px !important;
  top: 0px !important;
  bottom: 0px !important;
}
.drag-handler-table-cell{
  padding-right: 0px !important;
  padding-left: 16px !important;
  text-align: center !important;
}

.text-value-full-width{
  width: 100% !important;
}
.multiselect-table{
  .table-without-cell-borders{
    border: none !important;
    padding: 5px !important;
  }
}

.multiselect-table-rows:hover{
  background: #F2F2FC;
}
.multiselect-table-rows-selected{
  background: #F2F2FC;
}

.multiselect-table-container::-webkit-scrollbar{
  display: none !important;
}

.multiselect-table-container{
  scrollbar-width: none !important;
}

.tax-declaration-table-header{
  color: #727293 !important;
  padding: 0 9px 0 9px !important;
  font-weight: 500 !important;
  white-space: nowrap;
  margin: 0px !important;
  font-size: 14px !important;
  line-height: 20px !important;
  border: 1px solid #DADAE6;
  //text-transform: capitalize !important;
  height: 48px !important;
}

@media only screen and (min-width: 501px) {
  .tax-declaration-table-body{
    color: #0e0e1d !important;
    padding: 0 9px 0 9px !important;
    font-weight: 400;
    white-space: nowrap !important;
    margin: 0px !important;
    font-size: 14px !important;
    line-height: 20px !important;
    border: 1px solid #DADAE6 !important;
    //text-transform: capitalize !important;
    height: 48px !important;
    // min-width: 90px;
  }
}

@media only screen and (max-width: 500px) {
  .tax-declaration-table-body{
    color: #0e0e1d !important;
    padding: 0 9px 0 9px !important;
    font-weight: 400;
    //white-space: nowrap !important;
    margin: 0px !important;
    font-size: 14px !important;
    line-height: 20px !important;
    border: 1px solid #DADAE6 !important;
    //text-transform: capitalize !important;
    height: 48px !important;
    word-break: normal;
    // min-width: 90px;
  }
  .display-modal-for-mobile-view{
    .tax-declaration-table-body{
      color: #0e0e1d !important;
      padding: 0 9px 0 9px !important;
      font-weight: 400;
      white-space: nowrap !important;
      margin: 0px !important;
      font-size: 14px !important;
      line-height: 20px !important;
      border: 1px solid #DADAE6 !important;
      //text-transform: capitalize !important;
      height: 48px !important;
      word-break: normal;
      // min-width: 90px;
    }
  }
}

.tax-declaration-appendix-accordion-control:hover{
  //background-color: #DADAE6 !important;
  //border: 1px solid #5555FF !important;
}

.tax-declaration-appendix-accordion-control{
  border-radius: 4px;
  border: 1px solid white !important;
}

.tax-declaration-appendix-accordion-content{
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.tax-declaration-appendix-accordion-icon{
  display: none !important;
}

.tax-declaration-appendix-accordion-contentInner{
  //padding: 5px 0px !important;
}

.tax-declaration-sub-headers{
  color: #727293 !important;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 22px !important;
}

.tax-declaration-sub-headers-mobile{
  color: #727293 !important;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 18px !important;
  white-space: nowrap;
}

.MuiTableCell-stickyHeader{
  background-color: white !important;
}

.expand-transition{
  transition: width 2s, height 4s;
}

.accordion-table {
  width: 100%;
  border-collapse: collapse;
}

.accordion-table .main-row {
  cursor: pointer;
  background-color: white;
  transition: background-color 2s ease;
}

.accordion-table .main-row.expanded {
  //background-color: #f1f1f1;
}

.accordion-table .detail-row {
  background-color: white;
  transition: all 2s ease;
  height: 0;
  overflow: hidden;
}

.accordion-table .expanded + .detail-row {
  height: auto;
  transition: height 2s ease, opacity 2s ease;
}

.banks-table-header{
  color: #727293 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  padding-bottom: 5px !important;
}

.banks-table-row{
  border-top: 8px solid white;
  border-bottom: 8px solid white;
}

.banks-table-row:hover{
  background-color: #f2f2fc !important;
}

.banks-table-row-selected{
  background-color: #f2f2fc !important;
}

.banks-table-row-disabled{
  background-color: #f1f3f5 !important;
  cursor: not-allowed !important;
}

.meter-logs-detail-meter-reading-table-header-cell{
  white-space: nowrap !important;
  padding-bottom: 0px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  color: rgb(114, 114, 147) !important;
}

.meter-logs-detail-meter-reading-table-body-cell{
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  font-size: 14px !important;
  font-family: Inter, sans-serif !important;
  font-weight: 400 !important;
  border-top: 8px solid white !important;
  padding: 16px !important;
}

.meter-logs-detail-meter-reading-table-body-row{
  background: rgb(247, 247, 250) !important;
  height: 70px !important;
}

.bank-account-heading{
  font-size: 18px;
  font-family: Inter, sans-serif !important;
  font-weight: 500;
  padding-left: 3px;
}


.hide-modal-header-close-button{
  .close{
    display: none !important;
  }
}

.add-new-bank-connection-card{
  font-family: Inter, sans-sarif;
  background-color: #B8B8F4 !important;
  padding: 20px;
  width: auto;
  //margin-left: -20px;
  //height: 150px;
  height: inherit !important;
  min-height: 67px !important;
  border-radius: 10px;
  //box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  z-index: 99;
}

.bank-connections-list{
  overflow-x: scroll;
  //min-height: 90px; 
  padding: 3px;
}

.record-dropdown-menu{
  scrollbar-width: none !important;
  width: max-content;
}

.record-dropdown-menu::-webkit-scrollbar{
  display: none !important;
}

.bank-account-cards{
  border-radius: 4px;
  cursor: pointer;
}

.tenant-rental-income-filters-container{
  scrollbar-width: none !important;
}

.tenant-rental-income-filters-container::-webkit-scrollbar{
  display: none !important;
}

.tax-declaration-input-values{
  padding-right: 46px !important;
  pointer-events: none !important;
}

.tax-declaration-right-section-values{
  width: 46px !important;
}

.mantine-Tooltip-body{
  background-color: #E3E3FC !important;
  color: #13135c !important;
  font-weight: 400 !important;
}

.mantine-MultiSelect-values{
  -webkit-mask: linear-gradient(90deg,#0000,#000 2% 98%,#0000);
}

.bank-transaction-sticky-header{
  background-color: #F7F7FA !important;
}

.bank-transaction-table-cell-root{
  padding-bottom: 6px !important;
}

// body{
//   font-weight: 300;
// }

.hide-accordion-icon{
  display: none !important;
}

.bank-details-accordion-card{
  font-family: Inter, sans-sarif;
  background-color: white !important;
  padding: 5px 10px;
  //width: auto;
  //height: 150px;
  //min-height: 37px !important;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  z-index: 99;
  cursor: pointer;
  border: 1px solid transparent;
  //max-width: 260px !important;
}

.bank-account-accordion-label{
  padding: 2px 0px;
}

.bank-account-accordion-content{
  padding-left: 0 !important;
}

.selected-bank-connection{
  box-shadow: 0 1px 3px #B8B8F4;
  border: 1px solid #B8B8F4;
  background-color: #f2f2fc !important;
}

.bank-account-accordion-control:hover{
  background-color: transparent !important;
}

.transaction-row-tooltip-popper{
  //background-color: #f2f2fc !important;
  // border-radius: 4px;
  // padding: 2px !important;
  //width: 100%;
}

.banks-table-cell{
  font-weight: 400 !important;
}

.font-weight-bold{
  font-weight: bold !important;
}

.add-receipt-modal-amount-negative{
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: rgb(232, 25, 60);
  font-family: Inter, sans-serif;
  background-color: rgb(255, 203, 203);
  padding: 2px 5px;
  border-radius: 4px;
  cursor: pointer;
}

.add-receipt-modal-amount-positive{
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: rgb(5, 146, 102);
  font-family: Inter, sans-serif;
  background-color: rgb(232, 244, 234);
  padding: 2px 5px;
  border-radius: 4px;
  cursor: pointer;
}

.add-receipt-modal-amount-negative-disabled{
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: #727293;
  font-family: Inter, sans-serif;
  background-color: #F7F7FA;
  padding: 2px 5px;
  border-radius: 4px;
  cursor: pointer;
}

.add-receipt-modal-amount-positive-disabled{
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: #727293;
  font-family: Inter, sans-serif;
  background-color: #F7F7FA;
  padding: 2px 5px;
  border-radius: 4px;
  cursor: pointer;
}

.all-receipts-table {
  border-collapse: separate !important;
  border-spacing: 0px 8px !important;
  thead{
    tr{
      th{
        padding: 10px 10px 0px !important;
        color: #727293 !important;
      }
    }
  }
  tbody{
    tr{
      td{
        padding: 0px 10px;
        color: #111147 !important;
        font-size: 14px !important;
        font-weight: 300 !important;
        font-family: Inter, sans-serif !important;
      }
    }
  }
}

.dashboard-general-card{
  width: auto !important;
  min-width: 360px !important;
  height: 254px !important;
  background-color: white !important;
  box-shadow: 0 1px 3px rgba(0,0,0,.08);
  border-radius: 4px !important;
  padding: 10px 20px !important;
}
@media screen and (max-width: 500px){
  .dashboard-general-card{
    min-width: 100% !important;
  }
}
.dashboard-general-card-heading{
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  color: #0E0E1D;
}
.dashboard-general-card-all{
  color: #252563;
  font-size: 14px;
  justify-content: flex-end;
  font-weight: 500;
}

.tenant-portal-preview-Modal-modal{
  padding: 0px !important;
  z-index: 1100 !important;
  width: 880px !important;
}
.tenant-portal-small-preview-Modal-modal{
  padding: 0px !important;
  z-index: 1100 !important;
}
.tenant-portal-preview-Modal-header{
  padding: 12.1px 24px !important;
  background: #f7f7fa;
  border-bottom: 1px solid #dadae6 !important;
  margin-bottom: 0px !important;
  margin-right: 0px !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}
.tenant-portal-preview-Modal-body{
  padding: 20px 24px !important;
}
.tenant-portal-preview-Modal-title{
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0e0e1d !important;
}

// #root {
//   margin: 1rem 4rem;
// }

// #root .ql-container {
//   border-bottom-left-radius: 0.5em;
//   border-bottom-right-radius: 0.5em;
//   background: #fefcfc;
// }

// /* Snow Theme */
// #root .ql-snow.ql-toolbar {
//   display: block;
//   background: #eaecec;
//   border-top-left-radius: 0.5em;
//   border-top-right-radius: 0.5em;
// }

// #root .ql-editor {
//   min-height: 18em;
// }

.drag-container-notes > div{
  width: inherit !important;
}

.drag-container-notes > div > div{
  width: inherit !important;
}

.drag-container-notes > div > div{
  width: inherit !important;
}

.transaction-search-bar-width{
  width: 300px;
}

.transaction-search-bar-disabled>.search-input{
  background-color: #f1f3f5 !important;
}

.rich-text-editor-box-label{
  color: #727293;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 4px;
}

.ql-container{
  height: 270px !important;
  overflow: hidden !important;
}
.ql-editor{
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-size: 14px;
  ul{
    margin-top: 12px;
    margin-bottom: 12px;
  }
}

.ql-editing .ql-action::before{
  display: none !important;
}
.ql-action::before{
  display: none !important;
}
.ql-tooltip{
  left: 0 !important;
}
.ql-snow .ql-tooltip::before{
  content: "URL:";
}
.ql-snow .ql-tooltip a.ql-action::after{
  content: "Bearbeiten";
}
.ql-snow .ql-tooltip a.ql-remove::before{
  content: "Löschen";
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after{
  content: "Speichern";
}
.ql-snow .ql-tooltip[data-mode="link"]::before{
  content: "URL:";
}
.ql-editor .resize-handle {
  position: absolute;
  max-width: 100%;
  box-sizing: border-box;
  outline: none;
}
.transaction-assigment-modal-remember-switch-label, .transaction-assigment-modal-early-payments-switch-label{
  color: rgb(0, 0, 0);
  font-size: 14px;
  line-height: 1.55;
  font-weight: 300;
  font-family: Inter, sans-serif;
}

.multi-select-bank-transactions-receipt-assignment{
  .mantine-MultiSelect-values{
    //white-space: nowrap;
    //display:flex;
    max-height: 34px !important;
    overflow-x: scroll !important;
    overflow-y: hidden !important;
    flex-wrap: nowrap !important;
    scrollbar-width: none !important;
  }
  .mantine-MultiSelect-values::-webkit-scrollbar {
    display: none;
  }
  // .mantine-MultiSelect-defaultValueRemove{
  //   display: none !important;
  // }
  .mantine-MultiSelect-value{
    //padding-right: 12px !important;
    background-color: #E3E3FC !important;
    border-radius: 10px !important;
  }
  .mantine-MultiSelect-input{
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

.tile-preview-title{
  color: #13135c;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.tile-preview-body{
  overflow-y: hidden; 
  height: 180px; 
  font-size: 14px; 
  color: #111147; 
  font-weight: 400;
}

.mantine-Select-input::placeholder {
  //color: #727293 !important;
  font-size: 14px !important;
  font-weight: 300 !important;
}

.bank-transaction-search-input-mantine{
  padding-left: 34px !important;
}

.mantine-DatePicker-input{
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.custom-navbar {
  scrollbar-width: none !important;
}

.custom-navbar::-webkit-scrollbar{
  display: none;
}

.tenant-type-cards-row{
  overflow-x: scroll;
  scrollbar-width: none !important;
}

.tenant-type-cards-row::-webkit-scrollbar{
  display: none;
}

.tenant-type-selection-add-tenant{
  overflow-x: scroll;
  scrollbar-width: none !important; 
}

.tenant-type-selection-add-tenant::-webkit-scrollbar{
  display: none;
}

.calendar-input{
  .react-datepicker__input-container .form-control{
    border: none !important;
    height: 34px !important;
  }
}

.receipts-main-menu-dropdown-item{
  padding: 10px !important;
}

@media (max-width: 768px) {
  .messages-list-screen{
    .record-dropdown-menu {
      transform: translate3d(-85px, 32px, 0) !important; /* Adjust positioning on smaller screens */
    }
  }

  .rdw-editor-toolbar{
    overflow-x: scroll !important;
    white-space: nowrap !important;
    width: 100% !important;
    flex-wrap: nowrap !important;
  }
}

.dashboard-expand2-subtitle-text{
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.dashboard-expand2-details-text{
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}

.dashboard-expand2-details-text-2{
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.dashboard-initial-state-after-signup-card-heading{
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.fw-300{
  font-weight: 300 !important;
}

.mw-200{
  max-width: 200px !important;
}
.finapi-webform2-webcomponent-2-MuiButton-outlinedPrimary{
  color: #13135c !important;
  background-color: white !important;
  font-weight: 400 !important;
  border: 1px solid #727293 !important;
}

.web-form-id {
  finapi-webform2-webcomponent{
    width: 100%;
  }
}

.transaction-mapping-select-input, .transaction-mapping-select-input:hover, .transaction-mapping-select-input:focus{
  border: none !important;
}
.transaction-mapping-select-right-section{
  display: none !important;
}
.transaction-mapping-select-dropdown{
  z-index: 99999 !important;
}

.transaction-mapping-capsules-receipts{
  background-color: #e6e6ff !important;
  color: #000 !important;
  padding: 2px 5px !important;
  border-radius: 4px !important;
  border: 1px solid #ced4da !important;
  //pointer-events: none;
  font-size: 12px !important;
  font-family: Inter, sans-serif !important;
  font-weight: 300 !important;
}

.transaction-mapping-capsules-tenant{
  background-color: rgb(247, 227, 205) !important;
  color: #000 !important;
  padding: 2px 5px !important;
  border-radius: 4px !important;
  border: 1px solid #ced4da !important;
  //pointer-events: none;
  font-size: 12px !important;
  font-family: Inter, sans-serif !important;
  font-weight: 300 !important;
}

.transaction-mapping-capsules-empty{
  background-color: rgb(255, 203, 203, 0.8) !important;
  color: #000 !important;
  padding: 2px 5px !important;
  border-radius: 4px !important;
  border: 1px solid #ced4da !important;
  //pointer-events: none;
  font-size: 12px !important;
  font-family: Inter, sans-serif !important;
  font-weight: 300 !important; 
}

.transaction-mapping-receipts-text{
  //white-space: nowrap;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 300;
}

.ql-snow .ql-editor h1, .tile-preview-body h1 {
  font-size: 1.5em !important;
}

.ql-snow .ql-editor h2, .tile-preview-body h2 {
  font-size: 1.2em !important;
}

.ql-snow.ql-toolbar button.ql-active{
  color: #13135c;
  background-color: #E3E3FC;
}

.ql-snow.ql-toolbar button:hover{
  color: #5555FF;
}

.ql-snow.ql-toolbar button{
  border-radius: 4px !important;
}

.mantine-RichTextEditor-toolbarGroup{
  button{
    margin-right: 2px;
  }
}

.mantine-Menu-itemLabel{
  line-height: 18px !important;
}

#editor-resizer .toolbar{
  display: none;
}

.ql-container, .ql-toolbar{
  background-color: white !important;
}

.ql-formats{
  button{
    margin-right: 2px;
  }
}

.ql-editor{
  overflow-y: visible !important;
  p{
    img{
      display: inline !important;
    }
  }
}
.ql-container{
  overflow-y: scroll !important;
}

.close-preview-modal-button:hover{
  //color: #5555FF !important;
  opacity: 1 !important;
}
.close-preview-modal-button{
  color: white !important;
  opacity: 0.4 !important;
}

#web-form-id{
  .finapi-webform2-webcomponent-1-MuiButton-outlinedPrimary{
    color: white !important;
    font-weight: 500 !important;
    background-color: #5555FF !important;
  }
  
  .finapi-webform2-webcomponent-1-MuiButton-outlinedPrimary:hover{
    color: white !important;
    font-weight: 500 !important;
    background-color: #5555FF !important;
  }
  
  .finapi-webform2-webcomponent-1-MuiButton-containedPrimary{
    background-color: rgb(246, 246, 255) !important;
    color: #5555FF !important;
    font-weight: 500 !important;
  }
  
  .finapi-webform2-webcomponent-1-MuiButton-containedPrimary:hover{
    background-color: rgb(246, 246, 255) !important;
    color: #5555FF !important;
    font-weight: 500 !important;
  }
}

.MuiTooltip-tooltip{
  background-color: #E3E3FC !important;
  color: #13135c !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  font-family: Inter, sans-serif !important;
  white-space: nowrap;
  width: auto !important;
  max-width: 100% !important;
  padding: 5px 10px !important;
}

.MuiTooltip-popper{
  width: auto !important;
  max-width: auto !important;
}

.react-pdf__Document{
  overflow: hidden !important;
}

.disabled-color-button{
  cursor: not-allowed !important;
}

.upload-icon{
  -webkit-filter: invert(32%) sepia(75%) saturate(7390%) hue-rotate(233deg) brightness(101%) contrast(101%);
  filter: invert(32%) sepia(75%) saturate(7390%) hue-rotate(233deg) brightness(101%) contrast(101%);
}

.excel-tenants-file-container{
  border: 1px solid #13135c;
  width: 210px;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px;
  color: #13135c;
}

.excel-tenants-file-container:hover{
  border: 1px solid #5555FF;
  color: #5555FF;
}

.excel-tenants-file-name{
  font-size: 14px;
}

.subscription-badge-icon{
  padding: 0px 8px !important;
  text-align: center !important;
  height: 24px !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  span{
    font-size: 13px !important;
    font-weight: 500 !important;  
    text-align: center !important;
    letter-spacing: 0.4px !important;
  }
}
// .documents-heading-container{
//   margin-bottom: 0px !important;
// }