.btn-text{
    font-weight: 500;
    font-size: 14px;
}
.card-body {
	padding: $panelPadding 17px;
}
.gray-btn{
    & .btn-text{
      color: $colorDarkPurple;
    }

}
.purple-btn{
      
       background:  linear-gradient(180deg, #5555FF 0%, #4949F5 100%)!important;
    
}

.dashboardCard{
    .btn.btn-secondary{
        align-self: flex-start !important;
    }
}