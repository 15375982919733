/*----------   GLOBAL STYLES ----------*/

body {
  background-color: $globalBackgroundColor;
  font-family: $globalFontFamily;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

hr {
  border-color: $hrColor;
}

#primary-content:focus {
  outline: transparent !important;
  // outline: 0px solid red !important;
}

.property-single_dropdown {
  display: flex;
  justify-content: space-between;
  font-size: 14px !important;
  > img {
    height: 4px !important;
  }
}

.wait-for-api {
  pointer-events: none !important;
  opacity: 25%;
}

.hh_loader_bg {
  background: #000;
  opacity: 0.5;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999;
}

.hh_loader_spinner {
  bottom: 0;
  height: 70px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 70px;
  z-index: 9999;
  color: #4b4bf6 !important;
}
// .hello-housing-sidebar {
//   z-index: 999999;
// }

.red-error-msg {
  width: 100% !important;
  margin-top: 0.25rem !important;
  font-size: 80% !important;
  color: #dc3545 !important;
}
.form-control.is-invalid {
  background-image: none !important;
}
.readings-form .form-control:disabled,
.readings-form .form-control[readonly] {
  background: #FFFFFF;
  border-radius: 0;
}
.dd-header-title-bold {
  border: none !important;
  outline: none !important;
}
.dd-header-title-bold:focus {
  border: none !important;
  outline: none !important;
}
// .dd-header-title-bold:active {
//   border: none !important;
// }

.switch-container {
  // .react-switch-bg {
  //   background: #4949f5 !important;
  // }
  .react-switch-bg:focus-visible {
    outline: none !important;
  }
}

.disable-annual-btn {
  color: #bfbfd2 !important;
  // opacity: 85%;
  // background-color: chartreuse;
}
.indivisual-dropdown-right-header {
  .form-check {
    margin-right: 12px;
    padding-right: 0px;
    > label {
      margin-right: 0px;
      padding-right: 0px;
      color: #0e0e1d;
    }
    .form-check.disable-annual-btn {
      > label {
        color: #bfbfd2 !important;
      }
    }
  }
}
.dropdown-select {
  display: flex;
  justify-content: space-between !important;
}
.meter-date-new-format {
  .react-datepicker-wrapper {
    width: 120.891px !important;
    .react-datepicker__input-container {
      > form {
        .input-group {
          border: none !important;
          position: relative;
          .input-group-text {
            padding: 0px 1px 0px 10px;
          }
          .input-group-prepend {
            position: absolute;
            right: 5;
            z-index: 999;
            padding: 10px 10px 10px 0px;
          }
        }
      }
    }
  }
}

.cc_currancy_val,
.cc_currancy_val:focus,
.cc_currancy_val:hover,
.cc_currancy_val:active,
.cc_currancy_val:focus-visible,
.cc_currancy_val:focus-within,
.cc_currancy_val:disabled,
.cc_currancy_val[readonly] {
  background: transparent !important;
  border: none !important;
  outline: none !important;
  color: inherit !important;
  font-style: inherit !important;
  font-weight: inherit !important;
  line-height: inherit;
  box-shadow: none;
  border-radius: unset;
  opacity: 1;
}

.rent-modal-month-picker {
  .react-datepicker {
    height: auto !important;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: #4242ed !important;
  }
}
.react-switch-handle {
  box-shadow: none !important;
  // height: 12px !important;
  // width: 12px !important;
  // transform: translateX(14px) !important;
  // top: 2px !important;
}
// .radio-btns {
//   .form-check .form-check-inline {
//     margin-left: 5px !important;
//   }
// // }
// .form-check-input[type="radio"]:checked {
//   // background-color: red;
//   background: linear-gradient(180deg, $blueForBg 0%, $purpleTheme 100%) !important;
// }
input[type='radio'],
input[type='checkbox'] {
  height: 16px !important;
  width: 16px !important;
}

.hello-housing-radio-options.checked {
  //background-color: pink;
  .form-check-input[type='radio'] {
    background-color: #4242ed !important;
    border-radius: 8px !important;
    border: 1px solid #4242ed !important;
    cursor: pointer !important;
    height: 16px;
    width: 16px;
    box-shadow: 0px 1px 2px $box-shadow !important;
    box-sizing: border-box;
    -webkit-appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .form-check-input[type='radio']:after {
    background-color: $colorWhite !important;
    border-radius: 25px !important;
    content: '';
    display: block;
    height: 8px;
    position: relative;
    width: 8px;
    -webkit-appearance: none !important;
  }
  .form-check-input[type='radio']:checked:after {
    background-color: $colorWhite !important;
    -webkit-appearance: none !important;
  }
}
.hello-housing-radio-options {
  //background-color: red;
  .form-check-input[type='radio'] {
    background-color: $colorWhite !important;
    border-radius: 8px !important;
    border: 1px solid #dadae6 !important;
    cursor: pointer;
    height: 16px;
    width: 16px;
    box-shadow: 0px 1px 2px $box-shadow !important;
    box-sizing: border-box;
    -webkit-appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .form-check-input[type='radio']:after {
    background-color: $colorWhite !important;
    border-radius: 25px !important;
    content: '';
    display: block;
    height: 8px;
    position: relative;
    width: 8px;
    -webkit-appearance: none !important;
  }
  .form-check-input[type='radio']:checked:after {
    background-color: $colorWhite !important;
    -webkit-appearance: none !important;
  }
}

.hello-housing-radio-options.checked {
  // background-color: pink;
  input[type='radio'] {
    background-color: #4242ed !important;
    border-radius: 8px !important;
    border: 1px solid #4242ed !important;
    cursor: pointer !important;
    height: 16px;
    width: 16px;
    box-shadow: 0px 1px 2px $box-shadow !important;
    box-sizing: border-box;
    -webkit-appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  input[type='radio']:after {
    background-color: $colorWhite !important;
    border-radius: 25px !important;
    content: '';
    display: block;
    height: 8px;
    position: relative;
    width: 8px;
    -webkit-appearance: none !important;
  }
  input[type='radio']:checked:after {
    background-color: $colorWhite !important;
    -webkit-appearance: none !important;
  }
}
.hello-housing-radio-options {
  // background-color: red;
  input[type='radio'] {
    background-color: $colorWhite !important;
    border-radius: 8px !important;
    border: 1px solid #dadae6 !important;
    cursor: pointer;
    height: 16px;
    width: 16px;
    box-shadow: 0px 1px 2px $box-shadow !important;
    box-sizing: border-box;
    -webkit-appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  input[type='radio']:after {
    background-color: $colorWhite !important;
    border-radius: 25px !important;
    content: '';
    display: block;
    height: 8px;
    position: relative;
    width: 8px;
    -webkit-appearance: none !important;
  }
  input[type='radio']:checked:after {
    background-color: $colorWhite !important;
    -webkit-appearance: none !important;
  }
}

.interaction-area {
  //overflow: hidden !important;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
}
.interaction-area::-webkit-scrollbar {
  display: none;
}
.allocationKeyType {
  overflow: hidden !important;
}

.checkbox-err {
  width: 100% !important;
  // margin-top: 0.25rem !important;
  font-size: 80% !important;
  color: #dc3545 !important;
}
.custom-modal.web-modal-center {
  margin-left: auto;
  margin-right: auto;
}
.help-modal-center {
  margin-left: 45%;
}
.hide-appbar {
  display: none;
}
///////////// accordian css
.MuiPaper-elevation1 {
  box-shadow: none !important;
}
.MuiPaper-root.MuiAccordion-root {
  background: #f8f9fb !important;
  border: none !important;
  width: 100% !important;
  // height: 72px;
}
.MuiTypography-root.MuiTypography-body1 {
  width: 100% !important;
  .divider {
    display: none !important;
  }
}
.MuiAccordionSummary-content {
  margin: 0 !important;
}
.meter-reading-heading {
  .heading.card-title {
    display: flex;
    width: 100%;
  }
}
input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}
input::-webkit-caps-lock-indicator {
  display: none !important;
}

input::-webkit-credentials-auto-fill-button {
  display: none !important;
}

.input_password:-webkit-auto-fill-button {
  display: none !important;
}
.input_password::-webkit-credentials-auto-fill-button {
  visibility: hidden !important;
  position: absolute;
  right: 0;
}

.daterangepicker td.in-range {
  .start-date.in-range.avaialble {
    background: linear-gradient(180deg, #5555ff 0%, #4949f5 100%);
    color: #fff !important;
  }
  .end-date.in-range.avaialble {
    background: linear-gradient(180deg, #5555ff 0%, #4949f5 100%);
    color: #fff !important;
  }

  // .off.ends.active.start-date.available{
  //   background: #fff !important;
  //   background-color: #fff !important;
  // }
}
.daterangepicker td.in-range.available {
  background-color: #f2f2fc;
  background: #f2f2fc;
}

.sidebar-mobile {
  height: 90vh;
  #main-menu {
    width: 280px;
  }
  .side-nav-icon {
    margin-left: 22px !important;
  }

  .nav-item {
    > a {
      display: flex;
      flex-direction: row !important;
    }
  }
  .logout-profile-btn {
    margin-left: -23px !important;
  }
}

////////////
.empty-state-container {
  .icon-container {
    height: 40px;
    width: 40px;
    margin: auto;
    background: #ffffff;
    border-radius: 4px;
    .empty-state-icon {
      height: 16px;
      width: 16px;
      margin: 12px;
    }
  }
}
.heatingDropdown_errormsg {
  width: 100% !important;
  margin-top: 0.25rem !important;
  font-size: 80% !important;
  color: #dc3545 !important;
}
.switch-container {
  display: flex;
  // vertical-align: middle;
  align-items: self-end;
}

//safari icons
input[type='password']::-webkit-contacts-auto-fill-button,
input[type='password']::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}
input[type='email']::-webkit-contacts-auto-fill-button,
input[type='email']::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}

.marginLeft_dashboard {
  margin-left: 12px !important;
}
.name-dashboard-rentalunits {
  //
  // white-space: nowrap;
  // text-overflow: ellipsis;
  // overflow: hidden;
  // // width: 139 px;
  // margin-bottom: 0;
  // max-width: 130px !important;
}

.utility_billing_row_emptyState {
  .main-container {
    margin-top: 0px !important;
  }
}
.utility-bility-button {
  justify-content: flex-end !important;
  .purple-btn {
    max-width: 100px;
  }
}
.subs-label {
  width: 100%;
}
.calendar-input {
  display: flex;
  background: $colorWhite;
  border: 1px solid $colorGrey;
  box-sizing: border-box;
  height: 36px;
  //box-shadow: 0px 1px 2px $box-shadow;
  border-radius: 4px;
  padding: 0px 12.33px;
  img {
    margin-right: 8.33px;
    width: 16px;
  }
  .react-datepicker-wrapper {
    border: none;
    .react-datepicker__input-container {
      .form-control {
        border: none !important;
        padding: 0px !important;
        box-shadow: none !important;
        margin-top: 4px;
        height: 29px !important;
      }
    }
  }
}

.dd-wrapper .dd-list li.dd-list-item.selected,
.dd-wrapper .dd-list li.dd-list-item:hover {
  background-color: #f2f2fc;
}
.teilweiseverfügbar {
  color: #d97706;
}
