@media only screen and (max-width: 1439px) {
  // 3rd page start
  .utility-bills-section {
    .utility-bills-total-details {
      .interim-unit-dropbox {
        width: 20%;
        //margin-right: 20px;
      }
      .days {
        width: 8%;
        margin-right: 20px;
      }
      .total-allocation {
        width: 12%;
        margin-right: 20px;
      }
      .payments {
        width: 10%;
        margin-right: 20px;
      }
      .balance {
        width: 12%;
      }
      .print-pdf {
        width: 27%;
        .color-btn {
          flex: 0.5;
          margin: 30px 0px 0px 0px;
        }
      }
    }
  }
  // 3rd page end
}
@media only screen and (max-width: 1405px) {
  .utility_headers_containers {
    .table-responsive {
      .table {
        .table-data,
        .utility-billing-table {
          .tenant {
            width: 40%;
          }
          .download {
            width: 16.9%;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
  .view-utility-billing {
    .utility-billing-head {
      .heading-right-content {
        width: 31% !important;
      }
    }
    .cost-allocation-section {
      .utility-cost-allocation-subheading {
        margin: 0px;
      }
    }
  }

  .utility_headers_containers {
    .table-responsive {
      .table {
        .table-data,
        .utility-billing-table {
          .tenant {
            width: 30%;
          }
          .occupancy-period {
            width: 32%;
          }
          .download {
            width: 20%;
          }
          .rental-unit {
            width: 18%;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .mobile-top-btn-section {
    .gray-btn {
      &.btn.btn-secondary {
        &:not(:disabled) {
          &:not(.disabled) {
            &:active {
              background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
              /* Oceanic Purple/200 */

              border: 1px solid $colorGrey;
              box-sizing: border-box;
              /* Card */

              box-shadow: 0px 1px 2px $box-shadow;
              border-radius: 4px;
            }
          }
        }
      }
      &:hover {
        box-shadow: none;
      }
      padding: 0px;
      flex: 0.34;
      margin: 0px 8px 0px 0px !important;
      background: linear-gradient(180deg, $colorWhite 0%, $globalBackgroundColor 100%);
      /* Oceanic Purple/200 */

      border: 1px solid $colorGrey;
      box-sizing: border-box;
      /* Card */

      box-shadow: 0px 1px 2px $box-shadow;
      border-radius: 4px;
    }
    .purple-btn {
      &.btn.btn-secondary {
        &:not(:disabled) {
          &:not(.disabled) {
            &:active {
              outline: none;
              color: $colorWhite !important;
            }
          }
        }
      }
      &:hover {
        box-shadow: none;
      }
      flex: 0.64;
    }
    display: flex;
    width: 100%;
    margin: 3px 0px 0px;
  }
  .utility-billing-head {
    .heading-right-content {
      display: none;
    }
    .heading-text-wrapper {
      div {
        width: 100%;
      }

      flex-flow: column;
      width: 100%;
      align-items: baseline !important;
    }
    margin: 0px 0px 0px 0px !important;
  }

  .table-responsive {
    padding: 0px;
    .table {
      box-shadow: none;
      border: none;
      display: flex;
      flex-flow: column;
      background: transparent;
      .utility-billing-table {
        border: none;
        width: 100%;
        background: $globalBackgroundColor;
        display: flex;
        padding: 10px 24px;
        .utility-billing-table-th {
          padding: 0px;
          border: none;
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          color: $colorLightPurple;
        }
        .tenant {
          width: 42.2%;
        }
        .rental-unit {
          width: 18.1%;
        }
        .occupancy-period {
          width: 39.7%;
        }
      }
      .table-data {
        width: 100%;
        padding: 27px 29.83px;
        display: flex;
        margin-bottom: 8px;
        background: $colorWhite;
        box-shadow: 0px 1px 2px $box-shadow;
        border-radius: 4px;
        td {
          padding: 0px;
          border: none;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: $colorBlack;
        }
        .tenant {
          width: 42.2%;
        }
        .rental-unit {
          width: 18.1%;
        }
        .occupancy-period {
          width: 26.2%;
        }
        .download {
          width: 13.5%;
          flex-direction: row-reverse;
          display: flex;
        }
      }
    }
  }
  .Utility-Billing-top-nav {
    margin: 0px;
    .page-header {
      margin: 0px;
      .heading-container {
        margin: 0px;
      }
    }
  }
  .utility_headers_containers {
    .utility_headers_box {
      flex-flow: column;
      padding: 28px 0px 3px;
      height: auto;
      .utility_headers_date {
        margin: -12px 0px 9px 0px;
        display: flex;
        width: 46%;
      }
      .utility_headers_current_period {
        width: 157px;
        margin: 0px 0px 0px 0px;
      }
      .utility_headers_previous_period {
        margin: 0px;
        width: 150px;
      }
      .edit_portion {
        margin: 0px 0px 0px 0px !important;
      }
      .utility_edit_text {
        margin: 5px 0px 0px 0px !important;
      }
    }
    .utility_headers_start_billing_button {
      display: flex;
      right: 15px;
      margin-top: -16px;
      .record-options {
        .record-dropdown {
          background: none !important;
          border: none !important;
          box-shadow: none !important;
        }
        .record-dropdown:active {
          background-color: none !important;
          border: none !important;
          box-shadow: none !important;
          color: none !important;
        }
        .record-dropdown:focus {
          background-color: none !important;
          border: none !important;
          box-shadow: none !important;
          color: none !important;
        }
      }
    }
    .table-responsive {
      .table {
        .utility-billing-table {
          display: none;
        }
        .table-data {
          height: 56px;
          padding: 21.83px 19px;
          display: flex;
          align-items: center;
          margin: 28px 0px 8px 0px;
          .rental-unit {
            display: none;
          }
          .occupancy-period {
            display: none;
          }
          .tenant {
            width: 90%;
          }
          .download {
            .download-btn-web {
              display: none;
            }
            .download-btn-mobile {
              display: block;
            }
            width: 10%;
          }
        }
      }
    }
  }

  // Utility 3rd page start
  .utility-bills-section {
    .utility-bills-total-details {
      .balance {
        display: none;
      }
      .payments {
        display: none;
      }
      .total-allocation {
        display: none;
      }
      .days {
        display: none;
      }
      .interim-unit-dropbox {
        width: 62%;
        margin-right: 8px;
      }
      .print-pdf {
        .color-btn {
          margin: 25px 0px 0px 0px;
          flex: 1;
        }
        width: 36%;
      }
      margin: 16px 0px !important;
    }
    .table {
      thead {
        .table-heading.ub-heading {
          .table-heading {
            td {
              display: none;
            }
            .ub-label-cost {
              border: none;
              padding: 0px 16px;
              width: 50%;
              display: flex;
            }
            .ub-label-tenant-cost {
              display: flex;
              border: none;
              width: 50%;
              padding: 0px 16px;
              justify-content: flex-end;
            }
          }
        }
      }
      .ub-body {
        .ub-row {
          margin: 0px 0px 8px;
          box-shadow: 0px 1px 2px $box-shadow;
          border-radius: 4px;
          background: $colorWhite;
          height: 56px;
          display: flex;
          align-items: center;
          td {
            border: none;
            display: none;
          }
          .ub-label-cost {
            display: block;
            width: 50%;
            font-weight: 500;
          }
          .ub-label-tenant-cost {
            display: block;
            width: 50%;
            text-align: right;
          }
        }
      }
      background: transparent !important;
      position: relative;
      width: 100%;
      margin: 0px;
      display: flex;
    }
    .mobile-view-tenant-bill-info {
      display: block;
    }
    .days,
    .total-allocation,
    .payments,
    .balance {
      width: 33.33%;
      float: left;
      lable {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: $colorPurple1;
      }
      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $colorBlack;
      }
    }
    .balance {
      width: 100%;
      float: left;
    }
  }
  // Utility 3rd page end

  // Utility 4rth page start
  .overview-section {
    .table {
      position: relative;
      width: 100%;
      margin: 0px;
      .ub-body {
        .table-heading {
          .cost-type {
            display: block;
            width: 50%;
          }
          .summe-mieter {
            display: block;
            width: 50%;
          }
          background: none;
          box-shadow: none;
          display: flex;
          align-items: flex-end;
        }
        .ub-row {
          .cost-type {
            display: flex;
            justify-content: flex-start;
            padding: 0px 16px;
            font-weight: 500;
          }
          .summe-mieter {
            display: flex;
            width: 50%;
            justify-content: flex-end;
            padding: 0px 16px;
          }
        }
      }
    }
  }
  // Utility 4rth page end

  // modal for 3rd & 4rth page
  // .utility-bills-modal {
  //     margin: 0px;
  //     width: 100%;
  //     height: 100%;
  //     .modal-content {
  //         height: 100%;
  //         border-radius: 0px;
  //         .modal-header {
  //             padding: 16px !important;
  //         }
  //         .modal-body {
  //             padding: 16px;
  //             .utility-table {
  //                 width: 100%;
  //                 display: flex;
  //                 justify-content: space-between;
  //                 margin-bottom: 24px;
  //                 .gray-text {
  //                     font-weight: 500;
  //                     font-size: 14px;
  //                     line-height: 24px;
  //                     color: $colorDarkPurple;
  //                 }
  //                 .black-text {
  //                     font-weight: 500;
  //                     font-size: 14px;
  //                     line-height: 24px;
  //                     color: $colorBlack;
  //                 }
  //                 .black-text.bold {
  //                     font-weight: 600 !important;
  //                 }
  //                 &:nth-child(7) {
  //                     .black-text {
  //                         font-weight: 600;
  //                     }
  //                 }
  //             }
  //             .billTable-comment {
  //                 width: 100%;
  //                 .form-control {
  //                     background: $colorWhite;
  //                     border: 1px solid $colorGrey;
  //                     box-sizing: border-box;
  //                     box-shadow: 0px 1px 2px $box-shadow;
  //                     border-radius: 4px;
  //                     height: 36px;
  //                     font-weight: normal;
  //                     font-size: 14px;
  //                     line-height: 20px;
  //                     color: $colorBlack;
  //                 }
  //                 label {
  //                     padding: 0px;
  //                     font-weight: normal;
  //                     font-size: 12px;
  //                     line-height: 20px;
  //                     margin: 0px 0px 4px 0px;
  //                     color: $colorLightPurple;
  //                 }
  //             }
  //         }
  //     }
  // }
  // modal for 3rd & 4rth page

  .utility-bills-modal {
    margin: 0px;
    // width: 100 !important;
    height: 100%;
    .modal-content {
      height: 100%;
      border-radius: 0px;
      .modal-header {
        padding: 16px !important;
      }
      .costBased_value {
        display: flex;
        justify-content: flex-end;
      }
      .utility-step-two-three {
        padding: 12px 0px;
        font-size: 14px;
      }
      .bold-user {
        font-weight: bold;
      }
    }
  }
}

.mobile-cost-table-rows {
  background-color: #fff;
  > tr {
    height: 56px !important;
  }
  .mobile-cost-type {
    padding-left: 16px !important;
  }
  .mobile-utility-edit-value {
    display: flex;
    justify-content: flex-end;
    padding-right: 16px !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
}

.mobile-utlity-cost-modal {
  .mobile-heating-costType {
    margin-top: 16px;
    margin-bottom: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #727293;
  }
  .mobile-heating-costType-value {
    outline: none;
    line-height: 1.5em;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    background: #fff;
    border: 1px solid #d5dce6;
    box-shadow: inset 0 2px 0 0 #f2f5f8;
    border-radius: 4px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .mobile-heating-amount {
    margin-top: 16px;
    margin-bottom: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #727293;
  }
  .mobile-heating-meterData {
    font-family: Inter;
    margin-top: 16px;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #727293;
  }
  .mobile-heating-receipt {
    margin-top: 16px;
    margin-bottom: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #727293;
  }

  .Available {
    color: $colorSuccess;
  }
  .Notavailable {
    color: $colorRed;
  }
  .nichtverfügbar {
    color: $colorRed;
  }
  .Someavailable {
    color: $colorWarning;
  }
  .Notrequired {
    color: $sideNavTextColor;
  }
  span {
    margin-left: 2%;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-top: 3px;
  }
}
