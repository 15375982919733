.drag-handler-table-cell{
  padding-right: 0px !important;
  padding-left: 16px !important;
  text-align: center !important;
}

.last-period-badge {
  margin-top: 11px;
  margin-left: 10px;
  padding: 4px 8px;
  height: 28px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #5555ff;
  background-color: #f2f2fc;
  border-radius: 4px;
}

.form-control:focus {
  color: $colorBlack !important;//#495057;
  background-color: #fff;
  border-color: rgb(213, 220, 230) !important;
  outline: 0;
  box-shadow: none !important;
}

@media only screen and (max-width:1340px) {
  .view-utility-billing {
    ._3vLmCG3bB3CM2hhAiQX1tN {
      padding: 0px 6px !important;
    }

    .form-control-utility {
      outline: none;
      line-height: 1.5em;
      padding-left: 0.8rem;
      padding-right: 0.8rem;
      background: #fff;
      border: 1px solid #d5dce6;
      //box-shadow: inset 0 2px 0 0 #f2f5f8;
      border-radius: 4px;
      padding-bottom: 6px;
      padding-left: 12.8px;
      padding-right: 12.8px;
      padding-top: 6px;
      //width: 90% !important;
      height: 36px !important;
      max-width: 150px !important;
    }
    .heating-ub-cost-type {
      padding-left: 24px !important;
      min-width: 110px !important;
    }
    ._2iA8p44d0WZ-WqRBGcAuEV {
      height: 38px !important;
    }
    // width:100vw;
    margin-top: -8px;
    .utilitiy--divider {
    }
    .heading-right-content {
      width: 24.5%;
    }
    .view-utility-divider {
      margin-bottom: 26px !important;
    }
    .profile-heading {
      margin-bottom: -3px;
      .heading-text-wrapper {
        .heading-icon {
          margin-top: 2%;
          cursor: pointer;
        }
      }
      .utility-subheading {
        font-size: 14px;
        font-weight: 500;
        color: $colorPurple1;
        margin-left: 3%;
      }
      .current-period-badge {
        background-color: white;
        color: $colorLightPurple;
        font-size: 14px;
        font-weight: 500;
        margin-left: 2%;
      }
    }
    .utility-bility-button {
      .purple-btn {
        padding: 7px 11px !important;
        // padding-left: 2% !important;
        // padding-right: 2% !important; // p{
        //     font-size: 12px!important;
        // }
      }
      .view-utility-go-back {
        padding-left: 3px;
        padding-right: 3px;
      }
    }
    .cost-allocation-section {
      .utility-cost-allocation-subheading {
        font-size: 14px;
        line-height: 20px;
        color: $colorPurple1;
      }
    }
    h5.utility-cost-allocation {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }
    .utility-cost-allocation {
      border-spacing: 0px 8px !important;
      border-collapse: separate !important;
      .utility-cost-head {
        td {
          border: none !important;
          box-shadow: none !important;
          padding-right: 0px;
          padding-left: 0px;
          padding-bottom: 0px;
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
          color: $colorLightPurple;
          .form-control {
            width: 100% !important;
          }
        }
        .cp-label-cost {
          padding-left: 2.3% !important;
          width: 19%;
        }
        .cp-label-amount {
          width: 13%;
          padding-right: 1.8%;
        }
        .cp-label-key {
          width: 19.5%;
          display: block !important;
          margin-left: 5px;
        }
        .cp-label-meter {
          width: 11.5%;
        }
      }
      tbody {
        tr.utility-cost-row {
          background-color: white !important;
          .form-control {
            width: 90% !important;
            height: 36px;
          }
          td {
            font-size: 14px;
            color: $colorBlack;
            padding-top: 16px;
            padding-bottom: 16px;
            padding-right: 0px;
            padding-left: 0px;
            .utitlity-add-cost-button {
              width: 90%;
              margin-bottom: 12px;
              color: #252563;
            }
          }
          td.ub-cost-type {
            padding-left: 1% !important;
            width: 19%;
            background-color: transparent !important;
            .form-control {
              width: 90% !important;
            }
          }
          td.ub-amount {
            padding-right: 1.6%;
            width: 11%;
            .form-control {
              width: 100% !important;
            }
            input {
              width: 100px;
              font-size: 14px;
              color: $colorBlack;
            }
          }
          td.ub-allocation-key {
            background-color: transparent !important;
            width: 15.5%;
            .search-wrapper {
              height: 36px !important;
              background: #ffffff;
              /* Oceanic Purple/200 */
              border: 1px solid #d5dce6;
              box-sizing: border-box;
              /* Card */
              box-shadow: none !important;//0px 1px 2px rgba(14, 14, 29, 0.06);
              border-radius: 4px;
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              // white-space: nowrap !important;
              // overflow: hidden !important;
              // text-overflow: ellipsis !important;
              color: $colorDeepPurple; // padding: 3px 11px !important;
              &:focus-within {
                border: 1px solid $purpleTheme !important;
                outline: none !important;
              }
            }
          }
          td.ub-meter-data {
            width: 13.4%;
            padding-right: 1.3%;
            text-transform: lowercase;
            .Available {
              color: $colorSuccess;
            }
            .Notavailable {
              color: $colorRed;
            }
            .nichtverfügbar {
              color: $colorRed;
            }
            .Someavailable {
              color: $colorWarning;
            }
            .Notrequired {
              color: $sideNavTextColor;
            }
            .teilweiseverfügbar {
              color: #d97706;
            }
            span {
              margin-left: 6%;
              font-size: 12px;
              font-weight: 500;
              line-height: 20px;
            }
          }
          td.ub-receipt {
            width: 130px !important;
            .multiselect-container._3vt7_Mh4hRCFbp__dFqBCI {
              width: 315px !important;
              // height:30px!important;
              .search-wrapper {
                width: 170px !important;
                height: 36px !important;
                //    height: 20px;
                overflow: hidden;
                background: #ffffff;
                border: 1px solid #d5dce6;
                box-sizing: border-box;
                /* Card */
                box-shadow: none !important; //0px 1px 2px rgba(14, 14, 29, 0.06);
                border-radius: 4px;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                // white-space: nowrap !important;
                // overflow: hidden !important;
                // text-overflow: ellipsis !important;
                color: $colorDeepPurple; // padding: 3px 11px !important;
                &:focus-within {
                  border: 1px solid $purpleTheme !important;
                  outline: none !important;
                }
                input {
                  width: 20px;
                  overflow: hidden !important;
                }
              }
              .optionListContainer {
                width: 300px !important;
                box-shadow: 0px 8px 16px rgba(14, 14, 29, 0.1), 0px 1px 2px rgba(14, 14, 29, 0.06);
                border-radius: 4px;
                table {
                  width: 100%;
                  thead {
                    th:first-child {
                      padding-left: 12px;
                    }
                    th {
                      color: $colorLightPurple;
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 20px;
                      letter-spacing: 0em;
                      text-align: left;
                      text-transform: capitalize;
                      background: white;
                      border: none;
                    }
                  }
                  tbody {
                    tr {
                      td {
                        font-size: 14px !important;
                        font-weight: 400;
                        line-height: 20px;
                      }

                      td:nth-child(3) {
                        .checkbox[type='checkbox']:checked {
                          & & {
                            // background: $hover-color;
                          }
                        }
                        .checkbox {
                          -webkit-appearance: none;
                          display: flex;
                          float: right;
                          margin: 0px;
                          font-size: 20px !important;
                        }
                        .checkbox[type='checkbox']:after {
                          position: relative !important;
                          display: block;
                          width: 16px;
                          height: 12px;
                          content: '' !important;
                          background-image: url('../../../../../assets/images/module/general/Check.svg') !important;
                          background-repeat: no-repeat;
                          background-position: center;
                        }
                        .checkbox[type='checkbox']:checked:after {
                          filter: invert(32%) sepia(75%) saturate(7390%) hue-rotate(233deg) brightness(101%)
                            contrast(101%);
                        }
                      }
                    }
                    tr.bgSelected {
                      background-color: rgb(242, 242, 252) !important;
                    }
                    td:first-child {
                      // text-align:left;
                      padding-left: 12px;
                      color: $colorBlack;
                    }
                    td:nth-child(2) {
                      color: $colorDeepPurple;
                    }
                    tr:hover {
                      // background: $hover-color !important;
                    }
                  }
                }
                .optionContainer {
                  li {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    color: $colorBlack;
                    &:hover {
                      // background: $hover-color !important;
                    }
                  }
                }
              }
              .chip {
                margin: 0px 4px 0px 0px;
                padding: 3px 8px;
                // background: $hover-color !important;
                border-radius: 4px !important;
                // width: 150px;
                // overflow: hidden;
                // text-overflow: ellipsis;
                // white-space: nowrap;
              }
              .icon_cancel {
                color: $colorLightPurple;
                font-size: 12px !important;
              }
            }
            .utitlity-add-cost-button {
              width: 95%;
              margin-bottom: 12px;
              color: #252563;
            }
          }
          .empty {
            background-color: #f7f7fa;
            // padding-left: 1%;
            padding-left: 10px;
            .img {
              height: 20px;
              width: 16px;
            }
          }
        }
      }
    }
    // .utility-cost-head {
    // }
    // .utility-cost-row {
    // }
  }
}
@media only screen and (max-width:1440px) {
  .view-utility-billing {
    .heating-ub-receipt.multiselect-page3 {
      max-width: 300px !important;
      width: 300px !important;
      padding-right: 10px !important;
    }
    // width:100vw;
    margin-top: -8px;
    .form-control {
      width: 100% !important;
    }
    ._3vLmCG3bB3CM2hhAiQX1tN {
      padding: 0px 6px !important;
    }
    .heating-ub-cost-type {
      padding-left: 24px !important;
      min-width: 110px !important;
    }
    .form-control-utility {
      outline: none;
      line-height: 1.5em;
      padding-left: 0.8rem;
      padding-right: 0.8rem;
      background: #fff;
      border: 1px solid #d5dce6;
      //box-shadow: inset 0 2px 0 0 #f2f5f8;
      border-radius: 4px;
      padding-bottom: 6px;
      padding-left: 12.8px;
      padding-right: 12.8px;
      padding-top: 6px;
      width: 90% !important;
      height: 36px !important;
    }
    ._2iA8p44d0WZ-WqRBGcAuEV {
      height: 38px !important;
    }
    // .ub-cost-type,
    // .ub-allocation-key,
    // .ub-receipt {
    //   & .multiselect-container {
    //     .search-wrapper {
    //       height: 38px !important;
    //       display: flex;
    //       overflow-x: auto;
    //       white-space: nowrap !important;
    //       overflow: hidden !important;
    //       text-overflow: ellipsis !important;
    //       input {
    //         // display: none !important;
    //       }
    //     }
    //   }
    // }

    .utilitiy--divider {
    }
    .heading-right-content {
      width: 24.5%;
    }
    .view-utility-divider {
      margin-bottom: 26px !important;
    }

    .profile-heading {
      margin-bottom: -3px;
      .heading-text-wrapper {
        .heading-icon {
          margin-top: 2%;
          cursor: pointer;
        }
      }
      .utility-subheading {
        font-size: 14px;
        font-weight: 500;
        color: $colorPurple1;
        margin-left: 3%;
      }
      .current-period-badge {
        background-color: white;
        color: $colorLightPurple;
        font-size: 14px;
        font-weight: 500;
        margin-left: 2%;
      }
    }
    .utility-bility-button {
      .purple-btn {
        padding: 7px 11px !important;
        // padding-left: 2% !important;
        // padding-right: 2% !important; // p{
        //     font-size: 12px!important;
        // }
        .btn-text {
          font-size: 14px;
        }
      }
      .view-utility-go-back {
        padding-left: 3px;
        padding-right: 3px;
      }
    }
    .cost-allocation-section {
      .utility-cost-allocation-subheading {
        font-size: 14px;
        line-height: 20px;
        color: $colorPurple1;
      }
    }
    h5.utility-cost-allocation {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }
    .utility-cost-allocation {
      border-spacing: 0px 8px !important;
      border-collapse: separate !important;
      .utility-cost-head {
        td {
          border: none !important;
          box-shadow: none !important;
          padding-right: 0px;
          padding-left: 0px;
          padding-bottom: 0px;
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
          color: $colorLightPurple;
        }
        .cp-label-cost {
          padding-left: 2.3% !important;
          width: 21%;
          .multiselect-container {
            .search-wrapper {
              width: 90% !important;
              height: 36px !important;
            }
          }
        }
        .cp-label-amount {
          width: 13%;
          padding-right: 1.8%;
        }
        .cp-label-key {
          width: 19.5%;
          display: block !important;
          margin-left: 5px;
        }
        .cp-label-meter {
          width: 11.5%;
        }
      }
      tbody {
        tr.utility-cost-row {
          background-color: white !important;
          .form-control {
            height: 36px;
          }
          td {
            font-size: 14px;
            color: $colorBlack;
            padding-top: 16px;
            padding-bottom: 16px;
            .multiselect-container {
              .search-wrapper {
                background: #ffffff;
                height: 36px !important;
                /* Oceanic Purple/200 */
                border: 1px solid #d5dce6;
                box-sizing: border-box;
                /* Card */
                box-shadow: none !important; //0px 1px 2px rgba(14, 14, 29, 0.06);
                border-radius: 4px;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                // white-space: nowrap !important;
                // overflow: hidden !important;
                // text-overflow: ellipsis !important;
                color: $colorDeepPurple; // padding: 3px 11px !important;
                &:focus-within {
                  border: 1px solid $purpleTheme !important;
                  outline: none !important;
                }
              }
              .optionListContainer {
                .optionContainer {
                  li {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    color: $colorBlack;
                    &:hover {
                      // background: $hover-color;
                    }
                  }
                }
              }
              .chip {
                margin: 0px 4px 0px 0px;
                padding: 3px 8px;
                // background: $hover-color !important;
                border-radius: 4px !important;
                // width: 90%;
                // overflow: hidden;
                // text-overflow: ellipsis;
                // white-space: nowrap;
              }
              .icon_cancel {
                color: $colorLightPurple;
                font-size: 12px !important;
              }
            }
            padding-right: 0px;
            padding-left: 0px;
            .utitlity-add-cost-button {
              width: 90%;
              margin-bottom: 12px;
              color: #252563;
            }
          }
          td.ub-cost-type {
            padding-left: 2.3% !important;
            width: 21%;
            .multiselect-container {
              .search-wrapper {
                width: 90% !important;
              }
            }
          }
          td.ub-amount {
            padding-right: 2.6%;
            width: 13%;
            input {
              width: 128px;
              font-size: 14px;
              color: $colorBlack;
            }
          }
          td.ub-allocation-key {
            background-color: transparent !important;
            width: 19.5%;
            .search-wrapper {
              width: 90% !important;
              background: #ffffff;
              /* Oceanic Purple/200 */
              border: 1px solid #d5dce6;
              box-sizing: border-box;
              /* Card */
              box-shadow: none !important;// 0px 1px 2px rgba(14, 14, 29, 0.06);
              border-radius: 4px;
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              // white-space: nowrap !important;
              // overflow: hidden !important;
              // text-overflow: ellipsis !important;
              color: $colorDeepPurple; // padding: 3px 11px !important;
              &:focus-within {
                border: 1px solid $purpleTheme !important;
                outline: none !important;
              }
            }
          }
          td.ub-meter-data {
            width: 12.4%;
            padding-right: 0.3%;
            .mannual-btn {
              margin-left: 0px;
            }
            .Available {
              color: $colorSuccess;
            }
            .Notavailable {
              color: $colorRed;
            }
            .nichtverfügbar {
              color: $colorRed;
            }
            .Someavailable {
              color: $colorWarning;
            }
            .Notrequired {
              color: $sideNavTextColor;
            }
            .teilweiseverfügbar {
              color: #d97706;
            }
            span {
              margin-left: 6%;
              font-size: 12px;
              font-weight: 500;
              line-height: 20px;
            }
          }
          td.ub-receipt {
            // width: 29%;
            // width: 130px !important;
            // width: 30% !important;
            max-width: 300px !important;
            padding: 0px 10px 0px 0px;
            .multiselect-container._3vt7_Mh4hRCFbp__dFqBCI {
              width: 220px !important;
              // height:30px!important;
              .search-wrapper {
                width: 90% !important;
                // width: 170px !important;
                width: 287px !important;
                //    height: 20px;
                overflow: hidden;
                background: #ffffff;
                /* Oceanic Purple/200 */
                border: 1px solid #d5dce6;
                box-sizing: border-box;
                /* Card */
                box-shadow: none !important; //0px 1px 2px rgba(14, 14, 29, 0.06);
                border-radius: 4px;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                // white-space: nowrap !important;
                // overflow: hidden !important;
                // text-overflow: ellipsis !important;
                color: $colorDeepPurple; // padding: 3px 11px !important;
                &:focus-within {
                  border: 1px solid $purpleTheme !important;
                  outline: none !important;
                }
                input {
                  width: 20px;
                  overflow: hidden !important;
                }
              }
              .optionListContainer {
                width: 300px !important;
                box-shadow: 0px 8px 16px rgba(14, 14, 29, 0.1), 0px 1px 2px rgba(14, 14, 29, 0.06);
                border-radius: 4px;
                table {
                  width: 100%;
                  thead {
                    th:first-child {
                      padding-left: 12px;
                    }
                    th {
                      color: $colorLightPurple;
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 20px;
                      letter-spacing: 0em;
                      text-align: left;
                      text-transform: capitalize;
                      background: white;
                      border: none;
                    }
                  }
                  tbody {
                    tr {
                      td {
                        font-size: 14px !important;
                        font-weight: 400;
                        line-height: 20px;
                      }

                      td:nth-child(3) {
                        .checkbox[type='checkbox']:checked {
                          & & {
                            // background: $hover-color;
                          }
                        }
                        .checkbox {
                          -webkit-appearance: none;
                          display: flex;
                          float: right;
                          margin: 0px;
                          font-size: 20px !important;
                        }
                        .checkbox[type='checkbox']:after {
                          position: relative !important;
                          display: block;
                          width: 16px;
                          height: 12px;
                          content: '' !important;
                          background-image: url('../../../../../assets/images/module/general/Check.svg') !important;
                          background-repeat: no-repeat;
                          background-position: center;
                        }
                        .checkbox[type='checkbox']:checked:after {
                          filter: invert(32%) sepia(75%) saturate(7390%) hue-rotate(233deg) brightness(101%)
                            contrast(101%);
                        }
                      }
                    }
                    tr.bgSelected {
                      background-color: rgb(242, 242, 252) !important;
                    }
                    td:first-child {
                      // text-align:left;
                      padding-left: 12px;
                      color: $colorBlack;
                    }
                    td:nth-child(2) {
                      color: $colorDeepPurple;
                    }
                    tr:hover {
                      // background: $hover-color !important;
                    }
                  }
                }
                .optionContainer {
                  li {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    color: $colorBlack;
                    &:hover {
                      // background: $hover-color !important;
                    }
                  }
                }
              }
              .chip {
                margin: 0px 4px 0px 0px;
                padding: 3px 8px;
                // background: $hover-color !important;
                border-radius: 4px !important;
                // width: 150px;
                // overflow: hidden;
                // text-overflow: ellipsis;
                // white-space: nowrap;
              }
              .icon_cancel {
                color: $colorLightPurple;
                font-size: 12px !important;
              }
            }

            .utitlity-add-cost-button {
              width: 95%;
              margin-bottom: 12px;
              color: #252563;
            }
          }
          .empty {
            background-color: #f7f7fa;
            // padding-left: 1.8%;
            padding-left: 10px;
            .img {
              height: 20px;
              width: 16px;
            }
          }
        }
      }
    }
    .utility-cost-head {
    }
    .utility-cost-row {
    }
  }
}

@media only screen and (max-width:2560px) {
  .view-utility-billing {
    .heating-ub-receipt.multiselect-page3 {
      max-width: 300px !important;
      width: 300px !important;
      padding-right: 10px !important;
      // white-space: nowrap !important;
      // overflow: hidden !important;
      // text-overflow: ellipsis !important;
    }
    // width:100vw;
    margin-top: -8px;
    .form-control {
      width: 100% !important;
    }
    ._3vLmCG3bB3CM2hhAiQX1tN {
      padding: 0px 6px !important;
    }
    .heating-ub-cost-type {
      padding-left: 24px !important;
      min-width: 110px !important;
    }
    .form-control-utility {
      outline: none;
      line-height: 1.5em;
      padding-left: 0.8rem;
      padding-right: 0.8rem;
      background: #fff;
      border: 1px solid #d5dce6;
      //box-shadow: inset 0 2px 0 0 #f2f5f8;
      border-radius: 4px;
      padding-bottom: 6px;
      padding-left: 12.8px;
      padding-right: 12.8px;
      padding-top: 6px;
      //width: 90% !important;
      height: 36px !important;
    }
    ._2iA8p44d0WZ-WqRBGcAuEV {
      height: 38px !important;
    }
    .ub-cost-type,
    .ub-allocation-key,
    .ub-receipt {
      & .multiselect-container {
        .search-wrapper {
          height: 38px !important;
          display: flex;
          overflow-x: auto;
          // white-space: nowrap !important;
          // overflow: hidden !important;
          // text-overflow: ellipsis !important;
          input {
            // display: none !important;
          }
        }
      }
    }

    .utilitiy--divider {
    }
    .heading-right-content {
      width: 24.5%;
    }
    .view-utility-divider {
      margin-bottom: 26px !important;
    }

    .profile-heading {
      margin-bottom: -3px;
      .heading-text-wrapper {
        .heading-icon {
          margin-top: 2%;
          cursor: pointer;
        }
      }
      .utility-subheading {
        font-size: 14px;
        font-weight: 500;
        color: $colorPurple1;
        margin-left: 25px;
      }
      .current-period-badge {
        background-color: white;
        color: $colorLightPurple;
        font-size: 14px;
        font-weight: 500;
        margin-left: 2%;
      }
    }
    .utility-bility-button {
      .purple-btn {
        padding: 7px 11px !important;
        // padding-left: 2% !important;
        // padding-right: 2% !important; // p{
        //     font-size: 12px!important;
        // }
        .btn-text {
          font-size: 14px;
        }
      }
      .view-utility-go-back {
        padding-left: 3px;
        padding-right: 3px;
      }
    }
    .cost-allocation-section {
      .utility-cost-allocation-subheading {
        font-size: 14px;
        line-height: 20px;
        color: $colorPurple1;
      }
    }
    h5.utility-cost-allocation {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }
    .utility-cost-allocation {
      border-spacing: 0px 8px !important;
      border-collapse: separate !important;
      .utility-cost-head {
        td {
          border: none !important;
          box-shadow: none !important;
          padding-right: 0px;
          padding-left: 0px;
          padding-bottom: 0px;
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
          color: $colorLightPurple;
        }
        .cp-label-cost {
          padding-left: 2.3% !important;
          width: 21%;
          .multiselect-container {
            .search-wrapper {
              width: 90% !important;
            }
          }
        }
        .cp-label-amount {
          width: 13%;
          padding-right: 1.8%;
        }
        .cp-label-key {
          width: 19.5%;
          display: block !important;
          margin-left: 5px;
        }
        .cp-label-meter {
          width: 11.5%;
        }
      }
      tbody {
        tr.utility-cost-row {
          background-color: white !important;
          .form-control {
            height: 36px;
          }
          td {
            font-size: 14px;
            color: $colorBlack;
            padding-top: 16px;
            padding-bottom: 16px;
            .multiselect-container {
              .search-wrapper {
                background: #ffffff;
                /* Oceanic Purple/200 */
                border: 1px solid #d5dce6;
                box-sizing: border-box;
                /* Card */
                box-shadow: none !important;// 0px 1px 2px rgba(14, 14, 29, 0.06);
                border-radius: 4px;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                overflow-x: auto;
                // white-space: nowrap !important;
                // overflow: hidden !important;
                // text-overflow: ellipsis !important;
                color: $colorDeepPurple; // padding: 3px 11px !important;
                &:focus-within {
                  border: 1px solid $purpleTheme !important;
                  outline: none !important;
                }
              }
              .optionListContainer {
                .optionContainer {
                  li {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    color: $colorBlack;
                    &:hover {
                      // background: $hover-color !important;
                    }
                  }
                }
              }
              .chip {
                margin: 0px 4px 0px 0px;
                padding: 3px 8px;
                // background: $hover-color !important;
                border-radius: 4px !important;
                // width: 150px;
                // overflow: hidden;
                // text-overflow: ellipsis;
                // white-space: nowrap;
              }
              .icon_cancel {
                color: $colorLightPurple;
                font-size: 12px !important;
              }
            }
            padding-right: 0px;
            padding-left: 0px;
            .utitlity-add-cost-button {
              width: 90%;
              margin-bottom: 12px;
              color: #252563;
            }
          }
          td.ub-cost-type {
            padding-left: 2.3% !important;
            width: 21%;
            .multiselect-container {
              .search-wrapper {
                width: 90% !important;
              }
            }
          }
          td.ub-amount {
            padding-right: 2.6%;
            width: 13%;
            input {
              width: 128px;
              font-size: 14px;
              color: $colorBlack;
            }
          }
          td.ub-allocation-key {
            background-color: transparent !important;
            width: 19.5%;
            .search-wrapper {
              width: 90% !important;
              background: #ffffff;
              /* Oceanic Purple/200 */
              border: 1px solid #d5dce6;
              box-sizing: border-box;
              /* Card */
              box-shadow: none !important;// 0px 1px 2px rgba(14, 14, 29, 0.06);
              border-radius: 4px;
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              // white-space: nowrap !important;
              // overflow: hidden !important;
              // text-overflow: ellipsis !important;
              color: $colorDeepPurple; // padding: 3px 11px !important;
              &:focus-within {
                border: 1px solid $purpleTheme !important;
                outline: none !important;
              }
            }
          }
          td.ub-meter-data {
            width: 12.4%;
            padding-right: 0.3%;
            .mannual-btn {
              margin-left: 0px;
            }
            .Available {
              color: $colorSuccess;
            }
            .Notavailable {
              color: $colorRed;
            }
            .nichtverfügbar {
              color: $colorRed;
            }
            .Someavailable {
              color: $colorWarning;
            }
            .Notrequired {
              color: $sideNavTextColor;
            }
            .teilweiseverfügbar {
              color: #d97706;
            }
            span {
              margin-left: 6%;
              font-size: 12px;
              font-weight: 500;
              line-height: 20px;
            }
          }
          td.ub-receipt {
            // width: 29%;
            // width: 130px !important;
            // width: 30% !important;
            max-width: 300px !important;
            padding: 0px 10px 0px 0px;
            .multiselect-container._3vt7_Mh4hRCFbp__dFqBCI {
              width: 220px !important;
              // height:30px!important;
              .search-wrapper {
                width: 90% !important;
                // width: 170px !important;
                width: 287px !important;
                //    height: 20px;
                overflow: hidden;
                background: #ffffff;
                /* Oceanic Purple/200 */
                border: 1px solid #d5dce6;
                box-sizing: border-box;
                /* Card */
                box-shadow: none !important; // 0px 1px 2px rgba(14, 14, 29, 0.06);
                border-radius: 4px;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                overflow-x: auto;
                // white-space: nowrap !important;
                // overflow: hidden !important;
                // text-overflow: ellipsis !important;
                color: $colorDeepPurple; // padding: 3px 11px !important;
                &:focus-within {
                  border: 1px solid $purpleTheme !important;
                  outline: none !important;
                }
                input {
                  width: 20px;
                  overflow: hidden !important;
                }
              }
              .optionListContainer {
                width: 300px !important;
                box-shadow: 0px 8px 16px rgba(14, 14, 29, 0.1), 0px 1px 2px rgba(14, 14, 29, 0.06);
                border-radius: 4px;
                table {
                  width: 100%;
                  thead {
                    th:first-child {
                      padding-left: 12px;
                    }
                    th {
                      color: $colorLightPurple;
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 20px;
                      letter-spacing: 0em;
                      text-align: left;
                      text-transform: capitalize;
                      background: white;
                      border: none;
                    }
                  }
                  tbody {
                    tr {
                      td {
                        font-size: 14px !important;
                        font-weight: 400;
                        line-height: 20px;
                      }

                      td:nth-child(3) {
                        .checkbox[type='checkbox']:checked {
                          & & {
                            // background: $hover-color;
                          }
                        }
                        .checkbox {
                          -webkit-appearance: none;
                          display: flex;
                          float: right;
                          margin: 0px;
                          font-size: 20px !important;
                        }
                        .checkbox[type='checkbox']:after {
                          position: relative !important;
                          display: block;
                          width: 16px;
                          height: 12px;
                          content: '' !important;
                          background-image: url('../../../../../assets/images/module/general/Check.svg') !important;
                          background-repeat: no-repeat;
                          background-position: center;
                        }
                        .checkbox[type='checkbox']:checked:after {
                          filter: invert(32%) sepia(75%) saturate(7390%) hue-rotate(233deg) brightness(101%)
                            contrast(101%);
                        }
                      }
                    }
                    tr.bgSelected {
                      background-color: rgb(242, 242, 252) !important;
                    }
                    td:first-child {
                      // text-align:left;
                      padding-left: 12px;
                      color: $colorBlack;
                    }
                    td:nth-child(2) {
                      color: $colorDeepPurple;
                    }
                    tr:hover {
                      // background: $hover-color !important;
                    }
                  }
                }
                .optionContainer {
                  li {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    color: $colorBlack;
                    &:hover {
                      // background: $hover-color !important;
                    }
                  }
                }
              }
              .chip {
                margin: 0px 4px 0px 0px;
                padding: 3px 8px;
                // background: $hover-color !important;
                border-radius: 4px !important;
                // width: 150px;
                // overflow: hidden;
                // text-overflow: ellipsis;
                // white-space: nowrap;
              }
              .icon_cancel {
                color: $colorLightPurple;
                font-size: 12px !important;
              }
            }

            .utitlity-add-cost-button {
              width: 95%;
              margin-bottom: 12px;
              color: #252563;
            }
          }
          .empty {
            background-color: #f7f7fa;
            // padding-left: 1.8%;
            padding-left: 10px;
            .img {
              height: 20px;
              width: 16px;
            }
          }
        }
      }
    }
    .utility-cost-head {
    }
    .utility-cost-row {
    }
  }
}

@media only screen and (max-width:1024px) {
  .view-utility-billing {
    // width:100vw;
    margin-top: -8px;
    .heating-ub-cost-type {
      padding-left: 24px !important;
      min-width: 110px !important;
    }
    ._3vLmCG3bB3CM2hhAiQX1tN {
      padding: 0px 6px !important;
    }
    .form-control-utility {
      outline: none;
      line-height: 1.5em;
      padding-left: 0.8rem;
      padding-right: 0.8rem;
      background: #fff;
      border: 1px solid #d5dce6;
      //box-shadow: inset 0 2px 0 0 #f2f5f8;
      border-radius: 4px;
      padding-bottom: 6px;
      padding-left: 12.8px;
      padding-right: 12.8px;
      padding-top: 6px;
      height: 36px !important;
      //width: 90% !important;
    }
    ._2iA8p44d0WZ-WqRBGcAuEV {
      height: 38px !important;
    }
    .form-control {
      width: 100% !important;
    }
    // .utilitiy--divider {
    // }
    .heading-right-content {
      width: 24.5%;
    }
    .view-utility-divider {
      margin-bottom: 26px !important;
    }
    .profile-heading {
      margin-bottom: -3px;
      .heading-text-wrapper {
        .heading-icon {
          margin-top: 2%;
          cursor: pointer;
        }
      }
      .utility-subheading {
        font-size: 14px;
        font-weight: 500;
        color: $colorPurple1;
        margin-left: 25px;
      }
      .current-period-badge {
        background-color: white;
        color: $colorLightPurple;
        font-size: 14px;
        font-weight: 500;
        margin-left: 2%;
      }
    }
    .utility-bility-button {
      .purple-btn {
        padding: 7px 11px !important;
        // padding-left: 2% !important;
        // padding-right: 2% !important; // p{
        //     font-size: 12px!important;
        // }
      }
      .view-utility-go-back {
        padding-left: 3px;
        padding-right: 3px;
      }
    }
    .cost-allocation-section {
      .utility-cost-allocation-subheading {
        font-size: 14px;
        line-height: 20px;
        color: $colorPurple1;
      }
    }
    h5.utility-cost-allocation {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }
    .utility-cost-allocation {
      border-spacing: 0px 8px !important;
      border-collapse: separate !important;
      .utility-cost-head {
        td {
          border: none !important;
          box-shadow: none !important;
          padding-right: 0px;
          padding-left: 0px;
          padding-bottom: 0px;
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
          color: $colorLightPurple;
        }
        .cp-label-cost {
          padding-left: 2.3% !important;
          width: 19%;
        }
        .cp-label-amount {
          width: 13%;
          padding-right: 1.8%;
        }
        .cp-label-key {
          width: 19.5%;
          display: block !important;
          margin-left: 5px;
        }
        .cp-label-meter {
          width: 11.5%;
        }
      }
      tbody {
        tr.utility-cost-row {
          background-color: white !important;
          .form-control {
            height: 36px;
          }
          td {
            font-size: 14px;
            color: $colorBlack;
            padding-top: 16px;
            padding-bottom: 16px;

            .multiselect-container._3vt7_Mh4hRCFbp__dFqBCI {
              width: 110px !important;
              // width: 242px !important;
              .search-wrapper {
                // width: 120px !important;
                width: 110px !important;
                background: #ffffff;
                /* Oceanic Purple/200 */
                border: 1px solid #d5dce6;
                box-sizing: border-box;
                /* Card */
                box-shadow: none !important;// 0px 1px 2px rgba(14, 14, 29, 0.06);
                border-radius: 4px;
                font-weight: normal;
                font-size: 14px;
                // white-space: nowrap !important;
                // overflow: hidden !important;
                // text-overflow: ellipsis !important;
                line-height: 20px;
                color: $colorDeepPurple; // padding: 3px 11px !important;
                &:focus-within {
                  border: 1px solid $purpleTheme !important;
                  outline: none !important;
                }
              }
              .optionListContainer {
                // width: 120px !important;
                width: 135px !important;
                margin-left: -6px !important;
                // width: 238px !important;
                .optionContainer {
                  li {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    color: $colorBlack;
                    &:hover {
                      // background: $hover-color !important;
                    }
                  }
                }
              }
              .chip {
                margin: 0px 4px 0px 0px;
                padding: 3px 8px;
                // background: $hover-color !important;
                border-radius: 4px !important;
                // width: 150px;
                // overflow: hidden;
                // text-overflow: ellipsis;
                // white-space: nowrap;
              }
              .icon_cancel {
                color: $colorLightPurple;
                font-size: 12px !important;
              }
            }
            padding-right: 0px;
            padding-left: 0px;
            .utitlity-add-cost-button {
              width: 90%;
              margin-bottom: 12px;
              color: #252563;
            }
          }
          td.ub-cost-type {
            padding-left: 1% !important;
            width: 19%;
            .optionListContainer {
              width: 135px !important;
              margin-left: -6px !important;
            }
          }
          td.ub-amount {
            padding-right: 2%;
            width: 11%;
            input {
              width: 100px;
              font-size: 14px;
              color: $colorBlack;
            }
          }
          td.ub-allocation-key {
            background-color: transparent !important;
            width: 15.5%;
            .search-wrapper {
              background: #ffffff;
              /* Oceanic Purple/200 */
              border: 1px solid #d5dce6;
              box-sizing: border-box;
              /* Card */
              box-shadow: none !important;// 0px 1px 2px rgba(14, 14, 29, 0.06);
              border-radius: 4px;
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              // white-space: nowrap !important;
              // overflow: hidden !important;
              // text-overflow: ellipsis !important;
              color: $colorDeepPurple; // padding: 3px 11px !important;
              &:focus-within {
                border: 1px solid $purpleTheme !important;
                outline: none !important;
              }
              .optionListContainer {
                width: 135px !important;
                margin-left: -6px !important;
              }
            }
          }
          td.ub-meter-data {
            width: 16.4%;
            padding-right: 1.3%;
            .Available {
              color: $colorSuccess;
            }
            .Notavailable {
              color: $colorRed;
            }
            .nichtverfügbar {
              color: $colorRed;
            }
            .Someavailable {
              color: $colorWarning;
            }
            .Notrequired {
              color: $sideNavTextColor;
            }
            .teilweiseverfügbar {
              color: #d97706;
            }
            span {
              margin-left: 6%;
              font-size: 12px;
              font-weight: 500;
              line-height: 20px;
            }
          }
          td.ub-receipt {
            width: 130px !important;
            .utitlity-add-cost-button {
              width: 95%;
              margin-bottom: 12px;
              color: #252563;
            }
            .multiselect-container._3vt7_Mh4hRCFbp__dFqBCI .search-wrapper {
              width: 90% !important;
              width: 187px !important;
            }

            .optionListContainer {
              width: 184px !important;
            }
          }

          .heating-ub-receipt.multiselect-page3 {
            width: 246px !important;
            max-width: 100px !important;
          }
          .heating-ub-receipt {
            // .multiselect-page3 {
            //   max-width: 300px !important;
            //   width: 300px !important;
            //   padding-right: 10px;
            // }

            .multiselect-container._3vt7_Mh4hRCFbp__dFqBCI .search-wrapper {
              // width: 90% !important;
              width: 235px !important;
            }

            .optionListContainer {
              width: 233px !important;
            }
          }
          .empty {
            background-color: #f7f7fa;
            // padding-left: 1%;
            padding-left: 10px;
            .img {
              height: 20px;
              width: 16px;
            }
          }
        }
      }
    }
    // .utility-cost-head {}
    // .utility-cost-row {}
  }
}

// @media only screen and(max-width:2560px) {
//   .view-utility-billing {
//     tbody {
//       tr.utility-cost-row {
//         background-color: white !important;
//       }
//     }
//   }
// }

.cost-allocation-section {
  .heating-cost-allocation {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0e0e1d;
    margin: 12px 0px;
  }
  .heating-cost-switch {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #0e0e1d;
  }
  .heating-cost-not-available {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #e8193c;
    margin-left: 5px;
  }
  .heating-cost-not-available.calculated {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #059266 !important;
    margin-left: 5px;
  }
  .calculation-status-div {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    align-items: center;
  }
  .calculation-status {
    margin-right: 10px;
    margin-top: 3px;
  }
}

.heating-cost-page {
  .heating-ub-cost-type {
    //width: 40% !important;
    min-width: 110px !important;
    .form-control-utility {
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      color: #0e0e1d;
      padding-top: 8px !important;
      padding-bottom: 8px !important;
      //width: 40% !important;
      // overflow: auto;
      // white-space: nowrap;
      // text-overflow: ellipsis;
      /* max-width: 90%; */
      // overflow: hidden;
      // overflow: auto;
    }
  }
  .heating-ub-amount {
    min-width: 110px !important;
    
    .form-control {
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: right;
      color: #727293;
    }
  }
  .heating-ub-allocation-key {
    .multiselect-container {
    }
    .form-control-utility {
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      color: #0e0e1d;
      padding-top: 8px !important;
      padding-bottom: 8px !important;

      // white-space: nowrap;
      // text-overflow: ellipsis;
      // max-width: 90%;
      // overflow: hidden;
      // overflow: auto;
    }
  }
  .heating-ub-meter-data {
    font-family: Inter;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: lowercase;
    .available {
      > span {
        color: #059266;
        margin-left: 5px;
      }
    }
    // .verfügbar {
    //   > span {
    //     color: #d97706 !important;
    //   }
    // }
    .Notavailable {
      > span {
        color: #e8193c;
        margin-left: 5px;
      }
    }
    .nichtverfügbar {
      > span {
        color: $colorRed;
      }
    }
    .Someavailable {
      > span {
        color: #d97706;
        margin-left: 5px;
      }
    }
    .Notrequired {
      > span {
        color: #727293;
        margin-left: 5px;
      }
    }
  }
}

.cp_costtypename {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
  text-align: left;
  font-size: 14px;
}

.heading-container.profile-heading.tenant-head.utility-billing-head {
  .color-btn.purple-btn.btn-secondary.disabled {
    display: none;
  }
}

.heating-ub-allocation-key {
  .multiselect-container {
    .searchBox {
      display: none;
    }
    .chip {
      background-color: #ffffff !important;
      background: #ffffff !important;
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.heating-ub-cost-type {
  min-width: 110px !important;
  .form-control-utility {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    //width: 89%;
    text-align: left;
    font-size: 14px;
  }
}

.rental-income-payment-column-not-paid{
  color: #E8193C !important;
  text-align: center;
}

.rental-income-payment-column-not-paid:focus{
  color: #E8193C !important;
}

.rental-income-payment-column-full-paid{
  color: #059266 !important;
  text-align: center;
}

.rental-income-payment-column-full-paid:focus{
  color: #059266 !important;
}

.rental-income-payment-column-partial-paid{
  color: #D97706 !important;
  text-align: center;
}

.rental-income-payment-column-partial-paid:focus{
  color: #D97706 !important;
}

.rental-income-payment-column-not-paid-modal{
  color: #E8193C !important;
}

.rental-income-payment-column-not-paid-modal:focus{
  color: #E8193C !important;
}

.rental-income-payment-column-full-paid-modal{
  color: #059266 !important;
}

.rental-income-payment-column-full-paid-modal:focus{
  color: #059266 !important;
}

.rental-income-payment-column-partial-paid-modal{
  color: #D97706 !important;
}

.rental-income-payment-column-partial-paid-modal:focus{
  color: #D97706 !important;
}
