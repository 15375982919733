.app-sidebar {
  position: fixed;
  z-index: 999999;
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: $sidebarWidth;
  background: $sidenavBgColor;
  display: flex;
  flex-direction: column;

  &.has-alert {
    top: 48px;
  }

  > nav {
    flex: 1 1;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0px;
    }
  }
}

.site-logo-bar {
  overflow: hidden;
  background: $sidenavBgColor;

  .navbar-brand {
    width: 100%;
    padding: 26px 21px;
    line-height: 48px;
    color: white;
    font-weight: bold;
    margin-right: 0;

    .keyboardActive &:focus {
      outline: none;
      box-shadow: inset 0px 0px 0px 2px $activeNavBorderColor !important;
    }

    > img {
      // max-height: 30px;
      // max-width: 30px;
      max-height: 78px;
      max-width: 156px;
      display: inline-block;
    }

    .logo-text {
      margin-left: 8px;
      opacity: 1;
      vertical-align: middle;

      img {
        max-height: 40px;
        max-width: 50px;
      }
    }
  }
}

// Collaped Side Menu
@media screen and (min-width: $breakPointSm) {
  .side-menu-collapsed {
    .site-logo-bar {
      .navbar-brand {
        padding: 0 11px;

        .logo-text {
          opacity: 0;
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) and (max-width: 1260px) {
  .nonempty-menu {
    height: 91% !important;
  }
  .emptystate-menu {
    height: 74% !important;
  }
}
@media screen and (min-width: 1270) and (max-width: 1430px) {
  .nonempty-menu {
    height: 84% !important;
  }
  .emptystate-menu {
    height: 50% !important;
    background-color: pink;
  }
}
@media screen and (min-width: 2560px) {
  .nonempty-menu {
    height: 91% !important;
  }
  .emptystate-menu {
    height: 77% !important;
  }
}
@media screen and (min-width: 1440px) {
  .nonempty-menu {
    height: 91% !important;
  }
  .emptystate-menu {
    height: 77% !important;
  }
}
#main-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 87%;
  position: relative;
  // .bottom-items-nav {
  //   position: absolute;
  //   bottom: 5px;
  //}
  .logout-profile-btn {
    border: none !important;
    box-shadow: none !important;
    background: none !important;
    opacity: 1;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #727293;
  }
  .nav-item {
    .logout-profile-btn-main {
      margin-left: 13px !important;
      margin-top: 6px;
    }
  }
  .logout-profile-dropdown {
    cursor: pointer !important;
    .card-value {
      cursor: pointer !important;
    }
  }

  .nav-item {
    position: relative;
    height: 42px;

    &.active {
      > a {
        border-color: $activeNavBorderColor;
      }
    }

    &.has-submenu {
      ul {
        list-style: none;
        padding: 0;
        height: 0;
        overflow: hidden;
        background: darken($sidenavBgHover, 4%);

        .nav-item {
          background: darken($sidenavBgHover, 4%);

          .keyboardActive &:focus,
          &:hover {
            a {
              background: darken($sidenavBgHover, 6%);
            }
          }

          a {
            padding-left: 45px;

            &.active {
              background: darken($sidenavBgHover, 6%);
            }

            .keyboardActive &:focus {
              outline: none;
              box-shadow: inset 0px 0px 0px 2px $activeNavBorderColor;
            }
          }
        }
      }

      &.open {
        ul {
          height: auto;
        }
      }
    }

    &.submenu.open {
      .menu-expand-icon {
        transform: rotate(90deg);
      }
    }

    > a {
      display: flex;
      width: 100%;
      text-decoration: none;
      // padding: 14px 20px;
      //changed
      padding: 12px 24px;
      color: $sideNavTextColor;
      border-color: transparent;
      position: absolute;
      top: 45%;
      bottom: 0;
      border-top: 3px solid white;
      border-bottom: 3px solid white;
      border-left: 3px solid white;
      &.active,
      &:hover {
        background: $sidenavBgHover;
        //changed
        color: $sideNavTextColorHover;
        > .side-nav-icon {
          filter: invert(32%) sepia(55%) saturate(7290%) hue-rotate(233deg) brightness(101%) contrast(101%);
        }
      }

      &:focus {
        outline: none;
      }

      // .keyboardActive &:focus {
      //    box-shadow: inset 0px 0px 0px 2px $activeNavBorderColor !important;

      // }

      &.active {
        // box-shadow: 0 8px 6px -6px black;
        // box-shadow: inset  3px 0px $activeNavBorderColor;
        //  background-image: url('../../../assets/images/module/general/Indicator.svg') !important;
        //   background-repeat: no-repeat;
        //   background-size: contain;
        border-top: 3px solid white;
        border-bottom: 3px solid white;
        border-left: 3px solid $activeNavBorderColor;

        > .side-nav-icon {
          filter: invert(32%) sepia(55%) saturate(7290%) hue-rotate(233deg) brightness(101%) contrast(101%);
        }
        // invert(30%) sepia(64%) saturate(4128%) hue-rotate(233deg) brightness(104%) contrast(102%)
      }

      > .arrow-icon {
        margin-top: 1% !important;
      }
    }
    &:last-child {
      > a {
        &.active,
        &:hover {
          > .side-nav-icon {
            filter: none;
          }
        }
      }
    }

    .nav-item-label {
      opacity: 1;
      vertical-align: middle;
      //added
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
    }

    .badge {
      opacity: 1;
      vertical-align: middle;
      margin-left: auto;
    }

    &.nav-item-spacer {
      flex: 1;
    }

    .side-nav-icon {
      margin-right: 10px;
      vertical-align: middle;
      position: relative;
      top: 0px;
      left: 0px;
      margin-left: -2px;
      // width: 1.25em;
    }
  }

  li.separator {
    border-top: 1px solid $sideNavSeparatorColor;
    margin: 10px 20px;
  }

  .menu-expand-icon {
    margin-right: 0;
    line-height: 20px;
    font-size: 11px;
    position: absolute;
    right: 20px;
    top: 16px;
    transform: rotate(0deg);
  }

  .badge {
    background: $activeNavBorderColor;
  }
}

// Collapsed Styles
// @media screen and (min-width: $breakPointSm) {
//   .app:not(.side-menu-collapsed) {
//     .app-sidebar {
//       > nav {
//         flex: 1 1;
//         overflow-y: auto;
//         height: 100%;
//       }
//     }
//   }
//   .side-menu-collapsed {
//     .app-sidebar {
//       width: 50px;

//       .site-logo {
//         padding: 5px;

//         img {
//           width: 100%;
//         }
//       }
//     }

//     ul#main-menu {
//       li.nav-item {
//         position: relative;
//         width: 50px;

//         a {
//           padding: 10px 12px;
//           text-align: center;

//           i {
//             padding-right: 0px;
//             left: 3px;
//           }
//           .side-nav-icon {
//             // font-size: 18px;
//             margin-right: 0px;
//             margin-left: 3px;
//           }
//           .nav-item-label,
//           .badge,
//           .menu-expand-icon {
//             display: none;
//           }
//         }

//         &.has-submenu {
//           > .nav-submenu {
//             margin-left: -20px;
//             height: auto;
//             display: none;
//             z-index: 999;

//             li.nav-item {
//               width: 240px;

//               .nav-item-label {
//                 opacity: 1;
//                 display: block;
//               }

//               .side-nav-icon {
//                 display: none;
//               }

//               a {
//                 padding-left: 20px;
//                 border-color: transparent;
//                 text-align: left;
//               }
//             }
//           }

//           .keyboardActive &:focus,
//           &:hover {
//             .nav-submenu {
//               position: absolute;
//               top: 0;
//               left: 50px;
//               margin-left: 0;
//               display: inline;
//               max-height: 1000px;
//             }
//           }
//         }
//       }
//       li.separator {
//         margin: 10px 15px;
//       }
//     }
//   }
// }

@media screen and (max-width: $breakPointSm) {
  .app-sidebar {
    transform: translate(-100%);
    transition: all 0.3s ease;
  }

  .sidebar-overlay {
    background: $sidebarMobileOverlay;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
  }
  // .logout-profile-btn-main {
  //   margin-left: 13px !important;
  //   margin-top: -4px;
  // }
  .logout-profile-dropdown {
    // position: absolute;
    z-index: 99999;
  }
  .logout-profile-btn-main {
    margin-left: 13px !important;
    margin-top: 0px !important;
  }

  .logout-profile-btn {
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* align-content: center; */
    align-items: center;
  }

  .side-menu-collapsed {
    .app-sidebar {
      transform: translate(0%);

      > nav {
        overflow-y: auto;
      }
    }

    // Stop app from scrolling when scrolling through app-sidebar
    overflow: hidden;
    height: 100vh;
  }
}
